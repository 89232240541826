import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { CompetenceProfileContext } from '../CompetenceProfile'
import Education from './education/Education'
import Survey from './survey/Survey'

const QualEduHub = () => {
  const { search } = useLocation()
  const type = search.split('=')[1]

  const { selectedSurveyID, selectedEducation, selectedUncompletedSurveyID } =
    useContext(CompetenceProfileContext)

  return (
    <>
      {type === 'qualification'
        ? selectedSurveyID && <Survey />
        : selectedEducation && <Education />}
    </>
  )
}

export default QualEduHub
