import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import PageTitleSpecial from '../../../components/pageTitle/PageTitleSpecial'
import BreadCrumbs from '../../../utils/BreadCrumbs'
import RequirementQuestion from '../requirementQuestion/RequirementQuestion'
import { SubQualificationContext } from '../subQualification/SubQualification'
import AddArea from './components/AddArea'
import AddRequirement from './components/AddRequirement'
import AreasTable from './components/AreasTable'
import Delete from './components/Delete'
import EditArea from './components/EditArea'
import EditRequirement from './components/EditRequirement'
import { AreasStyle } from './Style'
import { useTranslation } from 'react-i18next';

export const AreaContext = createContext(null)

const QualificationAreas = () => {
  const { selectedSubQualification: subQualification } = useContext(
    SubQualificationContext
  )

  const [pageInfo, setPageInfo] = useState({
    name: '',
    id: Number(),
    description: '',
  })

  const [areaList, setAreaList] = useState([])
  const [selectedArea, setSelectedArea] = useState({})
  const [selectedRequirement, setSelectedRequirement] = useState(
    JSON.parse(localStorage.getItem('selectedRequirement'))
  )

  const [modalAddIsVisible, dispatchModalAddIsVisible] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'area':
          if (action.current === false) {
            return { ...state, area: true }
          }
          if (action.current === true) {
            return { ...state, area: false }
          }
          break

        case 'knowledge':
          if (action.current === false) {
            return { ...state, knowledge: true }
          }
          if (action.current === true) {
            return { ...state, knowledge: false }
          }
          break
        case 'skill':
          if (action.current === false) {
            return { ...state, skill: true }
          }
          if (action.current === true) {
            return { ...state, skill: false }
          }
          break
        case 'responsibilityAndIndependence':
          if (action.current === false) {
            return { ...state, responsibilityAndIndependence: true }
          }
          if (action.current === true) {
            return { ...state, responsibilityAndIndependence: false }
          }
          break
        default:
          return state
      }
    },
    {
      area: false,
      knowledge: false,
      Skill: false,
      responsibilityAndIndependence: false,
    }
  )
  
  const [modalEditIsVisible, dispatchModalEditIsVisible] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'area':
          if (action.current === false) {
            return { ...state, area: true }
          }
          if (action.current === true) {
            return { ...state, area: false }
          }
          break

        case 'knowledge':
          if (action.current === false) {
            return { ...state, knowledge: true }
          }
          if (action.current === true) {
            return { ...state, knowledge: false }
          }
          break
        case 'skill':
          if (action.current === false) {
            return { ...state, skill: true }
          }
          if (action.current === true) {
            return { ...state, skill: false }
          }
          break
        case 'responsibilityAndIndependence':
          if (action.current === false) {
            return { ...state, responsibilityAndIndependence: true }
          }
          if (action.current === true) {
            return { ...state, responsibilityAndIndependence: false }
          }
          break
        default:
          return state
      }
    },
    {
      area: false,
      knowledge: false,
      Skill: false,
      responsibilityAndIndependence: false,
    }
  )
  
  const [modalDeleteIsVisible, setModalDeleteIsVisible] = useState(false)

  const initialContext = {
    subQualification,
    areaList,
    setAreaList,
    selectedArea,
    setSelectedArea,
    selectedRequirement,
    setSelectedRequirement,
    modalEditIsVisible,
    dispatchModalEditIsVisible,
    modalDeleteIsVisible,
    setModalDeleteIsVisible,
    modalAddIsVisible,
    dispatchModalAddIsVisible,
  }

  useEffect(() => {
    setPageInfo({
      id: subQualification.id,
      name: subQualification.name || subQualification.subName,
      description: subQualification.description,
    })
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'selectedRequirement',
      JSON.stringify(selectedRequirement)
    )
  }, [selectedRequirement])

  const { path } = useRouteMatch()
  const { t } = useTranslation();

  return (
    <AreaContext.Provider value={initialContext}>
      <Route exact path={path}>
        <BreadCrumbs custom />
        <AreasStyle>
          <PageTitleSpecial
            altCollapseTracker='qualificationArea-collapse'
            title={t('pageQualificationAreas.pageTitle')}
            lsTitleValue='TestAreaName'
            info={t('pageQualificationAreas.pageDescription.2')}
            className='page-title'
            description={
              <Fragment>
                <br />
                <p>{t('pageQualificationAreas.pageDescription.1')}</p>
                <br />
                <p>{t('pageQualificationAreas.pageDescription.3')}</p>
                <p>{t('pageQualificationAreas.pageDescription.4')}</p>
                <p>{t('pageQualificationAreas.pageDescription.5')}</p>
                <br/>
                <p>{t('pageQualificationAreas.pageDescription.0')}</p>
                <br />
                <p>{t('pageQualificationAreas.pageDescription.6')}</p>
                <br />
                <p>{t('pageQualificationAreas.pageDescription.7')}</p>
                <p>{t('pageQualificationAreas.pageDescription.8')}</p>
                <p>{t('pageQualificationAreas.pageDescription.9')}</p>
              </Fragment>
            }
          />
          <br />

          <AreasTable />

          {modalAddIsVisible.area && <AddArea />}
          {modalEditIsVisible.area && <EditArea />}
          {modalEditIsVisible.area && modalDeleteIsVisible && <Delete />}
          {modalAddIsVisible.knowledge && <AddRequirement />}
          {modalAddIsVisible.skill && <AddRequirement />}
          {modalAddIsVisible.responsibilityAndIndependence && (
            <AddRequirement />
          )}
          {modalEditIsVisible.knowledge && <EditRequirement />}
          {modalEditIsVisible.skill && <EditRequirement />}
          {modalEditIsVisible.responsibilityAndIndependence && (
            <EditRequirement />
          )}
          {modalEditIsVisible.knowledge && modalDeleteIsVisible && <Delete />}
          {modalEditIsVisible.skill && modalDeleteIsVisible && <Delete />}
          {modalEditIsVisible.responsibilityAndIndependence &&
            modalDeleteIsVisible && <Delete />}
        </AreasStyle>
      </Route>
      <Route path={`${path}/:qualificationArea`}>
        {selectedRequirement && <RequirementQuestion />}
      </Route>
    </AreaContext.Provider>
  )
}

export default QualificationAreas
