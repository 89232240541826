import React, { Fragment, useContext } from 'react'

import { Button, Delete } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import { deleteArea, deleteRequirement } from '../../functions'
import { AreaContext } from '../QualificationAreas'
import { useTranslation } from 'react-i18next'

const DeleteArea = () => {
  const {
    setModalDeleteIsVisible,
    selectedArea,
    dispatchModalEditIsVisible,
    modalEditIsVisible,
    areaList,
    setAreaList,
    selectedRequirement,
  } = useContext(AreaContext)
  const { knowledge, skill, responsibilityAndIndependence } = modalEditIsVisible
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal
        title={
          ((modalEditIsVisible.knowledge ||
            modalEditIsVisible.skill ||
            modalEditIsVisible.responsibilityAndIndependence) &&
            t('pageQualifications.modalDeleteRequirement.title1')) ||
          (modalEditIsVisible.area && t('pageQualifications.modalDeleteRequirement.title2'))
        }
        setState={setModalDeleteIsVisible}
      >
        <br />
        <br />
        {modalEditIsVisible.area && (
          <Fragment>
            <p>
              {t('pageQualifications.modalDeleteRequirement.confirmPromt1')}{' '}
              <span className="bold">{selectedArea.name}</span>?
            </p>
            <br />
            <p>
            {t('pageQualifications.modalDeleteRequirement.explanation1')}
            </p>
            <br />
          </Fragment>
        )}
        {(modalEditIsVisible.knowledge ||
          modalEditIsVisible.skill ||
          modalEditIsVisible.responsibilityAndIndependence) && (
          <Fragment>
            <p>
            {t('pageQualifications.modalDeleteRequirement.confirmPrompt2')}{' '}
              <span className="bold">{selectedRequirement.description}</span>?
            </p>
            <br />
            <p>
               {t('pageQualifications.modalDeleteRequirement.explanation2')}
            </p>
            <br />
          </Fragment>
        )}

        <p>{t('pageQualifications.modalDeleteRequirement.explanation3')}</p>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '36px',
            paddingBottom: '20px',
          }}
        >
          {modalEditIsVisible.area && (
            <Delete
              onClick={(e) =>
                deleteArea(e, {
                  dispatchModalEditIsVisible,
                  modalEditIsVisible,
                  setModalDeleteIsVisible,
                  areaList,
                  setAreaList,
                  selectedArea,
                })
              }
            >
            {t('buttons.deleteButton')}
            </Delete>
          )}
          {(modalEditIsVisible.knowledge ||
            modalEditIsVisible.skill ||
            modalEditIsVisible.responsibilityAndIndependence) && (
            <Delete
              onClick={(e) => {
                deleteRequirement(e, {
                  setModalDeleteIsVisible,
                  dispatchModalEditIsVisible,
                  knowledge,
                  skill,
                  responsibilityAndIndependence,
                  selectedRequirement,
                  areaList,
                  setAreaList,
                  selectedArea,
                })
              }}
            >
                {t('buttons.deleteButton')}
            </Delete>
          )}
          <Button
            type='submit'
            style={{ marginLeft: '10px' }}
            onClick={() => setModalDeleteIsVisible(false)}
          >
            {t('buttons.cancelButton')}
          </Button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default DeleteArea
