import { Grid } from "@mui/material";
import { useContext } from "react";
import PageTitleSpecial from "../../../../components/pageTitle/PageTitleSpecial";
import { QualificationWrapper } from "../../../../pages/catalog/subPages/style";
import { EducationModulesContext } from "../educationModules/EducationModules";
import BreadCrumbs from "../../../../utils/BreadCrumbs";
import { useTranslation } from 'react-i18next'

const SubQualification = ({ setSubQualMode }) => {
    const { t } = useTranslation()
    const { selectedSubQualification } = useContext(EducationModulesContext)
    const {
        description,
        id,
        name: subName,
        qualificationArea,
    } = selectedSubQualification

    return (
        <QualificationWrapper className='sub-page-wrapper'>
            <Grid container item xs={12}>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <BreadCrumbs custom />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    {/** -------------------------------- Qualification section -------------------------------- */}
                    <Grid container item xs={12} spacing={2} className='qualification'>
                        <Grid container item xs={12} mb={3}>
                            <PageTitleSpecial
                                className='qualification-title'
                                title={subName}
                                info={description}
                            />
                        </Grid>
                        <Grid item xs={12} py={1}>
                            <div className="mode-switch">
                                <h4>
                                    <span className="desc"
                                        onClick={() => setSubQualMode('descriptions')}
                                    >
                                       {t('educations.subEducations.subtitle1')}
                                    </span>
                                    <span>
                                        |
                                    </span>
                                    <span className="reqs"
                                        onClick={() => setSubQualMode('requirements')}
                                    >
                                        {t('educations.subEducations.subtitle2')}
                                    </span>
                                </h4>
                            </div>
                        </Grid>

                        {/** -------------------------------- Sub-qualification section -------------------------------- */}

                        <Grid container item xs={12} className='sub-qualifications'>
                            {/** Table Head */}
                            <Grid container item xs={12} className='table-head'>
                                <Grid item xs={12}>
                                    <h4>{t('educations.subEducations.subtitle3')}</h4>
                                </Grid>
                            </Grid>

                            {/** Table Body */}
                            <Grid container item xs={12} pt={2}>
                                <Grid
                                    key={id}
                                    container
                                    item
                                    xs={12}
                                    pt={2}
                                    className='table-body'
                                >
                                    {/* -------- Areas -------- */}
                                    {qualificationArea &&
                                        qualificationArea.map(
                                            ({ description, id, name: areaName }) => (
                                                <Grid
                                                    key={id}
                                                    container
                                                    item
                                                    xs={12}
                                                    className='areas'
                                                >
                                                    <Grid item xs={12}>
                                                        <Grid pt={2}>
                                                            <p className='area-title bold'>
                                                                {areaName}
                                                            </p>
                                                            <p className='area-body'>{description}</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </QualificationWrapper>
    )
}

export default SubQualification;