import { simpleUser } from './../../../utils/Amplify'
/**
 * Get all surveys by individualId.
 * @param individualId The individual id as a number.
 * @return Array of Objects [{"endDate": "YYYY-mm-dd", "id": 1,  "price": 100.50, "startDate": "YYYY-mm-dd"}].
 */
export const getQualificationSurvey = async ({ individualId }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/initiate/survey/get/all/${individualId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error)
  }
}

/**
 * Create qualification survey to individual.
 * @param individualId The individual id as a number.
 * @param qualificationId The qualification id  as a number.
 * @param startDate Start date in the format of 'YYYY-MM-DD' ex. '2022-01-27'.
 * @param price This is a float value ex '500.50'.
 */
export const addQualificationSurvey = async ({
  individualId,
  qualificationId,
  price,
  startDate,
  setAddSurveyModal,
}) => {
  console.log(individualId)
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/initiate/survey/individual/${individualId.individualId}/qualification/${qualificationId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
        body: JSON.stringify({
          price: price,
          startDate: startDate,
        }),
      }
    )
    const data = await response.json()
    setAddSurveyModal(false)
    return data
  } catch (error) {
    console.log(error)
  }
}

/**
 * Delete qualification survey.
 * @param individualSurveyId The survey id as a number.
 */
export const removeSurvey = async ({ individualSurveyId }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/initiate/delete/survey/${individualSurveyId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.log(error)
  }
}

/**
 * Create education to individual.
 * @param individualId The individual id as a number.
 * @param educicationId The qualification id  as a number.
 * @param startDate Start date in the format of 'YYYY-MM-DD' ex. '2022-01-27'.
 * @param price This is a float value ex '500.50'.
 */
export const addEducation = async ({
  individualId,
  educationId,
  price,
  startDate,
  setAddEducationModal,
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/initiate/survey/individual/${individualId.individualId}/education/${educationId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
        body: JSON.stringify({
          price: price,
          startDate: startDate,
        }),
      }
    )
    const data = await response.json()
    setAddEducationModal(false)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const submitSurvey = async ({ id, questions }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/submit/survey/${id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
        body: JSON.stringify(questions),
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error)
  }
}

export const editSurveyOrEducation = async ({
  id,
  editQualificationSurveyModal,
  editEducationModal,
  setEditQualificationSurveyModal,
  setEditEducationModal,
  isAttemptUpdate,
  startDate,
  price,
  attempts,
}) => {
  try {
    let body
    if (isAttemptUpdate) {
      body = JSON.stringify({
        attempts: attempts,
        startDate: startDate,
        isStarted: true,
      })
    } else {
      body = JSON.stringify({
        price,
        startDate: startDate,
      })
    }
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/initiate/survey/${id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
        body: body,
      }
    )
    const data = await response.json()
    if (editQualificationSurveyModal) {
      setEditQualificationSurveyModal(false)
    }
    if (editEducationModal) {
      setEditEducationModal(false)
    }
    return data
  } catch (error) {
    console.log(error)
  }
}

export const deleteSurveyOrEducation = async ({ id, setOngoing }) => {
  try {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/initiate/delete/survey/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    })
    setOngoing((prev) =>
      prev.filter((survey) => survey.id !== id).map((survey) => survey)
    )
  } catch (error) {
    console.log(error)
  }
}

export const getSurvey = async (id) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/initiate/survey/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (await simpleUser()).token,
    },
  }).then((res) => res.json())
}
