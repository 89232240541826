import styled from 'styled-components'

export const EducationsWrapper = styled.main`
  margin-top: 43px;
  &.wrapper-prs {
    min-height: 800px;
    margin-bottom: 50px;
  }
  .pre-title {
    margin-bottom: 16px;
  }  
  /* ---------- Modal ---------- */
  .modal {
    min-width: 550px;
    max-width: 800px;
    padding-left: 0px;
    margin-left: 0px;
    .left-column {
      width: 50%;

      .top {
        height: 78px;
      }
      .bottom {
        textarea {
          height: 270px;
        }
      }
      padding-bottom: 10px;
    }
    .right-column {
      width: 50%;
      padding-left: 20px;
      margin-left: 20px;
      border-left: 1px solid #cfcfd5;
      .select-qual__label {
        width: 200px;

        .react-select {
          width: 100%;
        }
      }
      .select-qualification {
        margin-top: 20px;
        padding-right: 5px;
      }
      .to-catalog-btn {
        margin-top: 20px;
      }
      .selected-qualification {
        margin-top: 35px;
      }
      .add-modal-btn {
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
        padding-bottom: 0;
      }
      .edit-modal-btn {
        display: flex;
        justify-content: center;
        padding-top: 36px;
        padding-bottom: 0;
      }
    }
    .modal-content {
      padding: 20px 20px;
    }
    .share {
      padding: 10px;
      min-height: 300px;
      nav {
        ul {
          display: flex;
          li:not(:first-child) {
            padding: 0 5px;
            margin: 0 5px;
            border-left: 1px solid grey;
          }
        }
      }
      .m-top-btm-20 {
        margin: 20px 0;
      }
      .flex {
        display: flex;
      }
      .align-button {
        align-items: flex-end;
      }
      .sb {
        justify-content: space-between;
      }
      .border-btm {
        border-bottom: 1px solid grey;
        padding-bottom: 20px;
      }
      .land .date {
        flex-direction: column;
      }
      .land {
        width: calc(50% - 30px);
      }
      .date {
        width: calc(50% - 30px);
      }
      .title {
        font-weight: bold;
      }
      .item,
      .title {
        border-bottom: 1px solid grey;
        padding: 3px 0;
      }
    }
    .un-publish-msg-wrapper {
      display: flex;
      flex-direction: column;
      > p {
        padding-top: 20px;
      }
      > div {
        display: flex;
        justify-content: flex-end;
      }
    }
    .area-table-container {
      align-items: flex-start;
      margin-bottom: 10px;
      padding: 5px 0;
      border-bottom: 1px solid #e0e0e0;
      > img {
        padding-top: 3px;
      }
    }
  }

  /* ---------- Table ---------- */
  .table {
    margin-top: 50px;
  }
  /* Table Head and Table Body */
  .table-head,
  .table-body {
    .name {
      width: 27.128712871%;
    }
    .refers-to-qualification {
      width: 27.128712871%;
    }
    .industry {
      width: 17.326732673%;
    }
    .language {
      width: 14.257425743%;
    }
    .ver {
      width: 4.95049505%;
    }
    .share {
      text-align: center;
      width: 4.455445545%;

      .react-select {
        width: 200px;
      }

      .active {
        outline: 2px solid yellow;
        border-radius: 100%;
      }
    }
    .edit {
      text-align: center;
      width: 4.455445545%;
    }
  }
  /* Table Head */
  .table-head {
    border-bottom: 1px solid #cfcfd5;
    padding: 5px 10px;
  }
  /* Table Body */
  .table-body > div {
    padding: 5px 10px;
  }
  /** colors evan table rows */
  .table-body > :nth-child(even) {
    background: #b8b8b824;
  }
  .btn-wrapper {
    position: relative;
    padding: 0;

    .add-btn {
      position: absolute;
      top: 15px;
      left: 0;
      background: #dbdbdf;
      color: #323145;
      font-size: 20px;
      width: 31px;
      height: 20;
      text-align: center;
      cursor: pointer;
      border: none;
    }
    .left-2 {
      left: 2px;
    }
  }
`
