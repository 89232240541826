import { EducationsWrapper } from './style'
import EducationTable from './components/EducationTable'
import PageTitle from '../../components/pageTitle/PageTitle'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import React, { createContext, useContext, useEffect, useState } from 'react'
import ModalAddEdit from './components/ModalAddEdit'
import { Context } from '../../utils/Context'
import Share from './components/Share'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import ModalConfirm from '../qualifications/qualification/components/ModalConfirm'
import EducationModules from './subPages/educationModules/EducationModules'
import { useTranslation } from 'react-i18next'
export const EducationContext = createContext(null)

const Educations = () => {
  const { t } = useTranslation()
  const {
    organisation: { organisationNumber, id: organisationId },
  } = useContext(Context)

  const location = useLocation()
  let { loadOldStates } = location.state || { loadOldStates: false }

  const [qualificationsList, setQualificationsList] = useState([]) // Store all qualifications
  const [educationsList, setEducationsList] = useState([]) // Store all educations
  const [selectedEducation, setSelectedEducation] = useState(
    JSON.parse(localStorage.getItem('educationsSelectedEducation')) || {}
  ) // To store the the selected education
  const [modalAddIsVisible, setModalAddIsVisible] = useState(
    loadOldStates
      ? JSON.parse(localStorage.getItem('educationsModalAddIsVisible'))
      : false
  )
  const [modalShareIsVisible, setModalShareIsVisible] = useState(false)
  const [modalEditIsVisible, setModalEditIsVisible] = useState(
    loadOldStates
      ? JSON.parse(localStorage.getItem('educationsModalEditIsVisible'))
      : false
  )
  const [modalDeleteIsVisible, setModalDeleteIsVisible] = useState(false)
  const [deleteOption, setDeleteOption] = useState(false)
  const [selectedCountryToRemove, setSelectedCountryToRemove] = useState({})
  const [modalConfirmIsVisible, setModalConfirmIsVisible] = useState(false)
  const [modalConfirmTempCountry, setModalConfirmTempCountry] = useState({})
  const { qualification: selectedQualification } = selectedEducation
  const { path } = useRouteMatch()

  useEffect(() => {
    localStorage.setItem(
      'educationsSelectedEducation',
      JSON.stringify(selectedEducation)
    )
  }, [selectedEducation])

  useEffect(() => {
    localStorage.setItem(
      'educationsSelectedQualification',
      JSON.stringify(selectedQualification)
    )
  }, [selectedQualification])

  useEffect(() => {
    localStorage.setItem(
      'educationsModalAddIsVisible',
      JSON.stringify(modalAddIsVisible)
    )
  }, [modalAddIsVisible])

  useEffect(() => {
    localStorage.setItem(
      'educationsModalEditIsVisible',
      JSON.stringify(modalEditIsVisible)
    )
  }, [modalEditIsVisible])

  useEffect(() => {
    location.state = undefined
    // eslint-disable-next-line
  }, [])

  const initialContext = {
    qualificationsList,
    setQualificationsList,
    educationsList,
    setEducationsList,
    selectedEducation,
    setSelectedEducation,
    selectedQualification,
    modalAddIsVisible,
    setModalAddIsVisible,
    modalEditIsVisible,
    setModalEditIsVisible,
    modalDeleteIsVisible,
    setModalDeleteIsVisible,
    deleteOption,
    setDeleteOption,
    selectedCountryToRemove,
    setSelectedCountryToRemove,
    modalShareIsVisible,
    setModalShareIsVisible,
    organisationNumber,
    organisationId,
    loadOldStates,
    modalConfirmTempCountry,
    setModalConfirmTempCountry,
    setModalConfirmIsVisible,
  }
  return (
    <EducationContext.Provider value={initialContext}>
      <Switch>
        <Route exact path={path}>
          <EducationsWrapper className='wrapper-prs'>
            <PageTitle title={t('educations.educationPage.title')}>
              <p>
                {t('educations.educationPage.info1')}
                {t('educations.educationPage.info2')}<br/>
                {t('educations.educationPage.info3')}
                {t('educations.educationPage.info4')}
              </p>
              <p>{t('educations.educationPage.info5')}<strong>{t('educations.educationPage.info6')}.</strong></p>
              <p>{t('educations.educationPage.info7')}</p>
              <p>
              {t('educations.educationPage.info8')}
              </p>
            </PageTitle>
            {/* Table of all education */}
            <EducationTable />
            {/* Modals only visible if needed */}
            {modalConfirmIsVisible && <ModalConfirm />}
            {(modalDeleteIsVisible && deleteOption === 'country' && (
              <ModalAddEdit option='country' />
            )) ||
              (modalDeleteIsVisible && <ModalAddEdit option='delete' />) ||
              (modalShareIsVisible && <Share option={'share'} />) ||
              (modalAddIsVisible && <ModalAddEdit option='add' />) ||
              (modalEditIsVisible && <ModalAddEdit option='edit' />)}
          </EducationsWrapper>
        </Route>
        <Route path={`${path}/:educationName`}>
          {selectedEducation && selectedQualification && <EducationModules />}
        </Route>
      </Switch>
    </EducationContext.Provider>
  )
}

export default Educations
