import { simpleUser } from './../../../utils/Amplify';
export const getQualifications = async (country, language, text) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/catalog/filter/search/${country}/language/${language}/input/${text}`,
      {
        method: 'GET',
       headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (await simpleUser()).token,
                  },
      }
    )

    return await res.json()
  } catch (error) {
    //console.error(error)
  }
}

export const getEducations = async (country, language, text) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/catalog/filter/search/education/${country}/language/${language}/input/${text}`,
      {
        method: 'GET',
       headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (await simpleUser()).token,
                  },
      }
    )

    return await res.json()
  } catch (error) {
    //console.error(error)
  }
}

export const addQualificationToProfessionalRoleFromCatalog = async (
  orgNr,
  proRoleId,
  qualificationId
) => {
  try {
    const option = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/${orgNr}/professionalrole/${proRoleId}/join/${qualificationId}`,
      option
    )
    return res
  } catch (error) {
    console.log(error)
  }
}

export const getProfessionalRoleQualifications = async (orgNr, roleId) => {
  try {
    const roles = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/${orgNr}/all/professionalrole`,
      {
        method:'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        }
      }
    ).then((data) => data.json())
    const filteredRole = roles.filter((role) => role.id === roleId)[0]

    return filteredRole.qualification
  } catch (err) {
    console.log(err)
  }
}
