import React, { Fragment, useContext, useState } from 'react'

import { Button } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import StyledLabel from '../../../../components/styledLabel/StyledLabel'
import { handleAddFormChange, handleAreaAddFormSubmit } from '../../functions'
import { SubQualificationContext } from '../SubQualification'
import { Wrapper } from './Style'
import { useTranslation } from 'react-i18next'

const AddAreaSub = () => {
  const {
    subQualificationsList,
    setSubQualificationsList,
    modalAddIsVisible,
    dispatchModalAddIsVisible,
    id,
    subQualificationId,
  } = useContext(SubQualificationContext)

  const { t } = useTranslation()
  const initializedAddFormData = {
    name: '',
    description: '',
    published: 0,
    modified: 1,
  }
  // State for the form inputs
  const [addFormData, setAddFormData] = useState(initializedAddFormData)

  return (
    <Fragment>
      <Modal
        title={t('competenceArea.addCompetenceArea')}
        dispatcher={{ modalAddIsVisible, dispatchModalAddIsVisible }}
      >
        <Wrapper>
          <form
            onSubmit={(e) =>
              handleAreaAddFormSubmit(e, {
                id,
                addFormData,
                subQualificationId,
                subQualificationsList,
                setSubQualificationsList,
                dispatchModalAddIsVisible,
              })
            }
          >
            <StyledLabel>
              <p>{t('competenceArea.competenceArea')} *</p>
              <input
                type='text'
                name='name'
                required='required'
                placeholder={t('competenceArea.competenceAreaPlaceholder')}
                onChange={(e) =>
                  handleAddFormChange(e, addFormData, setAddFormData)
                }
              />
            </StyledLabel>
            <StyledLabel>
              <br />
              <p>{t('competenceArea.description')} *</p>
              <textarea
                name='description'
                className='textarea'
                required='required'
                rows='5'
                placeholder={t('competenceArea.descriptionPlaceholder')}
                onChange={(e) =>
                  handleAddFormChange(e, addFormData, setAddFormData)
                }
              ></textarea>
            </StyledLabel>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '30px',
              }}
            >
              <Button type='Add'>{t('competenceArea.modal.buttonAdd')}</Button>
            </div>
          </form>
        </Wrapper>
      </Modal>
    </Fragment>
  )
}
export default AddAreaSub
