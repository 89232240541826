import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Delete } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import { simpleUser } from '../../../../utils/Amplify'
import { Context } from '../../../../utils/Context'
import { DeleteEmployerForm } from './style'

const DeleteEmployer = ({ setState }) => {
  const {
    employee,
    organisation,
    setOrganisation,
    individual,
    setIndividual,
    setMode,
  } = useContext(Context)
  const { t } = useTranslation()

  const handleSubmit = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/individual/${individual.id}/disconnect/employee/${employee.id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + (await simpleUser()).token,
          },
        }
      )

      setOrganisation()
      localStorage.removeItem('organisation')
      setIndividual({
        ...individual,
        organisationEmployee: null,
      })
      localStorage.setItem(
        'individual',
        JSON.stringify({
          ...individual,
          organisationEmployee: null,
        })
      )

      setMode('individual')

      setState(false)
    } catch (err) {
      console.log('handleSubmit had an error:', err)
      return
    }
  }

  return (
    <>
      {organisation && (
        <Modal
          closeOnClickOutside
          setState={setState}
          title='Ta bort arbetsgivare'
        >
          <DeleteEmployerForm
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <p>
              Är du säker på att du vill ta bort arbetsgivaren:
              <br />
              <br />
              <span className='bold'>{organisation.orgName}</span>
            </p>
            <div className='buttons'>
              <Delete>{t('buttons.deleteButton')}</Delete>
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  setState(false)
                }}
              >
                {t('extra.cancel')}
              </Button>
            </div>
          </DeleteEmployerForm>
        </Modal>
      )}
    </>
  )
}

export default DeleteEmployer
