import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

const ControlledRadioButtonsGroup = ({
  options,
  initialValue,
  selectOption,
  setSelectOption,
  row,
}) => {
  const handleChange = (event) => {
    setSelectOption(event.target.value)
  }

  return (
    <FormControl>
      {setSelectOption ? (
        <RadioGroup
          row={row}
          name='controlled-radio-buttons-group'
          value={selectOption}
          onChange={handleChange}
        >
          {options &&
            options.map(({ label, value }, i) => (
              <FormControlLabel
                key={i}
                value={value}
                control={<Radio />}
                label={label}
              />
            ))}
        </RadioGroup>
      ) : (
        <RadioGroup
          row={row}
          name='controlled-radio-buttons-group'
          defaultValue={options[0].value}
        >
          {options &&
            options.map(({ label, value }, i) => (
              <FormControlLabel
                key={i}
                value={value}
                control={<Radio />}
                label={label}
              />
            ))}
        </RadioGroup>
      )}
    </FormControl>
  )
}

export default ControlledRadioButtonsGroup
