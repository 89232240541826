import React, { useContext } from 'react'
import Modal from '../../../../components/modal/Modal'
import { QualificationContext } from '../Qualification'
import { Button } from '../../../../components/button/Button'
import { publishQualification } from '../../service/REST'
import { publishEducation } from '../../../educations/services/Crud'
import { EducationContext } from '../../../educations/Educations'
import { useTranslation } from 'react-i18next';
const ModalConfirm = () => {
  const { t } = useTranslation();
  const fromQualificationContext = useContext(QualificationContext)
  const fromEducationContext = useContext(EducationContext)

  const qualificationsContext =
    fromQualificationContext !== null
      ? {
          selectedQualification: fromQualificationContext.selectedQualification,
          setModalConfirmIsVisible:
            fromQualificationContext.setModalConfirmIsVisible,
          qualificationsList: fromQualificationContext.qualificationsList,
          setSelectedQualification:
            fromQualificationContext.setSelectedQualification,
          setQualificationsList: fromQualificationContext.setQualificationsList,
          modalConfirmTempCountry:
            fromQualificationContext.modalConfirmTempCountry,
        }
      : null

  const educationsContext =
    fromEducationContext !== null
      ? {
          setModalConfirmIsVisibleEducation:
            fromEducationContext.setModalConfirmIsVisible,
          selectedEducation: fromEducationContext.selectedEducation,
          educationsList: fromEducationContext.educationsList,
          setSelectedEducation: fromEducationContext.setSelectedEducation,
          setEducationsList: fromEducationContext.setEducationsList,
          modalConfirmTempCountry: fromEducationContext.modalConfirmTempCountry,
          setModalShareIsVisible: fromEducationContext.setModalShareIsVisible,
          setModalConfirmIsVisible:
            fromEducationContext.setModalConfirmIsVisible,
        }
      : null

  return (
    <Modal
      title={t('pageShareQualification.modalConfirm.title')}
      setState={
        fromQualificationContext !== null
          ? qualificationsContext.setModalConfirmIsVisible
          : educationsContext.setModalConfirmIsVisibleEducation
      }
    >
      <div className='modal-content-body'>
        <div className='message-body'>
          <p>
          {t('pageShareQualification.modalConfirm.confirmPublishQualificationMsg-1')}{' '}
            <span className='bold'>
              {fromQualificationContext !== null &&
                qualificationsContext.modalConfirmTempCountry.label}
              {fromEducationContext !== null &&
                educationsContext.modalConfirmTempCountry.label}
            </span>{' '}
            {t('pageShareQualification.modalConfirm.confirmPublishQualificationMsg-2')}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '36px',
            paddingBottom: '25px',
          }}
        >
          {fromEducationContext && (
            <Button
              onClick={(e) => {
                publishEducation(e, {
                  setModalShareIsVisible:
                    educationsContext.setModalShareIsVisible,
                  setModalConfirmIsVisibleEducation:
                    educationsContext.setModalConfirmIsVisible,
                  selectedEducation: educationsContext.selectedEducation,
                  modalConfirmTempCountry:
                    educationsContext.modalConfirmTempCountry,
                  educationsList: educationsContext.educationsList,
                  setSelectedEducation: educationsContext.setSelectedEducation,
                  setEducationsList: educationsContext.setEducationsList,
                  setModalConfirmIsVisible:
                    educationsContext.setModalConfirmIsVisible,
                })
              }}
            >
              {t('pageShareQualification.buttons.publishButton')}
            </Button>
          )}
          {fromQualificationContext && (
            <Button
              onClick={(e) =>
                publishQualification(e, {
                  selectedQualification:
                    qualificationsContext.selectedQualification,
                  qualificationsList: qualificationsContext.qualificationsList,
                  setSelectedQualification:
                    qualificationsContext.setSelectedQualification,
                  setQualificationsList:
                    qualificationsContext.setQualificationsList,
                  modalConfirmTempCountry:
                    qualificationsContext.modalConfirmTempCountry,
                  setModalConfirmIsVisible:
                    qualificationsContext.setModalConfirmIsVisible,
                })
              }
            >
              {t('pageShareQualification.buttons.publishButton')}
            </Button>
          )}
          <Button
            type='submit'
            style={{ marginLeft: '10px' }}
            onClick={() => {
              fromQualificationContext !== null
                ? qualificationsContext.setModalConfirmIsVisible(false)
                : educationsContext.setModalConfirmIsVisibleEducation(false)
            }}
          >
            {t('buttons.cancelButton')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirm
