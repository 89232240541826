import React, { useContext, useEffect, useRef, useState } from 'react'
import { t } from 'i18next'

import crossDark from '../../../../assets/img/cross-dark-alt.svg'
import {
  AddButton,
  Button,
  Delete,
  Secondary,
} from '../../../../components/button/Button'
import { handleEditRequirementQuestion } from '../../functions'
import Modal from '../../../../components/modal/Modal'
import StyledLabel from '../../../../components/styledLabel/StyledLabel'
import { RequirementQuestionContext } from '../RequirementQuestion'
import { Answer, AnswerWrapper, ButtonContainer, ModalWrapper } from '../Style'
import {
  handleAddAnswerChange,
  handleAddAnswerOption,
  handleRemoveAnswer,
} from '../../../../utils/helper'
import StyledSelect from '../../../../components/styledSelect/StyledSelect'
import {
  getOptionLabels,
  getQuestionType,
  handleQuestionTypeChange,
  handleSelectAnswerImg,
  handleSelectQuestionImg,
  isPreviewDisabled,
  useQuestionTypeOptions,
} from '../helper'

const EditRequirementQuestion = () => {
  const {
    setModalEditIsVisible,
    setModalDeleteIsVisible,
    setModalPreviewIsVisible,
    setPreviewQuestion,
    questionList,
    setQuestionList,
    selectedQuestionId,
  } = useContext(RequirementQuestionContext)

  const [currentIndex, setCurrentIndex] = useState(
    questionList.findIndex((question) => question.id === selectedQuestionId)
  )

  const [questionImageFile, setQuestionImageFile] = useState(null)

  const initializedEditFormData = {
    id: questionList[`${currentIndex}`]?.id,
    questionName: questionList[`${currentIndex}`]?.questionName,
    questionImageKey: questionList[`${currentIndex}`]?.questionImageKey,
    questionVideoUrl: questionList[`${currentIndex}`]?.questionVideoUrl,
    questionAnswer: questionList[`${currentIndex}`]?.questionAnswer,
    published: 0,
  }

  const [editFormData, setEditFormData] = useState(initializedEditFormData)
  const [questionType, setQuestionType] = useState()
  const questionImageRef = useRef(null)
  const answerImageRefs = useRef([])

  useEffect(() => {
    setCurrentIndex(
      questionList.findIndex((question) => question.id === selectedQuestionId)
    )
    // eslint-disable-next-line
  }, [selectedQuestionId])

  useEffect(() => {
    setQuestionType(getQuestionType(editFormData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalWrapper>
      <Modal
        title={t('pageRequirementQuestion.modalEditTitle')}
        setState={setModalEditIsVisible}
      >
        <form
          onSubmit={(e) =>
            handleEditRequirementQuestion(e, {
              setModalEditIsVisible,
              questionList,
              setQuestionList,
              selectedQuestionId,
              editFormData,
              questionType,
              questionImageFile,
            })
          }
        >
          <div className='questionType'>
            <StyledLabel style={{ width: '50%' }}>
              <p>{t('pageRequirementQuestion.typeOfQuestion')}</p>
              <StyledSelect
                border
                translate
                options={useQuestionTypeOptions()}
                value={{
                  value: questionType,
                  label: getOptionLabels(questionType),
                }}
                onChange={(e) => {
                  handleQuestionTypeChange(
                    e.value,
                    questionType,
                    editFormData,
                    initializedEditFormData,
                    setEditFormData,
                    setQuestionType,
                    answerImageRefs
                  )
                }}
              />
            </StyledLabel>
            {questionType === 'imageQuestion' && (
              <StyledLabel className='imageQuestion'>
                <Button
                  type='button'
                  onClick={() => handleSelectQuestionImg(questionImageRef)}
                >
                  {t('pageRequirementQuestion.questionImage').toUpperCase()}
                </Button>
                <input
                  type='file'
                  ref={questionImageRef}
                  style={{ display: 'none' }}
                  accept='image/jpg, image/jpeg, image/png, image/gif'
                  onChange={(e) => setQuestionImageFile(e.target.files[0])}
                />
                {questionImageFile && (
                  <p className='fileName'>{questionImageFile.name}</p>
                )}
              </StyledLabel>
            )}
            {questionType === 'videoQuestion' && (
              <StyledLabel className='videoQuestion'>
                <p>{t('pageRequirementQuestion.videoUrl')}</p>
                <input
                  type='text'
                  name='questionVideo'
                  required='required'
                  placeholder='https://www.youtube.com/watch?v=...'
                  defaultValue={
                    editFormData.questionVideoUrl
                      ? `https://www.youtube.com/watch?v=${editFormData.questionVideoUrl}`
                      : ''
                  }
                  onChange={(e) => {
                    const videoId = e.target.value.split('v=')[1]
                    setEditFormData({
                      ...editFormData,
                      questionVideoUrl: videoId,
                    })
                  }}
                />
              </StyledLabel>
            )}
          </div>
          <StyledLabel>
            <br />
            <p>{t('pageRequirementQuestion.question')}</p>
            <textarea
              rows='5'
              name='questionName'
              required='required'
              placeholder={t(
                'pageRequirementQuestion.modalQuestionPlaceholder'
              )}
              onChange={(e) =>
                setEditFormData({
                  ...editFormData,
                  questionName: e.target.value,
                })
              }
              defaultValue={editFormData.questionName}
            />
          </StyledLabel>
          <AnswerWrapper>
            {editFormData.questionAnswer.map((answer, i) => (
              <Answer
                style={{
                  justifyContent: questionType === 'imageAnswer' && 'center',
                }}
                key={answer.id}
              >
                <StyledLabel
                  style={{
                    width: questionType === 'imageAnswer' && '30%',
                  }}
                >
                  <p className={i === 0 ? 'bold' : undefined}>
                    {`${t(
                      'pageRequirementQuestion.answer'
                    )} ${String.fromCharCode(65 + i)}:`}
                    {i === 0 &&
                      ` ${t('pageRequirementQuestion.correctAnswer')}`}
                  </p>
                  {questionType === 'imageAnswer' ? (
                    <>
                      <Button
                        type='button'
                        onClick={() =>
                          handleSelectAnswerImg(i, answerImageRefs)
                        }
                      >
                        {t(
                          'pageRequirementQuestion.questionImage'
                        ).toUpperCase()}
                      </Button>
                      <input
                        type='file'
                        ref={(ref) => (answerImageRefs.current[i] = ref)}
                        style={{ display: 'none' }}
                        accept='image/jpg, image/jpeg, image/png, image/gif'
                        onChange={(e) => {
                          const newAnswers = editFormData.questionAnswer.map(
                            (answer, index) => {
                              return index === i
                                ? {
                                    ...answer,
                                    questionImageFile: e.target.files[0],
                                  }
                                : answer
                            }
                          )
                          setEditFormData({
                            ...editFormData,
                            questionAnswer: newAnswers,
                          })
                        }}
                      />
                      {answer.questionImageFile && (
                        <p className='fileName'>
                          {answer.questionImageFile.name}
                        </p>
                      )}
                    </>
                  ) : (
                    <input
                      type='text'
                      placeholder={
                        questionType === 'videoAnswer'
                          ? 'https://www.youtube.com/watch?v=...'
                          : ''
                      }
                      name='questionAnswer'
                      required='required'
                      onChange={(e) =>
                        handleAddAnswerChange(
                          e,
                          i,
                          questionType,
                          editFormData,
                          setEditFormData
                        )
                      }
                      defaultValue={
                        questionType === 'videoAnswer'
                          ? answer.questionVideoUrl
                            ? `https://www.youtube.com/watch?v=${answer.questionVideoUrl}`
                            : ''
                          : answer.answer
                      }
                    />
                  )}
                </StyledLabel>
                <img
                  className='pointer removeAnswerBtn'
                  src={crossDark}
                  alt='Edit pencil'
                  onClick={() =>
                    handleRemoveAnswer(
                      answer.id,
                      editFormData,
                      setEditFormData,
                      questionType,
                      answerImageRefs
                    )
                  }
                />
              </Answer>
            ))}
          </AnswerWrapper>
          <AddButton
            className='addButton'
            type='button'
            onClick={() => handleAddAnswerOption(editFormData, setEditFormData)}
          />
          <ButtonContainer>
            <Secondary
              className='previewBtn'
              disabled={
                !editFormData.questionAnswer.length ||
                isPreviewDisabled(questionType, editFormData, questionImageFile)
              }
              onClick={(e) => {
                e.preventDefault()
                setModalPreviewIsVisible(true)
                setPreviewQuestion({
                  question: editFormData,
                  questionType,
                  questionImageFile,
                })
              }}
            >
              {t('pageRequirementQuestion.preview')}
            </Secondary>
            <div className='buttons'>
              <Delete
                type='button'
                onClick={(e) => {
                  e.preventDefault()
                  setModalDeleteIsVisible(true)
                }}
              >
                {t('pageRequirementQuestion.delete')}
              </Delete>
              <Button type='Add'>{t('pageRequirementQuestion.save')}</Button>
            </div>
          </ButtonContainer>
        </form>
      </Modal>
    </ModalWrapper>
  )
}

export default EditRequirementQuestion
