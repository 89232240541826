import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const UpdateMsgBase = ({ className, children }) => {

    const { t } = useTranslation()

    return (
        <div className={className}>
            <p>{t('extra.updateMsg')}</p>
        </div>
    )
}

const UpdateMsg = styled(UpdateMsgBase)`
    position: relative;
    top: 10px;
    left: 5px;
    height: 0;
    display: flex;
    align-items: center;
    gap: 4px;

    p {
        font-size: 12px;
        color: #0ABD7B;
    }
`

export default UpdateMsg