import styled from 'styled-components'

export const AreasStyle = styled.div`
  .MuiTable-root {
    border: 1px solid #ddd;
  }

  .MuiTable-root .MuiTableHead-root .MuiTableCell-root {
    padding: 10px 0 10px 5px;
    font-size: 1rem;
    color: #3f3d56;
  }
  .MuiTable-root .MuiTableBody-root .MuiTableCell-root {
    font-size: 1em;
    border: 1px solid #ddd;
    vertical-align: baseline;
    padding: 5px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .btn-wrapper-areas {
    display: flex;
    flex-direction: row;

    .add-btn-areas {
      background: #dbdbdf;
      color: #323145;
      font-size: 20px;
      width: 31px;
      height: 20;
      text-align: center;
      cursor: pointer;
    }
  }
  .page-title {
    margin-bottom: 20px;
  }
  .table {
    margin-bottom: 20px;
  }
  .top-requirement-container {
    margin-bottom: 35px;
  }
  .bottom-requirement-container {
    position: absolute;
    bottom: 5px;
    right: 10px;
  }

  .table-header {
    background-color: #ececee;
    border: 1px solid #ddd;
    font-weight: bold;
    padding: 10px 0 10px 5px;
  }

  .requirement-paragraph {
    padding: 10px 5px;
  }

  .area-name {
    border: none;
  }

  .question-summary {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
  }
`

/* Add Edit Area */
export const Wrapper = styled.div`
  textarea {
    padding: 5px 13px;
  }
  .textarea {
    margin-top: 2px;
    height: 127px;
    resize: none;
  }
  .questions {
    display: flex;
    gap: 30px;
    padding-top: 30px;
    width: 300px;
    label {
      input {
        margin: 0 auto;
        width: 100px;
        text-align: center;
      }
    }
  }

  .no-of-questions {
    margin-top: 20px;
  }
  
  .qualificationQuestion {
    align-items: end;
  }
`
