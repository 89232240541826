import React, { useContext } from 'react'
import { Button, Delete } from '../../../components/button/Button'
import Modal from '../../../components/modal/Modal'
import { useTranslation } from 'react-i18next';
import { CatalogContext } from '../Catalog'
import styled from 'styled-components'

const ExternalModalWrapper = styled.div`
  /* This renders the buttons above... Edit me! */
  line-height: 28px;
  width: 680px;
`

const Confirm = ({ setState }) => {
  const { t } = useTranslation();
  const { helper } = useContext(CatalogContext)
  const {
    addQualificationToProfessionalRoleFromCatalog,
    orgNr,
    qualificationRoleId,
    setConfirmIsOpen,
    setHelper,
    history,
    prevPath,
    qualificationName,
    professionalRole,
    qualification
  } = helper ?? null

  const { professionalRoleName, id: professionalRoleId } =
    professionalRole ?? null
    return (
      <Modal setState={setConfirmIsOpen} title={t('pageQualifications.addQualification.title')}>
        <ExternalModalWrapper>
          <br />
          <p>
            {t('pageQualifications.addQualification.message', {
              qualificationName: qualificationName,
              professionalRoleName: professionalRoleName
            })}
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '36px',
              paddingBottom: '25px',
            }}
          >
            <Delete
              onClick={(e) => {
                e.preventDefault();
                setHelper({});
                setConfirmIsOpen(false);
              }}
            >
              {t('pageQualifications.addQualification.cancelButton')}
            </Delete>
            <Button
              type='submit'
              style={{ marginLeft: '10px' }}
              onClick={() => {
                addQualificationToProfessionalRoleFromCatalog(
                  orgNr,
                  professionalRoleId,
                  qualificationRoleId
                ).then((res) => {
                  if (res.status === 200) {
                    history.push({
                      pathname: prevPath,
                      state: {
                        addedQual: qualification,
                      },
                    });
                  }
                });
              }}
            >
              {t('pageQualifications.addQualification.addButton')}
            </Button>
          </div>
        </ExternalModalWrapper>
      </Modal>
    );
  };

export default Confirm
