import styled from 'styled-components'

export const Wrapper = styled.main`
  margin-top: 43px;

  &.wrapper-prs {
    min-height: 800px;
    margin-bottom: 50px;
  }
  .pre-title {
    margin-bottom: 16px;
  }
  .modal {
    select {
      background: white;
      padding: 0;
      padding-left: 5px;
    }

    .react-select {
      width: 50%;
    }

    .textarea {
      margin-top: 2px;
      height: 127px;
      resize: none;
      border: #cfcfd5 1px solid;
    }
    width: 600px;
    & form {
      padding: 30px 0;
      p {
        line-height: 20px;
      }
      label {
        display: flex;
        flex-direction: column;
        font-size: 1.2em;

        input {
          line-height: 2em;

          ::placeholder {
            font-size: 1.6em;
          }
        }
      }
      .language {
        width: 100px;
      }
    }
  }
  .table-wrapper {
    margin-top: 25px;
    h4 {
      margin-bottom: 26px;
    }
    /* Last row inside the table */
    .MuiTableBody-root > :last-child {
      border-bottom: 1px solid #e0e0e0;
    }
  }

  .add-button {
    margin: 10px 5px;
  }

  /* Inside modal for adding qualifications */
  .language-container {
    display: flex;
    align-items: center;
    > p {
      margin-right: 10px;
    }
  }
  .modal-btn {
    display: flex;
    justify-content: flex-end;
  }
  .first_btn-margin {
    margin-right: 17px;
  }
  .pointer {
    cursor: pointer;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .MuiTableCell-root {
    vertical-align: baseline;
    padding: 5px;
  }
`
