import styled from 'styled-components'
import error from '../../assets/img/error.svg'

/**
 * Styled label with error-handling
 * add className 'error' for error-styling
 * @property {errorContent} optional: display content put here as an :after sudo-element
 */
const StyledLabel = styled.label`
  position: relative;
  display: block;

  > p {
    line-height: 140%;
    color: #858585;
    font-size: 14px;
    margin-bottom: 2px;
  }

  > input {
    height: 37px;
  }
  
  > textarea {
    resize: none;
    height: 7em;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  > input,
  > textarea {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    border: solid 1px #cfcfd5;
    border-radius: 2px;
    padding-left: 13px;
    color: #202020;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    outline: none;
    transition: all 50ms ease-in-out;
    
    :disabled, :read-only {
      background-color: #f5f5f5;
    }

    :focus {
      border: 4px solid #9b9af2;
    }

    &.error {
      border: 1px solid #de0500;

      :focus {
        border: 4px solid #9b9af2;
      }
    }
  }

  &.error {
    > input,
    > textarea {
      border: 1px solid #de0500;

      :focus {
        border: 4px solid #9b9af2;
      }
    }
    .react-select__control {
      border: 1px solid #de0500;
    }

    :after {
      position: absolute;
      bottom: -20px;
      left: 10px;
      padding-left: 20px;
      color: #de0500;
      content: '${(props) => props.errorContent || ''}';
      background: url(${error});
      background-repeat: no-repeat;
      background-position: 0 2px;
      white-space: nowrap;
    }
  }
`

export const StyledInput = styled.input`
  height: 37px;
  box-sizing: border-box;
  border: solid 1px #cfcfd5;
  border-radius: 2px;
  padding-left: 13px;
  color: #202020;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  outline: none;
  transition: all 50ms ease-in-out;

  :focus {
    border: 4px solid #9b9af2;
  }
`

export default StyledLabel
