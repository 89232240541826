import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '../../../utils/Context'

const EmployeeInfo = () => {
  const { t } = useTranslation()

  const { organisation: { levelOne, levelTwo, levelThree, levelFour }, employee, individual } = useContext(Context)
  const levels = {
    levelOne: levelOne.label || null,
    levelTwo: levelTwo ? levelTwo.label : null,
    levelThree: levelThree ? levelThree.label : null,
    levelFour: levelFour ? levelFour.label : null,
  }

  return (
    <TableContainer classes={{ root: 'table-container' }}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('pageEmployees.employeeSearch.textFields.employeeNumber')}
            </TableCell>
            <TableCell>
              {t('pageEmployees.employeeSearch.textFields.firstName')}
            </TableCell>
            <TableCell>
              {t('pageEmployees.employeeSearch.textFields.lastName')}
            </TableCell>
            <TableCell>
              {t('pageEmployees.searchResults.table.professionalRoleName')}
            </TableCell>
            {levels.levelOne && <TableCell>{t('pageEmployees.searchResults.table.level1')}</TableCell>}
            {levels.levelTwo && <TableCell>{t('pageEmployees.searchResults.table.level2')}</TableCell>}
            {levels.levelThree && <TableCell>{t('pageEmployees.searchResults.table.level3')}</TableCell>}
            {levels.levelFour && <TableCell>{t('pageEmployees.searchResults.table.level4')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{employee.employeeNumber}</TableCell>
            <TableCell>{individual.firstName}</TableCell>
            <TableCell>{individual.lastName}</TableCell>
            <TableCell>{employee.roleName}</TableCell>
            {levels.levelOne && <TableCell>{employee.levelOne}</TableCell>}
            {levels.levelTwo && <TableCell>{employee.levelTwo}</TableCell>}
            {levels.levelThree && <TableCell>{employee.levelThree}</TableCell>}
            {levels.levelFour && <TableCell>{employee.levelFour}</TableCell>}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EmployeeInfo
