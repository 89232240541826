import React, { useContext } from 'react'
import { t } from 'i18next'

import Modal from '../../../../components/modal/Modal'
import { RequirementQuestionContext } from '../RequirementQuestion'
import { ButtonContainer, ModalWrapper } from '../Style'
import { Button, Delete } from '../../../../components/button/Button'
import { handleDeleteRequirementQuestion } from '../../functions'

const DeleteRequirementQuestion = () => {
  const {
    setModalEditIsVisible,
    setModalDeleteIsVisible,
    selectedQuestionId,
    questionList,
    setQuestionList,
  } = useContext(RequirementQuestionContext)

  return (
    <ModalWrapper>
      <Modal
        title={t('pageRequirementQuestion.modalDeleteTitle')}
        setState={setModalDeleteIsVisible}
      >
        <br />
        <br />
        <p>
          {t('pageRequirementQuestion.modalDeleteText1')}
          <span className='bold'>
            "
            {
              questionList.find(
                (question) => question.id === selectedQuestionId
              )?.questionName
            }
            "
          </span>
          ?
        </p>
        <br />
        <p>{t('pageRequirementQuestion.modalDeleteText2')}</p>
        <ButtonContainer>
          <div className='buttons'>
            <Delete
              onClick={() =>
                handleDeleteRequirementQuestion({
                  selectedQuestionId,
                  setQuestionList,
                  setModalDeleteIsVisible,
                  setModalEditIsVisible,
                })
              }
            >
              {t('pageRequirementQuestion.delete')}
            </Delete>
            <Button onClick={() => setModalDeleteIsVisible(false)}>
              {t('pageRequirementQuestion.cancel')}
            </Button>
          </div>
        </ButtonContainer>
        <br />
      </Modal>
    </ModalWrapper>
  )
}

export default DeleteRequirementQuestion
