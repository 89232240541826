import Breadcrumbs from '@mui/material/Breadcrumbs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

function handleClick(event) {
  event.preventDefault()
}

/**
 * Customized mui Breadcrumbs component.
 * @param custom optional: declares that you have parameters in your url. Only works with path parameters - not search parameters.
 * @param offset optional: describes after how many pathnames your parameters start. Default is 1.
 * @param locationStates optional: puts location states in breadcrumb in same order as array.
 * @param search optional: puts search parameter in url of last link.
 */
const BreadCrumbs = ({ custom, offset = 1, locationStates = [], search }) => {
  let history = useHistory()

  const [allPathNames, setAllPathNames] = useState([])

  let pathNames = history.location.pathname.split('/')

  useEffect(() => {
    splitPath()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function splitPath() {
    if (history.location.pathname.split('/').length > 0) {
      pathNames
        .filter((pathName) => pathName !== '')
        .map((pathName) => setAllPathNames((prev) => [...prev, pathName]))
    }
  }

  const basePaths = allPathNames.slice(0, offset)
  const customPaths = allPathNames.slice(offset)

  const { t } = useTranslation()

  return (
    <div role='presentation' onClick={handleClick} className='breadcrumb'>
      <Breadcrumbs aria-label='breadcrumb'>
        {allPathNames.length > 0 &&
          allPathNames.map((pathName, i) =>
            i !== allPathNames.length - 1 ? (
              <span key={i}>
                {custom ? (
                  i < offset ? (
                    <Link to={{
                      pathname: `/${pathName}`,
                      state: locationStates[i]
                    }}>
                      {pathName
                        ? t(`breadCrumbs.${pathName.toLowerCase()}`)
                        : t(`breadCrumbs.home`)}
                    </Link>
                  ) : (
                    <Link to={{
                      pathname: `/${basePaths.join('/')}/${customPaths.slice(0, i - offset + 1).join('/')}`,
                      ...(search && { search }),
                      state: locationStates[i]
                    }}>
                      {pathName}
                    </Link>
                  )
                ) : (
                  <Link to={{
                    pathname: `/${pathName}`,
                    state: locationStates[i]
                  }}>
                    {pathName
                      ? t(`breadCrumbs.${pathName.toLowerCase()}`)
                      : t(`breadCrumbs.home`)}
                  </Link>
                )}
              </span>
            ) : (
              <span key={i}>{pathName}</span>
            )
          )}
      </Breadcrumbs>
    </div>
  )
}

export default BreadCrumbs
