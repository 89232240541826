import styled from 'styled-components'

export const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  background: linear-gradient(90deg, rgba(236,236,238,1) 50%, rgba(255,255,255,1) 50%);

  .modal {

    .modal-content {
      padding-top: 35px;
      padding-bottom: 35px;

      form {
  
        .small-copy {
          position: absolute;
          top: 5px;
          right: 5px;
  
          :hover {
            text-decoration: dotted underline;
            cursor: pointer;
          }
        }
  
        label > p {
          margin-bottom: 10px;
        }
  
        button {
          display: inline-block;
        }
  
        & input {
          width: 100px;
          margin-right: 35px;
        }
      }
    }
  }

  .left-side {
    background-color: #ececee;
    display: flex;
    justify-content: flex-end;
    
    .left-side-content {
      margin-top: 41px;
      display: flex;
      flex-direction: column;
      width: 483px;
      min-height: 985px;

      .logo {
        height: 55px;
        width: fit-content;
      }
  
      nav {
        margin-top: 27px;
        margin-left: 8px;
      }
  
      .undraw {
        align-self: center;
        margin-top: 166px;
      }
      div:nth-child(1) {
        width: 175px;
        margin-top: 41px;
        margin-bottom: 23px;
        margin-left: 132px;
      }
    }
  }

  .right-side {
    background-color: white;
    flex-basis: 830px;
    padding: 108px 0 108px 163px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .flex-row {
      width: 420px;
      display: flex;
      justify-content: space-evenly;
    }
    .flex-column {
      width: 420px;
      display: flex;
      flex-direction: column;
    }

    
    form {
      max-width: 420px;

      .country-language-select {
        margin-top: 67px;
        margin-bottom: 67px;
        display: flex;
        justify-content: space-between;
        
        label {
          width: calc(50% - 20px);
        }
      }

      input {
        margin-bottom: 15px;
      }

      .f-l-name-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
  
        label {
          width: 202px;
        }
      }
    }


    form button {
      margin-top: 80px;
      align-self: flex-start;
    }
  }
`
