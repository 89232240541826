import React, { useContext } from 'react'
import { Checkbox, Grid } from '@mui/material'
import certificateIcon from '../../../assets/img/Certificate.svg'
import { CompetenceProfileContext } from '../CompetenceProfile'
import { useTranslation } from 'react-i18next'

const CompletedEducations = () => {
  const { t } = useTranslation()
  const { completed } = useContext(CompetenceProfileContext)
  return (
    <Grid container spacing={2} mb={6}>
      {/** ---------- Completed Educations ---------- */}
      <Grid item xs={12} mt={6}>
        <Grid item xs={12}>
          <h4
            style={{
              color: '#bfbecf',
              paddingBottom: '5px',
            }}
          >
            {t('competenceProfile.completedEducations')}
          </h4>
        </Grid>
        {completed?.filter((education) => education.qualificationName).length > 0 && (

        <Grid container item xs={12}>
          <Grid item xs={12}></Grid>
          {/** Grid head */}
          <Grid
            container
            item
            sx={{ borderBottom: '1px solid #e0e0e0', padding: '10px' }}
            columns={30}
          >
            <Grid item xs={13}>
              <p style={{ color: '#bfbecf' }}>{t('competenceProfile.qualification')}</p>
            </Grid>
            <Grid item xs={8}>
              <p style={{ color: '#bfbecf' }}>{t('competenceProfile.completed')}</p>
            </Grid>
            <Grid item xs={8}>
              <p style={{ color: '#bfbecf' }}>{t('competenceProfile.shareWithEmployer')}</p>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          {/** Grid body */}
          {completed
              ?.filter((education) => education.educationName)
              .map(({ educationName, id, completedDate }) => ( 
          <Grid
            key={id}
            container
            item
            sx={{
              borderBottom: '1px solid #e0e0e0',
              padding: '10px',
              alignItems: 'center',
            }}
            columns={30}
          >
            <Grid item xs={13}>
              {educationName}
            </Grid>
            <Grid item xs={8}>
              {completedDate}
            </Grid>
            <Grid item xs={8}>
              <Checkbox sx={{ padding: 0, marginLeft: '68px' }} />
            </Grid>
            <Grid item xs={1} sx={{ textAlign: 'right' }}>
              <img src={certificateIcon} alt='Certificate icon' />
            </Grid>
          </Grid>
              ))}          
        </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default CompletedEducations
