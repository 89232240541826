import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button, Secondary } from "../../components/button/Button";
import ErrorMsg from "../../components/errorMsg/ErrorMsg";
import StyledLabel from "../../components/styledLabel/StyledLabel";
import { simpleUser } from "../../utils/Amplify";

import StyledSelect from "../../components/styledSelect/StyledSelect";
import { EmployeesContext } from "./Employees";
import { Context } from "../../utils/Context";
import { emailIsValid } from "../../utils/Validation";

import { ReactComponent as Spinner } from "../../assets/img/spinner.svg";
import { AddRole, EditListModal } from "./Modals";
import { freshPerms } from "../../utils/Backend";

const AddEmployee = ({
  setShowAddEmployee,
  localOrganisation,
  setLocalOrganisation,
}) => {
  const { t } = useTranslation();

  const { rows, setRows, roleOptions } = useContext(EmployeesContext);

  const {
    organisation,
    permissions: { rightsAccess: editorRightsAccess },
  } = useContext(Context);

  const [showEditList, setShowEditList] = useState(false);
  const [editListParams, setEditListParams] = useState({
    setShowEditList,
    orgLevel: "",
    orgLevelGroups: [],
  });

  const [formInputs, setFormInputs] = useState({
    employeeNumber: "",
    firstName: "",
    lastName: "",
    privateEmail: "",
    employeeEmail: "",
    reEmail: "",
    professionalRole: null,
    levelOne: "",
    levelTwo: "",
    levelThree: "",
    levelFour: "",
  });
  const professionalRoleName = formInputs.professionalRole
    ? formInputs.professionalRole.professionalRoleName
    : null;
  const professionalRoleId = formInputs.professionalRole
    ? formInputs.professionalRole.id
    : null;

  const [permissions, setPermissions] = useState({
    competencePlanning: {
      label: t("pageEmployees.addEmployee.permissionsNone"),
      value: "none",
    },
    employees: {
      label: t("pageEmployees.addEmployee.permissionsNone"),
      value: "none",
    },
    validations: {
      label: t("pageEmployees.addEmployee.permissionsNone"),
      value: "none",
    },
    permissions: {
      label: t("pageEmployees.addEmployee.permissionsNone"),
      value: "none",
    },
    initiations: {
      label: t("pageEmployees.addEmployee.permissionsNone"),
      value: "none",
    },
    qualifications: {
      label: t("pageEmployees.addEmployee.permissionsNone"),
      value: "none",
    },
    educations: {
      label: t("pageEmployees.addEmployee.permissionsNone"),
      value: "none",
    },
    stats: {
      label: t("pageEmployees.addEmployee.permissionsNone"),
      value: "none",
    },
  });
  const [isHidden, setIsHidden] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  // const [showAddRole, setShowAddRole] = useState(false);

  const updateRows = (user) => {
    const newRows = [...rows];

    newRows.push(user);

    setRows(newRows);
  };

  const handleAddNew = async () => {
    setErrorMsg({});

    const {
      firstName,
      lastName,
      employeeNumber,
      employeeEmail,
      reEmail,
      professionalRole,
      levelOne,
      levelTwo,
      levelThree,
      levelFour,
    } = formInputs;

    try {
      if (
        !(
          firstName &&
          lastName &&
          employeeNumber &&
          ((emailIsValid(employeeEmail) && employeeEmail === reEmail) ||
            !employeeEmail)
        )
      ) {
        setErrorMsg({
          ...errorMsg,
          firstNameMissing: !firstName,
          lastNameMissing: !lastName,
          employeeNumberMissing: !employeeNumber,
          employeeEmailInvalid: employeeEmail && !emailIsValid(employeeEmail),
          employeeEmailMismatch: employeeEmail && employeeEmail === reEmail,
        });
        throw new Error("Form is not valid");
      }
      const storedPerms = await freshPerms();

      if ((await storedPerms.employeeAccess) !== "edit") {
        let throwMsg = "Not authorized";
        throw throwMsg;
      }

      const jwtToken = (await simpleUser()).token;

      const addingEmployee = JSON.stringify({
        organisationNumber: localOrganisation.organisationNumber,
        employeeNumber: employeeNumber,
        employeeEmail,
        roleName: professionalRoleName,
        roleId: professionalRoleId,
        professionalRole,
        levelOne,
        levelTwo,
        levelThree,
        levelFour,
        ...(storedPerms.rightsAccess === "edit" && {
          qualificationAccess: permissions.qualifications.value,
          initializeAccess: permissions.initiations.value,
          validationAccess: permissions.validations.value,
          rightsAccess: permissions.permissions.value,
          employeeAccess: permissions.employees.value,
          qualificationPlanningAccess: permissions.competencePlanning.value,
          educationAccess: permissions.educations.value,
          statisticAccess: permissions.stats.value,
        }),
      });

      const user = await fetch(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
        method: "POST",
        body: JSON.stringify({
          firstName,
          lastName,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await simpleUser()).token,
        },
      })
        .then((res) => res.json())
        .then(async ({ id }) => {
          return await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/organisation/coworker/add/temporary/${id}`,
            {
              method: "POST",
              body: addingEmployee,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + jwtToken,
              },
            }
          ).then((res) => res.json());
        });

      user.errorMessage && console.log(user.errorMessage);
      if (user.errorMessage === "Individual already exists") {
        alert(user.errorMessage);
        throw user.errorMessage;
      } else if (user.errorMessage) {
      } else {
        updateRows({
          ...user,
          firstName,
          lastName,
          temporary: true,
          qualifications: [
            {
              id: 7,
              qualificationName: "JavaScript",
              qualificationDescription: "JavaScript",
              qualificationType: "Kvalifikation",
              qualificationAccess: "Frontend-utveckling",
              qualificationLanguage: "Swedish",
              version: 0.0,
              published: false,
              subscribers: 0,
              startDate: null,
              endDate: null,
            },
          ],
          competenceDevelopments: [
            {
              name: "JavaScript",
              finished: true,
            },
            {
              name: "Java",
              finished: false,
            },
          ],
          qualificationMappings: [
            {
              name: "foobar",
              finished: true,
            },
            {
              name: "barfoo",
              finished: true,
            },
            {
              name: "unfinished",
              finished: false,
            },
          ],
          educations: [
            {
              name: "foobar",
              finished: true,
            },
            {
              name: "barfoo",
              finished: true,
            },
            {
              name: "unfinished",
              finished: false,
            },
          ],
          permissions: {
            qualifications:
              storedPerms.rightsAccess === "edit"
                ? user.qualificationAccess
                : "none",
            initiations:
              storedPerms.rightsAccess === "edit"
                ? user.initializeAccess
                : "none",
            /* validations: storedPerms.rightsAccess === 'edit' ? user.validationAccess : 'none', */
            permissions:
              storedPerms.rightsAccess === "edit" ? user.rightsAccess : "none",
            employees:
              storedPerms.rightsAccess === "edit"
                ? user.employeeAccess
                : "none",
            competencePlanning:
              storedPerms.rightsAccess === "edit"
                ? user.qualificationPlanningAccess
                : "none",
            educations:
              storedPerms.rightsAccess === "edit"
                ? user.educationAccess
                : "none",
            stats:
              storedPerms.rightsAccess === "edit"
                ? user.statisticAccess
                : "none",
          },
        });
      }
      setShowAddEmployee(false);
    } catch (err) {
      typeof err === "string" ? alert(err) : console.log(err);
    }
    setShowSpinner(false);
  };

  const handleAddExisting = async () => {
    setErrorMsg({});

    const jwtToken = (await simpleUser()).token;
    const {
      employeeNumber,
      privateEmail,
      employeeEmail,
      reEmail,
      professionalRole,
      levelOne,
      levelTwo,
      levelThree,
      levelFour,
    } = formInputs;

    try {
      if (
        !(
          employeeNumber &&
          emailIsValid(privateEmail) &&
          ((emailIsValid(privateEmail) && employeeEmail === reEmail) ||
            !employeeEmail)
        )
      ) {
        setErrorMsg({
          ...errorMsg,
          employeeNumberMissing: !employeeNumber,
          privateEmailMissing: !privateEmail,
          privateEmailInvalid: privateEmail && !emailIsValid(privateEmail),
          employeeEmailInvalid: employeeEmail && !emailIsValid(employeeEmail),
          employeeEmailMismatch: employeeEmail && employeeEmail !== reEmail,
          privateEmailDoesNotExist:
            !privateEmail && !emailIsValid(privateEmail),
        });
        let throwMsg = "Missing or invalid input";
        throw throwMsg;
      }
      const storedPerms = await freshPerms();

      if ((await storedPerms.employeeAccess) !== "edit") {
        let throwMsg = "Not authorized";
        throw throwMsg;
      }

      // Referring to an existing professional role
      const addingEmployee = JSON.stringify({
        organisationNumber: 4,
        employeeNumber: employeeNumber,
        employeeEmail,
        roleName: professionalRoleName,
        roleId: professionalRoleId,
        professionalRole,
        levelOne,
        levelTwo,
        levelThree,
        levelFour,
        ...(storedPerms.rightsAccess === "edit" && {
          qualificationAccess: permissions.qualifications.value,
          initializeAccess: permissions.initiations.value,
          validationAccess: permissions.validations.value,
          rightsAccess: permissions.permissions.value,
          employeeAccess: permissions.employees.value,
          qualificationPlanningAccess: permissions.competencePlanning.value,
          educationAccess: permissions.educations.value,
          statisticAccess: permissions.stats.value,
        }),
      });

      const options = {
        method: "POST",
        body: addingEmployee,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtToken,
        },
      };

      const user = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/coworker/add/${privateEmail}`,
        options
      ).then((res) => res.json());

      if (user.errorMessage === "Individual already exists") {
        setErrorMsg({
          ...errorMsg,
          emailExists: true,
        });
        let throwMsg = "Email already exists";
        throw throwMsg;
      } else {
        updateRows({
          ...user,
          qualifications: [
            {
              id: 7,
              qualificationName: "JavaScript",
              qualificationDescription: "JavaScript",
              qualificationType: "Kvalifikation",
              qualificationAccess: "Frontend-utveckling",
              qualificationLanguage: "Swedish",
              version: 0.0,
              published: false,
              subscribers: 0,
              startDate: null,
              endDate: null,
            },
          ],
          competenceDevelopments: [
            {
              name: "JavaScript",
              finished: true,
            },
            {
              name: "Java",
              finished: false,
            },
          ],
          qualificationMappings: [
            {
              name: "foobar",
              finished: true,
            },
            {
              name: "barfoo",
              finished: true,
            },
            {
              name: "unfinished",
              finished: false,
            },
          ],
          educations: [
            {
              name: "foobar",
              finished: true,
            },
            {
              name: "barfoo",
              finished: true,
            },
            {
              name: "unfinished",
              finished: false,
            },
          ],
          permissions: {
            qualifications:
              storedPerms.rightsAccess === "edit"
                ? user.qualificationAccess
                : "none",
            initiations:
              storedPerms.rightsAccess === "edit"
                ? user.initializeAccess
                : "none",
            validations:
              storedPerms.rightsAccess === "edit"
                ? user.validationAccess
                : "none",
            permissions:
              storedPerms.rightsAccess === "edit" ? user.rightsAccess : "none",
            employees:
              storedPerms.rightsAccess === "edit"
                ? user.employeeAccess
                : "none",
            competencePlanning:
              storedPerms.rightsAccess === "edit"
                ? user.qualificationPlanningAccess
                : "none",
            educations:
              storedPerms.rightsAccess === "edit"
                ? user.educationAccess
                : "none",
            stats:
              storedPerms.rightsAccess === "edit"
                ? user.statisticAccess
                : "none",
          },
        });
        setShowAddEmployee(false);
        // console.log('Added successfully')
      }
    } catch (err) {
      typeof err === "string" ? alert(err) : console.log(err);
    }
    setShowSpinner(false);
  };

  const permissionOptions = [
    { value: "none", label: t("pageEmployees.addEmployee.permissionsNone") },
    { value: "read", label: t("pageEmployees.addEmployee.permissionsRead") },
    { value: "edit", label: t("pageEmployees.addEmployee.permissionsEdit") },
  ];

  const levelOptions = {
    levelOne: localOrganisation.levelOne.groups.map((group) => {
      return {
        value: group.groupName,
        label: group.groupName,
      };
    }),
    levelTwo: localOrganisation.levelTwo
      ? localOrganisation.levelTwo.groups.map((group) => {
          return {
            value: group.groupName,
            label: group.groupName,
          };
        })
      : null,
    levelThree: localOrganisation.levelThree
      ? localOrganisation.levelThree.groups.map((group) => {
          return {
            value: group.groupName,
            label: group.groupName,
          };
        })
      : null,
    levelFour: localOrganisation.levelFour
      ? localOrganisation.levelFour.groups.map((group) => {
          return {
            value: group.groupName,
            label: group.groupName,
          };
        })
      : null,
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <>
      {showEditList && (
        <EditListModal
          {...editListParams}
          setLocalOrganisation={setLocalOrganisation}
        />
      )}
      <form>
        <div className={`personal-info new ${isHidden ? "hidden" : ""}`}>
          <StyledLabel>
            <p>{t("pageEmployees.employeeSearch.textFields.firstName")} *</p>
            <input
              className={errorMsg.firstNameMissing ? "error" : ""}
              type="text"
              value={formInputs.firstName}
              onInput={(e) =>
                setFormInputs({ ...formInputs, firstName: e.target.value })
              }
            />
            {errorMsg.firstNameMissing && (
              <ErrorMsg>
                <p>{t("pageProfile.errors.firstNameMissing")}</p>
              </ErrorMsg>
            )}
          </StyledLabel>
          <StyledLabel>
            <p>{t("pageEmployees.employeeSearch.textFields.lastName")} *</p>
            <input
              className={errorMsg.lastNameMissing ? "error" : ""}
              type="text"
              value={formInputs.lastName}
              onInput={(e) =>
                setFormInputs({ ...formInputs, lastName: e.target.value })
              }
            />
            {errorMsg.lastNameMissing && (
              <ErrorMsg>
                <p>{t("pageProfile.errors.lastNameMissing")}</p>
              </ErrorMsg>
            )}
          </StyledLabel>
        </div>
        <div className={`personal-info existing ${!isHidden ? "hidden" : ""}`}>
          <StyledLabel className="span-2">
            <p>
              {t("pageEmployees.addEmployee.existingEmail")}{" "}
              {t("pageEmployees.addEmployee.private")} *
            </p>
            <input
              className={
                errorMsg.privateEmailMissing ||
                errorMsg.privateEmailInvalid ||
                errorMsg.emailExists
                  ? "error"
                  : ""
              }
              type="text"
              value={formInputs.privateEmail}
              onInput={(e) =>
                setFormInputs({ ...formInputs, privateEmail: e.target.value })
              }
            />

            {errorMsg.privateEmailMissing ||
              (errorMsg.emailExists && (
                <ErrorMsg>
                  {errorMsg.privateEmailMissing && (
                    <p>{t("pageProfile.errors.emailMissing")}</p>
                  )}
                  {errorMsg.emailExists && (
                    <p>{t("pageProfile.errors.emailAlreadyExists")}</p>
                  )}
                </ErrorMsg>
              ))}
          </StyledLabel>
        </div>
        <StyledLabel>
          <p>{t("pageEmployees.employeeSearch.textFields.employeeNumber")} *</p>
          <input
            className={errorMsg.employeeNumberMissing ? "error" : ""}
            type="text"
            value={formInputs.employeeNumber}
            onInput={(e) =>
              setFormInputs({ ...formInputs, employeeNumber: e.target.value })
            }
          />
          {errorMsg.employeeNumberMissing && (
            <ErrorMsg>
              <p>{t("pageProfile.errors.employeeNumberMissing")}</p>
            </ErrorMsg>
          )}
        </StyledLabel>
        <StyledLabel>
          <p>{t("extra.professionalRoleName")}</p>
          <StyledSelect
            options={roleOptions}
            //edit={() => {
            //  setShowAddRole(true);
            //}}
            value={{
              value: formInputs.professionalRole,
              label: formInputs.professionalRole
                ? formInputs.professionalRole.professionalRoleName
                : t("styledSelect.noneSelectedSingle"),
            }}
            onChange={(e) =>
              setFormInputs({
                ...formInputs,
                professionalRole: e.value,
              })
            }
          />
        </StyledLabel>
        {localOrganisation.levelOne && (
          <StyledLabel>
            <p>{localOrganisation.levelOne.label}</p>
            <StyledSelect
              options={levelOptions.levelOne}
              edit={() => {
                setEditListParams({
                  ...editListParams,
                  orgLevel: localOrganisation.levelOne.label,
                  orgLevelGroups: localOrganisation.levelOne.groups,
                  localOrganisation: localOrganisation,
                });
                setShowEditList(true);
              }}
              value={{ value: formInputs.levelOne, label: formInputs.levelOne }}
              onChange={(e) =>
                setFormInputs({
                  ...formInputs,
                  levelOne: e.label,
                })
              }
            />
          </StyledLabel>
        )}
        {localOrganisation.levelTwo && (
          <StyledLabel>
            <p>{localOrganisation.levelTwo.label}</p>
            <StyledSelect
              options={levelOptions.levelTwo}
              value={{ value: formInputs.levelTwo, label: formInputs.levelTwo }}
              edit={() => {
                setEditListParams({
                  ...editListParams,
                  orgLevel: localOrganisation.levelTwo.label,
                  orgLevelGroups: localOrganisation.levelTwo.groups,
                  localOrganisation: localOrganisation,
                });
                setShowEditList(true);
              }}
              onChange={(e) =>
                setFormInputs({
                  ...formInputs,
                  levelTwo: e.value,
                })
              }
            />
          </StyledLabel>
        )}
        {localOrganisation.levelThree && (
          <StyledLabel>
            <p>{localOrganisation.levelThree.label}</p>
            <StyledSelect
              options={levelOptions.levelThree}
              value={{
                value: formInputs.levelThree,
                label: formInputs.levelThree,
              }}
              edit={() => {
                setEditListParams({
                  ...editListParams,
                  orgLevel: localOrganisation.levelThree.label,
                  orgLevelGroups: localOrganisation.levelThree.groups,
                  localOrganisation: localOrganisation,
                });
                setShowEditList(true);
              }}
              onChange={(e) =>
                setFormInputs({
                  ...formInputs,
                  levelThree: e.value,
                })
              }
            />
          </StyledLabel>
        )}
        {localOrganisation.levelFour && (
          <StyledLabel>
            <p>{localOrganisation.levelFour.label}</p>
            <StyledSelect
              options={levelOptions.levelFour}
              value={{
                value: formInputs.levelFour,
                label: formInputs.levelFour,
              }}
              edit={() => {
                setEditListParams({
                  ...editListParams,
                  orgLevel: localOrganisation.levelFour.label,
                  orgLevelGroups: localOrganisation.levelFour.groups,
                  localOrganisation: localOrganisation,
                });
                setShowEditList(true);
              }}
              onChange={(e) =>
                setFormInputs({
                  ...formInputs,
                  levelFour: e.value,
                })
              }
            />
          </StyledLabel>
        )}
        <div className="email">
          <StyledLabel>
            <p>
              {t("pageEmployees.addEmployee.email")}{" "}
              {t("pageEmployees.addEmployee.business")}
            </p>
            <input
              className={errorMsg.employeeEmailInvalid ? "error" : ""}
              type="text"
              value={formInputs.employeeEmail}
              onInput={(e) =>
                setFormInputs({ ...formInputs, employeeEmail: e.target.value })
              }
            />
            {(errorMsg.employeeEmailInvalid || errorMsg.emailExists) && (
              <ErrorMsg>
                {errorMsg.employeeEmailInvalid && (
                  <p>{t("pageProfile.errors.emailInvalid")}</p>
                )}
                {errorMsg.emailExists && (
                  <p>{t("pageProfile.errors.emailAlreadyExists")}</p>
                )}
              </ErrorMsg>
            )}
          </StyledLabel>
          <StyledLabel>
            <p>
              {t("pageEmployees.addEmployee.reEmail")}{" "}
              {t("pageEmployees.addEmployee.business")}
            </p>
            <input
              className={errorMsg.employeeEmailMismatch ? "error" : ""}
              type="text"
              value={formInputs.reEmail}
              onInput={(e) =>
                setFormInputs({ ...formInputs, reEmail: e.target.value })
              }
            />
            {errorMsg.employeeEmailMismatch && (
              <ErrorMsg>
                <p>{t("pageProfile.errors.emailMismatch")}</p>
              </ErrorMsg>
            )}
          </StyledLabel>
        </div>
      </form>
      {editorRightsAccess !== "none" && (
        <div className="permissions">
          <StyledLabel>
            <p>{t("pageHome.employeesTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={editorRightsAccess !== "edit"}
              options={permissionOptions}
              value={permissions.employees}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  employees: e,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("extra.permissions")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={editorRightsAccess !== "edit"}
              options={permissionOptions}
              value={permissions.permissions}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  permissions: e,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("pageHome.initiationsTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={editorRightsAccess !== "edit"}
              options={permissionOptions}
              value={permissions.initiations}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  initiations: e,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("pageHome.competencePlanningTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={editorRightsAccess !== "edit"}
              options={permissionOptions}
              value={permissions.competencePlanning}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  competencePlanning: e,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("pageHome.qualificationsTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={editorRightsAccess !== "edit"}
              options={permissionOptions}
              value={permissions.qualifications}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  qualifications: e,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("pageHome.educationsTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={editorRightsAccess !== "edit"}
              options={permissionOptions}
              value={permissions.educations}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  educations: e,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("pageHome.statsTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={editorRightsAccess !== "edit"}
              options={permissionOptions}
              value={permissions.stats}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  stats: e,
                });
              }}
            />
          </StyledLabel>
        </div>
      )}
      <div className="buttons">
        <Secondary onClick={() => setIsHidden(!isHidden)}>
          {isHidden
            ? t("common.back")
            : t("pageEmployees.addEmployee.getExistingButton")}
        </Secondary>
        <div className="add">
          <Spinner className={showSpinner ? "" : "hidden"} />
          <Button
            onClick={() => {
              setShowSpinner(true);
              if (isHidden) {
                handleAddExisting();
              } else {
                handleAddNew();
              }
            }}
          >
            {t("pageEmployees.addEmployee.addNewButton")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
