import { LinkLeft } from '../../components/button/Button'
import Results from './components/Results'
import Filter from './components/Filter'
import Wrapper from './style'
import Search from './components/Search'
import { createContext, useEffect, useState } from 'react'
import { Route, useLocation } from 'react-router-dom'
import CatalogQualification from './subPages/CatalogQualification'
import { getEducations, getQualifications } from './utils/functions'
import CatalogSubQualification from './subPages/CatalogSubQualification'
import { Context } from '../../utils/Context'
import { useContext } from 'react'
import { getCountryName, getLanguage } from './utils/helper'
import Confirm from './components/Confirm'
import CatalogEducation from './subPages/catalogEducation/CatalogEducation'
import { useTranslation } from 'react-i18next'
export const CatalogContext = createContext()

const Catalog = () => {
  const { t } = useTranslation()
  const { country: globalCountry, language: globalLanguage } =
    useContext(Context)

  const country = getCountryName(globalCountry)
  const language = getLanguage(globalLanguage)
  const [selectedResult, setSelectedResult] = useState(
    JSON.parse(localStorage.getItem('selectedCatalogResult'))
  )
  const [selectedSubQualification, setSelectedSubQualification] = useState(
    JSON.parse(localStorage.getItem('selectedCatalogSubQualification'))
  )
  const [resultsLoading, setResultsLoading] = useState(false)

  const location = useLocation()
  const locationStateProfessionalRole = location.state
    ? location.state.professionalRole
    : null
  const [professionalRole, setProfessionalRole] = useState(
    JSON.parse(localStorage.getItem('catalogProfessionalRole'))
  )

  let includeResults = location.state?.includeResults || ['qualifications', 'educations']

  if (!includeResults) {
    includeResults = locationStateProfessionalRole ? ['qualifications']
      : []
  }

  const locationStatePrevPath = location.state ? location.state.prevPath : null
  const [prevPath, setPrevPath] = useState(
    JSON.parse(localStorage.getItem('catalogPrevPath'))
  )

  const { saveStates: locationStateSaveStates } = location.state || {
    saveStates: false,
  }
  const [saveStates, setSaveStates] = useState(
    JSON.parse(localStorage.getItem('catalogSaveStates'))
  )

  const [results, setResults] = useState([])
  const [filter, setFilter] = useState({
    qualifications: false,
    educations: false,
    qualificationArea: [],
    qualificationType: [],
    qualificationAccess: [],
    qualificationEqf: [],
    author: [],
  })
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const [helper, setHelper] = useState({})
  useEffect(() => {
    setProfessionalRole(
      locationStateProfessionalRole ||
      JSON.parse(localStorage.getItem('catalogProfessionalRole'))
    )
    setPrevPath(
      locationStatePrevPath ||
      JSON.parse(localStorage.getItem('catalogPrevPath'))
    )
    setSaveStates(
      locationStateSaveStates ||
      JSON.parse(localStorage.getItem('catalogSaveStates'))
    )
    setFilter({
      ...filter,
      qualifications: includeResults.includes('qualifications'),
      educations: includeResults.includes('educations')
    })
    const getResults = async () => {
      setResultsLoading(true)

           try {
        setResults([
          ...(await getQualifications(country, language, '')),
          ...(await getEducations(country, language, '')),
        ])
        setResultsLoading(false)
      } catch (error) {
        setResultsLoading(false)
      }


      /*
      setResults([
        ...(await getQualifications(country, language, '')),
        ...(await getEducations(country, language, '')),
      ])
      setResultsLoading(false)

       */
    }
    getResults()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'catalogProfessionalRole',
      JSON.stringify(professionalRole || null)
    )
  }, [professionalRole])

  useEffect(() => {
    localStorage.setItem('catalogPrevPath', JSON.stringify(prevPath || null))
  }, [prevPath])

  useEffect(() => {
    localStorage.setItem(
      'catalogSaveStates',
      JSON.stringify(saveStates || null)
    )
  }, [saveStates])

  useEffect(() => {
    localStorage.setItem(
      'selectedCatalogResult',
      JSON.stringify(selectedResult)
    )
  }, [selectedResult])

  useEffect(() => {
    localStorage.setItem(
      'selectedCatalogSubQualification',
      JSON.stringify(selectedSubQualification)
    )
  }, [selectedSubQualification])

  return (
    <CatalogContext.Provider
      value={{
        results,
        setResults,
        filter,
        setFilter,
        selectedResult,
        setSelectedResult,
        selectedSubQualification,
        setSelectedSubQualification,
        resultsLoading,
        setResultsLoading,
        professionalRole,
        prevPath,
        confirmIsOpen,
        setConfirmIsOpen,
        helper,
        setHelper,
        includeResults
      }}
    >
      <Route exact path='/catalog'>
        <Wrapper className='catalog-wrapper'>
          {confirmIsOpen && <Confirm />}
          <LinkLeft
            className='back-button'
            to={{
              pathname: prevPath,
              state: {
                loadOldStates: saveStates,
              },
            }}
          >
            {t('common.back')}
          </LinkLeft>
          <section className='content'>
            <Search
              setResults={setResults}
              globalCountry={globalCountry}
              globalLanguage={globalLanguage}
            />
            <Filter />
            {results && <Results />}
          </section>
        </Wrapper>
      </Route>
      <Route exact path={'/catalog/:result'}>
        {selectedResult && (selectedResult.qualificationName ? <CatalogQualification /> : <CatalogEducation />)}
      </Route>
      <Route exact path={'/catalog/:result/:subQualification'}>
        {selectedSubQualification && <CatalogSubQualification />}
      </Route>
    </CatalogContext.Provider>
  )
}

export default Catalog
