import { Grid } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import pencil from "../../../assets/img/Edit_pencil.svg";
import { ReactComponent as LoadingSpinner } from "../../../assets/img/spinner.svg";
import { AddButton } from "../../../components/button/Button";
import { EmployeeSubPageContext } from "../EmployeeSubPage";

const OngoingEducations = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  /** ---------- CONTEXTS ---------- */
  const {
    setAddEducationModal,
    ongoing,
    setAddBtnClicked,
    setEditEducationModal,
    setSelectedEducation,
    initializeAccess,
  } = useContext(EmployeeSubPageContext);

  return (
    <>
      {/** ---------- Ongoing Educations ---------- */}
      <Grid item xs={12} mt={6}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          sx={{
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <h4
            style={{
              color: "#bfbecf",
              paddingBottom: "5px",
            }}
          >
            {t("competenceProfile.initiatedEducations")}
          </h4>
          {initializeAccess === "edit" && (
            <AddButton
              className="add-button"
              data-id={"id"}
              onClick={(e) => {
                setAddEducationModal(true);
                setAddBtnClicked(true);
                localStorage.setItem(
                  "employeeSubAddSurveyQualificationByOrg",
                  JSON.stringify({
                    value: "",
                    label: "Välj kartläggning",
                  })
                );
                localStorage.setItem(
                  "employeeSubSelectedSurveyFromDate",
                  JSON.stringify(new Date().toISOString().split("T")[0])
                );
              }}
            />
          )}
        </Grid>
        {!ongoing ? (
          <LoadingSpinner width={100} height={100} />
        ) : (
          ongoing.filter((survey) => survey.educationName).length > 0 && (
            <>
              <Grid container item xs={12}>
                {/** Grid head */}
                <Grid
                  container
                  item
                  sx={{ borderBottom: "1px solid #e0e0e0", padding: "10px" }}
                  columns={20}
                >
                  <Grid item xs={7}>
                    <p style={{ color: "#bfbecf" }}>
                      {t("competenceProfile.education")}
                    </p>
                  </Grid>
                  <Grid item xs={3}>
                    <p style={{ color: "#bfbecf" }}>
                      {t("competenceProfile.startDate")}
                    </p>
                  </Grid>
                  <Grid item xs={3}>
                    <p style={{ color: "#bfbecf" }}>
                      {t("competenceProfile.finishDate")}
                    </p>
                  </Grid>
                  <Grid item xs={3}>
                    <p style={{ color: "#bfbecf" }}></p>
                  </Grid>
                  <Grid item xs={1} sx={{ textAlign: "right" }}></Grid>
                </Grid>
                {/** Grid body */}
                <Grid
                  container
                  item
                  sx={{ borderBottom: "1px solid #e0e0e0", padding: "10px" }}
                  columns={20}
                >
                  {ongoing
                    .filter((survey) => survey?.educationName)
                    .map((education) => {
                      const { endDate, id, educationName, startDate, price } =
                        education;
                      return (
                        <Fragment key={id}>
                          <Grid item xs={7}>
                            <Link
                              to={`${pathname}/${educationName}?type=education`}
                              onClick={() => setSelectedEducation(education)}
                            >
                              {educationName}
                            </Link>
                          </Grid>
                          <Grid item xs={3}>
                            {startDate}
                          </Grid>
                          <Grid item xs={3}>
                            {endDate}
                          </Grid>
                          <Grid item xs={6}>
                            <p style={{ color: "#bfbecf" }}></p>
                          </Grid>
                          <Grid item xs={1} sx={{ textAlign: "right" }}>
                            {initializeAccess === "edit" && (
                              <img
                                src={pencil}
                                alt="Certificate icon"
                                className="pointer"
                                onClick={(e) => {
                                  setEditEducationModal(true);
                                  setSelectedEducation({
                                    endDate,
                                    id,
                                    educationName,
                                    startDate,
                                    price,
                                  });
                                  localStorage.setItem(
                                    "employeeOngoingEducationSelected",
                                    JSON.stringify({
                                      endDate,
                                      id,
                                      educationName,
                                      startDate,
                                      price,
                                    })
                                  );
                                }}
                              />
                            )}
                          </Grid>
                        </Fragment>
                      );
                    })}
                </Grid>
              </Grid>
            </>
          )
        )}
      </Grid>
    </>
  );
};

export default OngoingEducations;
