import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'

import { Context } from '../../utils/Context'
import StyledLabel from '../styledLabel/StyledLabel'
import StyledSelect from '../styledSelect/StyledSelect'

/**
 * Sets country in SkillUpTool
 * @param {className} param0: self-explanatory
 */
const Countries = ({ className }) => {
  const { t } = useTranslation()

  const { country, setCountry } = useContext(Context)

  const countries = [
    {
      label: 'Sweden',
      value: 'SE',
      icon: (
        <Flag
          code='SE'
          height={30}
          width={30}
          style={{ marginRight: '10px' }}
        />
      ),
    },
    {
      label: 'Great Britain',
      value: 'GB',
      icon: (
        <Flag
          code='GB'
          height={30}
          width={30}
          style={{ marginRight: '10px' }}
        />
      ),
    },
  ]

  return (
    <StyledLabel className={className}>
      <p>{t('extra.country')}</p>
      <StyledSelect
        dark
        country
        isSearchable={false}
        options={countries}
        value={countries.filter((option) => option.value === country)}
        onChange={(e) => {
          setCountry(e.value)
        }}
      />
    </StyledLabel>
  )
}

export default Countries
