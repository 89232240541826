import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  color: #3f3d56;
  min-height: 100vh;

  main,
  header {
    width: 1010px;
    margin-left: auto;
    margin-right: auto;
    
  }

  footer {
    @media screen and ( min-height: 1800px) {
      flex-grow: 1;
    }
  }

  main {
    margin-top: 43px;
    flex-grow: 1;
  }

  .light {
    color: #ffffff;
  }
  h1 {
    font-weight: bold;
    font-size: 64px;
    letter-spacing: -0.02em;
  }

  h2 {
    font-weight: bold;
    font-size: 48px;
    letter-spacing: -0.02em;
  }

  h3 {
    font-weight: bold;
    font-size: 36px;
    letter-spacing: -0.02em;
  }

  h4 {
    font-weight: bold;
    font-size: 24px;
  }

  .subtitle {
    font-weight: 500;
    font-size: 24px;
  }

  .body {
    font-size: 16px;
    line-height: 140%;
  }

  .white {
    color: #ffffff;
  }

  .dark {
    color: #3f3d56;
  }

  .bold {
    font-weight: bold;
  }

  .small-copy {
    font-size: 12px;
  }

  .pre-title {
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.025em;
  }

  a,
  .link {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    color: #183eb5;
    cursor: pointer;
  }

  .hoverUnderline:hover {
    text-decoration: underline;
  }

  hr {
    border-color: #cfcfd5;
    border-bottom: none;
  }

  .hidden {
    display: none !important;
  }
  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: #000000;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

  .breadcrumb {
    margin-bottom: 36px;
  }
  .pointer {
    cursor: pointer;
  }


`
