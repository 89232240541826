import { TableContainer } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useContext } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import pencil from '../../../assets/img/Edit_pencil.svg'
import { ProfessionalRoleContext } from './CompetencePlanning'
import { useTranslation } from 'react-i18next'

const ProfessionalRoleTable = () => {
  const { t } = useTranslation()
  const {
    professionalRoleList,
    setProfessionalRole,
    setModalEditIsVisible,
    setSelectedRole,
  } = useContext(ProfessionalRoleContext)
  const { path } = useRouteMatch()
  return (
    <TableContainer sx={{ maxWidth: '100%' }} aria-label='simple table'>
      <Table>
        <colgroup>
          <col style={{ width: '48.5%' }} />
          <col style={{ width: '48.5%' }} />
          <col style={{ width: '5%' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell sx={{ paddingBottom: 0 }}>{t('common.name')}</TableCell>
            <TableCell sx={{ paddingBottom: 0 }}>{t('common.description')}</TableCell>
            <TableCell sx={{ paddingBottom: 0 }} align='right'></TableCell>
          </TableRow>
        </TableHead>
        <colgroup>
          <col style={{ width: '48.5%' }} />
          <col style={{ width: '48.5%' }} />
          <col style={{ width: '5%' }} />
        </colgroup>
        <TableBody>
          {professionalRoleList &&
            professionalRoleList.map(
              ({ id, professionalRoleName, roleDescription }) => (
                <TableRow
                  key={id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    <Link
                      onClick={() => {
                        setSelectedRole({
                          id,
                          professionalRoleName,
                          roleDescription,
                        })
                      }}
                      to={{
                        pathname: `${path}/${professionalRoleName}`,
                      }}
                    >
                      {professionalRoleName}
                    </Link>
                  </TableCell>
                  <TableCell>{roleDescription}</TableCell>
                  <TableCell align='right'>
                    <img
                      className='pointer'
                      src={pencil}
                      alt='Edit pencil'
                      onClick={(e) => {
                        setModalEditIsVisible(true)
                        setProfessionalRole({
                          id,
                          professionalRoleName,
                          roleDescription,
                        })
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ProfessionalRoleTable
