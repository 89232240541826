import styled from 'styled-components';

export const Wrapper = styled.div`

    main {
        margin: 0 auto;
    }

    .first {
        margin: 111px 0 212px 0;
        
        h2 {
            margin-bottom: 5px;
        }

        div {
            display: flex;
            justify-content: space-between;
            margin-top: 52px;
        }
        
        a {
            width: 324px;
            border-bottom: solid 10px #526EC7;
            padding-bottom: 20px;
            text-decoration: none;
            color: #3F3D56;
            
            h4 {
                margin: 23px 0 5px 0;
            }

            p {
                font-weight: 400;
            }
        }

    }

    .second {
        display: flex;
        justify-content: space-between;
        margin-bottom: 216px;

        div {
            max-width: 477px;

            h2 {
                margin-bottom: 22px;
            }

            button {
                margin-top: 50px;
            }
        }
    }

    .third {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 217px;
        background-color: #DBDBDF;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 142px;

        div {
            max-width: 555px;

            h4 {
                margin-bottom: 23px;
            }
        }
    }

`