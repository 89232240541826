import React, { createContext, useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { Prompt, Route, Switch } from 'react-router-dom'
import { t } from 'i18next'

import { ReactComponent as LoadingSpinner } from '../../../../assets/img/spinner.svg'
import { getSubQualificationList } from '../../../competencePlanning/services/Crud'
import { CompetenceProfileContext } from '../../CompetenceProfile'
import { Button } from '../../../../components/button/Button'
import SurveyQuestion from './components/SurveyQuestion'
import SurveyResult from './components/SurveyResult'
import SurveyStart from './components/SurveyStart'
import SurveySubmit from './components/SurveySubmit'
import SurveyTable from './components/SurveyTable'
import PageTitleSpecial from '../../../../components/pageTitle/PageTitleSpecial'
import {
  editSurveyOrEducation,
  getSurvey,
  submitSurvey,
} from '../../../Employees/services/crud'
import { SurveyWrapper } from '../../../../pages/catalog/subPages/style'
import {
  getSubQualificationQuestions,
  getSubQualifications,
} from '../../../qualifications/functions'
import QualificationAreas from '../qualification/QualificationAreas'
import BreadCrumbs from '../../../../utils/BreadCrumbs'
import { simpleUser } from './../../../../utils/Amplify'

export const SubContext = createContext(null)
const Survey = () => {
  const {
    selectedSurvey,
    setSelectedSurvey,
    selectedSurveyID,
    selectedUncompletedSurveyID,
    ongoing,
    setOngoing,
    hasUpdated,
    setHasUpdated,
  } = useContext(CompetenceProfileContext)

  const [qualification, setQualification] = useState({})
  const [qualLoading, setQualLoading] = useState(true)

  const [subQualifications, setSubQualifications] = useState([])
  const [subQualLoading, setSubQualLoading] = useState(true)

  const [selectedSubQualification, setSelectedSubQualification] = useState(
    JSON.parse(
      localStorage.getItem('competenceProfileSelectedSubQualification')
    )
  )

  /***************************** NEW STATES *********************************/
  const [mappingTime, setMappingTime] = useState(0)

  const [mappingQuestions, setMappingQuestions] = useState([])
  const [totalNoOfMappingAnswers, setTotalNoOfMappingAnswers] = useState(0)

  const [showStartModal, setShowStartModal] = useState(false)
  const [surveyStarted, setSurveyStarted] = useState(false)

  const [showQuestionModal, setShowQuestionModal] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)

  const [result, setResult] = useState({})
  const [showResultModal, setShowResultModal] = useState(false)

  const [timeLeft, setTimeLeft] = useState(mappingTime)

  const basePath = `/competence-profile/${selectedSurvey.qualificationName}`

  useEffect(() => {
    localStorage.setItem(
      'competenceProfileSelectedSubQualification',
      JSON.stringify(selectedSubQualification)
    )
  }, [selectedSubQualification])

  useEffect(() => {
    let loaded = true

    const getQualsAndSubQuals = async () => {
      let qualRes
      let subQuals

      if (selectedSurveyID) {
        qualRes = await getSurvey(selectedSurveyID)
        const completedSubQuals = new Set(qualRes.completedSubQualifications)
        subQuals = await getSubQualifications(qualRes.qualification.id)

        subQuals = subQuals.map((subQual) => {
          return {
            ...subQual,
            status: completedSubQuals.has(subQual.id)? 2 : 0,
          }
        })
      } else {
        qualRes = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/organisation/qualification/get/${selectedUncompletedSurveyID}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + (await simpleUser()).token,
            },
          }
        ).then((res) => res.json())
        subQuals = await getSubQualificationList(selectedUncompletedSurveyID)
      }

      if (loaded) {
        setQualification(qualRes.qualification)
        setMappingTime(Math.round(qualRes.totalTimeInSeconds / 60))
        setQualLoading(false)
        setSubQualifications(subQuals)
        setSubQualLoading(false)
      }
    }
    getQualsAndSubQuals()

    return () => {
      loaded = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Array.isArray(mappingQuestions)) {
      setTotalNoOfMappingAnswers(
        mappingQuestions.reduce((acc, curr) => {
          return acc + curr.Questions.filter((q) => q.selectedAnswer !== 0).length;
        }, 0)
      );
    }
  }, [mappingQuestions])

  useEffect(() => {
    setTimeLeft(mappingTime)
  }, [mappingTime])

  /** Handle browser refresh/close and show prompt to user */
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (surveyStarted) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [surveyStarted])

  /** Update no of attempts on survey and fetch questions when survey is started */
  const startSurvey = async () => {
    try {
      const updatedSurvey = await editSurveyOrEducation({
        id: selectedSurveyID,
        isAttemptUpdate: true,
        startDate: selectedSurvey.startDate,
        attempts: selectedSurvey.attempts + 1,
      })

      let newOngoing = [...ongoing]
      const indexOfSelected = ongoing.findIndex(
        (survey) => survey.id === selectedSurvey.id
      )
      newOngoing[indexOfSelected] = updatedSurvey

      setOngoing(newOngoing)
      setSelectedSurvey(newOngoing[indexOfSelected])
      resetSurvey()

      const questions = await getSubQualificationQuestions(qualification.id,selectedSurveyID)
      setMappingQuestions(questions)
    } catch (error) {
      console.log(error)
    }
  }

  const resetSurvey = () => {
    // Reset the status of the subQualifications
    setSubQualifications(
      subQualifications.map((subQual) => {
        if (subQual.status !== 0) {
          return {
            ...subQual,
            status: subQual.status ? subQual.status : 0,
          }
        }
        return subQual
      })
    )

    // Reset the selected answers of the questions
    setMappingQuestions((prev) => {
      return prev.map((subQual) => {
        return {
          ...subQual,
          Questions: subQual.Questions.map((question) => {
            return {
              ...question,
              selectedAnswer: 0,
            }
          }),
        }
      })
    })
  }

  const handleSubmitSurvey = () => {
    submitSurvey({
      id: selectedSurveyID,
      questions: mappingQuestions,
    }).then((res) => {
      setSubQualifications(
        subQualifications.map((subQual) => {
          const subQualStatus = res.SubQualificationStatus.find(
            (subQualStatus) => subQualStatus.Id === subQual.id
          )

          if (subQualStatus) {
            return {
              ...subQual,
              status: mapStatus(subQualStatus.Status),
            }
          }
          return subQual
        })
      )

      /** Reset survey states */
      setSurveyStarted(false)
      setTimeLeft(mappingTime)

      setResult({
        passed: res.Passed,
        attempts: res.Attempts,
      })
      setShowResultModal(true)
    })
  }

  const { qualificationName, qualificationDescription } = qualification

  const initialContext = {
    subQualifications,
    setSubQualifications,
    showStartModal,
    setShowStartModal,
    selectedSurvey,
    mappingTime,
    setMappingTime,
    surveyStarted,
    setSurveyStarted,
    setShowQuestionModal,
    selectedSubQualification,
    setSelectedSubQualification,
    setShowResultModal,
    totalNoOfMappingAnswers,
    mappingQuestions,
    setMappingQuestions,
    timeLeft,
    setTimeLeft,
    startSurvey,
    hasUpdated,
    setHasUpdated,
    handleSubmitSurvey,
    showSubmitModal,
    setShowSubmitModal,
  }

  const titleDescription = (
    <>
      <br />
      <p>{t('pageSurvey.titleInfo0')}</p>
      <br />
      <p>{t('pageSurvey.titleInfo1')}</p>
      <br />
      <p>{t('pageSurvey.titleInfo2')}</p>
      <br />
      <p>{t('pageSurvey.titleInfo3')}</p>
      <br />
    </>
  )

  /**
   * Allow navigation if a sub qualification is clicked, or if the user
   * returns to the survey page. Otherwise, show a prompt to the user
   */
  const allowNavigation = (navigatedPath) => {
    /* eslint-disable no-useless-escape */
    const regex = new RegExp(`^${basePath}(\/.*)?$|^${basePath}$`)
    return regex.test(navigatedPath)
  }

  /** Map subqualification status from backend when survey is submitted */
  const mapStatus = (status) => {
    switch (status) {
      case 'Genomförd':
        return 2
      case 'Gör om':
        return 3
      default:
        return 0
    }
  }

  return (
    <SubContext.Provider value={initialContext}>
      <Switch>
        <Route exact path='/:name/:qualification'>
          <SurveyWrapper className='sub-page-wrapper'>
            <Grid container item xs={12}>
              <Grid container item>
                <BreadCrumbs custom />
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} py={1}>
                  <p className='pre-title'>{t('pageSurvey.preTitle')}</p>
                </Grid>
                {/** -------------------------------- Qualification section -------------------------------- */}
                {qualification && subQualifications ? (
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className='qualification'
                  >
                    <Grid container item xs={12} mb={3}>
                      <PageTitleSpecial
                        className='qualification-title'
                        title={qualificationName}
                        info={qualificationDescription}
                        description={titleDescription}
                      />
                    </Grid>

                    {/** -------------------------------- Survey table & Action buttons -------------------------------- */}
                    <SurveyTable />
                    <div className='btn-spacing'>
                      {!surveyStarted && (
                        <Button
                          disabled={selectedSurvey.attempts === 3}
                          onClick={() => setShowStartModal(true)}
                        >
                          {selectedSurvey.attempts < 3
                            ? t('pageSurvey.startSurvey', {
                                attempt: selectedSurvey.attempts + 1,
                              })
                            : t('pageSurvey.maxAttempts', {
                                attempts: selectedSurvey.attempts,
                              })}
                        </Button>
                      )}
                      {surveyStarted && (
                        <div className='exit-send-container'>
                          <Button onClick={() => setShowSubmitModal(true)}>
                            {t('pageSurvey.sendSurvey')}
                          </Button>
                        </div>
                      )}
                    </div>
                  </Grid>
                ) : (
                  qualLoading &&
                  subQualLoading && <LoadingSpinner width={300} height={300} />
                )}
              </Grid>
            </Grid>

            {/** -------------------------------- Start Modal -------------------------------- */}
            {showStartModal && <SurveyStart />}
            {/** -------------------------------- Question Modal ----------------------------- */}
            {showQuestionModal && selectedSubQualification && (
              <SurveyQuestion />
            )}
            {/** -------------------------------- Submit Modal ----------------------------- */}
            {showSubmitModal && <SurveySubmit />}
            {/** -------------------------------- Result Modal ----------------------------- */}
            {showResultModal && <SurveyResult result={result} />}

            <Prompt
              when={surveyStarted}
              message={(location) =>
                allowNavigation(location.pathname)
                  ? true
                  : t('pageSurvey.ongoingSurvey')
              }
            />
          </SurveyWrapper>
        </Route>
        <Route path='/:name/:qualification/:subQual'>
          {selectedSubQualification ? (
            <QualificationAreas
              selectedSubQualification={selectedSubQualification}
              allowNavigation={allowNavigation}
              surveyStarted={surveyStarted}
            />
          ) : subQualLoading ? (
            <LoadingSpinner width={300} height={300} />
          ) : (
            t('pageSurvey.noSubQualification')
          )}
        </Route>
      </Switch>
    </SubContext.Provider>
  )
}

export default Survey
