import React, { useEffect, useState, useContext } from 'react'
import { Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material'
import { Wrapper } from '../../style'
import { Route } from 'react-router-dom'
import PageTitleSpecial from '../../../../components/pageTitle/PageTitleSpecial'
import { getSubQualificationList } from '../../services/Crud'
import { Link, useRouteMatch } from 'react-router-dom'
import { VIEWAREA, VIEWSUBQUALIFICATION } from '../../services/routes'
import ViewArea from './ViewAreas'
import { CompetenceProfileContext } from '../Profile'
import BreadCrumbs from '../../../../utils/BreadCrumbs'
import { useTranslation } from 'react-i18next'

const ViewSubQualifications = () => {
  const { t } = useTranslation()
  const [subQualificationList, setSubQualificationList] = useState([])
  const [selectedSubQualification, setSelectedSubQualification] = useState(
    JSON.parse(localStorage.getItem('selectedRoleSubQualification'))
  )

  const {
    selectedQualification: { id, qualificationName, qualificationDescription },
  } = useContext(CompetenceProfileContext)

  useEffect(() => {
    let isLoading = true
    getSubQualificationList(id).then((data) => {
      if (isLoading) {
        setSubQualificationList(data)
      }
    })
    return () => {
      isLoading = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'selectedRoleSubQualification',
      JSON.stringify(selectedSubQualification)
    )
  }, [selectedSubQualification])

  const { url } = useRouteMatch()
  return (
    <Wrapper className='wrapper-prs'>
      <Route exact path={VIEWSUBQUALIFICATION}>
        <BreadCrumbs custom />
        <PageTitleSpecial
          title={qualificationName}
          info={qualificationDescription}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('pageSubQualification.modals.addEdit.subQual')}</TableCell>
              <TableCell>{t('pageSubQualification.modals.addEdit.competenceArea')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subQualificationList &&
              subQualificationList.map((subqualification) => (
                <TableRow key={subqualification.id}>
                  <TableCell>
                    <Link
                      onClick={() => {
                        setSelectedSubQualification(subqualification)
                      }}
                      to={{
                        pathname: `${
                          url + '/' + subqualification.name.replaceAll(' ', '-')
                        }`,
                      }}
                    >
                      {subqualification.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {subqualification.qualificationArea.map((area) => (
                      <p key={area.id}>{area.name}</p>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Route>
      <Route path={VIEWAREA}>
        <ViewArea subqualification={selectedSubQualification} />
      </Route>
    </Wrapper>
  )
}

export default ViewSubQualifications
