import React, { useContext, useEffect, useState } from "react";

import { Button, Delete } from "../../../components/button/Button";
import Modal from "../../../components/modal/Modal";
import StyledLabel from "../../../components/styledLabel/StyledLabel";
import {
  bindRoleWithQualification,
  getAllQualificationsByOrganisation,
  unbindRoleWithQualification,
} from "../services/Crud";
import StyledSelect from "./../../../components/styledSelect/StyledSelect";
import { CompetenceProfileContext } from "./Profile";
import { useTranslation } from "react-i18next";

const ModalAddEditDelete = ({ option }) => {
  const { t } = useTranslation();
  const [fetchedQualification, setFetchedQualification] = useState([]);
  const {
    organisationNumber,
    setModalAddIsVisible,
    setModalDeleteIsVisible,
    setSelectedRoleQualifications,
    professionalRole,
    selectQualificationToUnbind,
    setSelectQualificationToUnbind,
  } = useContext(CompetenceProfileContext);

  const { roleId, professionalRoleName } = professionalRole;

  useEffect(() => {
    let isLoading = true;
    getAllQualificationsByOrganisation({ organisationNumber }).then((data) => {
      if (isLoading) {
        setFetchedQualification(data);
      }
    });
    return () => {
      isLoading = false;
    };
    // eslint-disable-next-line
  }, []);

  const [addFormData, setAddFormData] = useState({
    qualificationName: t("catalogue.modal.add.dropdownLabel"),
  });

  return (
    <Modal
      setState={
        (option === "add" && setModalAddIsVisible) ||
        (option === "delete" && setModalDeleteIsVisible)
      }
      title={
        (option === "add" && t("catalogue.modal.add.title")) ||
        (option === "delete" && t("catalogue.modal.delete.title"))
      }
    >
      {option === "add" && (
        <form
          style={{ paddingTop: "10px" }}
          onSubmit={(e) => {
            e.preventDefault();
            bindRoleWithQualification(e, {
              addFormData,
              roleId,
              organisationNumber,
              setModalAddIsVisible,
              setSelectedRoleQualifications,
            });
          }}
        >
          <StyledLabel>
            <p>{t("catalogue.modal.add.qualification")}</p>
            <StyledSelect
              dark
              value={{
                value: addFormData,
                label: addFormData.qualificationName,
              }}
              options={
                fetchedQualification &&
                fetchedQualification.map((qualification) => ({
                  value: qualification,
                  label: qualification.qualificationName,
                }))
              }
              onChange={(e) =>
                setAddFormData({
                  ...e.value,
                })
              }
            />
          </StyledLabel>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "30px",
            }}
          >
            <Button type="Add">{t("buttons.addButton")}</Button>
          </div>
        </form>
      )}
      {option === "delete" && (
        <form>
          <p>
            {t("catalogue.modal.delete.confirmation", {
              qualificationName: selectQualificationToUnbind.qualificationName,
              professionalRoleName,
            })}
          </p>
          <br />
          <p>{t("catalogue.modal.delete.note")}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "36px",
              paddingBottom: "25px",
            }}
          >
            <Delete
              className="bold"
              onClick={(e) => {
                e.preventDefault();

                unbindRoleWithQualification(e, {
                  roleId,
                  organisationNumber,
                  setModalDeleteIsVisible,
                  selectQualificationToUnbind,
                  setSelectQualificationToUnbind,
                  setSelectedRoleQualifications,
                });
              }}
            >
              {t("buttons.deleteButton")}
            </Delete>
            <Button
              type="submit"
              style={{ marginLeft: "10px" }}
              onClick={() => setModalDeleteIsVisible(false)}
            >
              {t("buttons.cancelButton")}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default ModalAddEditDelete;
