import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { Context } from "./utils/Context";

import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Landing from "./pages/landing/Landing";
import Login from "./pages/login/Login";
import SignUp from "./pages/signup/SignUp";
import { Wrapper } from "./Style-App";
import ScrollToTop from "./utils/ScrollToTop";

import PrivateRoute from "./private/PrivateRoute";
import { simpleUser } from "./utils/Amplify";

import { ReactComponent as LoadingSpinner } from "./assets/img/spinner.svg";
import Catalog from "./pages/catalog/Catalog";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const GlobalStyle = createGlobalStyle`
  ${reset}

  .loading-container {
    height: 500px;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  html {
    overflow-x: hidden;
  }
`;

function App() {
  const [loggedIn, setLoggedIn] = useState();
  const [country, setCountry] = useState(
    localStorage.getItem("country") || "SE"
  );
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") || "SE"
  );
  const [individual, setIndividual] = useState(
    JSON.parse(localStorage.getItem("individual"))
  );
  const { organisationEmployee: employee } = individual || {
    organisationEmployee: null,
  };
  const [organisation, setOrganisation] = useState(
    JSON.parse(localStorage.getItem("organisation"))
  );
  const [mode, setMode] = useState(
    localStorage.getItem("mode") || "individual"
  );
  const [getUserAgain, setGetUserAgain] = useState(0);

  const { pathname } = useLocation();
  if (!pathname.startsWith("/catalog")) {
    localStorage.removeItem("catalogProfessionalRole");
    localStorage.removeItem("catalogEducation");
    localStorage.removeItem("catalogPrevPath");
    localStorage.removeItem("catalogSaveStates");
  }
  if (!pathname.startsWith("/employees/")) {
    localStorage.removeItem("employeeSubResetOnReload");
  }
  if (
    !(pathname.startsWith("/catalog") || pathname.startsWith("/employees/"))
  ) {
    localStorage.removeItem("employeesOngoing");
  }

  useEffect(() => {
    if (individual && !employee) {
      setMode("individual");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individual]);

  useEffect(() => {
    const settingLoggedIn = async () => {
      try {
        setLoggedIn(!!(await simpleUser()));
      } catch {
        setLoggedIn(false);
      }
    };

    if (localStorage.getItem("individual")) {
      setLoggedIn(true);
    } else {
      settingLoggedIn();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("mode", mode);
  }, [mode]);

  useEffect(() => {
    localStorage.setItem("country", country);
  }, [country]);

  useEffect(() => {
    const getIndividual = async () => {
      try {
        const storedUser = await simpleUser();
        const data = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/individual/profile/${storedUser.email}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + (await simpleUser()).token,
            },
          }
        ).then((res) => res.json());

        setIndividual(data);
        localStorage.setItem("individual", JSON.stringify(data));
        return data;
      } catch (err) {
        return err;
      }
    };

    const getUser = async () => {
      const user = await getIndividual();

      if (!user.organisationEmployee) {
        setOrganisation(undefined);
        localStorage.removeItem("organisation");
        return;
      }

      const {
        organisationEmployee: { organisation },
      } = user;

      try {
        const storedUser = await simpleUser();
        const unsortedLevelGroups = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisation.id}/get/all/levels`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + storedUser.token,
            },
          }
        ).then((res) => res.json());

        organisation.levelOne = organisation.levelOne
          ? {
              label: organisation.levelOne,
              groups: unsortedLevelGroups
                .filter(({ typeOfLevel }) => typeOfLevel === "One")
                .map(({ id, levelName }) => ({ id, groupName: levelName })),
            }
          : null;

        organisation.levelTwo = organisation.levelTwo
          ? {
              label: organisation.levelTwo,
              groups: unsortedLevelGroups
                .filter(({ typeOfLevel }) => typeOfLevel === "Two")
                .map(({ id, levelName }) => ({ id, groupName: levelName })),
            }
          : null;

        organisation.levelThree = organisation.levelThree
          ? {
              label: organisation.levelThree,
              groups: unsortedLevelGroups
                .filter(({ typeOfLevel }) => typeOfLevel === "Three")
                .map(({ id, levelName }) => ({ id, groupName: levelName })),
            }
          : null;

        organisation.levelFour = organisation.levelFour
          ? {
              label: organisation.levelFour,
              groups: unsortedLevelGroups
                .filter(({ typeOfLevel }) => typeOfLevel === "Four")
                .map(({ id, levelName }) => ({ id, groupName: levelName })),
            }
          : null;

        setOrganisation(organisation);
        localStorage.setItem("organisation", JSON.stringify(organisation));
      } catch (err) {
        console.log(err);
      }
    };

    const getUserInfo = async () => {
      if (loggedIn) {
        getUser();
      } else {
        setIndividual();
        setOrganisation();
      }
    };

    getUserInfo();
    // eslint-disable-next-line
  }, [loggedIn, getUserAgain]);

  const permissions = {
    competencePlanningAccess: employee
      ? employee.qualificationPlanningAccess
      : "none",
    employeeAccess: employee ? employee.employeeAccess : "none",
    rightsAccess: employee ? employee.rightsAccess : "none",
    initializeAccess: employee ? employee.initializeAccess : "none",
    qualificationAccess: employee ? employee.qualificationAccess : "none",
    educationAccess: employee ? employee.educationAccess : "none",
    statisticAccess: employee ? employee.statisticAccess : "none",
    organisationAccess: organisation && organisation.id === 7 ? true : false,
  };

  const isLoading = () => {
    const orgLoaded = mode === "employee" ? organisation : true;

    return loggedIn && !(individual && orgLoaded);
  };

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <Wrapper>
        <Switch>
          <Context.Provider
            value={{
              loggedIn,
              setLoggedIn,
              country,
              setCountry,
              language,
              setLanguage,
              individual,
              setIndividual,
              organisation,
              setOrganisation,
              employee,
              permissions,
              mode,
              setMode,
              getUserAgain,
              setGetUserAgain,
            }}
          >
            <Header loggedIn={loggedIn} />

            <Route exact path="/" component={Landing} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            {/* <Route path='/catalog' component={Catalog} /> */}
            {isLoading() ? (
              <div className="loading-container">
                <LoadingSpinner height={150} style={{ margin: 0 }} />
              </div>
            ) : (
              <PrivateRoute />
            )}
          </Context.Provider>
        </Switch>
        <Footer />
      </Wrapper>
    </>
  );
}

export default App;
