import React, { useContext, useRef, useState } from 'react'
import { t } from 'i18next'

import {
  AddButton,
  Button,
  Secondary,
} from '../../../../components/button/Button'
import { handleAddRequirementQuestion } from '../../functions'
import Modal from '../../../../components/modal/Modal'
import StyledLabel from '../../../../components/styledLabel/StyledLabel'
import { RequirementQuestionContext } from '../RequirementQuestion'
import { ModalWrapper, Answer, AnswerWrapper, ButtonContainer } from '../Style'
import {
  handleAddAnswerChange,
  handleAddAnswerOption,
  handleRemoveAnswer,
} from '../../../../utils/helper'
import StyledSelect from '../../../../components/styledSelect/StyledSelect'
import crossDark from '../../../../assets/img/cross-dark-alt.svg'
import {
  getOptionLabels,
  handleQuestionTypeChange,
  handleSelectAnswerImg,
  handleSelectQuestionImg,
  isPreviewDisabled,
  useQuestionTypeOptions,
} from '../helper'

const AddRequirementQuestion = () => {
  const initializedFormData = {
    questionName: '',
    questionImageKey: '',
    questionVideoUrl: '',
    questionAnswer: [
      {
        answer: '',
        questionImageFile: null,
        questionImageKey: '',
        questionVideoUrl: '',
        correct: true,
        id: Math.random(),
      },
      {
        answer: '',
        questionImageFile: null,
        questionImageKey: '',
        questionVideoUrl: '',
        correct: false,
        id: Math.random(),
      },
      {
        answer: '',
        questionImageFile: null,
        questionImageKey: '',
        questionVideoUrl: '',
        correct: false,
        id: Math.random(),
      },
      {
        answer: '',
        questionImageFile: null,
        questionImageKey: '',
        questionVideoUrl: '',
        correct: false,
        id: Math.random(),
      },
    ],
    published: 0,
  }

  const {
    setModalAddIsVisible,
    setModalPreviewIsVisible,
    selectedRequirement,
    setPreviewQuestion,
    setQuestionList,
  } = useContext(RequirementQuestionContext)

  const [formData, setFormData] = useState(initializedFormData)
  const [questionType, setQuestionType] = useState('text')
  const [questionImageFile, setQuestionImageFile] = useState(null)
  const questionImageRef = useRef(null)
  const answerImageRefs = useRef([])

  return (
    <ModalWrapper>
      <Modal
        title={t('pageRequirementQuestion.modalAddTitle')}
        setState={setModalAddIsVisible}
      >
        <form
          onSubmit={(e) =>
            handleAddRequirementQuestion(e, {
              selectedRequirement,
              setModalAddIsVisible,
              setQuestionList,
              formData,
              questionType,
              questionImageFile,
            })
          }
        >
          <div className='questionType'>
            <StyledLabel style={{ width: '50%' }}>
              <p>{t('pageRequirementQuestion.typeOfQuestion')}</p>
              <StyledSelect
                border
                translate
                options={useQuestionTypeOptions()}
                value={{
                  value: questionType,
                  label: getOptionLabels(questionType),
                }}
                onChange={(e) => {
                  handleQuestionTypeChange(
                    e.value,
                    questionType,
                    formData,
                    initializedFormData,
                    setFormData,
                    setQuestionType,
                    answerImageRefs
                  )
                }}
              />
            </StyledLabel>
            {questionType === 'imageQuestion' && (
              <StyledLabel className='imageQuestion'>
                <Button
                  type='button'
                  onClick={() => handleSelectQuestionImg(questionImageRef)}
                >
                  {t('pageRequirementQuestion.questionImage').toUpperCase()}
                </Button>
                <input
                  type='file'
                  ref={questionImageRef}
                  style={{ display: 'none' }}
                  accept='image/jpg, image/jpeg, image/png, image/gif'
                  onChange={(e) => setQuestionImageFile(e.target.files[0])}
                />
                {questionImageFile && (
                  <p className='fileName'>{questionImageFile.name}</p>
                )}
              </StyledLabel>
            )}
            {questionType === 'videoQuestion' && (
              <StyledLabel className='videoQuestion'>
                <p>{t('pageRequirementQuestion.videoUrl')}</p>
                <input
                  type='text'
                  name='questionVideo'
                  required='required'
                  placeholder='https://www.youtube.com/watch?v=...'
                  onChange={(e) => {
                    const videoId = e.target.value.split('v=')[1]
                    setFormData({ ...formData, questionVideoUrl: videoId })
                  }}
                />
              </StyledLabel>
            )}
          </div>
          <StyledLabel>
            <p>{t('pageRequirementQuestion.question')}</p>
            <textarea
              rows='5'
              name='questionName'
              required='required'
              placeholder={t(
                'pageRequirementQuestion.modalQuestionPlaceholder'
              )}
              onChange={(e) =>
                setFormData({ ...formData, questionName: e.target.value })
              }
            />
          </StyledLabel>
          <AnswerWrapper>
            {formData.questionAnswer.map((answer, i) => (
              <Answer
                style={{
                  justifyContent: questionType === 'imageAnswer' && 'center',
                }}
                key={answer.id}
              >
                <StyledLabel
                  style={{
                    width: questionType === 'imageAnswer' && '30%',
                  }}
                >
                  <p className={i === 0 ? 'bold' : undefined}>
                    {`${t(
                      'pageRequirementQuestion.answer'
                    )} ${String.fromCharCode(65 + i)}:`}
                    {i === 0 &&
                      ` ${t('pageRequirementQuestion.correctAnswer')}`}
                  </p>

                  {questionType === 'imageAnswer' ? (
                    <>
                      <Button
                        type='button'
                        onClick={() =>
                          handleSelectAnswerImg(i, answerImageRefs)
                        }
                      >
                        {t(
                          'pageRequirementQuestion.questionImage'
                        ).toUpperCase()}
                      </Button>
                      <input
                        type='file'
                        ref={(ref) => (answerImageRefs.current[i] = ref)}
                        style={{ display: 'none' }}
                        accept='image/jpg, image/jpeg, image/png, image/gif'
                        onChange={(e) => {
                          const newAnswers = formData.questionAnswer.map(
                            (answer, index) => {
                              return index === i
                                ? {
                                    ...answer,
                                    questionImageFile: e.target.files[0],
                                  }
                                : answer
                            }
                          )
                          setFormData({
                            ...formData,
                            questionAnswer: newAnswers,
                          })
                        }}
                      />
                      {answer.questionImageFile && (
                        <p className='fileName'>
                          {answer.questionImageFile.name}
                        </p>
                      )}
                    </>
                  ) : (
                    <input
                      type='text'
                      placeholder={
                        questionType === 'videoAnswer'
                          ? 'https://www.youtube.com/watch?v=...'
                          : ''
                      }
                      name='questionAnswer'
                      required='required'
                      onChange={(e) =>
                        handleAddAnswerChange(
                          e,
                          i,
                          questionType,
                          formData,
                          setFormData
                        )
                      }
                    />
                  )}
                </StyledLabel>
                <img
                  className='pointer removeAnswerBtn'
                  id={i}
                  src={crossDark}
                  alt='Edit pencil'
                  onClick={() =>
                    handleRemoveAnswer(
                      answer.id,
                      formData,
                      setFormData,
                      questionType,
                      answerImageRefs
                    )
                  }
                />
              </Answer>
            ))}
          </AnswerWrapper>
          <AddButton
            className='addButton'
            type='button'
            onClick={() => handleAddAnswerOption(formData, setFormData)}
          />
          <ButtonContainer>
            <div className='buttons'>
              <Secondary
                className='previewBtn'
                disabled={
                  !formData.questionAnswer.length ||
                  isPreviewDisabled(questionType, formData, questionImageFile)
                }
                onClick={(e) => {
                  e.preventDefault()
                  setModalPreviewIsVisible(true)
                  setPreviewQuestion({
                    question: formData,
                    questionType,
                    questionImageFile,
                  })
                }}
              >
                {t('pageRequirementQuestion.preview')}
              </Secondary>
              <Button>{t('pageRequirementQuestion.add')}</Button>
            </div>
          </ButtonContainer>
        </form>
      </Modal>
    </ModalWrapper>
  )
}

export default AddRequirementQuestion
