import styled from 'styled-components';

export const StyledNav = styled.nav`
    background-color: #ECECEE;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    min-height: 34px;

    ul {
        width: 1010px;
        margin: 0 auto;
        display: flex;

        a {
            color: #3F3D56
        }

        li {
            padding: 9px 59px;
            cursor: pointer;
        }

        .selected, li:hover {
            background-color: #CFCFD5;
        }
    }
`