import React, { useState, useEffect, useContext, createContext } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useRouteMatch } from "react-router";
import { Secondary } from "../../components/button/Button";
import StyledLabel from "../../components/styledLabel/StyledLabel";
import styled from "styled-components";
import { Context } from "../../utils/Context";
import AddOrganisation from "./AddOrganisation";
import EditOrganisation from "./EditOrganisation";
import { Wrapper } from "./style";
import DenseTable from "./Table";
import Modal from "../../components/modal/Modal";
import Employees from "../Employees/Employees";

// Create the OrganisationsContext
export const OrganisationsContext = createContext();

const SearchText = styled.div`
  padding: 10px;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #3f3d56;
`;

const Organisations = () => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();

  const {
    permissions: { rightsAccess },
  } = useContext(Context);

  const [searchFilter, setSearchFilter] = useState({
    id: "",
    name: "",
    city: "",
    country: "",
    subscription: "",
  });
  const [rows, setRows] = useState([]);
  const [row, setRow] = useState(
    JSON.parse(localStorage.getItem("organisationsSelectedRow"))
  );

  const [showAddOrganisation, setShowAddOrganisation] = useState(false);
  const [showEditOrganisation, setShowEditOrganisation] = useState(false);

  useEffect(() => {
    localStorage.setItem("organisationsSelectedRow", JSON.stringify(row));
  }, [row]);

  return (
    <OrganisationsContext.Provider value={{ row, setRow, rows, setRows }}>
      <Switch>
        <Route exact path={path}>
          <Wrapper className="organisation-wrapper">
            {showAddOrganisation && (
              <Modal
                title={t("pageOrganisations.addOrganisation.title")}
                setState={setShowAddOrganisation}
                closeOnClickOutside
              >
                <AddOrganisation
                  setShowAddOrganisation={setShowAddOrganisation}
                />
              </Modal>
            )}

            {showEditOrganisation && (
              <Modal
                title={t("pageOrganisations.editOrganisation.title")}
                setState={setShowEditOrganisation}
                closeOnClickOutside
              >
                <EditOrganisation
                  setShowEditOrganisation={setShowEditOrganisation}
                />
              </Modal>
            )}

            <section className="organisation-search">
              <div className="top">
                <h3>{t("pageOrganisations.title.title")}</h3>
                {rightsAccess === "edit" && (
                  <Secondary onClick={() => setShowAddOrganisation(true)}>
                    {t("pageOrganisations.title.buttonText")}
                  </Secondary>
                )}
              </div>
              <form>
                <div className="form-fields">
                  <SearchText>
                    <p>
                      {t(
                        "pageOrganisations.organisationSearch.textFields.search"
                      )}
                    </p>
                  </SearchText>
                  <div className="text-fields">
                    <StyledLabel>
                      <p>
                        {t(
                          "pageOrganisations.organisationSearch.textFields.organisationID"
                        )}
                      </p>
                      <input
                        type="text"
                        value={searchFilter.id}
                        onInput={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            id: e.target.value,
                          })
                        }
                      ></input>
                    </StyledLabel>

                    <StyledLabel>
                      <p>
                        {t(
                          "pageOrganisations.organisationSearch.textFields.name"
                        )}
                      </p>
                      <input
                        type="text"
                        value={searchFilter.name}
                        onInput={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            name: e.target.value,
                          })
                        }
                      ></input>
                    </StyledLabel>

                    <StyledLabel>
                      <p>
                        {t(
                          "pageOrganisations.organisationSearch.textFields.city"
                        )}
                      </p>
                      <input
                        type="text"
                        value={searchFilter.city}
                        onInput={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            city: e.target.value,
                          })
                        }
                      ></input>
                    </StyledLabel>

                    <StyledLabel>
                      <p>
                        {t(
                          "pageOrganisations.organisationSearch.textFields.country"
                        )}
                      </p>
                      <input
                        type="text"
                        value={searchFilter.country}
                        onInput={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            country: e.target.value,
                          })
                        }
                      ></input>
                    </StyledLabel>

                    <StyledLabel style={{ marginBottom: "20px" }}>
                      <p>
                        {t(
                          "pageOrganisations.organisationSearch.textFields.subscription"
                        )}
                      </p>
                      <input
                        type="text"
                        value={searchFilter.subscription}
                        onInput={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            subscription: e.target.value,
                          })
                        }
                      ></input>
                    </StyledLabel>
                  </div>
                </div>
              </form>
            </section>

            <hr />

            <section className="organisation-results">
              <DenseTable
                setShowEditOrganisation={setShowEditOrganisation}
                searchFilter={searchFilter}
              />
            </section>
          </Wrapper>
        </Route>
        <Route path={`${url}/employees/organisationId/:organisationId`}>
          <Employees />
        </Route>
      </Switch>
    </OrganisationsContext.Provider>
  );
};

export default Organisations;
