import { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { signOut } from '../../utils/Amplify'
import { Context } from '../../utils/Context'
import { ArrowButton } from '../button/Button.js'
import { ReactComponent as LogoDark } from '../../assets/img/logo-dark.svg'
import { ReactComponent as UserIcon } from '../../assets/img/User.svg'
import Navbar from '../navbar/Navbar.js'
import Countries from '../styledFlagsSelect/Countries.js'
import Languages from '../styledFlagsSelect/Languages.js'
import { Wrapper } from './style.js'

const Header = ({ loggedIn }) => {
  const history = useHistory()
  const {
    setLoggedIn,
    individual,
    setIndividual,
    organisation,
    setOrganisation,
    mode,
    setMode,
  } = useContext(Context)

  // organisation and individual are saved in localStorage for persistance, but fetch data is prioritized
  const localOrg = JSON.parse(localStorage.getItem('organisation'))
  const localInd = JSON.parse(localStorage.getItem('individual'))

  const location = useLocation()
  const { t } = useTranslation()

  // anchor element for mui Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  /**returns true if employee has no permissions*/
  const employeeHasNoPerms = () => {
    if (individual && individual.organisationEmployee) {
      return Object.keys(individual.organisationEmployee)
        .filter((key) => key.includes('Access'))
        .every((key) => individual.organisationEmployee[key] === 'none')
    } else if (localInd && localInd.organisationEmployee) {
      Object.keys(localInd.organisationEmployee)
        .filter((key) => key.includes('Access'))
        .every((key) => localInd.organisationEmployee[key] === 'none')
    }
  }

  if (location.pathname === '/login' || location.pathname === '/signup')
    return null

  return (
    <>
      <Wrapper loggedIn={loggedIn}>
        {!loggedIn && location.pathname !== '/catalog' && (
          <div className='country-langugage-select'>
            <Countries />
            <Languages />
          </div>
        )}
        <LogoDark className='logo' onClick={() => history.push('/')} />
        {loggedIn && (individual || localInd) && (
          <div className='identity'>
            <div className='info'>
              {mode === 'employee' ? (
                <>
                  <p className='subtitle'>
                    {organisation
                      ? organisation.orgName
                      : localOrg &&
                      localOrg.orgName}
                  </p>
                  <p className='small-copy'>
                    ID:{' '}
                    {organisation ? organisation.id : localOrg && localOrg.id}
                  </p>
                </>
              ) : (
                <>
                  <p className='subtitle'>
                    {individual
                      ? individual.firstName
                      : localInd &&
                      localInd.firstName}{' '}
                    {individual
                      ? individual.lastName
                      : localInd &&
                      localInd.lastName}
                  </p>
                  <p className='small-copy'>
                    ID: {individual ? individual.id : localInd && localInd.id}
                  </p>
                </>
              )}
            </div>
          </div>
        )}
        <nav>
          {loggedIn ? (
            <div className='menu'>
              <UserIcon onClick={handleClick} />
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
                disableAutoFocusItem
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  transform: 'translate(32px, 10px)',
                }}
                MenuListProps={{
                  sx: {
                    maxWidth: '600px',
                  },
                }}
              >
                {/* Individual */}
                <MenuItem
                  sx={{
                    whiteSpace: 'normal',
                  }}
                  onClick={() => {
                    setMode('individual')
                    history.push('/home')
                    handleClose()
                  }}
                >
                  {individual
                    ? individual.firstName
                    : localInd && localInd.firstName}{' '}
                  {individual
                    ? individual.lastName
                    : localInd && localInd.lastName}
                </MenuItem>
                {/* Employee */}
                {(organisation || localOrg) && (
                  <MenuItem
                    sx={{
                      whiteSpace: 'normal',
                    }}
                    disabled={employeeHasNoPerms()}
                    onClick={() => {
                      setMode('employee')
                      history.push('/home')
                      handleClose()
                    }}
                  >
                    <p className='subtitle'>
                      {organisation
                        ? organisation.orgName
                        : localOrg.orgName}
                    </p>
                  </MenuItem>
                )}
                {/* Catalog Link */}
                <MenuItem
                  onClick={() => {
                    history.push({
                      pathname: '/catalog',
                      state: {
                        prevPath: history.location.pathname,
                      },
                    })
                    handleClose()
                  }}
                >
                  <p>{t('pageLanding.first.catTitle')}</p>
                </MenuItem>
                {/* Logout */}
                <MenuItem
                  sx={{
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    setMode() // reset individual/organisation mode
                    setOrganisation()
                    setIndividual()
                    setLoggedIn(false)
                    signOut()
                    history.push('/')
                    handleClose()
                  }}
                >
                  <p className='logout'>{t('header.logOut')}</p>
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <ul>
              <li><a href='https://headonnails.com/skilluptool/'>SkillUpTool</a></li>
              <li><a href="https://headonnails.com/contact/">{t('header.contact')}</a></li>
              <li><a href="https://headonnails.com/about-2/">{t('header.about')}</a></li>
              <li className='login'>
                <ArrowButton
                  onClick={() => {
                    localStorage.setItem('org', 'false')
                    history.push('/login')
                  }}
                >
                  {t('buttons.signInButton')}
                </ArrowButton>
              </li>
            </ul>
          )}
        </nav>
      </Wrapper>
      {loggedIn && <Navbar />}
    </>
  )
}

export default Header
