import React, { createContext, useContext, useEffect, useState } from 'react'
import { t } from 'i18next'

import AddRequirementQuestion from './components/AddRequirementQuestion'
import DeleteRequirementQuestion from './components/DeleteRequirementQuestion'
import EditRequirementQuestion from './components/EditRequirementQuestion'
import PreviewRequirementQuestion from './components/PreviewRequirementQuestion'
import PageTitleSpecial from '../../../components/pageTitle/PageTitleSpecial'
import RequirementQuestionTable from './components/RequirementQuestionTable'
import { getRequirementQuestions } from '../functions'
import { AreaContext } from '../qualificationAreas/QualificationAreas'
import BreadCrumbs from '../../../utils/BreadCrumbs'

export const RequirementQuestionContext = createContext(null)

const RequirementQuestion = () => {
  const { selectedRequirement, setSelectedRequirement } =
    useContext(AreaContext)

  const [modalAddIsVisible, setModalAddIsVisible] = useState(false)
  const [modalDeleteIsVisible, setModalDeleteIsVisible] = useState(false)
  const [modalEditIsVisible, setModalEditIsVisible] = useState(false)
  const [modalPreviewIsVisible, setModalPreviewIsVisible] = useState(false)
  const [previewQuestion, setPreviewQuestion] = useState(null)
  const [questionList, setQuestionList] = useState([])
  const [selectedQuestionId, setSelectedQuestionId] = useState(null)

  const initialContext = {
    modalAddIsVisible,
    setModalAddIsVisible,
    modalDeleteIsVisible,
    setModalDeleteIsVisible,
    modalEditIsVisible,
    setModalEditIsVisible,
    previewQuestion,
    setPreviewQuestion,
    modalPreviewIsVisible,
    setModalPreviewIsVisible,
    questionList,
    setQuestionList,
    selectedRequirement,
    setSelectedRequirement,
    selectedQuestionId,
    setSelectedQuestionId,
  }

  useEffect(() => {
    const getQuestions = async () => {
      const res = await getRequirementQuestions(selectedRequirement.id)
      setQuestionList(res)
    }
    getQuestions()

    return () => {
      setQuestionList([])
    }
  }, [selectedRequirement.id])

  const titleDescription = (
    <>
      <br />
      <p>{t('pageRequirementQuestion.titleInfo1')}</p>
      <br />
      <p>{t('pageRequirementQuestion.titleInfo2')}</p>
      <br />
      <p>{t('pageRequirementQuestion.titleInfo3')}</p>
    </>
  )

  return (
    <RequirementQuestionContext.Provider value={initialContext}>
      <BreadCrumbs custom />
      <PageTitleSpecial
        title={t('pageRequirementQuestion.title')}
        info={t(selectedRequirement.description)}
        description={titleDescription}
      ></PageTitleSpecial>
      <RequirementQuestionTable />
      {modalAddIsVisible && <AddRequirementQuestion />}
      {modalEditIsVisible && <EditRequirementQuestion />}
      {modalEditIsVisible && modalDeleteIsVisible && (
        <DeleteRequirementQuestion />
      )}
      {modalPreviewIsVisible && <PreviewRequirementQuestion />}
    </RequirementQuestionContext.Provider>
  )
}

export default RequirementQuestion
