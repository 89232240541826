import React, { createContext, useContext, useEffect, useState } from 'react'

import PageTitle from '../../../components/pageTitle/PageTitle'
import { Context } from '../../../utils/Context'
import { Route, useRouteMatch } from 'react-router-dom'
import SubQualification from '../subQualification/SubQualification'
import { Wrapper } from '../style'
import { getQualifications } from '../functions'
import AddQualification from './components/AddQualification'
import Share from './components/Share'
import DeleteQualification from './components/DeleteQualification'
import EditQualification from './components/EditQualification'
import QualificationTable from './components/QualificationTable'
import { useTranslation } from 'react-i18next'


//  FIXME Need to modify it to work in qualifications..
// import BreadCrumbs from '../../../utils/BreadCrumbs'
import ModalConfirm from './components/ModalConfirm'

export const QualificationContext = createContext(null)

const Qualification = () => {
  const { t } = useTranslation()
  const {
    organisation: { organisationNumber },
  } = useContext(Context)
  // States
  const [qualificationsList, setQualificationsList] = useState([]) // Store all qualifications
  const [qualificationId, setQualificationId] = useState(null) // To store the id of the selected qualification
  // To show/hide the modals the modals
  const [modalAddIsVisible, setModalAddIsVisible] = useState(false)
  const [modalShareIsVisible, setModalShareIsVisible] = useState(false)
  const [modalConfirmIsVisible, setModalConfirmIsVisible] = useState(false)
  const [modalConfirmTempCountry, setModalConfirmTempCountry] = useState({})
  const [modalEditIsVisible, setModalEditIsVisible] = useState(false)
  const [modalDeleteIsVisible, setModalDeleteIsVisible] = useState(false)
  const [deleteOption, setDeleteOption] = useState('')
  const [selectedCountryToRemove, setSelectedCountryToRemove] = useState('')
  const [selectedQualification, setSelectedQualification] = useState(
    JSON.parse(localStorage.getItem('selectedQualification'))
  )

  // ContextProvider-object
  const initialContext = {
    organisationNumber,
    qualificationsList,
    setQualificationsList,
    qualificationId,
    setQualificationId,
    modalAddIsVisible,
    setModalAddIsVisible,
    modalShareIsVisible,
    setModalShareIsVisible,
    modalEditIsVisible,
    setModalEditIsVisible,
    modalDeleteIsVisible,
    setModalDeleteIsVisible,
    modalConfirmIsVisible,
    setModalConfirmIsVisible,
    selectedQualification,
    setSelectedQualification,
    deleteOption,
    setDeleteOption,
    selectedCountryToRemove,
    setSelectedCountryToRemove,
    modalConfirmTempCountry,
    setModalConfirmTempCountry,
  }

  // Runs on page loads
  useEffect(() => {
    let isLoading = true

    // Get all Qualifications
    getQualifications(organisationNumber).then((data) => {
      if (isLoading) {
        setQualificationsList(data)
      }
    })

    // clean up function
    return () => {
      isLoading = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'selectedQualification',
      JSON.stringify(selectedQualification)
    )
  }, [selectedQualification])
  useEffect(() => {
    // Clean the selectedQualification when Share modal is closed

    if (!modalShareIsVisible) {
      setSelectedQualification(
        JSON.parse(localStorage.getItem('selectedQualification'))
      )
    }
  }, [setModalShareIsVisible, setSelectedQualification, modalShareIsVisible])
  useEffect(() => {
    if (!modalDeleteIsVisible) {
      setDeleteOption('')
    }
  }, [modalDeleteIsVisible])
  const { path } = useRouteMatch()

  return (
    <QualificationContext.Provider value={initialContext}>
      <Wrapper>
        <Route exact path={path}>
          <PageTitle title={t('common.qualifications')}>
            <p>
              {t('qualificationsHome.qualificationsInfo1')}
              {t('qualificationsHome.qualificationsInfo2')}<br/>
              {t('qualificationsHome.qualificationsInfo3')}
              <span className='bold'>{t('qualificationsHome.qualificationsInfo4')}</span>
              <span className='bold'>{t('qualificationsHome.qualificationsInfo5')} </span>{t('qualificationsHome.qualificationsInfo6')}
              <span className='bold'>{t('qualificationsHome.qualificationsInfo7')}</span><br/><br/>
            </p>
            <p>
              <span className='bold'>{t('qualificationsHome.qualificationsInfo8')}</span>
              {t('qualificationsHome.qualificationsInfo9')}
            </p>
            <p>
              <span className='bold'>{t('qualificationsHome.qualificationsInfo10')}</span>
              {t('qualificationsHome.qualificationsInfo11')}
            </p>
            <p>
              <span className='bold'>{t('qualificationsHome.qualificationsInfo12')}</span> 
              {t('qualificationsHome.qualificationsInfo13')}
            </p>
            <br />
            <p>
            {t('qualificationsHome.qualificationsInfo14')}
              <span className='bold'>{t('qualificationsHome.qualificationsInfo15')}</span>.{' '}
            </p>
            <p>
            {t('qualificationsHome.qualificationsInfo16')}
              <span className='bold'>{t('qualificationsHome.qualificationsInfo17')}</span>.
            </p>
            <p>
              {t('qualificationsHome.qualificationsInfo18')}
              <span className='bold'>{t('qualificationsHome.qualificationsInfo19')}</span>.{' '}
            </p>
          </PageTitle>

          {/* Table of all qualifications */}
          <QualificationTable />

          {/* Modals only visible if needed */}
          {modalShareIsVisible && <Share option={'share'} />}
          {modalConfirmIsVisible && <ModalConfirm />}
          {modalAddIsVisible && <AddQualification />}
          {modalEditIsVisible && <EditQualification />}
          {modalDeleteIsVisible && <DeleteQualification />}
        </Route>
        <Route path={`${path}/:subQualifications`}>
          {selectedQualification && <SubQualification />}
        </Route>
      </Wrapper>
    </QualificationContext.Provider>
  )
}

export default Qualification
