import { Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Delete } from '../../../components/button/Button'
import Modal from '../../../components/modal/Modal'
import StyledLabel from '../../../components/styledLabel/StyledLabel'
import { selectedDatePlus60Days } from '../../../utils/helper'
import { EmployeeSubPageContext } from '../EmployeeSubPage'
import {
  deleteSurveyOrEducation,
  editSurveyOrEducation,
} from '../services/crud'

const StyledModal = styled.div`
  .error {
    color: red;
    font-size: small;
  }
  .error::before {
    content: '* ';
  }
  .modal {
    width: 886px;
  }
  .modal-content {
    padding: 20px;
    .person {
      display: flex;
      border-bottom: 1px solid #cfcfd5;
      margin-bottom: 15px;
      span:not(:last-child) {
        padding-right: 10px;
      }
      span {
        font-size: 22px;
      }
      span:not(:first-child) {
        padding-left: 5px;
      }
    }
    .info-text {
      padding-bottom: 15px;
    }
    .to-catalog-btn {
      padding-top: 20px;
    }
    .survey-wrapper {
      display: flex;
      justify-content: space-between;
      input {
        width: 100%;
        text-overflow: ellipsis;
      }
      .price {
        padding-right: 30px;
        margin-top: 35px;
      }
    }
    .survey-date-wrapper {
      width: 80%;
      padding-top: 15px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .start-date {
        width: 170px;
      }
      .end-date {
        width: 150px;
      }
      .survey-date-info-text {
        width: 80%;
        padding: 15px 0;
      }
    }
    .add-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
`

const EditSurvey = () => {
  const { t } = useTranslation()
  // ---------- Contexts ----------
  const {
    employee,
    employee: { professionalRoleName },
    individual: { id: individualId },
    setEditQualificationSurveyModal,
    setOngoing,
    ongoing,
    selectedSurvey,
    editQualificationSurveyModal,
  } = useContext(EmployeeSubPageContext)
  const { employeeNumber, firstName, lastName } = employee
  // ---------- States ----------
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  // eslint-disable-next-line
  const [error, setError] = useState({ fromDate: false, qualification: false })

  //  console.log(locationCatalogQualification)
  // eslint-disable-next-line
  const [price, setPrice] = useState(Number('0.00'))

  const [selectedSurveyFromDate, setSelectedSurveyFromDate] = useState(
    selectedSurvey.startDate
  )
  const [selectedSurveyToDate, setSelectedSurveyToDate] = useState(
    selectedSurvey?.startDate &&
      selectedDatePlus60Days(selectedSurvey?.startDate)
  )

  // ---------- useEffects ----------
  // Qualification can only be selected from one source either by "role" or by "organisation" or "Catalog"
  useEffect(() => {
    localStorage.removeItem('employeeSubResetOnReload2')
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'employeeSubSelectedSurveyFromDate',
      selectedSurveyFromDate
    )
  }, [selectedSurveyFromDate])

  return (
    <StyledModal>
      <Modal
        setState={setEditQualificationSurveyModal}
        title={t("pageEmployees.editSurvey.title")}
        modalStyle={{maxWidth: "50vw"}}
      >
        <Grid container columns={24}>
          {/** ---------- Person ---------- */}
          <Grid item xs={24} className='person'>
            <span>{employeeNumber}</span>
            <span>
              {firstName} {lastName}
            </span>
            <span>{professionalRoleName}</span>
          </Grid>
  
          {/** ---------- Info-text ---------- */}
          <Grid item xs={24}>
            <Grid item xs={24} className='info-text'>
              <p>{t("pageEmployees.editSurvey.infoText")}</p>
            </Grid>
          </Grid>
  
          {/** ---------- Right Columns ---------- */}
          <Grid item xs={12} px={0}>
            <Grid item xs={24}>
              <div className='survey-wrapper'>
                <StyledLabel>
                  <p>{t("pageEmployees.editSurvey.selectedQualification")}</p>
                  <input
                    value={selectedSurvey.qualificationName}
                    type='text'
                    readOnly
                  />
                </StyledLabel>
                <span className='price'>{price.toFixed(2)} {t('common.currency')}</span>
              </div>
  
              <div className='survey-date-wrapper'>
                <div className='start-date'>
                  <StyledLabel>
                    <p>
                      {t("pageEmployees.editSurvey.startDate")}
                      {error.fromDate && (
                        <span style={{ color: 'red' }}>{t("pageEmployees.editSurvey.chooseDate")}</span>
                      )}
                    </p>
                    <input
                      value={selectedSurveyFromDate}
                      onChange={(e) => {
                        const regex = new RegExp(
                          /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
                        )
                        if (regex.test(e.target.value)) {
                          setSelectedSurveyFromDate(e.target.value)
                          setSelectedSurveyToDate(
                            (prev) =>
                              (prev = selectedDatePlus60Days(e.target.value))
                          )
                        }
                      }}
                      min={new Date().toISOString().split('T')[0]}
                      id='date'
                      type='date'
                    />
                  </StyledLabel>
                </div>
  
                <div className='end-date'>
                  <StyledLabel>
                    <p>{t("pageEmployees.editSurvey.endDate")}</p>
                    <input
                      value={selectedSurveyToDate}
                      readOnly
                      type='text'
                      style={{ maxWidth: '90%' }}
                    />
                  </StyledLabel>
                </div>
  
                <div className='survey-date-info-text'>
                  <p>{t("pageEmployees.editSurvey.dateInfo")}</p>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={24} className='add-btn '>
            <Delete
              style={{ margin: '0 20px' }}
              onClick={() => setShowDeleteConfirmModal(true)}
            >
              {t("buttons.removeButton")}
            </Delete>
            <Button
              onClick={(e) => {
                selectedSurveyFromDate !== null &&
                  editSurveyOrEducation({
                    editQualificationSurveyModal,
                    individualId,
                    qualificationId: selectedSurvey.qualificationId,
                    price,
                    startDate: selectedSurveyFromDate,
                    id: selectedSurvey.id,
  
                    setEditQualificationSurveyModal,
                    type: 'qualification',
                  }).then((data) => {
                    let newOngoing = [...ongoing]
  
                    const indexOfSelected = ongoing.findIndex(
                      (survey) => survey.id === selectedSurvey.id
                    )
                    newOngoing[indexOfSelected] = data
  
                    setOngoing(newOngoing)
                    setEditQualificationSurveyModal(false)
                    localStorage.setItem(
                      'employeeSelectedSurvey',
                      JSON.stringify(newOngoing)
                    )
                    localStorage.removeItem(
                      'employeeSubAddSurveyQualificationByOrg'
                    )
                  })
              }}
            >
              {t("buttons.saveButton")}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      {showDeleteConfirmModal && (
        <Modal
          setState={setShowDeleteConfirmModal}
          title={t("pageEmployees.editSurvey.deleteTitle")}
          modalStyle={{ width: '500px' }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={12} py={2}>
                <p>
                  {t("pageEmployees.editSurvey.confirmDelete", {
                    qualificationName: selectedSurvey.qualificationName,
                    employeeDetails: `${employeeNumber} ${firstName} ${lastName} ${professionalRoleName}`,
                  })}
                </p>
                <br />
                <p>{t("pageEmployees.editSurvey.chargeInfo")}</p>
              </Grid>
              <Grid item xs={12} className='add-btn '>
                <Button
                  onClick={(e) => {
                    setShowDeleteConfirmModal(false)
                  }}
                >
                  {t("pageEmployees.editSurvey.cancel")}
                </Button>
                <Delete
                  style={{ margin: '0 20px' }}
                  onClick={() => {
                    deleteSurveyOrEducation({
                      id: selectedSurvey.id,
                      setOngoing,
                    })
                    setEditQualificationSurveyModal(false)
                  }}
                >
                  {t("buttons.removeButton")}
                </Delete>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      )}
    </StyledModal>
  )
}  

export default EditSurvey
