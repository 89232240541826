import React, { useContext, useState, useEffect, Fragment } from 'react'
import { ArrowButton, Button, Delete } from '../../../components/button/Button'
import Modal from '../../../components/modal/Modal'
import StyledLabel from '../../../components/styledLabel/StyledLabel'
import { updateField } from '../../../utils/helper'
import {
  addEducation,
  deleteEducation,
  editEducation,
  unPublishEducation,
} from '../services/Crud'
import { EducationContext } from '../Educations'
import { Box } from '@mui/system'
import StyledSelect from '../../../components/styledSelect/StyledSelect'
import { Link, useLocation } from 'react-router-dom'
import { getQualifications } from '../../qualifications/functions'
import ControlledRadioButtonsGroup from '../../../components/radioButton/RadioButton'
import { useTranslation } from 'react-i18next'

const ModalAddEdit = ({ option }) => {  
  const { t } = useTranslation()
  const {
    qualificationsList,
    setQualificationsList,
    educationsList,
    setEducationsList,
    selectedEducation,
    setSelectedEducation,
    selectedCountryToRemove,
    setModalAddIsVisible,
    setModalEditIsVisible,
    setModalDeleteIsVisible,
    organisationNumber,
    organisationId,
    deleteOption,
    setDeleteOption,
    loadOldStates,
  } = useContext(EducationContext)

  const location = useLocation()
  const { catalogQualification } = location.state || {
    catalogQualification: undefined,
  }

  const isInCatalog = selectedEducation?.educationCountries?.length > 0

  const [hasError, setError] = useState({
    title: false,
    description: false,
    select: false,
    outputBox: false,
  })
  // FIXME Translation
  const [selectOption, setSelectOption] = useState(
    loadOldStates
      ? JSON.parse(localStorage.getItem('educationsModalAddEditSelectOption'))
      : 'internal'
  )
  const [addFormData, setAddFormData] = useState(
    loadOldStates
      ? JSON.parse(localStorage.getItem('educationsModalAddEditAddFormData'))
      : {
          educationName:
            option === 'add' ? '' : selectedEducation.educationName,
          educationDescription:
            option === 'add' ? '' : selectedEducation.educationDescription,
        }
  )

  const [temp, setTemp] = useState(
    catalogQualification
      ? {
          label: catalogQualification.qualificationName,
          value: catalogQualification.id,
        }
      : loadOldStates
      ? JSON.parse(localStorage.getItem('educationsModalAddEditTemp'))
      : (option === 'add' && {
          label: '',
          value: '',
        }) ||
        (option === 'edit' && {
          label: selectedEducation.qualification.qualificationName,
          value: selectedEducation.qualification.id,
        })
  )
  // Runs on page loads
  useEffect(() => {
    let isLoading = true

    // Get all Qualifications
    getQualifications(organisationNumber).then((data) => {
      if (isLoading) {
        setQualificationsList(
          data.map((qualification) => {
            return {
              label: qualification.qualificationName,
              value: qualification.id,
            }
          })
        )
      }
    })

    // clean up function
    return () => {
      isLoading = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'educationsModalAddEditAddFormData',
      JSON.stringify(addFormData)
    )
  }, [addFormData])

  useEffect(() => {
    localStorage.setItem('educationsModalAddEditTemp', JSON.stringify(temp))
  }, [temp])

  useEffect(() => {
    localStorage.setItem(
      'educationsModalAddEditSelectOption',
      JSON.stringify(selectOption)
    )
  }, [selectOption])

  const handleAddSubmit = (e) => {
    e.preventDefault()
    let selectedQualificationFieldIsEmpty = temp.value.length < 1
    let educationNameFieldIsEmpty = addFormData.educationName.length < 1
    let educationDescriptionFieldIsEmpty =
      addFormData.educationDescription.length < 1

    if (educationNameFieldIsEmpty) {
      setError((prev) => ({ ...prev, title: true }))
    } else {
      setError((prev) => ({ ...prev, title: false }))
    }

    if (educationDescriptionFieldIsEmpty) {
      setError((prev) => ({ ...prev, description: true }))
    } else {
      setError((prev) => ({ ...prev, description: false }))
    }

    if (selectedQualificationFieldIsEmpty && selectOption === 'internal') {
      setError((prev) => ({ ...prev, select: true }))
    } else {
      setError((prev) => ({ ...prev, select: false }))
    }

    if (selectedQualificationFieldIsEmpty && selectOption === 'catalog') {
      setError((prev) => ({ ...prev, outputBox: true }))
    } else {
      setError((prev) => ({ ...prev, outputBox: false }))
    }

    if (
      (!hasError.outputBox || !hasError.select) &&
      !educationNameFieldIsEmpty &&
      !educationDescriptionFieldIsEmpty
    ) {
      ;(option === 'add' &&
        addEducation(e, {
          addFormData,
          setModalAddIsVisible,
          qualificationId: temp.value,
          organisationId,
          setEducationsList,
        })) ||
        (option === 'edit' &&
          editEducation(e, {
            setModalEditIsVisible,
            selectedEducation,
            addFormData,
            qualificationId: temp.value,
            setEducationsList,
            educationsList,
          }))
    }
  }

  return (
    <Modal
      setState={
        option === 'add'
          ? setModalAddIsVisible
          : option === 'edit'
          ? setModalEditIsVisible
          : setModalDeleteIsVisible
      }
      title={
        (option === 'add' && t('educations.modal.headerAdd')) ||
        (option === 'edit' && t('educations.modal.headerEdit')) ||
        (option === 'country' && t('educations.modal.headerEdit')) ||
        (option === 'delete' && t('educations.modal.headerDelete'))
      }
    >
      {(option === 'add' || option === 'edit') && (
        <form
          style={{ paddingTop: '10px' }}
          onSubmit={(e) =>
            (option === 'add' &&
              handleAddSubmit(e, {
                addFormData,
                setModalAddIsVisible,
                qualificationId: temp.value,
                organisationId,
                setEducationsList,
              })) ||
            (option === 'edit' &&
              handleAddSubmit(e, {
                addFormData,
                setModalAddIsVisible,
                qualificationId: temp.value,
                organisationId,
                setEducationsList,
              }))
          }
        >
          <Box display='flex'>
            {/** ---------- left-column ---------- */}
            <div className='left-column'>
              <div className='top'>
                <StyledLabel
                  className={hasError.title ? 'error' : ''}
                  errorContent={hasError.title ? t('educations.modal.errorEnterTitle') : ''}
                >
                  <p>{t('educations.modal.educationName')} *</p>
                  <input
                    type='text'
                    name='educationName'
                    value={addFormData.educationName}
                    onChange={(e) =>
                      updateField(e, { addFormData, setAddFormData })
                    }
                  />
                </StyledLabel>
              </div>
              <div className='bottom'>
                <StyledLabel
                  className={hasError.description ? 'error' : ''}
                  errorContent={
                    hasError.description ? t('educations.modal.errorEnterDescription') : ''
                  }
                >
                  <p>{t('educations.modal.description')} *</p>
                  <textarea
                    style={{ resize: 'none', padding: '10px' }}
                    type='text'
                    rows='7'
                    name='educationDescription'
                    value={addFormData.educationDescription}
                    onChange={(e) =>
                      updateField(e, { addFormData, setAddFormData })
                    }
                  />
                </StyledLabel>
              </div>
            </div>
            {/** ---------- right-column ---------- */}

            <div className='right-column'>
              <p>
                {t('educations.modal.chooseQualification')}
              </p>

              <div className='select-qualification'>
                <div className='select-from'>
                  <ControlledRadioButtonsGroup
                    options={[
                      { label: t('educations.modal.internal'), value: 'internal' },
                      { label: t('educations.modal.catalog'), value: 'catalog' },
                    ]}
                    initialValue={'internal'}
                    selectOption={selectOption}
                    setSelectOption={setSelectOption}
                    row={true}
                  />
                </div>

                {selectOption === 'internal' && (
                  <StyledLabel
                    className={
                      hasError.select
                        ? 'select-qual__label error'
                        : 'select-qual__label'
                    }
                    errorContent={
                      hasError.select ? t('educations.modal.errorEnterQualification') : ''
                    }
                  >
                    <p>{t('educations.modal.internalQualifications')} *</p>
                    <StyledSelect
                      dark
                      value={{
                        label: temp.label || t('educations.modal.internalQualifications'),
                        value: temp.value,
                      }}
                      onChange={(e) => {
                        setTemp(e)
                      }}
                      options={qualificationsList}
                    />
                  </StyledLabel>
                )}
              </div>
              {selectOption === 'catalog' && (
                <div className='to-catalog-btn'>
                  <Link
                    to={{
                      pathname: '/catalog',
                      state: {
                        includeResults: ['qualifications'],
                        prevPath: '/educations',
                        saveStates: true,
                      },
                    }}
                  >
                    <ArrowButton sm className='pointer'>
                      {t('educations.modal.toCatalog')}
                    </ArrowButton>
                  </Link>
                </div>
              )}

              <div className='selected-qualification'>
                <StyledLabel
                  className={hasError.outputBox ? 'error' : ''}
                  errorContent={
                    hasError.outputBox ? t('educations.modal.selectedQualification') : ''
                  }
                >
                  <p>{t('educations.modal.selectedQualification')} *</p>
                  <input type='text' value={temp.label || ''} readOnly />
                </StyledLabel>
              </div>

              {option === 'add' && (
                <Fragment>
                  <div className='add-modal-btn'>
                    <Button type='Add'>{t('educations.modal.addButton')}</Button>
                  </div>
                </Fragment>
              )}

              {option === 'edit' && (
                <div className='edit-modal-btn'>
                  <Delete
                    onClick={(e) => {
                      e.preventDefault()
                      setDeleteOption('')
                      setModalEditIsVisible(true)
                      setModalDeleteIsVisible(true)
                    }}
                  >
                    {t('educations.modal.delete')}
                  </Delete>
                  <Button type='submit' style={{ marginLeft: '10px' }}>
                    {t('educations.modal.saveButton')}
                  </Button>
                </div>
              )}
            </div>
          </Box>
        </form>
      )}
      {option === 'delete' && deleteOption === '' && isInCatalog ? (
        <div className='un-publish-msg-wrapper'>
          <p>
            {t('educations.modal.errorCannotDeletePublished')}
          </p>
          <div className='btn-wrapper'>
            <Button
              type='submit'
              style={{ marginLeft: '10px' }}
              onClick={() => setModalDeleteIsVisible(false)}
            >
              {t('educations.modal.cancelButton')}
            </Button>
          </div>
        </div>
      ) : option === 'delete' && deleteOption === '' ? (
        <div className='delete-modal'>
          <br />
          <p>{t('educations.modal.promptSureDelete1')} </p>
          <br />
          <p className='bold'>{selectedEducation.educationName}?</p>
          <br />
          <p>{t('educations.modal.promptUnreversible')}</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '36px',
              paddingBottom: '25px',
            }}
          >
            <Delete
              onClick={(e) => {
                e.preventDefault()
                deleteEducation({
                  setModalDeleteIsVisible,
                  educationsList,
                  setEducationsList,
                  selectedEducation,
                })
                setModalEditIsVisible(false)
                setModalDeleteIsVisible(false)
              }}
            >
              {t('educations.modal.delete')}
            </Delete>
            <Button
              type='submit'
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setModalDeleteIsVisible(false)
              }}
            >
              {t('educations.modal.cancelButton')}
            </Button>
          </div>
        </div>
      ) : (
        option === 'country' && (
          <div className='delete-modal'>
            <br />
            <p>
              {t('educations.modal.promptSureDelete1')}{' '}
              <span className='bold'>{selectedCountryToRemove.country}</span> {t('educations.modal.promptSureDelete2')}{' '}
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '36px',
                paddingBottom: '25px',
              }}
            >
              <Delete
                onClick={(e) => {
                  e.preventDefault()
                  unPublishEducation(e, {
                    organisationId,
                    setModalDeleteIsVisible,
                    educationsList,
                    setEducationsList,
                    selectedCountryToRemove,
                    selectedEducation,
                    setSelectedEducation,
                  })
                }}
              >
                {t('educations.modal.delete')}
              </Delete>
              <Button
                type='submit'
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  setModalDeleteIsVisible(false)
                }}
              >
                {t('educations.modal.cancelButton')}
              </Button>
            </div>
          </div>
        )
      )}
    </Modal>
  )
}
export default ModalAddEdit

