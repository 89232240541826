import React, { Fragment, useContext, useState } from 'react'

import { Button } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import StyledLabel from '../../../../components/styledLabel/StyledLabel'
import { addArea, handleAddFormChange } from '../../functions'
import { AreaContext } from '../QualificationAreas'
import { Wrapper } from '../Style'
import { useTranslation } from 'react-i18next'

const AddArea = () => {
  const { t } = useTranslation()
  const initializedAddFormData = {
    name: '',
    description: '',
    published: 0,
    modified: 1,
  }
  // State for the form inputs
  const [addFormData, setAddFormData] = useState(initializedAddFormData)

  // Getting som states from
  const {
    subQualification,
    modalAddIsVisible,
    dispatchModalAddIsVisible,
    setAreaList,
  } = useContext(AreaContext)

  return (
    <Fragment>
      <Modal
        title={t('competenceArea.addCompetenceArea')}
        dispatcher={{ modalAddIsVisible, dispatchModalAddIsVisible }}
      >
        <Wrapper>
          <form
            onSubmit={(e) =>
              addArea(e, {
                id: subQualification.id,
                addFormData,
                setAreaList,
                dispatchModalAddIsVisible,
              })
            }
          >
            <StyledLabel>
              <p>{t('competenceArea.addCompetenceArea')} *</p>
              <input
                type='text'
                name='name'
                required='required'
                placeholder={t('competenceArea.addCompetenceArea')}
                onChange={(e) =>
                  handleAddFormChange(e, addFormData, setAddFormData)
                }
              />
            </StyledLabel>
            <StyledLabel>
              <br />
              <p>{t('common.description')} *</p>
              <textarea
                name='description'
                className='textarea'
                required='required'
                rows='5'
                placeholder={t('competenceArea.descriptionPlaceholder')}
                onChange={(e) =>
                  handleAddFormChange(e, addFormData, setAddFormData)
                }
              ></textarea>
            </StyledLabel>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '30px',
              }}
            >
              <Button>{t('common.add')}</Button>
            </div>
          </form>
        </Wrapper>
      </Modal>
    </Fragment>
  )
}

export default AddArea
