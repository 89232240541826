import React, { Fragment, useContext, useEffect, useState } from 'react'

import { Button, Delete } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import { deleteSubQualification } from '../../functions'
import { SubQualificationContext } from '../SubQualification'
import { useTranslation } from 'react-i18next'

const DeleteSubQualification = () => {
  const { t } = useTranslation()
  const {
    id: qualId,
    setModalDeleteIsVisible,
    dispatchModalEditIsVisible,
    modalEditIsVisible,
    subQualificationsList,
    setSubQualificationsList,
    subQualificationId,
  } = useContext(SubQualificationContext)

  // To get the selected qualification
  const currentIndex = subQualificationsList.findIndex(
    (qualification) => qualification.id === subQualificationId
  )

  // The name is for the delete modal msg and the id is to the deleteQualification function
  const [currentSubQualification, setCurrentSubQualification] = useState({
    name: '',
    id: Number(),
  })

  useEffect(() => {
    // This is to update the state of name and id every time the edit btn is clicked in qualification table
    setCurrentSubQualification({
      name: subQualificationsList[currentIndex].name,
      id: subQualificationsList[currentIndex].id,
    })
    // eslint-disable-next-line
  }, [subQualificationId])

  return (
    <Fragment>
      <Modal
        title={t('competenceArea.modal.deleteTitle')} 
        setState={setModalDeleteIsVisible}
      >
        <br />
        <br />
        <p>
        {t('competenceArea.modal.subDeleteInfo1')} {' '}
          <span className="bold">{currentSubQualification.name}</span>?
        </p>
        <br />
        <p>
        {t('competenceArea.modal.subDeleteInfo2')}
        </p>
        <br />
        <p>{t('competenceArea.modal.deleteInfo3')}</p>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '36px',
            paddingBottom: '20px',
          }}
        >
          <Delete
            onClick={() =>
              deleteSubQualification({
                dispatchModalEditIsVisible,
                modalEditIsVisible,
                setModalDeleteIsVisible,
                setSubQualificationsList,
                subQualificationsList,
                id: currentSubQualification.id,
                qualId,
              })
            }
          >
            {t('competenceArea.modal.buttonDelete')}
          </Delete>
          <Button
            type='submit'
            style={{ marginLeft: '10px' }}
            onClick={() => setModalDeleteIsVisible(false)}
          >
            {t('competenceArea.modal.buttonCancel')}
          </Button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default DeleteSubQualification
