import React, { Fragment, useContext, useState } from 'react'

import { Button, Delete } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import StyledLabel from '../../../../components/styledLabel/StyledLabel'
import { editRequirement, handleAddFormChange } from '../../functions'
import { AreaContext } from '../QualificationAreas'
import { Wrapper } from '../Style'
import { useTranslation } from 'react-i18next'

const EditRequirement = () => {
  const { t } = useTranslation();

  // destructuring context
  const {
    modalEditIsVisible,
    dispatchModalEditIsVisible,
    selectedRequirement,
    areaList,
    setAreaList,
    selectedArea,
    setModalDeleteIsVisible,
  } = useContext(AreaContext)

  const initializedAddFormData = {
    description: selectedRequirement.description,
    published: 0,
    modified: 1,
    passQuestions: selectedRequirement.passQuestions,
    totalQuestions: selectedRequirement.totalQuestions,
  }

  const [addFormData, setAddFormData] = useState(initializedAddFormData)

  return (
    <Fragment>
      <Modal
        title={t('pageQualifications.modalEditRequirement.title')}
        dispatcher={{ modalEditIsVisible, dispatchModalEditIsVisible }}
      >
        <Wrapper>
          <form
            onSubmit={(e) =>
              editRequirement(e, {
                addFormData,
                selectedArea,
                modalEditIsVisible,
                selectedRequirement,
                areaList,
                setAreaList,
                dispatchModalEditIsVisible,
              })
            }
          >
            <StyledLabel>
              <p>{t('pageQualifications.modalEditRequirement.descriptionLabel')}</p>
              <textarea
                name='description'
                defaultValue={addFormData.description}
                className='textarea'
                required='required'
                rows='5'
                placeholder={t('pageQualifications.modalEditRequirement.descriptionPlaceholder')}
                onChange={(e) =>
                  handleAddFormChange(e, addFormData, setAddFormData)
                }
              ></textarea>
            </StyledLabel>
            <div className='questions qualificationQuestion'>
              <StyledLabel className='no-of-questions'>
                <p>{t('pageQualifications.modalEditRequirement.amountQuestionsLabel')}</p>
                <input
                  disabled
                  defaultValue={selectedRequirement?.amountQuestions || 0}
                  type='number'
                />
              </StyledLabel>

              <StyledLabel>
                <p>{t('pageQualifications.modalEditRequirement.totalQuestionsLabel')}</p>
                <input
                  id='totalQuestions'
                  name='totalQuestions'
                  type='number'
                  defaultValue={addFormData.totalQuestions}
                  onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === 'E') e.preventDefault()
                  }}
                  onChange={(e) =>
                    handleAddFormChange(e, addFormData, setAddFormData)
                  }
                />
              </StyledLabel>
              <StyledLabel>
                <p>{t('pageQualifications.modalEditRequirement.passQuestionsLabel')}</p>
                <input
                  id='passQuestions'
                  name='passQuestions'
                  type='number'
                  defaultValue={addFormData.passQuestions}
                  onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === 'E') e.preventDefault()
                  }}
                  onChange={(e) =>
                    handleAddFormChange(e, addFormData, setAddFormData)
                  }
                />
              </StyledLabel>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '36px',
              }}
            >
              <Delete
                onClick={(e) => {
                  e.preventDefault()
                  setModalDeleteIsVisible(true)
                }}
              >
                {t('pageQualifications.modalEditRequirement.deleteButton')}
              </Delete>
              <Button type='submit' style={{ marginLeft: '10px' }}>
                {t('pageQualifications.modalEditRequirement.saveButton')}
              </Button>
            </div>
          </form>
        </Wrapper>
      </Modal>
    </Fragment>
  )
}

export default EditRequirement
