import React, { Fragment, useContext, useState } from 'react'
import Modal from '../../../components/modal/Modal'
import { EducationContext } from '../Educations'
import { Button } from '../../../components/button/Button'
import Flag from 'react-world-flags'
import StyledSelect from '../../../components/styledSelect/StyledSelect'
import { useTranslation } from 'react-i18next'

const Share = ({ option }) => {
  const { t } = useTranslation()
  const {
    selectedEducation,
    setModalShareIsVisible,
    setModalDeleteIsVisible,
    setDeleteOption,
    setSelectedCountryToRemove,
    setModalConfirmTempCountry,
    setModalConfirmIsVisible,
  } = useContext(EducationContext)

  const [tempCountry, setTempCountry] = useState({
    label: 'Sweden',
    value: 'SE',
    icon: (
      <Flag code='SE' height={30} width={30} style={{ marginRight: '10px' }} />
    ),
  })

  const [page, setPage] = useState('publish')

  return (
    <Modal
      setState={option === 'share' && setModalShareIsVisible}
      title={option === 'share' && t('educations.share.title')}
    >
      <div className='share'>
        <nav>
          <ul>
            <li className='pointer' onClick={() => setPage('share')}>
              <span
                style={
                  page === 'share'
                    ? {
                      borderBottom: '1px solid grey',
                      padding: '2px 0',
                      fontWeight: 'bold',
                    }
                    : { padding: '2px 0' }
                }
              >
                {t('educations.share.title')}
              </span>
            </li>
            <li className='pointer' onClick={() => setPage('publish')}>
              <span
                style={
                  page === 'publish'
                    ? {
                      borderBottom: '1px solid grey',
                      padding: '2px 0',
                      fontWeight: 'bold',
                    }
                    : {}
                }
              >
                {t('educations.share.publish')}
              </span>
            </li>
          </ul>
        </nav>
        {page === 'publish' && (
          <Fragment>
            <div className='m-top-btm-20'>
              <p>
                {t('educations.share.selectCountry')}
              </p>
              <div className='m-top-btm-20 flex align-button sb border-btm'>
                <StyledSelect
                  dark
                  country
                  value={tempCountry}
                  onChange={(e) => setTempCountry(e)}
                  options={[
                    {
                      label: 'Sweden',
                      value: 'SE',
                      icon: (
                        <Flag
                          code='SE'
                          height={30}
                          width={30}
                          style={{ marginRight: '10px' }}
                        />
                      ),
                    },
                    {
                      label: 'Great Britain',
                      value: 'GB',
                      icon: (
                        <Flag
                          code='GB'
                          height={30}
                          width={30}
                          style={{ marginRight: '10px' }}
                        />
                      ),
                    },
                  ]}
                />
                {selectedEducation.educationCountries.some(
                  (shared) => shared.country === tempCountry.label
                ) ? (
                  <p className='published'>
                      {t('educations.share.promptAlreadyPublished')}
                  </p>
                ) : (
                  <Button
                    onClick={(e) => {
                      setModalConfirmIsVisible(true)
                      setModalShareIsVisible(false)
                      setModalConfirmTempCountry(tempCountry)
                    }}
                  >
                    {t('educations.share.buttonPublish')}
                  </Button>
                )}
              </div>
            </div>

            <div className='list'>
              <p>{t('educations.share.publishedVersionsListTitle')}</p>
              <div className='shared-container flex'>
                <Fragment>
                  <div className='land'>
                    <p className='title'>{t('educations.share.country')}</p>
                    {selectedEducation.educationCountries &&
                      selectedEducation.educationCountries.map(
                        ({ id, country }) => (
                          <p key={id} className='item'>
                            {country}
                          </p>
                        )
                      )}
                  </div>

                  <div className='date'>
                    <p className='title'>{t('educations.share.date')}</p>
                    {selectedEducation.educationCountries &&
                      selectedEducation.educationCountries.map(
                        ({ id, localDate }) => (
                          <p key={id} className='item'>
                            {localDate}
                          </p>
                        )
                      )}
                  </div>

                  <div style={{ maxWidth: '60px' }}>
                    <p className='item'>
                      <br />
                    </p>
                    {selectedEducation.educationCountries &&
                      selectedEducation.educationCountries.map(
                        ({ id, country }) => (
                          <p
                            key={id}
                            className='item pointer'
                            onClick={(e) => {
                              setSelectedCountryToRemove({ id, country })
                              setDeleteOption('country')
                              setModalDeleteIsVisible(true)
                            }}
                          >
                            {t('educations.share.delete')}
                          </p>
                        )
                      )}
                  </div>
                </Fragment>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'flex-end',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                }}
              >
                <Button onClick={() => setModalShareIsVisible(false)}>
                  {t('educations.share.done')}
                </Button>
              </div>
            </div>
          </Fragment>
        )}

        {page === 'share' && (
          <div
            style={{
              display: 'grid',
              gridTemplate: '1fr 1fr',
            }}
          >
            <p
              style={{
                margin: '22% auto',
              }}
            >
              {t('educations.share.shareQualificationUnderConstructionMessage')}
            </p>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default Share
