import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { AddButton } from '../../../../../components/button/Button'
import PageTitleSpecial from '../../../../../components/pageTitle/PageTitleSpecial'
import { getSubQualifications } from '../../../../qualifications/functions'
import { EducationContext } from '../../../Educations'
import { ReactComponent as EditPencil } from '../../../../../assets/img/Edit_pencil.svg'
import { EducationModulesContext } from '../EducationModules'
import { Context } from '../../../../../utils/Context'
import { simpleUser } from './../../../../../utils/Amplify';
import { useTranslation } from 'react-i18next'


const ModulesTable = ({ setModalModule }) => {
    const { t } = useTranslation()
    const { employee:{educationAccess} } = useContext(Context)
    const mounted = useRef(true)

    const { selectedQualification, selectedEducation } = useContext(EducationContext)
    const { modules, setModules, subQualifications, setSubQualifications, setSelectedSubQualification, setModalSubQualification } = useContext(EducationModulesContext)

    useEffect(() => {
        const getModules = async () => {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/organisation/education/get/module/${selectedEducation.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (await simpleUser()).token,
                  },
            })

            const resModules = await res.json()

            if (mounted.current) {
                setModules(resModules)
            }
        }

        getSubQualifications(selectedQualification.id).then((res) => {
            if (mounted.current) {
                setSubQualifications(res)
            }
        })

        getModules()

        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="modules-table">
            <p className="subtitle">{t('educations.moduleTable.title')}</p>
            <PageTitleSpecial
                className='small'
                title={selectedQualification.qualificationName}
                description={
                    <p className="bold">{selectedQualification.qualificationDescription}</p>
                }
            />
            <TableContainer>
                <Table size="small">
                    <colgroup>
                        <col style={{ width: '55%' }} />
                        <col style={{ width: '40%' }} />
                        <col style={{ width: '5%' }} />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ borderRight: '1px solid #CFCFD5' }}>
                                <p className="subtitle">{t('educations.moduleTable.subtitle1')}</p>
                            </TableCell>
                            <TableCell>
                                <p className="subtitle">{t('educations.moduleTable.subtitle2')}</p>
                            </TableCell>
                            <TableCell>

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subQualifications.map((subQualification, i) => {
                            const module = modules.find(mod => mod.subQualification.id === subQualification.id)

                            return (
                                <TableRow key={i}>
                                    <TableCell sx={{ borderRight: '1px solid #CFCFD5' }}>
                                        <Link
                                            to={`${selectedEducation.educationName}/${subQualification.name}`}
                                            onClick={() => setSelectedSubQualification(subQualification)}
                                        >{subQualification.name}</Link>
                                    </TableCell>
                                    <TableCell>
                                        {module &&
                                            <a target='_blank' rel='noreferrer' href={module.moduleLink}>
                                                {module.moduleName}
                                            </a>
                                        }
                                    </TableCell>
                                    {educationAccess ==='edit'? ( <TableCell>
                                        { module ?
                                            <EditPencil
                                                style={{ display: 'block', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setModalModule({ ...module, mode: 'edit' })
                                                    setModalSubQualification(subQualification)
                                                }}
                                            />
                                            :
                                            <AddButton
                                                onClick={() => {
                                                    setModalModule({ mode: 'add' })
                                                    setModalSubQualification(subQualification)
                                                }}
                                            />
                                        }
                                    </TableCell>)
                                    :<TableCell></TableCell>
                                    
                                    }
                                </TableRow>
                            )
                        }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default ModulesTable
