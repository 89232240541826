import { simpleUser } from "./../../utils/Amplify";
export const getProfessionalRoles = async (orgNum) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/organisation/${orgNum}/all/professionalrole`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await simpleUser()).token,
      },
    }
  );
  const data = await response.json();
  return data;
};

export const getOrganisationById = async (orgId) => {
  try {
    const storedUser = await simpleUser();
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/${orgId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + storedUser.token,
        },
      }
    );

    const organisation = await response.json();

    const unsortedLevelGroups = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/${orgId}/get/all/levels`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + storedUser.token,
        },
      }
    ).then((res) => res.json());

    organisation.levelOne = organisation.levelOne
      ? {
          label: organisation.levelOne,
          groups: unsortedLevelGroups
            .filter(({ typeOfLevel }) => typeOfLevel === "One")
            .map(({ id, levelName }) => ({ id, groupName: levelName })),
        }
      : null;

    organisation.levelTwo = organisation.levelTwo
      ? {
          label: organisation.levelTwo,
          groups: unsortedLevelGroups
            .filter(({ typeOfLevel }) => typeOfLevel === "Two")
            .map(({ id, levelName }) => ({ id, groupName: levelName })),
        }
      : null;

    organisation.levelThree = organisation.levelThree
      ? {
          label: organisation.levelThree,
          groups: unsortedLevelGroups
            .filter(({ typeOfLevel }) => typeOfLevel === "Three")
            .map(({ id, levelName }) => ({ id, groupName: levelName })),
        }
      : null;

    organisation.levelFour = organisation.levelFour
      ? {
          label: organisation.levelFour,
          groups: unsortedLevelGroups
            .filter(({ typeOfLevel }) => typeOfLevel === "Four")
            .map(({ id, levelName }) => ({ id, groupName: levelName })),
        }
      : null;

    return organisation;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
