import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '../../utils/Context'

const Employer = () => {
  const { t } = useTranslation()
  const { individual, employee, organisation } = useContext(Context)

  return (
    <TableContainer className='profile__employee-info__table'>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('pageEmployees.employeeSearch.textFields.employeeNumber')}
            </TableCell>
            <TableCell>
              {t('pageEmployees.employeeSearch.textFields.firstName')}
            </TableCell>
            <TableCell>
              {t('pageEmployees.employeeSearch.textFields.lastName')}
            </TableCell>
            <TableCell>
              {t('pageEmployees.searchResults.table.professionalRoleName')}
            </TableCell>
            {organisation.levelOne && (
              <TableCell>{t('pageEmployees.searchResults.table.level1')}</TableCell>
            )}
            {organisation.levelTwo && (
              <TableCell>{t('pageEmployees.searchResults.table.level2')}</TableCell>
            )}
            {organisation.levelThree && (
              <TableCell>{t('pageEmployees.searchResults.table.level3')}</TableCell>
            )}
            {organisation.levelFour && (
              <TableCell>{t('pageEmployees.searchResults.table.level4')}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{employee.employeeNumber}</TableCell>
            <TableCell>{individual.firstName}</TableCell>
            <TableCell>{individual.lastName}</TableCell>
            <TableCell>{employee.roleName}</TableCell>
            {organisation.levelOne && (
              <TableCell>{employee.levelOne}</TableCell>
            )}
            {organisation.levelTwo && (
              <TableCell>{employee.levelTwo}</TableCell>
            )}
            {organisation.levelThree && (
              <TableCell>{employee.levelThree}</TableCell>
            )}
            {organisation.levelFour && (
              <TableCell>{employee.levelFour}</TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Employer
