import { Grid } from '@mui/material'
import { useContext } from 'react'
import PageTitleSpecial from '../../../../components/pageTitle/PageTitleSpecial'
import {
  SubQualificationWrapper,
  tableBody,
} from '../../../../pages/catalog/subPages/style'
import BreadCrumbs from '../../../../utils/BreadCrumbs'
import { EducationModulesContext } from '../educationModules/EducationModules'
import { useTranslation } from 'react-i18next'

const CompetenceAreas = ({ setSubQualMode }) => {
  const { selectedSubQualification } = useContext(EducationModulesContext)
  const { qualificationArea } = selectedSubQualification
  const { t } = useTranslation()

  return (
    <SubQualificationWrapper className='sub-page-wrapper'>
      <Grid container item xs={12}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={4}>
            <BreadCrumbs custom />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} py={1} mb={2}>
            <PageTitleSpecial
              title={selectedSubQualification.name}
              info={selectedSubQualification.description}
            />
          </Grid>
          <Grid item xs={12} pb={3}>
            <div className='mode-switch'>
              <h4>
                <span
                  className='desc'
                  onClick={() => setSubQualMode('descriptions')}
                >
                  {t('common.description')}
                </span>
                <span>|</span>
                <span
                  className='reqs'
                  onClick={() => setSubQualMode('requirements')}
                >
                  {t('common.competencyRequirements')}
                </span>
              </h4>
            </div>
          </Grid>
          {/** -------------------------------- Area section -------------------------------- */}

          <Grid container item xs={12}>
            {/* start table */}
            <Grid container item xs={12}>
              {/* ----- start table-head ----- */}
              <Grid container item xs={12} className='table-header'>
                <Grid item xs={3}>
                  <p className='qualification-category'>{t('common.competencyArea')}</p>
                </Grid>
                <Grid item xs={3} sx={{ borderLeft: 0 }}>
                  <p className='qualification-category'>{t('common.knowledge')}</p>
                </Grid>
                <Grid item xs={3} sx={{ borderLeft: 0 }}>
                  <p className='qualification-category'>{t('common.skill')}</p>
                </Grid>
                <Grid item xs={3} sx={{ borderLeft: 0 }}>
                  <p className='qualification-category'>
                  {t('common.responsibilityAndIndependence')}
                  </p>
                </Grid>
              </Grid>
              {/* ----- End table-head ----- */}

              {/* ----- start table-body ----- */}
              {qualificationArea?.map(
                ({ id: areaId, name: areaName, qualificationRequirement }) => (
                  <Grid
                    key={areaId}
                    container
                    item
                    xs={12}
                    className='table-body'
                  >
                    <Grid item xs={3} sx={{ ...tableBody, borderTop: 0 }}>
                      <p className='area-name'>{areaName}</p>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{ ...tableBody, borderTop: 0, borderLeft: 0 }}
                    >
                      <div className='requirements'>
                        {qualificationRequirement
                          ?.filter(
                            (requirement) =>
                              requirement.requirementType === 'knowledge'
                          )
                          .map(({ id: requirementId, description }) => (
                            <p
                              key={requirementId}
                              className='requirement-paragraphs'
                            >
                              {description}
                            </p>
                          ))}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{ ...tableBody, borderTop: 0, borderLeft: 0 }}
                    >
                      <div className='requirements'>
                        {qualificationRequirement
                          ?.filter(
                            (requirement) =>
                              requirement.requirementType === 'skill'
                          )
                          .map(({ id: requirementId, description }) => (
                            <p
                              key={requirementId}
                              className='requirement-paragraphs'
                            >
                              {description}
                            </p>
                          ))}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{ ...tableBody, borderTop: 0, borderLeft: 0 }}
                    >
                      <div className='requirements'>
                        {qualificationRequirement
                          ?.filter(
                            (requirement) =>
                              requirement.requirementType ===
                              'responsibilityAndIndependence'
                          )
                          .map(({ id: requirementId, description }) => (
                            <p
                              key={requirementId}
                              className='requirement-paragraphs'
                            >
                              {description}
                            </p>
                          ))}
                      </div>
                    </Grid>
                  </Grid>
                )
              )}
              {/* ----- End table-body ----- */}
            </Grid>
            {/* ----- End table ----- */}
          </Grid>
        </Grid>
      </Grid>
    </SubQualificationWrapper>
  )
}

export default CompetenceAreas
