import styled from "styled-components";

export const Wrapper = styled.main`
    margin: 59px 214px 37px 214px;
    
    .grid {
        display: grid;
        grid-template-columns: max-content auto;
        grid-column-gap: 30px;
        
        h3 {
            display: inline-block;
            color: #3f3d56;
            
            :hover {
                text-decoration: underline;
            }
        }
    
        h3, p {
            display: flex;
            align-items: flex-end;
        }
    
        hr {
            grid-column: 1 / 3;
            margin-top: 5px;
            margin-bottom: 65px;
        }
    }

`