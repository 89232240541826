import React, { Fragment, useContext, useEffect, useState } from 'react'

import { Button, Delete } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import StyledLabel from '../../../../components/styledLabel/StyledLabel'
import StyledSelect from '../../../../components/styledSelect/StyledSelect'
import { handleEditFormChange, handleEditFormSubmit } from '../../functions'
import { QualificationContext } from '../Qualification'
import { QualModalStyle } from './QualModalStyle'
import { useTranslation } from 'react-i18next'

const EditQualification = () => {
  const { t } = useTranslation();
  // TODO Translation needed!

  const {
    qualificationsList,
    setQualificationsList,
    modalEditIsVisible,
    setModalEditIsVisible,
    setModalDeleteIsVisible,
    qualificationId,
    setQualificationId,
  } = useContext(QualificationContext)

  const [currentIndex, setCurrentIndex] = useState(
    qualificationsList.findIndex(
      (qualification) => qualification.id === qualificationId
    )
  )

  useEffect(() => {
    setCurrentIndex(
      qualificationsList.findIndex(
        (qualification) => qualification.id === qualificationId
      )
    )
    // eslint-disable-next-line
  }, [setQualificationId])

  const [editFormData, setEditFormData] = useState({
    qualificationName: qualificationsList[`${currentIndex}`].qualificationName,
    qualificationArea: qualificationsList[`${currentIndex}`].qualificationArea,
    qualificationType: qualificationsList[`${currentIndex}`].qualificationType,
    qualificationAccess:
      qualificationsList[`${currentIndex}`].qualificationAccess,
    qualificationDescription:
      qualificationsList[`${currentIndex}`].qualificationDescription,
    qualificationLanguage:
      qualificationsList[`${currentIndex}`].qualificationLanguage,
    qualificationCountries:
      qualificationsList[`${currentIndex}`].qualificationCountries || [],
    qualificationEqf: qualificationsList[`${currentIndex}`].qualificationEqf,
    version: qualificationsList[`${currentIndex}`].version,
    published: 0,
    modified: 1,
  })

  return (
    <Fragment>
      <Modal
        title={t('pageEditQualification.title')}
        setState={setModalEditIsVisible}
      >
        <QualModalStyle>
          <form
            onSubmit={(e) =>
              handleEditFormSubmit(e, {
                editFormData,
                qualificationsList,
                setQualificationsList,
                qualificationId,
                setModalEditIsVisible,
                modalEditIsVisible,
              })
            }
          >
            <StyledLabel>
              <p>{t('pageEditQualification.name')}</p>
              <input
                type='text'
                name='qualificationName'
                placeholder={t('pageEditQualification.namePlaceholder')}
                defaultValue={editFormData.qualificationName}
                onChange={(e) =>
                  handleEditFormChange(e, editFormData, setEditFormData)
                }
              />
            </StyledLabel>
            <div className='flex custom-style-1'>
              <StyledLabel>
                <p>{t('pageEditQualification.areaLabel')}</p>
                <input
                  type='text'
                  name='qualificationArea'
                  placeholder={t('pageEditQualification.areaPlaceholder')}
                  defaultValue={editFormData.qualificationArea}
                  onChange={(e) =>
                    handleEditFormChange(e, editFormData, setEditFormData)
                  }
                />
              </StyledLabel>
              <StyledLabel>
                <p>{t('pageEditQualification.typeLabel')}</p>
                <input
                  type='text'
                  name='qualificationType'
                  placeholder={t('pageEditQualification.typePlaceholder')}
                  defaultValue={editFormData.qualificationType}
                  onChange={(e) =>
                    handleEditFormChange(e, editFormData, setEditFormData)
                  }
                />
              </StyledLabel>
            </div>
            <StyledLabel>
              <p>{t('pageEditQualification.authorizationLabel')}</p>
              <input
                type='text'
                name='qualificationAccess'
                placeholder={t('pageEditQualification.authorizationPlaceholder')}
                defaultValue={editFormData.qualificationAccess}
                onChange={(e) =>
                  handleEditFormChange(e, editFormData, setEditFormData)
                }
              />
            </StyledLabel>
            <StyledLabel>
              <p>{t('pageEditQualification.descriptionLabel')}</p>
              <textarea
                type='text'
                name='qualificationDescription'
                placeholder={t('pageEditQualification.descriptionPlaceholder')}
                defaultValue={editFormData.qualificationDescription}
                onChange={(e) =>
                  handleEditFormChange(e, editFormData, setEditFormData)
                }
              ></textarea>
            </StyledLabel>
            <div className='flex custom-style-2'>
              <StyledLabel className='language-select'>
                <p>{t('pageEditQualification.languageLabel')}</p>
                <StyledSelect
                  dark
                  options={[
                    { value: 'Swedish', label: 'Swedish' },
                    { value: 'English', label: 'English' },
                  ]}
                  value={{
                    value: editFormData.qualificationLanguage,
                    label: editFormData.qualificationLanguage,
                  }}
                  onChange={(e) => {
                    setEditFormData({
                      ...editFormData,
                      qualificationLanguage: e.value,
                    })
                  }}
                />
              </StyledLabel>
              <StyledLabel>
                <p>{t('pageEditQualification.eqfLabel')}</p>

                <input
                  name='qualificationEqf'
                  style={{
                    background: '#dbdbdf',
                    minWidth: '160px',
                    width: 'auto',
                    maxWidth: 'fit-content',
                  }}
                  type='number'
                  value={editFormData.qualificationEqf}
                  min='0'
                  max='8'
                  onFocus={(e) => e.target.select()}
                  onClick={(e) => e.target.select()}
                  onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === 'E') e.preventDefault()
                  }}
                  onChange={(e) => {
                    if (e.target.value >= 0 && e.target.value <= 8)
                      setEditFormData({
                        ...editFormData,
                        qualificationEqf: e.target.value,
                      })
                  }}
                />
              </StyledLabel>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '36px',
              }}
            >
              <Delete
                type='button'
                onClick={(e) => {
                  e.preventDefault()
                  setModalDeleteIsVisible(true)
                }}
              >
                {t('buttons.deleteButton')}
              </Delete>
              <Button type='submit' style={{ marginLeft: '10px' }}>
              {t('buttons.saveButton')}
              </Button>
            </div>
          </form>
        </QualModalStyle>
      </Modal>
    </Fragment>
  )
}

export default EditQualification
