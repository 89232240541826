import React, { useState } from 'react'
import { useContext } from 'react'
import { ReactComponent as LoadingSpinner } from '../../../../assets/img/spinner.svg'
import { Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'
import PageTitleSpecial from '../../../../components/pageTitle/PageTitleSpecial'
import BreadCrumbs from '../../../../utils/BreadCrumbs'
import { useTranslation } from 'react-i18next'
import { EducationContext } from './Education'
import {
  SubQualificationWrapper,
  tableBody,
} from '../../../../pages/catalog/subPages/style'

const SubQualification = () => {
  const { t } = useTranslation()
  const { selectedSubQualification, resultsLoading } =
    useContext(EducationContext)
  const { qualificationArea } = selectedSubQualification

  const { education } = useLocation().state || {}

  const [subQualMode, setSubQualMode] = useState(
    education ? 'descriptions' : 'requirements'
  )

  return (
    <SubQualificationWrapper
      className='sub-page-wrapper'
      subQualMode={subQualMode}
    >
      {resultsLoading ? (
        <LoadingSpinner height={500} width={500} />
      ) : (
        <Grid container item xs={12}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4}>
              <BreadCrumbs custom search='type=education' />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} py={1}>
              <PageTitleSpecial
                title={selectedSubQualification.name}
                info={selectedSubQualification.description}
              />
            </Grid>
            {education && (
              <Grid item xs={12} pb={3}>
                <div className='mode-switch'>
                  <h4>
                    <span
                      className='desc'
                      onClick={() => setSubQualMode('descriptions')}
                    >
                      {t('competenceProfile.description')}
                    </span>
                    <span>|</span>
                    <span
                      className='reqs'
                      onClick={() => setSubQualMode('requirements')}
                    >
                       {t('competenceProfile.qualifikationRequirement')}
                      
                    </span>
                  </h4>
                </div>
              </Grid>
            )}

            {subQualMode === 'descriptions' ? (
              <>
                {/** -------------------------------- Descriptions section -------------------------------- */}

                <Grid container item xs={12} className='sub-qualifications'>
                  {/** Table Head */}
                  <Grid container item xs={12} className='table-head'>
                    <Grid item xs={12}>
                      <h4>Kompetensområden</h4>
                    </Grid>
                  </Grid>

                  {/** Table Body */}
                  <Grid container item xs={12} pt={2}>
                    <Grid container item xs={12} pt={2} className='table-body'>
                      {/* -------- Areas -------- */}
                      {qualificationArea &&
                        qualificationArea.map(
                          ({ description, id, name: areaName }) => (
                            <Grid
                              key={id}
                              container
                              item
                              xs={12}
                              className='areas'
                            >
                              <Grid item xs={12}>
                                <Grid pt={2}>
                                  <p className='area-title bold'>{areaName}</p>
                                  <p className='area-body'>{description}</p>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {/** -------------------------------- Area section -------------------------------- */}

                <Grid container item xs={12}>
                  {/* start table */}
                  <Grid container item xs={12}>
                    {/* ----- start table-head ----- */}
                    <Grid container item xs={12} className='table-header'>
                      <Grid item xs={3}>
                        <p className='qualification-category'>
                           {t('pageQualificationAreas.columnHeaders.0')}
                        </p>
                      </Grid>
                      <Grid item xs={3} sx={{ borderLeft: 0 }}>
                        <p className='qualification-category'>   {t('pageQualificationAreas.columnHeaders.1')}</p>
                      </Grid>
                      <Grid item xs={3} sx={{ borderLeft: 0 }}>
                        <p className='qualification-category'>   {t('pageQualificationAreas.columnHeaders.2')}</p>
                      </Grid>
                      <Grid item xs={3} sx={{ borderLeft: 0 }}>
                        <p className='qualification-category'>
                        {t('pageQualificationAreas.columnHeaders.3')}
                        </p>
                      </Grid>
                    </Grid>
                    {/* ----- End table-head ----- */}

                    {/* ----- start table-body ----- */}
                    {qualificationArea?.map(
                      ({
                        id: areaId,
                        name: areaName,
                        qualificationRequirement,
                      }) => (
                        <Grid
                          key={areaId}
                          container
                          item
                          xs={12}
                          className='table-body'
                        >
                          <Grid item xs={3} sx={{ ...tableBody, borderTop: 0 }}>
                            <p className='area-name'>{areaName}</p>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{ ...tableBody, borderTop: 0, borderLeft: 0 }}
                          >
                            <div className='requirements'>
                              {qualificationRequirement
                                ?.filter(
                                  (requirement) =>
                                    requirement.requirementType === 'knowledge'
                                )
                                .map(({ id: requirementId, description }) => (
                                  <p
                                    key={requirementId}
                                    className='requirement-paragraphs'
                                  >
                                    {description}
                                  </p>
                                ))}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{ ...tableBody, borderTop: 0, borderLeft: 0 }}
                          >
                            <div className='requirements'>
                              {qualificationRequirement
                                ?.filter(
                                  (requirement) =>
                                    requirement.requirementType === 'skill'
                                )
                                .map(({ id: requirementId, description }) => (
                                  <p
                                    key={requirementId}
                                    className='requirement-paragraphs'
                                  >
                                    {description}
                                  </p>
                                ))}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{ ...tableBody, borderTop: 0, borderLeft: 0 }}
                          >
                            <div className='requirements'>
                              {qualificationRequirement
                                ?.filter(
                                  (requirement) =>
                                    requirement.requirementType ===
                                    'responsibilityAndIndependence'
                                )
                                .map(({ id: requirementId, description }) => (
                                  <p
                                    key={requirementId}
                                    className='requirement-paragraphs'
                                  >
                                    {description}
                                  </p>
                                ))}
                            </div>
                          </Grid>
                        </Grid>
                      )
                    )}

                    {/* ----- End table-body ----- */}
                  </Grid>
                  {/* ----- End table ----- */}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </SubQualificationWrapper>
  )
}

export default SubQualification
