import Modal from "../../../../components/modal/Modal";

const ModuleModal = ({ modalModule, setModalModule }) => {    return (
        <Modal
            title={modalModule.moduleName}
            setState={setModalModule}
        >
            <p>{modalModule.moduleDescription}</p>
        </Modal>
    )
}

export default ModuleModal;