import styled from 'styled-components'

export const Wrapper = styled.main`
  margin-top: 43px;

  .react-select {
    min-width: 100%;
  }

  &.employee-wrapper {
    width: 1160px;
  }

  .employee-search {
    margin-bottom: 25px;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 31px;
    }

    form {
      .form-fields {
        background-color: #ececee;
        border-radius: 2px;
        margin-bottom: 25px;

        > div {
          grid-gap: 12px;
          width: 1059px;
          margin: 0 auto;
        }

        .text-fields {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
          justify-items: center;
          padding-top: 30px;

          label,
          input {
            max-width: 100%;
          }

          input {
            box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.2);
          }
        }

        .filter-options {
          display: grid;
          margin-top: 49px;
          grid-template-rows: auto auto auto auto;
          justify-content: space-around;

          .filter-option {
            box-sizing: border-box;
            width: 141px;
            grid-row: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            label {
              white-space: nowrap;
            }

            .include,
            .exclude,
            .exact-match {
              margin-bottom: 9px;
              display: flex;
              align-items: center;

              input,
              .checkbox {
                margin-right: 6px;
              }
            }

            .react-select {
              max-width: 200px;
            }

            .styled-autocomplete {
              min-width: 100%;
              max-width: 250px;
            }

            &.row1 {
              grid-row: 1;
            }
          }
        }
      }

      button {
        margin: 0 auto;
      }
    }
  }

  .employee-results {
    margin-top: 22px;
    margin-bottom: 162px;

    .mode-select-wrapper {
      display: inline-block;
      width: fit-content;
      position: relative;

      .mode-select {
        display: grid;
        grid-template-columns: 150px auto 150px;
        column-gap: 15px;

        color: #858585;

        .selected {
          color: #3f3d56;
        }

        h4 {
          cursor: pointer;
          font-size: 22px;
          grid-row: 1;
          text-align: center;
        }
      }
    }

    > .small-copy {
      margin-bottom: 34px;
      float: right;

      span {
        margin-left: 23px;
      }
    }
  }

  .modal-wrapper {
    .modal {
      width: 886px;

      .modal-content {
        padding-top: 40px;
        padding-bottom: 35px;

        form {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 21px;
          row-gap: 20px;

          .personal-info {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 21px;
            row-gap: 20px;
            grid-row: 1;
            grid-column: 1 / 3;

            .span-2 {
              grid-column: 1 / 3;
            }
          }

          .email {
            grid-row: 4;
            grid-column: 1 / 3;
            column-gap: 21px;
            display: grid;
            grid-template-columns: 1fr 1fr;
          }

          .react-select {
            border: solid 1px #cfcfd5;
            border-radius: 2px;
          }
        }

        .permissions {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 21px;
          row-gap: 48px;
          margin-top: 51px;
          color: black;
        }

        .buttons {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 59px;

          .add {
            position: relative;

            svg {
              position: absolute;
              height: 40px;
              width: auto;
              left: -65px;
            }
          }
        }

        &.edit {
          .buttons {
            grid-column: 3;
          }
        }
      }
    }
  }
`

export const SubPageWrapper = styled.main`
  margin-top: 30px;

  .page-title {
    margin-bottom: 67px;

    .red {
      color: #de0500;
    }
  }

  .table-container {
    .last {
      min-width: 59px;
      cursor: pointer;
    }
  }

  .suggestion-form {
    width: 500px;
    padding: 50px 0;

    .buttons {
      display: flex;
      justify-content: space-evenly;
      margin-top: 30px;

      button {
        width: 100px;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    padding-bottom: 20px;
    gap: 20px;
  }
`

export const AddRoleWrapper = styled.div`
  .modal-wrapper {
    .modal {
      width: 500px;

      .modal-content {
        form {
          display: flex;
          flex-direction: column;

          .inputs {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            height: 300px;
            overflow: auto;

            label {
              width: 400px;
              position: relative;

              .del-item {
                position: absolute;
                top: 10px;
                right: 15px;
                cursor: pointer;
              }
            }
          }
          button {
            align-self: flex-end;
          }
        }
      }
    }
  }
`
