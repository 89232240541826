import { useContext } from 'react'
import { t } from 'i18next'

import { Button, Delete } from '../../../../../components/button/Button'
import Modal from '../../../../../components/modal/Modal'
import { SubContext } from '../Survey'

const SurveySubmit = () => {
  const { setShowSubmitModal, handleSubmitSurvey } = useContext(SubContext)

  return (
    <Modal setState={setShowSubmitModal} title={t('pageSurvey.survey')}>
      <p className='bold start-info'>{t('pageSurvey.confirm')}</p>
      <div className='button-container'>
        <Delete onClick={() => setShowSubmitModal(false)} className='button'>
          {t('pageSurvey.cancel')}
        </Delete>
        <Button
          onClick={() => {
            setShowSubmitModal(false)
            handleSubmitSurvey()
          }}
        >
          {t('pageSurvey.sendSurvey')}
        </Button>
      </div>
    </Modal>
  )
}

export default SurveySubmit
