import { Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as LoadingSpinner } from '../../../assets/img/spinner.svg'
import { Context } from '../../../utils/Context'
import { getSelectedRoleQualifications } from '../../competencePlanning/services/Crud'
import { CompetenceProfileContext } from '../CompetenceProfile'
import { useTranslation } from 'react-i18next'

const UnCompletedSurvey = () => {
  const { t } = useTranslation()
  const [uncompletedSurveys, setUncompletedSurveys] = useState()
  const [roleQuals, setRoleQuals] = useState([])

  const {
    organisation: { organisationNumber },
    employee: { roleId, roleName },
  } = useContext(Context)
  const {
    ongoing,
    completed,
    setSelectedUncompletedSurveyID,
    setSelectedSurveyID,
  } = useContext(CompetenceProfileContext)

  useEffect(() => {
    let isLoading = true
    const getRoleQuals = async () => {
      if (isLoading) {
        setRoleQuals(
          await getSelectedRoleQualifications({ organisationNumber, roleId })
        )
      }
    }

    getRoleQuals()
    return () => {
      isLoading = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isLoading = true
    if (isLoading &&  roleQuals) {
      setUncompletedSurveys(
        roleQuals.filter(
          (qual) =>
            !ongoing?.some(
              (survey) => survey.qualificationName === qual.qualificationName
            ) &&
            !completed?.some(
              (survey) => survey.qualificationName === qual.qualificationName
            )
        )
      )
    }
    return () => {
      isLoading = false
    }
  }, [roleQuals, ongoing, completed])

  return Array.isArray(uncompletedSurveys) &&
    uncompletedSurveys.length === 0 ? null : (
    <>
      {/** ---------- Uncompleted Surveys ---------- */}
      <Grid container item xs={12} mt={10}>
        <Grid item xs={12}>
          <h4
            style={{
              color: '#bfbecf',
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: '5px',
            }}
          >
            {t('competenceProfile.uncompleteMappingsTitle')}{roleName}
            )
          </h4>
        </Grid>

        <Grid item xs={12}>
          <ul style={{ padding: '10px 0', borderBottom: '1px solid #e0e0e0' }}>
            {!uncompletedSurveys ? (
              <LoadingSpinner width={100} height={100} />
            ) : (
              uncompletedSurveys.map((qual, i) => (
                <li style={{ padding: '5px 0' }} key={i}>
                  <Link
                    to={`competence-profile/${qual.qualificationName}?type=qualification`}
                    onClick={() => {
                      setSelectedSurveyID('')
                      setSelectedUncompletedSurveyID(qual.id)
                    }}
                  >
                    {qual.qualificationName}
                  </Link>
                </li>
              ))
            )}
          </ul>
        </Grid>
      </Grid>
    </>
  )
}

export default UnCompletedSurvey
