import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import catalog from '../../assets/img/catalog.png'
import individual from '../../assets/img/individual.png'
import organization from '../../assets/img/organization.png'
import { ReactComponent as UndrawCertification } from '../../assets/img/undraw-certification.svg'
import { ReactComponent as UndrawInvesting } from '../../assets/img/undraw-investing.svg'
import Banner from '../../components/banner/Banner'
import { Button } from '../../components/button/Button'
import { Wrapper } from './style'

const Landing = () => {

  const { t } = useTranslation()

  return (
    <Wrapper>
      <Banner />
      <main>
        <section className='first'>
          <h2>{t('pageLanding.first.title')}</h2>
          <p className='subtitle'>{t('pageLanding.first.subtitle')}</p>
          <div>
            <Link
              to={{
                pathname: '/login',
                state: {
                  org: true,
                },
              }}
              onClick={() => localStorage.setItem('org', 'true')}
            >
              <article>
                <img src={organization} alt='organization' />
                <br />
                <h4>{t('pageLanding.first.orgTitle')}</h4>
                <p className='body'>{t('pageLanding.first.orgDesc')}</p>
              </article>
            </Link>
            <Link
              to='/login'
              onClick={() => localStorage.setItem('org', 'false')}
            >
              <article>
                <img src={individual} alt='individual' />
                <br />
                <h4>{t('pageLanding.first.indTitle')}</h4>
                <p className='body'>{t('pageLanding.first.indDesc')}</p>
              </article>
            </Link>
            <Link to={{
              pathname: '/catalog',
              state: {
                prevPath: '/'
              }
            }}>
              <article>
                <img src={catalog} alt='catalog' />
                <br />
                <h4>{t('pageLanding.first.catTitle')}</h4>
                <p className='body'>{t('pageLanding.first.catDesc')}</p>
              </article>
            </Link>
          </div>
        </section>
        <section className='second'>
          <div>
            <h2>{t('pageLanding.second.title')}</h2>
            <p className='body'>{t('pageLanding.second.descOne')}<br /><br />{t('pageLanding.second.descTwo')}<a href='https://headonnails.com/skilluptool/' className='hoverUnderline'>{t('pageLanding.second.descLink')}</a>.</p>
            <Button>{t('pageLanding.second.contactBtn')}</Button>
          </div>
          <UndrawInvesting />
        </section>
        <section className='third'>
          <UndrawCertification />
          <div>
            <h4>{t('pageLanding.third.title')}</h4>
            <p className='body'>{t('pageLanding.third.desc')}<a href='https://headonnails.com/about-2/' className='hoverUnderline'>{t('pageLanding.third.descLink')}</a>.</p>
          </div>
        </section>
      </main>
    </Wrapper>
  )
}

export default Landing
