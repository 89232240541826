import styled from 'styled-components'

export const Wrapper = styled.main`
  margin-top: 59px;

  h3 {
    margin-bottom: 45px;
  }

  .profile__edit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 106px;

    form {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(4, auto);
      position: relative;

      label {
        width: 240px
      }

      .row1 {
        grid-row: 1;
      }
      
      .row2 {
        width: 100%;
        grid-row: 2;
        grid-column: 1 / 3;
      }

      .row3 {
        margin-top: 30px;
        grid-row: 3;
      }

      .row4 {
        grid-row: 4;
      }

      button {
        margin-top: 30px;
      }
    }

    .profile__edit-pfp {
      display: flex;
      height: 218px;
      flex-direction: column;
      justify-content: space-between;

      button {
        width: 100%;
        height: 30px;
      }
    }
  }

  .profile__employee-info {
    margin-bottom: 255px;
    
    h4 {
      font-weight: 900;
      margin-bottom: 20px;
    }

    & > p {
      font-size: 14px;
      font-weight: 500;
    }

    form {
      display: flex;
      gap: 20px;
      align-items: flex-end;

      label {
        width: 240px
      }
    }

    .profile__employee-info__table {
      margin-top: 33px;
    }

    .delete-employer {
      margin-top: 20px;
    }
  }
`
