import { simpleUser } from './../../utils/Amplify'
/**
 *
 *           Qualification - Qualification
 *
 */

// Gets all qualifications from an oraganisation
export const getQualifications = async (organisationNumber) => {
  try {
    // Fetching data from db
    // TODO Make the organistaion-number dynamic
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationNumber}/qualification/get/all`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }
    )

    // Array of qualifications-objects converted from json to js-code.
    const qualificationsList = await response.json()

    // Returning the js-code as a promise
    return qualificationsList
  } catch (error) {
    console.log(error)
  }
}

// Handel input changes in forms/inputs and setting i
export const handleAddFormChange = (e, addFormData, setAddFormData) => {
  // Storing the field-name and field-value from the input-fields
  const fieldName = e.target.getAttribute('name')
  const fieldValue = e.target.value

  // Create a copy of addFormData-state
  const newFormData = { ...addFormData }

  // Manipulating the field-value for the current field
  newFormData[fieldName] = fieldValue

  // Updating the addFormData-state withe the newFormData-object
  setAddFormData(newFormData)
}

// Add qualificatiopn
export const handleAddFormSubmit = (
  e,
  {
    organisationNumber,
    addFormData,
    qualificationsList,
    setQualificationsList,
    setModalAddIsVisible,
  }
) => {
  e.preventDefault()

  // This stringify the qualification-object we want to save in the database
  const newQualification = JSON.stringify({
    qualificationName: addFormData.qualificationName,
    qualificationArea: addFormData.qualificationArea,
    qualificationType: addFormData.qualificationType,
    qualificationAccess: addFormData.qualificationAccess,
    qualificationDescription: addFormData.qualificationDescription,
    qualificationLanguage: addFormData.qualificationLanguage,
    qualificationEqf: parseInt(addFormData.qualificationEqf),
    version: addFormData.version,
    modified: 1,
  })

  // Post qualification to database and return the added qualification object
  const createQualification = async () => {
    try {
      const options = {
        method: 'POST',
        body: newQualification,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationNumber}/qualification/add`,
        options
      )

      const returnedObject = await response.json()
      return returnedObject
    } catch (error) {
      console.log(error)
    }
  }

  /* This is to add the returned object to the list of qualifications
   * and to close the add modal
   */
  createQualification().then((QualificationObject) => {
    setQualificationsList([...qualificationsList, QualificationObject])
    setModalAddIsVisible(false)
  })
}

// Edit a qualification
export const handleEditFormSubmit = (
  e,
  {
    editFormData,
    qualificationsList,
    setQualificationsList,
    qualificationId,
    dispatchModalEditIsVisible,
    setModalEditIsVisible,
    modalEditIsVisible,
  }
) => {
  e.preventDefault()

  const initializedQualification = {
    qualificationName: editFormData.qualificationName,
    qualificationArea: editFormData.qualificationArea,
    qualificationType: editFormData.qualificationType,
    qualificationAccess: editFormData.qualificationAccess,
    qualificationDescription: editFormData.qualificationDescription,
    qualificationLanguage: editFormData.qualificationLanguage,
    qualificationEqf: editFormData.qualificationEqf,
    qualificationCountries: editFormData.qualificationCountries,
    version: 0,
    modified: 1,
  }

  // Create a copy of the qualificationsList-state array
  const newQualifications = [...qualificationsList]

  // Getting the index of the selected qualification-object
  const index = qualificationsList.findIndex(
    (qualification) => qualification.id === qualificationId
  )

  // Modifying the selected qualification-object
  newQualifications[index] = initializedQualification

  // Edit the qualification that is stored in the database
  const editQualification = async () => {
    // Initialize options for the fetch
    const options = {
      method: 'PATCH',
      body: JSON.stringify(initializedQualification),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }

    try {
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/qualification/update/${qualificationId}`,
        options
      )
    } catch (error) {
      console.log(error)
    }
  }

  // Calling the function above
  editQualification()

  // assigning an id to the edited-object to prevent render errors
  newQualifications[index] = {
    ...initializedQualification,
    id: qualificationId,
  }

  // Render the qualifications-array with the new changes included
  setQualificationsList(newQualifications)

  // Close the edit-modal
  setModalEditIsVisible(false)
  modalEditIsVisible.area &&
    dispatchModalEditIsVisible({ type: 'area', current: true })

  modalEditIsVisible.sub &&
    dispatchModalEditIsVisible({ type: 'sub', current: true })
}

// Handle the editFormData-state for editQualification-modal onChange
export const handleEditFormChange = (e, editFormData, setEditFormData) => {
  const fieldName = e.target.getAttribute('name')
  const fieldValue = e.target.value

  const newFormData = { ...editFormData }

  newFormData[fieldName] = fieldValue
  setEditFormData(newFormData)
}

// Function to delete qualification from database and qualificationList-state
export const deleteQualification = async (
  setModalEditIsVisible,
  setModalDeleteIsVisible,
  setQualificationsList,
  id
) => {
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/qualification/delete/${id}`,
      options
    )

    // Close the modals
    setModalEditIsVisible(false)
    setModalDeleteIsVisible(false)

    // Filter out the out the removed qualification from the qualificationList-state
    setQualificationsList((prev) => {
      return prev
        .filter((qualification) => qualification.id !== id)
        .map((qualification) => qualification)
    })
  } catch (error) {
    console.log(error)
  }
}

/**
 *
 *           Qualification - SubQualification / SubArea Section
 *
 */

export const getSubQualifications = async (id) => {
  try {
    // Fetching data from db
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/qualification/${id}/get/all/subqualification`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }
    )
    // Array of qualifications-objects converted from json to js-code.
    const qualificationsList = await response.json()
    // Returning the js-code as a promise
    return qualificationsList
  } catch (error) {
    console.log(error)
  }
}

export const getSubQualificationQuestions = async (id,surveyId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/qualification/${id}/get/all/subqualification/questions?surveyId=${parseInt(surveyId)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        }
      }
    )

    const questionsList = await response.json()
    return questionsList
  } catch (error) {
    console.log(error)
  }
}

export const handleSubAddFormSubmit = (
  e,
  {
    id,
    addFormData,
    subQualificationsList,
    setSubQualificationsList,
    dispatchModalAddIsVisible,
  }
) => {
  e.preventDefault()

  // This stringify the qualification-object we want to save in the database
  const newQualification = JSON.stringify({
    name: addFormData.name,
    description: addFormData.description,
    modified: 1,
  })

  // Post qualification to database and return the added qualification object
  const createSubQualification = async () => {
    const options = {
      method: 'POST',
      body: newQualification,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/qualification/${id}/subqualification/add`,
      options
    )

    const returnedObject = await response.json()

    return returnedObject
  }

  /* This is to add the returned object to the list of qualifications
   * and to close the add modal
   */
  createSubQualification().then((subQualificationObject) => {
    setSubQualificationsList([
      ...subQualificationsList,
      { ...subQualificationObject, qualificationArea: [] },
    ])
    dispatchModalAddIsVisible({ type: 'sub', current: true })
  })
}

// Edit Sub
export const handleSubEditFormSubmit = (
  e,
  {
    editFormData,
    subQualificationsList,
    setSubQualificationsList,
    subQualificationId,
    dispatchModalEditIsVisible,
    modalEditIsVisible,
  }
) => {
  e.preventDefault()
  const initializedQualification = {
    name: editFormData.name,
    description: editFormData.description,
    modified: 1,
  }

  // Create a copy of the qualificationsList-state array
  const newSubQualifications = [...subQualificationsList]

  // Getting the index of the selected qualification-object
  const index = subQualificationsList.findIndex(
    (qualification) => qualification.id === subQualificationId
  )

  // Modifying the selected qualification-object
  newSubQualifications[index] = initializedQualification

  // Edit the qualification that is stored in the database
  const editQualification = async () => {
    // Initialize options for the fetch
    const options = {
      method: 'PATCH',
      body: JSON.stringify(initializedQualification),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/qualification/subqualification/${subQualificationId}/edit`,
        options
      )
      const data = await response.json()
      return data
    } catch (error) {
      console.log(error)
    }
  }

  // Calling the editQualification function above
  editQualification().then((newSub) => {
    // assigning an id to the edited-object to prevent render errors
    newSubQualifications[index] = { ...newSub }
    // Render the qualifications-array with the new changes included
    setSubQualificationsList(newSubQualifications)
  })

  // Close the edit-modal
  modalEditIsVisible.sub &&
    dispatchModalEditIsVisible({ type: 'sub', current: true })

  modalEditIsVisible.area &&
    dispatchModalEditIsVisible({ type: 'area', current: true })
}

export const deleteSubQualification = async ({
  dispatchModalEditIsVisible,
  modalEditIsVisible,
  setModalDeleteIsVisible,
  setSubQualificationsList,
  id,
  qualId,
}) => {
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/qualification/${qualId}/subqualification/${id}/delete`,
      options
    )

    // Close the modals
    modalEditIsVisible.sub &&
      dispatchModalEditIsVisible({ type: 'sub', current: true })

    modalEditIsVisible.area &&
      dispatchModalEditIsVisible({ type: 'area', current: true })
    setModalDeleteIsVisible(false)

    // Filter out the out the removed qualification from the qualificationList-state
    setSubQualificationsList((prev) => {
      return prev
        .filter((qualification) => qualification.id !== id)
        .map((qualification) => qualification)
    })
  } catch (error) {
    console.log(error)
  }
}

// Add area from SubQualification
export const handleAreaAddFormSubmit = (
  e,
  {
    id,
    addFormData,
    subQualificationsList,
    setSubQualificationsList,
    subQualificationId,
    dispatchModalAddIsVisible,
  }
) => {
  e.preventDefault()

  // Post qualification to database and return the added qualification object
  const createSubQualification = async () => {
    try {
      // This stringify the qualification-object we want to save in the database
      const newArea = JSON.stringify({
        name: addFormData.name,
        description: addFormData.description,
        modified: 1,
      })
      const options = {
        method: 'POST',
        body: newArea,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/${subQualificationId}/qualificationarea/add`,
        options
      )

      const returnedObject = await response.json()
      return returnedObject
    } catch (error) {
      console.log(error)
    }
  }

  /* This is to add the returned object to the list of qualifications
   * and to close the add modal
   */

  createSubQualification().then((SubQualificationObject) => {
    const newSubList = [...subQualificationsList]

    const subIndex = subQualificationsList.findIndex(
      (sub) => sub.id === subQualificationId
    )
    newSubList[subIndex].qualificationArea.push(SubQualificationObject)

    setSubQualificationsList(newSubList)

    dispatchModalAddIsVisible({ type: 'area', current: true })
  })
}
// Edit area from SubQualification
export const handleAreaEditFormSubmit = (
  e,
  {
    selectedArea,
    addFormData,
    subQualificationId,
    subQualificationsList,
    setSubQualificationsList,
    dispatchModalEditIsVisible,
  }
) => {
  e.preventDefault()
  const initializedQualification = {
    name: addFormData.name,
    description: addFormData.description,
    modified: 1,
  }

  // Create a copy of the qualificationsList-state array
  const newSubQualifications = [...subQualificationsList]

  // Getting the index of the selected qualification-object
  const subQualificationIndex = subQualificationsList.findIndex(
    (subQualification) => subQualification.id === subQualificationId
  )

  // Getting the index of the selected area-object
  const areaIndex = newSubQualifications[
    subQualificationIndex
  ].qualificationArea.findIndex((area) => area.id === selectedArea.id)

  // Modifying the selected qualification-object
  newSubQualifications[subQualificationIndex].qualificationArea[areaIndex] = {
    ...initializedQualification,
    id: selectedArea.id,
  }

  const editSubArea = async () => {
    const options = {
      method: 'PATCH',
      body: JSON.stringify(initializedQualification),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/qualificationarea/update/${selectedArea.id}`,
        options
      )
      const data = await response.json()
      return data
    } catch (error) {
      console.log(error)
    }
  }

  editSubArea().then((newSub) => {
    // assigning an id to the edited-object to prevent render errors
    newSubQualifications[subQualificationIndex].qualificationArea[areaIndex] = {
      ...newSub,
    }
    // Render the subqualifications-array with the new changes included
    setSubQualificationsList(newSubQualifications)
    dispatchModalEditIsVisible({ type: 'area', current: true })
  })
}

export const deleteSubArea = async ({
  dispatchModalEditIsVisible,
  modalEditIsVisible,
  setModalDeleteIsVisible,
  subQualificationsList,
  setSubQualificationsList,
  subQualificationId,
  selectedArea: { id },
}) => {
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/qualificationarea/delete/${id}`,
      options
    )

    // Close the modals
    modalEditIsVisible.sub &&
      dispatchModalEditIsVisible({ type: 'sub', current: true })

    modalEditIsVisible.area &&
      dispatchModalEditIsVisible({ type: 'area', current: true })
    setModalDeleteIsVisible(false)

    const newQualificationAreas = [...subQualificationsList]

    // Getting the index of the selected subQualification-object
    const subQualificationIndex = subQualificationsList.findIndex(
      (subQualification) => subQualification.id === subQualificationId
    )

    // Getting the index of the selected area-object
    const areaIndex = newQualificationAreas[
      subQualificationIndex
    ].qualificationArea.findIndex((area) => area.id === id)

    // Remove the area from the list
    newQualificationAreas[subQualificationIndex].qualificationArea.splice(
      areaIndex,
      1
    )

    // Filter out the out the removed qualification from the qualificationList-state
    setSubQualificationsList(newQualificationAreas)
  } catch (error) {
    console.log(error)
  }
}

/**
 *
 *           Qualification - Area Section
 *
 */

export const getAreas = async (subId) => {
  try {
    // Fetching data from db
    // TODO Make the organistaion-number dynamic
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/subqualification/${subId}/get/all/qualificationarea`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }
    )

    // Array of qualifications-objects converted from json to js-code.
    const qualificationsList = await response.json()
    // Returning the js-code as a promise
    return qualificationsList
  } catch (error) {
    console.log(error)
  }
}

export const addArea = (
  e,
  { id, addFormData, setAreaList, dispatchModalAddIsVisible }
) => {
  e.preventDefault()

  // Post qualification to database and return the added qualification object
  const createArea = async () => {
    try {
      // This stringify the qualification-object we want to save in the database
      const newArea = JSON.stringify({
        name: addFormData.name,
        description: addFormData.description,
        passQuestions: addFormData.passQuestions,
        totalQuestions: addFormData.totalQuestions,
        modified: 1,
      })
      const options = {
        method: 'POST',
        body: newArea,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/${id}/qualificationarea/add`,
        options
      )

      const returnedObject = await response.json()
      return returnedObject
    } catch (error) {
      console.log(error)
    }
  }

  /* This is to add the returned object to the list of qualifications
   * and to close the add modal
   */

  createArea().then((areaObject) => {
    setAreaList((prev) => [
      ...prev,
      { ...areaObject, qualificationRequirement: [] },
    ])
    dispatchModalAddIsVisible({ type: 'area', current: true })
  })
}
export const editArea = (
  e,
  {
    areaList,
    setAreaList,
    selectedArea,
    addFormData,
    dispatchModalEditIsVisible,
  }
) => {
  e.preventDefault()
  const options = {
    method: 'PATCH',
    body: JSON.stringify(addFormData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + simpleUser().token,
    },
  }
  const updateArea = () => {
    try {
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/qualificationarea/update/${selectedArea.id}`,
        options
      )
      let newAreaList = areaList
      const selectedAreaIndex = areaList.findIndex(
        (area) => area.id === selectedArea.id
      )
      newAreaList.splice(selectedAreaIndex, 1, {
        id: selectedArea.id,
        ...addFormData,
      })
      setAreaList(newAreaList)
      dispatchModalEditIsVisible({ type: 'area', current: true })
    } catch (error) {
      console.log(error)
    }
  }
  updateArea()
}
export const deleteArea = async (
  e,
  {
    dispatchModalEditIsVisible,
    modalEditIsVisible,
    setModalDeleteIsVisible,
    areaList,
    setAreaList,
    selectedArea,
  }
) => {
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/qualificationarea/delete/${selectedArea.id}`,
      options
    )

    // Close the modals
    modalEditIsVisible.sub &&
      dispatchModalEditIsVisible({ type: 'sub', current: true })

    modalEditIsVisible.area &&
      dispatchModalEditIsVisible({ type: 'area', current: true })
    setModalDeleteIsVisible(false)

    const newAreaList = [...areaList]

    const areaIndex = areaList.findIndex((area) => area.id === selectedArea.id)

    // Remove the area from the areaList
    newAreaList.splice(areaIndex, 1)

    setAreaList(newAreaList)
  } catch (error) {
    console.log(error)
  }
}
export const addRequirement = (
  e,
  {
    selectedArea,
    modalAddIsVisible,
    addFormData,
    areaList,
    setAreaList,
    dispatchModalAddIsVisible,
  }
) => {
  e.preventDefault()

  const { knowledge, skill, responsibilityAndIndependence } = modalAddIsVisible
  // Post qualification to database and return the added qualification object
  const createRequirement = async () => {
    try {
      // This stringify the qualification-object we want to save in the database

      const options = {
        method: 'POST',
        body: JSON.stringify({
          description: addFormData.description,
          requirementType:
            (knowledge && 'knowledge') ||
            (skill && 'skill') ||
            (responsibilityAndIndependence && 'responsibilityAndIndependence'),

          modified: 1,
          passQuestions: addFormData.passQuestions,
          totalQuestions: addFormData.totalQuestions,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/${selectedArea.id}/qualificationrequirement/add`,
        options
      )

      const returnedObject = await response.json()
      return returnedObject
    } catch (error) {
      console.log(error)
    }
  }

  /* This is to add the returned object to the list of qualifications
   * and to close the add modal
   */

  createRequirement().then((requirement) => {
    let newAreaList = areaList
    const areaIndex = newAreaList.findIndex(
      (area) => area.id === selectedArea.id
    )
    newAreaList[areaIndex].qualificationRequirement.push(requirement)
    setAreaList(newAreaList)

    dispatchModalAddIsVisible({
      type:
        (knowledge && 'knowledge') ||
        (skill && 'skill') ||
        (responsibilityAndIndependence && 'responsibilityAndIndependence'),
      current: true,
    })
  })
}
export const editRequirement = (
  e,
  {
    addFormData,
    selectedArea,
    modalEditIsVisible,
    selectedRequirement,
    areaList,
    setAreaList,
    dispatchModalEditIsVisible,
  }
) => {
  e.preventDefault()
  const { knowledge, skill, responsibilityAndIndependence } = modalEditIsVisible

  // Post qualification to database and return the added qualification object
  const createRequirement = async () => {
    try {
      // This stringify the qualification-object we want to save in the database

      const options = {
        method: 'PATCH',
        body: JSON.stringify({
          description: addFormData.description,
          totalQuestions: addFormData.totalQuestions,
          passQuestions: addFormData.passQuestions,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }

      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/qualificationrequirement/update/${selectedRequirement.id}`,
        options
      )

      let newAreaList = areaList

      const areaIndex = areaList.findIndex(
        (area) => area.id === selectedArea.id
      )

      const requirementIndex = areaList[
        areaIndex
      ].qualificationRequirement.findIndex(
        (requirement) => requirement.id === selectedRequirement.id
      )

      // Replacing the old values with the new
      newAreaList[areaIndex].qualificationRequirement.splice(
        requirementIndex,
        1,
        {
          ...selectedRequirement,
          description: addFormData.description,
          totalQuestions: addFormData.totalQuestions,
          passQuestions: addFormData.passQuestions,
        }
      )

      dispatchModalEditIsVisible({
        type:
          (knowledge && 'knowledge') ||
          (skill && 'skill') ||
          (responsibilityAndIndependence && 'responsibilityAndIndependence'),
        current: true,
      })
      setAreaList(newAreaList)
    } catch (error) {
      console.log(error)
    }
  }
  createRequirement()
}
export const deleteRequirement = async (
  e,
  {
    setModalDeleteIsVisible,
    dispatchModalEditIsVisible,
    knowledge,
    skill,
    responsibilityAndIndependence,
    selectedRequirement,
    setAreaList,
    areaList,
    selectedArea,
  }
) => {
  e.preventDefault()
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/qualificationrequirement/delete/${selectedRequirement.id}`,
      options
    )

    const newAreaList = [...areaList]

    const areaIndex = areaList.findIndex((area) => area.id === selectedArea.id)

    const requirementIndex = areaList[
      areaIndex
    ].qualificationRequirement.findIndex(
      (requirement) => requirement.id === selectedRequirement.id
    )

    // Remove the area from the areaList
    newAreaList[areaIndex].qualificationRequirement.splice(requirementIndex, 1)

    setAreaList(newAreaList)

    // Close the modals
    setModalDeleteIsVisible(false)
    dispatchModalEditIsVisible({
      type:
        (knowledge && 'knowledge') ||
        (skill && 'skill') ||
        (responsibilityAndIndependence && 'responsibilityAndIndependence'),
      current: true,
    })
  } catch (error) {
    console.log(error)
  }
}

/************************** REQUIREMENT QUESTION FUNCTIONS  **************************/

/** Handle logic for adding a new requirement question */
export const handleAddRequirementQuestion = (
  e,
  {
    selectedRequirement,
    setModalAddIsVisible,
    setQuestionList,
    formData,
    questionType,
    questionImageFile,
  }
) => {
  e.preventDefault()

  const addRequirementQuestion = async () => {
    try {
      const jwtToken = (await simpleUser()).token

      const newQuestion = JSON.stringify({
        questionName: formData.questionName,
        questionVideoUrl: formData.questionVideoUrl ?? '',
        questionAnswer: formData.questionAnswer.map((answer) => {
          const { id, ...rest } = answer
          return rest
        }),
      })
      const options = {
        method: 'POST',
        body: newQuestion,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/add/question/qualificationrequirement/${selectedRequirement.id}`,
        options
      )

      const returnedObject = await response.json()
      return returnedObject
    } catch (error) {
      console.log(error)
    }
  }

  addRequirementQuestion().then((question) => {
    if (questionType === 'imageQuestion') {
      return uploadQuestionImage(questionImageFile, question.id)
        .then((imageKey) => {
          question = { ...question, questionImageKey: imageKey }
          return editRequirementQuestion(question, question.id)
        })
        .then((updatedQuestion) => {
          setQuestionList((prev) => [...prev, updatedQuestion])
          setModalAddIsVisible(false)
        })
    } else if (questionType === 'imageAnswer') {
      const promises = question.questionAnswer.map(async (answer, index) => {
        const imageKey = await uploadAnswerImage(
          formData.questionAnswer[index].questionImageFile,
          question.id,
          answer.id
        )
        return { ...answer, questionImageKey: imageKey }
      })

      Promise.all(promises)
        .then((answers) => {
          question = { ...question, questionAnswer: answers }
          return editRequirementQuestion(question, question.id)
        })
        .then((updatedQuestion) => {
          setQuestionList((prev) => [...prev, updatedQuestion])
          setModalAddIsVisible(false)
        })
    } else {
      setQuestionList((prev) => [...prev, question])
      setModalAddIsVisible(false)
    }
  })
}

/** Handle logic for deleting a requirement question */
export const handleDeleteRequirementQuestion = async ({
  selectedQuestionId,
  setQuestionList,
  setModalDeleteIsVisible,
  setModalEditIsVisible,
}) => {
  try {
    const jwtToken = (await simpleUser()).token
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/delete/question/${selectedQuestionId}`,
      options
    )

    // Update the questionList and close the modal
    setQuestionList((prev) =>
      prev.filter((question) => question.id !== selectedQuestionId)
    )

    setModalDeleteIsVisible(false)
    setModalEditIsVisible(false)
  } catch (error) {
    console.log(error)
  }
}

/** Handle logic for editing a requirement question */
export const handleEditRequirementQuestion = async (
  e,
  {
    setModalEditIsVisible,
    questionList,
    setQuestionList,
    selectedQuestionId,
    editFormData,
    questionType,
    questionImageFile,
  }
) => {
  e.preventDefault()

  let editedRequirementQuestion = {
    questionName: editFormData.questionName,
    questionImageKey: editFormData.questionImageKey,
    questionVideoUrl: editFormData.questionVideoUrl ?? '',
    questionAnswer: editFormData.questionAnswer,
  }

  if (questionType === 'imageQuestion' && questionImageFile) {
    const imageKey = await uploadQuestionImage(
      questionImageFile,
      selectedQuestionId
    )
    editedRequirementQuestion = {
      ...editedRequirementQuestion,
      questionImageKey: imageKey,
    }
  } else if (questionType === 'imageAnswer') {
    let updatedQuestion = await editRequirementQuestion(
      editedRequirementQuestion,
      selectedQuestionId
    )

    updatedQuestion = {
      ...updatedQuestion,
      questionAnswer: updatedQuestion.questionAnswer.map((answer, index) => {
        const updatedAnswer = editFormData.questionAnswer[index]
        return {
          ...answer,
          questionImageFile: updatedAnswer.questionImageFile,
        }
      }),
    }

    const promises = updatedQuestion.questionAnswer.map(async (answer) => {
      if (answer.questionImageFile) {
        const imageKey = await uploadAnswerImage(
          answer.questionImageFile,
          selectedQuestionId,
          answer.id
        )

        return {
          ...answer,
          questionImageKey: imageKey,
        }
      } else {
        return answer
      }
    })

    const answers = await Promise.all(promises)

    const updatedAnswers = updatedQuestion.questionAnswer.map((answer) => {
      const updatedAnswer = answers.find((a) => a.id === answer.id)
      return updatedAnswer ?? answer
    })

    editedRequirementQuestion = {
      ...updatedQuestion,
      questionAnswer: updatedAnswers.map((answer) => {
        const { answer: _, ...rest } = answer
        return rest
      }),
    }
  } else if (questionType === 'videoAnswer') {
    editedRequirementQuestion = {
      ...editedRequirementQuestion,
      questionAnswer: editedRequirementQuestion.questionAnswer.map((answer) => {
        const { answer: _, ...rest } = answer
        return rest
      }),
    }
  }

  const newQuestionList = [...questionList]
  const index = questionList.findIndex(
    (question) => question.id === selectedQuestionId
  )

  newQuestionList[index] = editedRequirementQuestion

  const question = await editRequirementQuestion(
    editedRequirementQuestion,
    selectedQuestionId
  )
  newQuestionList[index] = { ...question }

  setQuestionList(newQuestionList)
  setModalEditIsVisible(false)
}

const editRequirementQuestion = async (
  editedRequirementQuestion,
  selectedQuestionId
) => {
  try {
    const jwtToken = (await simpleUser()).token
    const options = {
      method: 'PATCH',
      body: JSON.stringify(editedRequirementQuestion),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/update/question/${selectedQuestionId}`,
      options
    )

    const returnedObject = await response.json()
    return returnedObject
  } catch (error) {
    console.log(error)
  }
}

/** Get all existing requirement questions for the specific requirement id */
export const getRequirementQuestions = async (requirementId) => {
  try {
    const jwtToken = (await simpleUser()).token
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/get/all/question/${requirementId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      }
    )

    const requirementQuestionList = await response.json()
    return requirementQuestionList
  } catch (error) {
    console.log(error)
  }
}

export const getPdfData = async (qualificationId) => {
  try {
    const jwtToken = (await simpleUser()).token
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/qualification/${qualificationId}/get/pdf/data`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      }
    )

    const pdfData = await response.json()
    return pdfData
  } catch (error) {
    console.log(error)
  }
}

export const removeCompletedSurvey = async ({ id, setCompleted }) => {
  try {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/initiate/delete/survey/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    })
    setCompleted((prev) =>
      prev.filter((survey) => survey.id !== id).map((survey) => survey)
    )
  } catch (error) {
    console.log(error)
  }
}

export const uploadQuestionImage = async (image, questionId) => {
  const formData = new FormData()
  formData.append('image', image)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/question/image/${questionId}`,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }
    )

    const imageKey = await response.text()

    return imageKey
  } catch (error) {
    console.log(error)
  }
}

export const uploadAnswerImage = async (image, questionId, answerId) => {
  const formData = new FormData()
  formData.append('image', image)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/question/image/${questionId}/${answerId}`,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }
    )

    const imageKey = await response.text()

    return imageKey
  } catch (error) {
    console.log(error)
  }
}

export const getQuestionImage = async (imageKey) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/question/image?key=` +
        encodeURIComponent(imageKey),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }
    )

    const imageUrl = await response.text()
    return imageUrl
  } catch (error) {
    console.log(error)
  }
}
