import React, {
  useState,
  useEffect,
  createContext,
  useReducer,
  useContext,
} from 'react'
import { getSubQualifications } from '../functions'
import SubQualificationTable from './components/SubQualificationTable'
import PageTitleSpecial from '../../../components/pageTitle/PageTitleSpecial'
import AddSubQualification from './components/AddSubQualification'
import EditSubQualification from './components/EditSubQualification'
import DeleteSubQualification from './components/DeleteSubQualification'
import DeleteAreaSub from './components/DeleteArea'
import { Route, useRouteMatch } from 'react-router-dom'
import AddAreaSub from './components/AddArea'
import EditAreaSub from './components/EditArea'
import QualificationAreas from '../qualificationAreas/QualificationAreas'
import { QualificationContext } from '../qualification/Qualification'
import BreadCrumbs from '../../../utils/BreadCrumbs'
import { AddButton } from '../../../components/button/Button'
import { Context } from '../../../utils/Context'
import { useTranslation } from 'react-i18next'
//  FIXME Need to modify it to work in qualifications..
// import BreadCrumbs from '../../../utils/BreadCrumbs'

export const SubQualificationContext = createContext(null)
const SubQualification = () => {
  const { t } = useTranslation();
  const {
    employee: { qualificationAccess },
  } = useContext(Context)
  const {
    selectedQualification: { qualificationId: id, name, description },
  } = useContext(QualificationContext)
  // States
  const [subQualificationsList, setSubQualificationsList] = useState([]) // Store all SubQualifications
  const [subQualificationId, setSubQualificationId] = useState(null) // To store the id of the selected qualification
  const [selectedArea, setSelectedArea] = useState(null) // To store the id of the selected qualification
  // To show/hide the modals the modals
  const [modalAddIsVisible, dispatchModalAddIsVisible] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'area':
          if (action.current === false) {
            return { ...state, area: true }
          }
          if (action.current === true) {
            return { ...state, area: false }
          }
          break

        case 'sub':
          if (action.current === false) {
            return { ...state, sub: true }
          }
          if (action.current === true) {
            return { ...state, sub: false }
          }
          break
        default:
          return state
      }
    },
    { sub: false, area: false }
  )
  const [modalEditIsVisible, dispatchModalEditIsVisible] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'area':
          if (action.current === false) {
            return { ...state, area: true }
          }
          if (action.current === true) {
            return { ...state, area: false }
          }
          break

        case 'sub':
          if (action.current === false) {
            return { ...state, sub: true }
          }
          if (action.current === true) {
            return { ...state, sub: false }
          }
          break
        default:
          return state
      }
    },
    { sub: false, area: false }
  )
  const [modalDeleteIsVisible, setModalDeleteIsVisible] = useState(false)
  const [selectedSubQualification, setSelectedSubQualification] = useState(
    JSON.parse(localStorage.getItem('selectedSubQualification'))
  )

  // ContextProvider-object
  const initialContext = {
    id,
    name,
    description,
    subQualificationsList,
    setSubQualificationsList,
    subQualificationId,
    setSubQualificationId,
    selectedArea,
    setSelectedArea,
    modalAddIsVisible,
    dispatchModalAddIsVisible,
    modalEditIsVisible,
    dispatchModalEditIsVisible,
    modalDeleteIsVisible,
    setModalDeleteIsVisible,
    selectedSubQualification,
    setSelectedSubQualification,
  }

  useEffect(() => {
    localStorage.setItem(
      'selectedSubQualification',
      JSON.stringify(selectedSubQualification)
    )
  }, [selectedSubQualification])

  const { path } = useRouteMatch()

  return (
    <SubQualificationContext.Provider value={initialContext}>
      {/** // TODO Waiting for fix */}
      <Route exact path={path}>
        <BreadCrumbs custom />
        {/* This is the page-title */}
        <PageTitleSpecial
          title={name}
          description={
            <>
              <p>{t('pageQualification.descriptionField-1')}</p>
              <br />
              <p>{t('pageQualification.descriptionField-2')}</p>
              <br />
              <p>{t('pageQualification.descriptionField-3')}</p>
              <br />
              <p>{t('pageQualification.descriptionField-4')}</p>
            </>
          }
          info={description}
        />

        {/* Table of all qualifications */}
        <SubQualificationTable />
        {qualificationAccess === 'edit' && (
          <AddButton
            className='add-button'
            onClick={() => {
              dispatchModalAddIsVisible({ type: 'sub', current: false })
            }}
          />
        )}
        <br />
        {/* Modals only visible if needed */}
        {modalAddIsVisible.sub && <AddSubQualification />}
        {modalAddIsVisible.area && <AddAreaSub />}
        {modalEditIsVisible.sub && <EditSubQualification />}
        {modalEditIsVisible.area && <EditAreaSub />}
        {modalEditIsVisible.sub && modalDeleteIsVisible && (
          <DeleteSubQualification />
        )}
        {modalEditIsVisible.area && modalDeleteIsVisible && <DeleteAreaSub />}
      </Route>
      <Route path={`${path}/:areas`}>
        <QualificationAreas />
      </Route>
    </SubQualificationContext.Provider>
  )
}

export default SubQualification
