import React, { useContext, useEffect } from 'react'
import { Grid } from '@mui/material'
import share from '../../../assets/img/share.svg'
import shareActive from '../../../assets/img/share-active.svg'
import { EducationContext } from '../Educations'
import pencil from '../../../assets/img/Edit_pencil.svg'
import { Link } from 'react-router-dom'
import { getAllEducations } from '../services/Crud'
import { Context } from '../../../utils/Context'
import { useTranslation } from 'react-i18next'

const EducationTable = () => {
  const { t } = useTranslation()
  const { employee:{educationAccess} } = useContext(Context)
  const {
    setEducationsList,
    educationsList,
    setModalAddIsVisible,
    modalShareIsVisible,
    setModalShareIsVisible,
    setModalEditIsVisible,
    setSelectedEducation,
    organisationId,
  } = useContext(EducationContext)

  useEffect(() => {
    let isLoading = true
    getAllEducations({ organisationId }).then((allEducations) => {
      if (isLoading) {
        setEducationsList(allEducations)
      }
    })

    return () => {
      isLoading = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isLoading = true
    getAllEducations({ organisationId }).then((allEducations) => {
      if (isLoading) {
        setEducationsList(allEducations)
      }
    })

    return () => {
      isLoading = false
    }
    // eslint-disable-next-line
  }, [modalShareIsVisible])

  return (
    <Grid container className='table'>
      {/** table Head */}
      <Grid
        container
        item
        xs={12}
        direction='row'
        className='table-head'
        justify='start'
        alignItems='center'
      >
        <Grid className='bold name'>{t('educations.educationPage.tableCourseName')}</Grid>
        <Grid className='bold refers-to-qualification'>
        {t('educations.educationPage.qualification')}
        </Grid>
        <Grid className='bold industry'>{t('educations.educationPage.industry')}</Grid>
        <Grid className='bold language'>{t('educations.educationPage.language')}</Grid>
        <Grid className='bold ver'>{t('educations.educationPage.version')}</Grid>
        <Grid className='bold share'>{t('educations.educationPage.share')}</Grid>
        <Grid className='bold edit'>{t('educations.educationPage.edit')}</Grid>
      </Grid>

      {/** table body */}
      <Grid container item xs={12} direction='row' className='table-body'>
        {educationsList.map((education) => (
          <Grid
            key={education.id}
            container
            item
            xs={12}
            direction='row'
            pt={1}
          >
            <Grid className='name' pt={1}>
              <Link to={`/educations/${education.educationName}`}
                onClick={() => setSelectedEducation(education)}
              >{education.educationName}</Link>
            </Grid>
            <Grid className='refers-to-qualification' pt={1}>
              {education.qualification.qualificationName}
            </Grid>
            <Grid className='industry' pt={1}>
              {education.qualification.qualificationArea}
            </Grid>
            <Grid className='language' pt={1}>
              {education.qualification.qualificationLanguage}
            </Grid>
            <Grid className='ver' pt={1}>
              {education.qualification.version}
            </Grid>
            <Grid className='share' pt={1}>
              { educationAccess ==='edit' &&
                <img
                  className='pointer'
                  src={education.educationCountries.length >= 1 ? shareActive : share}
                  alt='round share button'
                  onClick={() => {
                    setSelectedEducation(education)
                    setModalShareIsVisible(true)
                  }}
                />
              }
            </Grid>
            <Grid pt={1} className='edit'>
              { educationAccess === 'edit' &&
              <img
               className='pointer'
                src={pencil}
                alt='pencil icon'
                onClick={(e) => {
                  setSelectedEducation(education)
                  setModalEditIsVisible(true)
                }}
              />
              }
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid container item xs={12} alignItems='center'>
        <Grid item xs={1} className='btn-wrapper'>
          { educationAccess ==='edit' &&
          <button
            className='add-btn'
            onClick={(e) => setModalAddIsVisible(true)}
          >
            +
          </button>
        }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EducationTable
