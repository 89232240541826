import styled from 'styled-components';

const Wrapper = styled.main`
    &.catalog-wrapper {
        width: 1160px;
    }

    .content {
        margin: 50px 0;
        display: grid;
        grid-template-rows: 175px auto;
        grid-template-columns: 350px auto;
        grid-gap: 50px;

        .search {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: stretch;

            .country-language {
                display: flex;
                justify-content: space-between;

                label {
                    width: 45%;

                    .react-select {
                        width: 100%;
                    }
                }
            }

            input {
                font-size: 14px;
            }

            button {
                align-self: flex-end;
            }
        }

        .filter {
            grid-column: 1;
            color: #858585;

            .MuiCheckbox-root {
                padding: 0;
            }

            > * {
                border-bottom: 1px solid #858585;

                &:not(h3) {
                    padding: 0 5px;
                }
            }

            h3, h4 {
                line-height: 50px;
            }

            h3 {
                font-size: 28px;
            }

            h4 {
                font-size: 24px;
            }

            .heading {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;

                img {
                    width: 30px;
                    cursor: pointer;
                    transition: transform 100ms ease-in-out;

                }
                .rotate {
                    transform: rotate(-180deg);
                    transition: transform 100ms ease-in-out;
                }

                .filter-count {
                    position: absolute;
                    right: -25px;
                }
            }

            .filter-content {
                overflow:hidden;
                transition:height 100ms ease-in-out;
                height: 0;
                padding-left: 5px;

                div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        font-size: 20px;
                        color: black;
                    }
                }
            }

        }

        .results {
            grid-row: 1 / 3;
            grid-column: 2;
            height: 700px;
            overflow-y: scroll;
            padding-right: 5px;

            .no-results {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                padding-top: 100px;
                display: flex;
                justify-content: center;

                svg {
                    height: 100px;
                    width: 100px;
                }
            }

            .result {
                padding: 20px;
                padding-left: 5px;
                border-bottom: 1px solid #858585;

                .result-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-bottom: 5px;

                    .subtitle {
                        color: #858585;
                        font-size: 16px;
                    }
                }

                .result-description {
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 20px;
                    height: 88px;
                    line-height: 1.1em;
                }
                
                .bottom {
                    line-height: 1.1em;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    span {
                        color: #858585;
                    }
                }
            }
        }
    }
`;

export default Wrapper;