import { useContext } from 'react'
import { Button, Delete } from '../../../../../components/button/Button'
import Modal from '../../../../../components/modal/Modal'
import { EducationModulesContext } from '../EducationModules'
import { simpleUser } from './../../../../../utils/Amplify';
import { useTranslation } from 'react-i18next';

const DeleteModal = ({ setIsLoading, setShowDeleteModal, module, closeModal }) => {

    const { t } = useTranslation();
    const { modules, setModules } = useContext(EducationModulesContext)

    const handleDelete = async () => {
        setIsLoading(true)
        try {
            const status = await fetch(`${process.env.REACT_APP_BACKEND_URL}/organisation/education/delete/module/${module.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (await simpleUser()).token,
                  }
            }).then(res => res.status)

            if (status !== 200) throw new Error('failed to delete')

            setModules(modules.filter(({ id }) => id !== module.id))

            closeModal()

        } catch (err) {
            console.log(err)
        }
        setIsLoading(false)
    }

    return (
        <Modal title={t('educations.deleteModule.title')} setState={setShowDeleteModal}>
            <p>
                {t('educations.deleteModule.verification')} "{module.moduleName}"?
            </p>
            <div className='buttons'>
                <Delete
                    onClick={(e) => {
                        e.preventDefault()
                        handleDelete()
                    }}
                >
                    {t('buttons.removeButton')}
                </Delete>
                <Button
                    onClick={(e) => {
                        e.preventDefault()
                        setShowDeleteModal(false)
                    }}
                >
                    {t('buttons.cancelButton')}
                </Button>
            </div>
        </Modal>
    )
}

export default DeleteModal
