import React, { useContext, useEffect, useState } from 'react'
import { t } from 'i18next'

import { RequirementQuestionContext } from '../RequirementQuestion'
import { SurveyWrapper } from '../../../../pages/catalog/subPages/style'
import { Grid } from '@mui/material'
import ControlledRadioButtonsGroup from '../../../../components/radioButton/RadioButton'
import { PreviewWrapper } from '../Style'
import Modal from '../../../../components/modal/Modal'
import { getQuestionImage } from '../../functions'

const PreviewRequirementQuestion = () => {
  const { previewQuestion, setModalPreviewIsVisible } = useContext(
    RequirementQuestionContext
  )

  const { question, questionType, questionImageFile } = previewQuestion

  const [imageQuestionUrl, setImageQuestionUrl] = useState('')

  const [imageAnswerUrls, setImageAnswerUrls] = useState([])

  useEffect(() => {
    const fetchImage = async () => {
      if (questionType === 'imageQuestion') {
        if (question.id && !questionImageFile) {
          const image = await getQuestionImage(question.questionImageKey)
          setImageQuestionUrl(image)
        } else {
          setImageQuestionUrl(URL.createObjectURL(questionImageFile))
        }
      } else if (questionType === 'imageAnswer') {
        const imageUrls = []
        for (let i = 0; i < question.questionAnswer.length; i++) {
          if (!question.questionAnswer[i].questionImageFile) {
            const image = await getQuestionImage(
              question.questionAnswer[i].questionImageKey
            )
            imageUrls.push(image)
          } else {
            imageUrls.push(
              URL.createObjectURL(question.questionAnswer[i].questionImageFile)
            )
          }
        }
        setImageAnswerUrls(imageUrls)
      }
    }
    fetchImage()
  }, [question, questionImageFile, questionType])

  return (
    <PreviewWrapper>
      <Modal
        title={t('pageRequirementQuestion.modalPreviewTitle')}
        setState={setModalPreviewIsVisible}
      >
        <SurveyWrapper>
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={12}
              paddingTop={2}
              gap={8}
              direction='column'
              alignItems='center'
              justifyContent='center'
              className='survey-container'
            >
              <Grid item paddingX={15}>
                <p className='question-name'>{question.questionName}</p>
              </Grid>

              {questionType === 'imageQuestion' && (
                <Grid item>
                  <img
                    className='question-image'
                    alt='question'
                    src={imageQuestionUrl}
                  />
                </Grid>
              )}
              {questionType === 'videoQuestion' && (
                <Grid item>
                  <iframe
                    width='560'
                    height='315'
                    src={`https://www.youtube.com/embed/${question.questionVideoUrl}`}
                    title='question video'
                  />
                </Grid>
              )}

              {questionType === 'imageAnswer' ||
              questionType === 'videoAnswer' ? (
                <Grid container style={{ marginBottom: '20px' }}>
                  {question.questionAnswer.map((answerOption, index) => (
                    <Grid
                      key={answerOption.id}
                      container
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Grid marginBottom='20px' item>
                        <label>
                          <input type='radio' name='answer' />
                          {` ${String.fromCharCode(65 + index)}) ${
                            answerOption.answer ?? ''
                          }`}
                        </label>
                      </Grid>
                      {questionType === 'videoAnswer' &&
                        answerOption.questionVideoUrl && (
                          <Grid marginLeft='15px' marginBottom='40px' item>
                            <iframe
                              width='360'
                              height='180'
                              src={`https://www.youtube.com/embed/${answerOption.questionVideoUrl}`}
                              title='answer video'
                            />
                          </Grid>
                        )}
                      {questionType === 'imageAnswer' && (
                        <Grid marginLeft='15px' marginBottom='40px' item>
                          <img
                            className='answer-image'
                            alt='answer'
                            src={imageAnswerUrls[index]}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid item style={{ marginBottom: '20px' }}>
                  <ControlledRadioButtonsGroup
                    options={question.questionAnswer.map(
                      (answerOption, index) => ({
                        value: String.fromCharCode(65 + index),
                        label: `${String.fromCharCode(65 + index)}) ${
                          answerOption.answer
                        }`,
                      })
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </SurveyWrapper>
      </Modal>
    </PreviewWrapper>
  )
}

export default PreviewRequirementQuestion
