import React, { Fragment, useContext, useState } from 'react'
import Modal from '../../../../components/modal/Modal'
import { QualificationContext } from '../Qualification'
import { Button } from '../../../../components/button/Button'
import Flag from 'react-world-flags'
import StyledSelect from '../../../../components/styledSelect/StyledSelect'
import { useTranslation } from 'react-i18next';

const Share = ({ option }) => {
  const { t } = useTranslation();
  const {
    setModalShareIsVisible,
    setModalDeleteIsVisible,
    setDeleteOption,
    selectedQualification,
    setSelectedCountryToRemove,
    setModalConfirmIsVisible,
    setModalConfirmTempCountry,
  } = useContext(QualificationContext)

  const [tempCountry, setTempCountry] = useState({
    label: 'Sweden',
    value: 'SE',
    icon: (
      <Flag code='SE' height={30} width={30} style={{ marginRight: '10px' }} />
    ),
  })

  const [page, setPage] = useState('publish')

  return (
    <Modal
      setState={option === 'share' && setModalShareIsVisible}
      title={option === 'share' && t('pageShareQualification.shareTitle')}
    >
      <div className='share'>
        <nav>
          <ul>
            <li className='pointer' onClick={() => setPage('share')}>
              <span
                style={
                  page === 'share'
                    ? {
                      borderBottom: '1px solid grey',
                      padding: '2px 0',
                      fontWeight: 'bold',
                    }
                    : { padding: '2px 0' }
                }
              >
                {t('pageShareQualification.shareOption')}
              </span>
            </li>
            <li className='pointer' onClick={() => setPage('publish')}>
              <span
                style={
                  page === 'publish'
                    ? {
                      borderBottom: '1px solid grey',
                      padding: '2px 0',
                      fontWeight: 'bold',
                    }
                    : {}
                }
              >
                {t('pageShareQualification.publishOption')}
              </span>
            </li>
          </ul>
        </nav>
        {page === 'publish' && (
          <Fragment>
            <div className='m-top-btm-20'>
              <p>
              {t('pageShareQualification.share.selectCountry')}

              </p>
              <div className='m-top-btm-20 flex align-button sb border-btm'>
                <StyledSelect
                  dark
                  country
                  value={tempCountry}
                  onChange={(e) => setTempCountry(e)}
                  options={[
                    {
                      label: 'Sweden',
                      value: 'SE',
                      icon: (
                        <Flag
                          code='SE'
                          height={30}
                          width={30}
                          style={{ marginRight: '10px' }}
                        />
                      ),
                    },
                    {
                      label: 'Great Britain',
                      value: 'GB',
                      icon: (
                        <Flag
                          code='GB'
                          height={30}
                          width={30}
                          style={{ marginRight: '10px' }}
                        />
                      ),
                    },
                  ]}
                />
                {selectedQualification.qualificationCountries.some(
                  (shared) => shared.country === tempCountry.label
                ) ? (
                  <p className='published'>{t('pageShareQualification.share.alreadyPublished')}</p>
                ) : (
                  <Button
                    onClick={(e) => {
                      setModalConfirmTempCountry(tempCountry)
                      setModalConfirmIsVisible(true)
                    }}
                  >
                    {t('pageShareQualification.buttons.publishButton')}
                  </Button>
                )}
              </div>
            </div>

            <div className='list'>
              <p>{t('pageShareQualification.share.versionsTitle')}</p>
              <div className='shared-container flex'>
                <Fragment>
                  <div className='land'>
                    <p className='title'>{t('pageShareQualification.country')}</p>
                    {selectedQualification.qualificationCountries &&
                      selectedQualification.qualificationCountries.map(
                        ({ id, country }) => (
                          <p key={id} className='item'>
                            {country}
                          </p>
                        )
                      )}
                  </div>

                  <div className='date'>
                    <p className='title'>{t('pageShareQualification.date')}</p>
                    {selectedQualification.qualificationCountries &&
                      selectedQualification.qualificationCountries.map(
                        ({ id, localDate }) => (
                          <p key={id} className='item'>
                            {localDate}
                          </p>
                        )
                      )}
                  </div>

                  <div style={{ maxWidth: '60px' }}>
                    <p className='item'>
                      <br />
                    </p>
                    {selectedQualification.qualificationCountries &&
                      selectedQualification.qualificationCountries.map(
                        ({ id, country }) => (
                          <p
                            key={id}
                            className='item pointer'
                            onClick={(e) => {
                              setDeleteOption('country')
                              setSelectedCountryToRemove({ id, country })
                              setModalDeleteIsVisible(true)
                            }}
                          >
                            {t('pageShareQualification.share.deleteCountryButton')}
                          </p>
                        )
                      )}
                  </div>
                </Fragment>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'flex-end',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                }}
              >
                <Button onClick={() => setModalShareIsVisible(false)}>
                {t('pageShareQualification.share.finishButton')}
                </Button>
              </div>
            </div>
          </Fragment>
        )}

        {page === 'share' && (
          <div
            style={{
              display: 'grid',
              gridTemplate: '1fr 1fr',
            }}
          >
            <p
              style={{
                margin: '22% auto',
              }}
            >
              {t('pageShareQualification.share.devMessage')}
            </p>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default Share
