import styled from 'styled-components';

const DescAndReqWrapper = styled.div`
    flex-grow: 1;
    
    .mode-switch {

        h4 {
            display: flex;
            gap: 15px;

            span {

                &.desc {
                    cursor: pointer;
                    color: ${props => props.subQualMode === 'descriptions' ? '#3f3d56' : '#858585'};
                }

                &.reqs {
                    cursor: pointer;
                    color: ${props => props.subQualMode === 'descriptions' ? '#858585' : '#3f3d56'};
                }
            }
        }
    }
`;

export default DescAndReqWrapper;