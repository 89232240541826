import React, { Fragment, useContext, useState } from 'react'

import { Button, Delete } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import StyledLabel from '../../../../components/styledLabel/StyledLabel'
import { handleAddFormChange, handleAreaEditFormSubmit } from '../../functions'
import { SubQualificationContext } from '../SubQualification'
import { Wrapper } from './Style'
import { useTranslation } from 'react-i18next'

const EditAreaSub = () => {
  const { t } = useTranslation()
  const {
    subQualificationsList,
    setSubQualificationsList,
    modalEditIsVisible,
    dispatchModalEditIsVisible,
    id,
    subQualificationId,
    selectedArea,
    setModalDeleteIsVisible,
  } = useContext(SubQualificationContext)

  const initializedAddFormData = {
    name: selectedArea.name,
    description: selectedArea.description,
    published: 0,
    modified: 1,
  }
  // State for the form inputs
  const [addFormData, setAddFormData] = useState(initializedAddFormData)

  return (
    <Fragment>
      <Modal
        title={t('competenceArea.editCompetenceArea')}
        dispatcher={{ modalEditIsVisible, dispatchModalEditIsVisible }}
      >
        <Wrapper>
          <form
            onSubmit={(e) =>
              handleAreaEditFormSubmit(e, {
                id,
                addFormData,
                subQualificationId,
                subQualificationsList,
                setSubQualificationsList,
                dispatchModalEditIsVisible,
                modalEditIsVisible,
                selectedArea,
              })
            }
          >
            <StyledLabel>
              <p>{t('competenceArea.competenceArea')} *</p>
              <input
                type='text'
                name='name'
                required='required'
                placeholder={t('competenceArea.competenceAreaPlaceholder')}
                defaultValue={addFormData.name}
                onChange={(e) =>
                  handleAddFormChange(e, addFormData, setAddFormData)
                }
              />
            </StyledLabel>
            <StyledLabel>
              <br />
              <p>{t('competenceArea.description')} *</p>
              <textarea
                name='description'
                className='textarea'
                required='required'
                rows='5'
                placeholder={t('competenceArea.descriptionPlaceholder')}
                defaultValue={addFormData.description}
                onChange={(e) =>
                  handleAddFormChange(e, addFormData, setAddFormData)
                }
              ></textarea>
            </StyledLabel>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '36px',
              }}
            >
              <Delete
                onClick={(e) => {
                  e.preventDefault()
                  setModalDeleteIsVisible(true)
                }}
              >
                {t('competenceArea.modal.buttonDelete')}
              </Delete>
              <Button type='submit' style={{ marginLeft: '10px' }}>
              {t('competenceArea.modal.buttonSave')}
              </Button>
            </div>
          </form>
        </Wrapper>
      </Modal>
    </Fragment>
  )
}
export default EditAreaSub
