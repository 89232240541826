import React, { useEffect, useState } from 'react'
import { Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material'
import { Wrapper } from '../../style'
import PageTitleSpecial from '../../../../components/pageTitle/PageTitleSpecial'
import { getAreaList } from '../../services/Crud'
import BreadCrumbs from '../../../../utils/BreadCrumbs'
import { useTranslation } from 'react-i18next'

const ViewArea = ({ subqualification }) => {
  const { t } = useTranslation()
  const [subQualificationList, setSubQualificationList] = useState([])
  useEffect(() => {
    let isLoading = true
    getAreaList(subqualification.id).then((data) => {
      if (isLoading) {
        setSubQualificationList(data)
      }
    })
    // eslint-disable-next-line
  }, [])
  return (
    <Wrapper className='wrapper-prs'>
      <BreadCrumbs custom />
      <PageTitleSpecial
        title={subqualification.name}
        info={subqualification.description}
      ></PageTitleSpecial>
      <div style={{ marginTop: '20px' }}>
        <Table
          sx={{
            maxWidth: '100%',
            border: '1px solid #e0e0e0',
            marginBottom: '10px',
          }}
          aria-label='simple table'
        >
          <colgroup>
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ paddingBottom: 0, borderLeft: '1px solid #e0e0e0' }}
              >
                {t('pageSubQualification.modals.addEdit.competenceArea')}
              </TableCell>
              <TableCell
                sx={{ paddingBottom: 0, borderLeft: '1px solid #e0e0e0' }}
              >
                {t('pageSubQualification.modals.addEdit.knowledge')}

              </TableCell>
              <TableCell
                sx={{ paddingBottom: 0, borderLeft: '1px solid #e0e0e0' }}
              >
                {t('pageSubQualification.modals.addEdit.application')}
              </TableCell>
              <TableCell
                sx={{ paddingBottom: 0, borderLeft: '1px solid #e0e0e0' }}
              >
                {t('pageSubQualification.modals.addEdit.autonomyResponsibility')}
              </TableCell>
            </TableRow>
          </TableHead>
          <colgroup>
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
          </colgroup>
          <TableBody>
            {subQualificationList &&
              subQualificationList.map(
                ({ id, name, qualificationRequirement }) => (
                  <TableRow key={id}>
                    <TableCell sx={{ borderLeft: '1px solid #e0e0e0' }}>
                      {name}
                    </TableCell>
                    <TableCell
                      sx={{ paddingBottom: 0, borderLeft: '1px solid #e0e0e0' }}
                    >
                      <div className='flex-column'>
                        {qualificationRequirement &&
                          qualificationRequirement
                            .filter((requirement) => {
                              return requirement.requirementType === 'knowledge'
                            })
                            .map((requirement) => (
                              <div className='flex' key={requirement.id}>
                                <p>{requirement.description}</p>
                              </div>
                            ))}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{ paddingBottom: 0, borderLeft: '1px solid #e0e0e0' }}
                    >
                      <div className='flex-column'>
                        {qualificationRequirement &&
                          qualificationRequirement
                            .filter(
                              (requirement) =>
                                requirement.requirementType === 'skill'
                            )
                            .map((requirement) => (
                              <div className='flex' key={requirement.id}>
                                <p>{requirement.description}</p>
                              </div>
                            ))}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{ paddingBottom: 0, borderLeft: '1px solid #e0e0e0' }}
                    >
                      <div className='flex-column'>
                        {qualificationRequirement &&
                          qualificationRequirement
                            .filter(
                              (requirement) =>
                                requirement.requirementType ===
                                'responsibilityAndIndependence'
                            )
                            .map((requirement) => (
                              <div className='flex' key={requirement.id}>
                                <p>{requirement.description}</p>
                              </div>
                            ))}
                      </div>
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </div>
    </Wrapper>
  )
}

export default ViewArea
