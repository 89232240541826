import { useContext } from 'react'
import { t } from 'i18next'

import { Button, Delete } from '../../../components/button/Button'
import Modal from '../../../components/modal/Modal'
import { CompetenceProfileContext } from '../CompetenceProfile'
import { removeCompletedSurvey } from '../../qualifications/functions'

const RemoveSurvey = () => {
  const { setShowRemoveSurveyModal, setCompleted, selectedSurveyID } =
    useContext(CompetenceProfileContext)

  return (
    <Modal title={t('pageRemoveSurvey.removeTitle')}>
      <p style={{ paddingTop: '15px', paddingBottom: '15px' }}>
        {' '}
        {t('pageRemoveSurvey.info-1')}
      </p>
      <p> {t('pageRemoveSurvey.info-2')}</p>
      <div className='button-container'>
        <Delete
          className='button'
          onClick={() => setShowRemoveSurveyModal(false)}
        >
          {t('pageRemoveSurvey.cancel')}
        </Delete>
        <Button
          onClick={() => {
            removeCompletedSurvey({ id: selectedSurveyID, setCompleted })
            setShowRemoveSurveyModal(false)
          }}
        >
          {t('pageRemoveSurvey.remove')}
        </Button>
      </div>
    </Modal>
  )
}

export default RemoveSurvey
