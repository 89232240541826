import React, { useContext, useEffect } from 'react'
import { t } from 'i18next'

import { SubContext } from '../Survey'

const SurveyTimer = () => {
  const {
    mappingTime,
    surveyStarted,
    timeLeft,
    setTimeLeft,
    handleSubmitSurvey,
  } = useContext(SubContext)

  useEffect(() => {
    let interval
    if (surveyStarted && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 60 * 1000)
    } else if (surveyStarted && timeLeft === 0) {
      handleSubmitSurvey()
    }

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyStarted, timeLeft, setTimeLeft])

  return (
    <div className='bold'>
      {t('pageSurvey.allowedTime', {
        time: mappingTime,
      })}{' '}
      / {t('pageSurvey.timeLeft')}
      <span className={timeLeft <= 5 ? 'time-left' : ''}>
        {t('pageSurvey.minutes', {
          timeLeft: timeLeft,
        })}
      </span>
    </div>
  )
}

export default SurveyTimer