import styled from 'styled-components'

// The Footer Style
export const Wrapper = styled.footer`
background: #3f3d56;
width: 100vw;

> div {
  display: flex;
  width: 1010px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 77px 0;

  .logo {
    height: 63px;
    width: auto;
  }
  
  p {
    height: fit-content;
    color: white;

    a {
      font-weight: 400;
      color: white;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

`