import { useContext, useState, useRef, useEffect } from "react"
import { Button, Delete } from "../../../../../components/button/Button"
import Modal from "../../../../../components/modal/Modal"
import StyledLabel from "../../../../../components/styledLabel/StyledLabel"
import { EducationModulesContext } from "../EducationModules"
import DeleteModal from "./DeleteModal"
import { ReactComponent as LoadingSpinner } from "../../../../../assets/img/spinner.svg"
import { EducationContext } from "../../../Educations"
import { simpleUser } from './../../../../../utils/Amplify';
import { useTranslation } from 'react-i18next';

const AddEdit = ({ modalModule, setModalModule, modalSubQualification }) => {
    const { t } = useTranslation(); // Add this line to use translations
    const [formInputs, setFormInputs] = useState({
        moduleName: modalModule.moduleName ? modalModule.moduleName : '',
        moduleDescription: modalModule.moduleDescription ? modalModule.moduleDescription : '',
        moduleLink: modalModule.moduleLink ? modalModule.moduleLink : ''
    })
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [errors, setErrors] = useState({
        name: null,
        description: null,
        link: null
    })
    const [isLoading, setIsLoading] = useState(false)

    const { modules, setModules } = useContext(EducationModulesContext)
    const { selectedEducation } = useContext(EducationContext)

    const mounted = useRef(true)
    useEffect(() => {
        return () => {
            mounted.current = false
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()

        const tempErrors = { ...errors }
        try {
            let { moduleName, moduleDescription, moduleLink } = formInputs

            if (!moduleName) tempErrors.name = t('educations.addEditModule.addmodule')
            if (moduleName.length > 400) tempErrors.description = t('educations.addEditModule.addmodule')
            if (!moduleDescription) tempErrors.description = t('educations.addEditModule.addmodule')
            if (moduleDescription.length > 1000) tempErrors.description = t('educations.addEditModule.addmodule')
            if (!moduleLink) tempErrors.link = t('educations.addEditModule.addmodule')
            if (moduleLink.length > 2083) tempErrors.description = t('educations.addEditModule.addmodule')

            if (Object.values(tempErrors).some(error => error)) {
                throw new Error('failed validation')
            }

            setIsLoading(true)

            moduleLink = moduleLink.trim()
            moduleLink = moduleLink.startsWith('http') ? moduleLink : `http://${moduleLink}`

            const resModule = await fetch(modalModule.mode === 'add' ?
                    `${process.env.REACT_APP_BACKEND_URL}/organisation/education/add/module/education/${selectedEducation.id}/subqualification/${modalSubQualification.id}`
                    : `${process.env.REACT_APP_BACKEND_URL}/organisation/education/module/update/${modalModule && modalModule.id}`, {
                method: modalModule.mode === 'add' ? 'POST' : 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (await simpleUser()).token,
                  },
                body: JSON.stringify({ ...formInputs, moduleLink })
            })
                .then(res => res.json())

            if (resModule.error) {
                throw new Error(resModule.message)
            }

            const tempModules = modules.filter(({ id }) => id !== modalModule.id)
            tempModules.push(resModule)

            setModules(tempModules)

            setModalModule()
        } catch (err) {
            console.log(err)

            setErrors(tempErrors)
        }
        setIsLoading(false)
    }

    return (
        <Modal
            title={
                modalModule.mode === 'add'
                    ? t('educations.addEditModule.addmodule')
                    : t('educations.addEditModule.editmodule')
            }
            setState={setModalModule}
        >
            {
                showDeleteModal &&
                <DeleteModal
                    setIsLoading={setIsLoading}
                    setShowDeleteModal={setShowDeleteModal}
                    module={modalModule}
                    closeModal={() => setModalModule()}
                />
            }
            <p><span>{t('educations.addEditModule.subqualification')}: </span>{modalModule.name}</p>
            <form onSubmit={e => { mounted.current && handleSubmit(e) }}>
                <StyledLabel
                    className={errors.name ? 'error' : ''}
                    errorContent={errors.name}
                >
                    <p>{t('educations.addEditModule.module')} *</p>
                    <input
                        value={formInputs.moduleName}
                        onInput={e => setFormInputs({ ...formInputs, moduleName: e.target.value })}
                        onFocus={() => setErrors({ ...errors, name: null })}
                    />
                </StyledLabel>
                <StyledLabel
                    className={errors.description ? 'error' : ''}
                    errorContent={errors.description}
                >
                    <p>{t('educations.addEditModule.description')} *</p>
                    <textarea
                        rows='7'
                        value={formInputs.moduleDescription}
                        onInput={e => setFormInputs({ ...formInputs, moduleDescription: e.target.value })}
                        onFocus={() => setErrors({ ...errors, description: null })}
                    />
                </StyledLabel>
                <StyledLabel
                    className={errors.link ? 'error' : ''}
                    errorContent={errors.link}
                >
                    <p>{t('educations.addEditModule.link')} *</p>
                    <input
                        value={formInputs.moduleLink}
                        onInput={e => setFormInputs({ ...formInputs, moduleLink: e.target.value })}
                        onFocus={() => setErrors({ ...errors, link: null })}
                    />
                </StyledLabel>
                <a className="module-link" href={formInputs.moduleLink}>
                    <Button type="button" sm onClick={e => {
                        e.preventDefault()
                        window.open(formInputs.moduleLink.startsWith('http') ? formInputs.moduleLink : `http://${formInputs.moduleLink}`, '_blank').focus();
                    }}>
                        {t('educations.addEditModule.view')}
                    </Button>
                </a>
                <div className="buttons">
                    <LoadingSpinner width={40} height={40} visibility={isLoading ? 'visible' : 'hidden'} />
                    {modalModule.mode === 'edit' &&
                        <Delete
                            type="button"
                            onClick={e => {
                                e.preventDefault()
                                setShowDeleteModal(true)
                            }}
                        >
                            {t('buttons.deleteButton')}
                        </Delete>}
                    <Button type="submit">{modalModule.mode === 'add' ? t('buttons.addButton') : t('buttons.saveButton')}</Button>
                </div>
            </form>
        </Modal >
    )
}


export default AddEdit
