import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { simpleUser } from "../../utils/Amplify";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { EmployeesContext } from "./Employees";
import { getPermissionShort } from "../../utils/Misc";
import { Context } from "../../utils/Context";
import { freshPerms } from "../../utils/Backend";
import { ReactComponent as LoadingSpinner } from "../../assets/img/spinner.svg";
import { ReactComponent as EditPencil } from "../../assets/img/Edit_pencil.svg";

const Wrapper = styled.div`
  white-space: nowrap;
  border-bottom: 1px solid #cfcfd5;

  .loading-employees {
    width: 1160px;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 30px;

    svg {
      height: 50px;
    }
  }

  .MuiTableHead-root {
    border-bottom: 1px solid #cfcfd5;
  }

  .MuiTableCell-head {
    height: 66px;
    vertical-align: bottom;
    padding-bottom: 5px;
    line-height: 1em;
    background-color: white;
    color: #858585;
  }

  .MuiTableRow-root {
    border-bottom: 1px solid #cfcfd5;
  }

  .MuiTableCell-root {
    border-bottom: none;
    height: fit-content;
    padding-left: 15px;
    padding-right: 15px;
  }

  .border {
    border-left: 1px solid #cfcfd5;

    &.end {
      border-right: 1px solid #cfcfd5;
    }
  }

  .grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: fit-content() fit-content();
    grid-template-rows: 36px 19px;
    text-align: center;
    margin-bottom: -1px;
    align-items: end;

    .span2 {
      font-weight: 400;
      line-height: 1.2em;
      grid-column: 1 / 3;

      &.bold {
        font-weight: inherit;
      }
    }
  }

  .data-grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;

    .span2 {
      grid-column: 1 / 3;
    }
  }

  .last {
    min-width: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const DenseTable = ({
  setShowEditEmployee,
  mode,
  searchFilter,
  filterProfessionalRoleNames,
  filterQualifications,
  filterEducations,
  filterPermissions,
  organisationId,
  hasOrganisationId,
}) => {
  const { t } = useTranslation();

  const { setRow, rows, setRows, levels } = useContext(EmployeesContext);
  const {
    permissions: { employeeAccess, rightsAccess, initializeAccess },
  } = useContext(Context);

  const [rowsLoading, setRowsLoading] = useState(false);

  const simpleFilter = {
    professionalRoleNames: {
      include: filterProfessionalRoleNames.include,
      selectedItems: filterProfessionalRoleNames.selectedItems.map(
        (selectedItem) => {
          return selectedItem.value;
        }
      ),
    },
    qualifications: {
      include: filterQualifications.include,
      selectedItems: filterQualifications.selectedItems.map((selectedItem) => {
        return selectedItem.value;
      }),
    },
    // educations: {
    //   include: filterEducations.include,
    //   selectedItems: filterEducations.selectedItems.map((selectedItem) => {
    //     return selectedItem.value
    //   }),
    // },
    permissions: {
      include: filterPermissions.include,
      qualifications: filterPermissions.selectedItems
        .filter((selectedItem) => {
          return selectedItem.value.startsWith("qualifications-");
        })
        .map((selectedItem) => selectedItem.value.split("-")[1]),
      initiations: filterPermissions.selectedItems
        .filter((selectedItem) => {
          return selectedItem.value.startsWith("initiations-");
        })
        .map((selectedItem) => selectedItem.value.split("-")[1]),
      permissions: filterPermissions.selectedItems
        .filter((selectedItem) => {
          return selectedItem.value.startsWith("permissions-");
        })
        .map((selectedItem) => selectedItem.value.split("-")[1]),
      employees: filterPermissions.selectedItems
        .filter((selectedItem) => {
          return selectedItem.value.startsWith("employees-");
        })
        .map((selectedItem) => selectedItem.value.split("-")[1]),
      competencePlanning: filterPermissions.selectedItems
        .filter((selectedItem) => {
          return selectedItem.value.startsWith("competencePlanning-");
        })
        .map((selectedItem) => selectedItem.value.split("-")[1]),
      educations: filterPermissions.selectedItems
        .filter((selectedItem) => {
          return selectedItem.value.startsWith("educations-");
        })
        .map((selectedItem) => selectedItem.value.split("-")[1]),
      stats: filterPermissions.selectedItems
        .filter((selectedItem) => {
          return selectedItem.value.startsWith("stats-");
        })
        .map((selectedItem) => selectedItem.value.split("-")[1]),
    },
  };
  const history = useHistory();

  useEffect(() => {
    const getRows = async () => {
      setRowsLoading(true);
      try {
        const storedPerms = await freshPerms();

        if (storedPerms.employeeAccess === "none") {
          let throwMsg = "Not authorized";
          throw throwMsg;
        }

        const data = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationId}/employees`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + (await simpleUser()).token,
            },
          }
        ).then((res) => res.json());

        setRows(
          data.map((user) => {
            return {
              ...user,
              qualifications: [
                {
                  id: 7,
                  qualificationName: "JavaScript",
                  qualificationDescription: "JavaScript",
                  qualificationType: "Kvalifikation",
                  qualificationAccess: "Frontend-utveckling",
                  qualificationLanguage: "Swedish",
                  version: 0,
                  published: false,
                  subscribers: 0,
                  startDate: null,
                  endDate: null,
                },
              ],
              competenceDevelopments: [
                {
                  name: "JavaScript",
                  finished: true,
                },
                {
                  name: "Java",
                  finished: false,
                },
              ],
              qualificationMappings: [
                {
                  name: "foobar",
                  finished: true,
                },
                {
                  name: "barfoo",
                  finished: true,
                },
                {
                  name: "unfinished",
                  finished: false,
                },
              ],
              educations: [
                {
                  name: "foobar",
                  finished: true,
                },
                {
                  name: "barfoo",
                  finished: true,
                },
                {
                  name: "unfinished",
                  finished: false,
                },
              ],
              permissions: {
                qualifications: user.qualificationAccess,
                initiations: user.initializeAccess,
                permissions: user.rightsAccess,
                employees: user.employeeAccess,
                competencePlanning: user.qualificationPlanningAccess,
                educations: user.educationAccess,
                stats: user.statisticAccess,
              },
            };
          })
        );
      } catch (err) {
        typeof err === "string" ? alert(err) : console.log(err);
        history.push("/home");
      }
      setRowsLoading(false);
    };

    getRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { path } = useRouteMatch();

  return (
    <Wrapper className="table-wrapper">
      <TableContainer>
        {mode === "org" ? (
          <Table size="small" aria-label="organisation table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t("pageEmployees.employeeSearch.textFields.employeeNumber")}
                </TableCell>
                <TableCell>
                  {t("pageEmployees.employeeSearch.textFields.firstName")}
                </TableCell>
                <TableCell>
                  {t("pageEmployees.employeeSearch.textFields.lastName")}
                </TableCell>
                <TableCell>
                 
                  {t("pageEmployees.searchResults.table.professionalRoleName")}
                </TableCell>

                {levels.levelOne && <TableCell>{t('pageEmployees.searchResults.table.level1')}</TableCell>}
                {levels.levelTwo && <TableCell>{t('pageEmployees.searchResults.table.level2')}</TableCell>}
                {levels.levelThree && (
                  <TableCell>{t('pageEmployees.searchResults.table.level3')}</TableCell>
                )}
                {levels.levelFour && <TableCell>{t('pageEmployees.searchResults.table.level4')}</TableCell>}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0
                ? rows
                    .filter((row, i) => {
                      Object.keys(row).forEach((key) => {
                        if (row[key] === null) {
                          row[key] = undefined;
                        }
                      });
                      return (
                        Object.keys(searchFilter).every((key) => {
                          const str = row[key] + "";
                          return str
                            .toLowerCase()
                            .startsWith(searchFilter[key].toLowerCase());
                        }) &&
                        (simpleFilter.permissions.include
                          ? Object.keys(row.permissions).every((key) => {
                              return (
                                simpleFilter.permissions[key].length === 0 ||
                                simpleFilter.permissions[key].includes(
                                  row.permissions[key]
                                )
                              );
                            })
                          : Object.keys(row.permissions).every((key) => {
                              return !simpleFilter.permissions[key].includes(
                                row.permissions[key]
                              );
                            })) &&
                        (simpleFilter.professionalRoleNames.include
                          ? filterProfessionalRoleNames.selectedItems.length ===
                              0 ||
                            simpleFilter.professionalRoleNames.selectedItems.includes(
                              row.professionalRoleName
                            )
                          : !simpleFilter.professionalRoleNames.selectedItems.includes(
                              row.professionalRoleName
                            )) &&
                        (simpleFilter.qualifications.include
                          ? filterQualifications.selectedItems.length === 0 ||
                            row.qualifications.some((qualification) =>
                              simpleFilter.qualifications.selectedItems.includes(
                                qualification.id
                              )
                            )
                          : !row.qualifications.some((qualification) =>
                              simpleFilter.qualifications.selectedItems.includes(
                                qualification.id
                              )
                            ))
                        //     &&
                        // (simpleFilter.educations.include
                        //   ? filterEducations.selectedItems.length === 0 ||
                        //     row.educations.some((education) =>
                        //       simpleFilter.educations.selectedItems.includes(
                        //         education.name
                        //       )
                        //     )
                        //   : !row.educations.some((education) =>
                        //       simpleFilter.educations.selectedItems.includes(
                        //         education.id
                        //       )
                        //     ))
                      );
                    })
                    .map((row) => (
                      <TableRow
                        key={row.employeeNumber}
                        classes={{ root: "row" }}
                      >
                        <TableCell component="th" align="left">
                          {initializeAccess !== "none" && hasOrganisationId ? (
                            // Render plain text when hasOrganisationId is true
                            <p>{row.employeeNumber}</p>
                          ) : (
                            // Render Link component when hasOrganisationId is false or undefined
                            <Link
                              to={`${path}/${row.firstName} ${row.lastName}`}
                              onClick={() => setRow(row)}
                            >
                              {row.employeeNumber}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>{row.firstName}</TableCell>
                        <TableCell>{row.lastName}</TableCell>
                        <TableCell>{row.professionalRoleName}</TableCell>
                        {levels.levelOne && (
                          <TableCell>
                            {row.levelOne ? row.levelOne : ""}
                          </TableCell>
                        )}
                        {levels.levelTwo && (
                          <TableCell>
                            {row.levelTwo ? row.levelTwo : ""}
                          </TableCell>
                        )}
                        {levels.levelThree && (
                          <TableCell>
                            {row.levelThree ? row.levelThree : ""}
                          </TableCell>
                        )}
                        {levels.levelFour && (
                          <TableCell>
                            {row.levelFour ? row.levelFour : ""}
                          </TableCell>
                        )}
                        {employeeAccess === "edit" && (
                          <TableCell
                            classes={{ root: "last" }}
                            onClick={() => {
                              setRow(row);
                              setShowEditEmployee(true);
                            }}
                          >
                            <EditPencil />
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                : rowsLoading && (
                    <TableRow className="loading-employees">
                      <TableCell>
                        <LoadingSpinner />
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        ) : (
          <Table
            classes={{ root: "permissions-table" }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  {t("pageEmployees.employeeSearch.textFields.employeeNumber")}
                </TableCell>
                <TableCell>
                  {t("pageEmployees.employeeSearch.textFields.firstName")}
                </TableCell>
                <TableCell>
                  {t("pageEmployees.employeeSearch.textFields.lastName")}
                </TableCell>
                <TableCell>{t("pageHome.employeesTitle")}</TableCell>
                <TableCell>{t("extra.permissions")}</TableCell>
                <TableCell>{t("pageHome.initiationsTitle")}</TableCell>
                <TableCell>{t("pageHome.competencePlanningTitle")}</TableCell>
                <TableCell>{t("pageHome.qualificationsTitle")}</TableCell>
                <TableCell>{t("pageHome.educationsTitle")}</TableCell>
                <TableCell>{t("pageHome.statsTitle")}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0
                ? rows
                    .filter((row, i) => {
                      Object.keys(row).forEach((key) => {
                        if (row[key] === null) {
                          row[key] = undefined;
                        }
                      });
                      return (
                        Object.keys(searchFilter).every((key) => {
                          const str = row[key] + "";
                          return str
                            .toLowerCase()
                            .startsWith(searchFilter[key].toLowerCase());
                        }) &&
                        (simpleFilter.permissions.include
                          ? Object.keys(row.permissions).every((key) => {
                              return (
                                simpleFilter.permissions[key].length === 0 ||
                                simpleFilter.permissions[key].includes(
                                  row.permissions[key]
                                )
                              );
                            })
                          : Object.keys(row.permissions).every((key) => {
                              return !simpleFilter.permissions[key].includes(
                                row.permissions[key]
                              );
                            })) &&
                        (simpleFilter.professionalRoleNames.include
                          ? filterProfessionalRoleNames.selectedItems.length ===
                              0 ||
                            simpleFilter.professionalRoleNames.selectedItems.includes(
                              row.professionalRoleName
                            )
                          : !simpleFilter.professionalRoleNames.selectedItems.includes(
                              row.professionalRoleName
                            )) &&
                        (simpleFilter.qualifications.include
                          ? filterQualifications.selectedItems.length === 0 ||
                            row.qualifications.some((qualification) =>
                              simpleFilter.qualifications.selectedItems.includes(
                                qualification.id
                              )
                            )
                          : !row.qualifications.some((qualification) =>
                              simpleFilter.qualifications.selectedItems.includes(
                                qualification.id
                              )
                            )) /* &&
                        (simpleFilter.educations.include
                          ? filterEducations.selectedItems.length === 0 ||
                            row.educations.some((education) =>
                              simpleFilter.educations.selectedItems.includes(
                                education.name
                              )
                            )
                          : !row.educations.some((education) =>
                              simpleFilter.educations.selectedItems.includes(
                                education.id
                              )
                            )) */
                      );
                    })
                    .map((row) => (
                      <TableRow
                        key={row.employeeNumber}
                        classes={{ root: "row" }}
                      >
                        <TableCell component="th">
                          {initializeAccess !== "none" ? (
                            <Link
                              to={`${path}/${row.firstName} ${row.lastName}`}
                              onClick={() => setRow(row)}
                            >
                              {row.employeeNumber}
                            </Link>
                          ) : (
                            <p>{row.employeeNumber} </p>
                          )}
                        </TableCell>
                        <TableCell>{row.firstName}</TableCell>
                        <TableCell>{row.lastName}</TableCell>
                        <TableCell align="center">
                          {getPermissionShort(row.permissions.employees)}
                        </TableCell>
                        <TableCell align="center">
                          {getPermissionShort(row.permissions.permissions)}
                        </TableCell>
                        <TableCell align="center">
                          {getPermissionShort(row.permissions.initiations)}
                        </TableCell>
                        <TableCell align="center">
                          {getPermissionShort(
                            row.permissions.competencePlanning
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {getPermissionShort(row.permissions.qualifications)}
                        </TableCell>
                        <TableCell align="center">
                          {getPermissionShort(row.permissions.educations)}
                        </TableCell>
                        <TableCell align="center">
                          {getPermissionShort(row.permissions.stats)}
                        </TableCell>
                        {rightsAccess === "edit" && (
                          <TableCell
                            classes={{ root: "last" }}
                            onClick={() => {
                              setRow(row);
                              setShowEditEmployee(true);
                            }}
                          >
                            <EditPencil />
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                : rowsLoading && (
                    <TableRow className="loading-employees">
                      <TableCell>
                        <LoadingSpinner />
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Wrapper>
  );
};

export default DenseTable;
