import React, { createContext, useContext, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'

import PageTitle from '../../components/pageTitle/PageTitle'
import { Context } from '../../utils/Context'
import {
  getAllQualificationsByOrganisation,
  getSelectedRoleQualifications,
} from '../competencePlanning/services/Crud'
import { getQualificationSurvey } from '../Employees/services/crud'
import { SubPageWrapper as CompetenceProfileWrapper } from '../Employees/style'
import CompletedEducations from './components/CompletedEducations'
import CompletedSurveys from './components/CompletedSurveys'
import EmployeeInfo from './components/EmployeeInfo'
import OngoingEducations from './components/OngoingEducations'
import OngoingSurveys from './components/OngoingSurveys'
import UnCompletedSurvey from './components/UnCompletedSurvey'
import QualEduHub from './subPages/QualEduHub'
import RemoveSurvey from './components/RemoveSurvey'
import { useTranslation } from 'react-i18next'

export const CompetenceProfileContext = createContext()

const CompetenceProfile = () => {
  const { t } = useTranslation()
  const { employee, organisation, individual } = useContext(Context)

  /** ---------- useState ---------- */
  const [addBtnClicked, setAddBtnClicked] = useState(false)
  const [editBtnClicked, setEditBtnClicked] = useState(false)

  const [qualificationsByRole, setQualificationsByRole] = useState([])
  const [qualificationsByOrganisation, setQualificationsByOrganisation] =
    useState([])
  const [selectedSurveyID, setSelectedSurveyID] = useState(
    localStorage.getItem('competenceProfileSelectedSurveyID')
  )
  const [selectedEducation, setSelectedEducation] = useState(
    JSON.parse(localStorage.getItem('competenceProfileSelectedEducation'))
  )
  const [selectedSurvey, setSelectedSurvey] = useState(
    JSON.parse(localStorage.getItem('competenceProfileSelectedSurvey'))
  )
  const [ongoing, setOngoing] = useState()
  const [completed, setCompleted] = useState()
  const [selectedUncompletedSurveyID, setSelectedUncompletedSurveyID] =
    useState(
      localStorage.getItem('competenceProfileSelectedUncompletedSurveyID')
    )

  const [hasUpdated, setHasUpdated] = useState(false)

  const [showRemoveSurveyModal, setShowRemoveSurveyModal] = useState(false)

  /** ---------- useEffect ---------- */
  useEffect(() => {
    let isLoading = true
    getQualificationSurvey({ individualId: individual.id })
      .then((survey) => {
        if (isLoading) {
          const ongoingSurveys = survey.filter((s) => !s.completed)
          const completedSurveys = survey.filter((s) => s.completed)
          setOngoing(ongoingSurveys)
          setCompleted(completedSurveys)
        }
      })
      .catch((err) => console.log(err))
    return () => {
      isLoading = false
    }
    // eslint-disable-next-line
  }, [hasUpdated])

  useEffect(() => {
    localStorage.setItem('competenceProfileSelectedSurveyID', selectedSurveyID)
  }, [selectedSurveyID])
  useEffect(() => {
    localStorage.setItem(
      'competenceProfileSelectedEducation',
      JSON.stringify(selectedEducation)
    )
  }, [selectedEducation])
  useEffect(() => {
    localStorage.setItem(
      'competenceProfileSelectedSurvey',
      JSON.stringify(selectedSurvey)
    )
  }, [selectedSurvey])
  useEffect(() => {
    localStorage.setItem(
      'competenceProfileSelectedUncompletedSurveyID',
      selectedUncompletedSurveyID
    )
  }, [selectedUncompletedSurveyID])

  useEffect(() => {
    let isLoading1 = true
    let isLoading2 = true

    const fetchQualificationsByOrganisation = async () => {
      let response = await getAllQualificationsByOrganisation({
        organisationNumber: organisation.organisationNumber,
      })
      let fetchedQualificationsByOrganisation = await response
      return fetchedQualificationsByOrganisation
    }

    const fetchQualificationsByRole = async function () {
      let response = await getSelectedRoleQualifications({
        organisationNumber: organisation.organisationNumber,
        roleId: employee.professionalRoleId,
        // roleId: organisation.professionalRole[0].id,
      })
      let fetchedQualificationsByRole = await response
      return fetchedQualificationsByRole
    }

    fetchQualificationsByRole().then((qualification) => {
      if (isLoading1) {
        setQualificationsByRole(qualification)
      }
    })

    fetchQualificationsByOrganisation().then((qualification) => {
      if (isLoading2) {
        setQualificationsByOrganisation(qualification)
      }
    })

    return () => {
      isLoading1 = false
      isLoading2 = false
    }

    // eslint-disable-next-line
  }, [])

  const initialContext = {
    employee,
    organisation,
    individual,
    qualificationsByRole,
    qualificationsByOrganisation,
    selectedSurveyID,
    setSelectedSurveyID,
    selectedEducation,
    setSelectedEducation,
    selectedSurvey,
    setSelectedSurvey,
    addBtnClicked,
    setAddBtnClicked,
    editBtnClicked,
    setEditBtnClicked,
    ongoing,
    setOngoing,
    completed,
    setCompleted,
    selectedUncompletedSurveyID,
    setSelectedUncompletedSurveyID,
    hasUpdated,
    setHasUpdated,
    showRemoveSurveyModal,
    setShowRemoveSurveyModal,
  }

  return (
    <CompetenceProfileContext.Provider value={initialContext}>
      <Switch>
        <Route exact path={'/:name'}>
          <CompetenceProfileWrapper>
            <PageTitle title={t('pageHome.myCompetenceProfileTitle')} />
            <EmployeeInfo />
            {employee.roleId && <UnCompletedSurvey />}
            <OngoingSurveys />
            <OngoingEducations />
            <CompletedSurveys />
            <CompletedEducations />
            {showRemoveSurveyModal && <RemoveSurvey />}
          </CompetenceProfileWrapper>
        </Route>
        <Route path={`/:name/:qualOrEdu`}>
          <QualEduHub />
        </Route>
      </Switch>
    </CompetenceProfileContext.Provider>
  )
}

export default CompetenceProfile
