import styled from 'styled-components'

export const QualModalStyle = styled.div`
  /* Inside StyledLabel */
  
  label {
    margin-bottom: 10px;
  }
  
  textarea {
    height: 6em;
    resize: none;
    padding: 10px;
    position: relative;
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 0.63em;
    vertical-align: middle;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 0.63em;
    vertical-align: middle;
  }
  input:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 0.63em;
    vertical-align: middle;
  }
  input:-moz-placeholder {
    /* Firefox 18- */
    font-size: 0.63em;
    vertical-align: middle;
  }

  textarea::-webkit-input-placeholder {
    font-size: 0.63em;
    vertical-align: middle;
    padding: 5px 0;
  }

  textarea:-moz-placeholder {
    /* Firefox 18- */
    font-size: 0.63em;
    vertical-align: middle;
    padding: 5px 0;
  }

  textarea::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 0.63em;
    vertical-align: middle;
    padding: 5px 0;
  }

  textarea:-ms-input-placeholder {
    font-size: 0.63em;
    vertical-align: middle;
    padding: 5px 0;
  }

  textarea::placeholder {
    font-size: 0.63em;
    vertical-align: middle;
    padding: 5px 0;
  }
  /* custom Style */
  .flex {
    display: flex;
  }
  .flex-column {
    flex-direction: column;
  }

  .custom-style-1 {
    justify-content: space-between;
    label {
      width: 260px;
    }
  }
  .custom-style-2 {
    gap: 50px;
    
    .language-select {

      .react-select {
        width: 150px;
      }
    }
  }
`
