import React from 'react'
import { t } from 'i18next'

import { Grid } from '@mui/material'
import {
  SubQualificationWrapper,
  tableBody,
} from '../../../../pages/catalog/subPages/style'
import BreadCrumbs from '../../../../utils/BreadCrumbs'
import PageTitleSpecial from '../../../../components/pageTitle/PageTitleSpecial'
import { Prompt, useHistory } from 'react-router-dom'
import { Prev } from '../../../../components/button/Button'
import { useTranslation } from 'react-i18next'

const QualificationAreas = ({
  selectedSubQualification,
  allowNavigation,
  surveyStarted,
}) => {
  const { t } = useTranslation()
  const { qualificationArea } = selectedSubQualification

  const history = useHistory()

  return (
    <SubQualificationWrapper className='sub-page-wrapper'>
      <Grid container item xs={12}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <BreadCrumbs custom search='?type=qualification' />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} py={1}>
            <p className='pre-title'>{t('competenceProfile.qualificationRequirementTitle')}</p>
          </Grid>
          <Grid item xs={12} py={1}>
            <PageTitleSpecial
              title={selectedSubQualification.name}
              info={selectedSubQualification.description}
            />
          </Grid>
          {/** -------------------------------- Area section -------------------------------- */}
          <Grid container item xs={12}>
            {/* start table */}
            <Grid container item xs={12}>
              {/* ----- start table-head ----- */}
              <Grid container item xs={12} className='table-header'>
                <Grid item xs={3} sx={{ borderLeft: 0 }}>
                  <p className='qualification-category'>{t('competenceProfile.qualificationArea')}</p>
                </Grid>
                <Grid item xs={3} sx={{ borderLeft: 0 }}>
                  <p className='qualification-category'>{t('competenceProfile.knowledge')}</p>
                </Grid>
                <Grid item xs={3} sx={{ borderLeft: 0 }}>
                  <p className='qualification-category'>{t('competenceProfile.skill')}</p>
                </Grid>
                <Grid item xs={3} sx={{ borderLeft: 0 }}>
                  <p className='qualification-category'>
                  {t('competenceProfile.responsibilityAndIndependence')}
                  </p>
                </Grid>
              </Grid>
              {/* ----- End table-head ----- */}

              {/* ----- start table-body ----- */}
              {qualificationArea?.map(
                ({ id: areaId, name: areaName, qualificationRequirement }) => (
                  <Grid
                    key={areaId}
                    container
                    item
                    xs={12}
                    className='table-body'
                  >
                    <Grid item xs={3} sx={{ ...tableBody, borderTop: 0 }}>
                      <p className='area-name'>{areaName}</p>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{ ...tableBody, borderTop: 0, borderLeft: 0 }}
                    >
                      <div className='requirements'>
                        {qualificationRequirement
                          ?.filter(
                            (requirement) =>
                              requirement.requirementType === 'knowledge'
                          )
                          .map(({ id: requirementId, description }) => (
                            <p
                              key={requirementId}
                              className='requirement-paragraphs'
                            >
                              {description}
                            </p>
                          ))}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{ ...tableBody, borderTop: 0, borderLeft: 0 }}
                    >
                      <div className='requirements'>
                        {qualificationRequirement
                          ?.filter(
                            (requirement) =>
                              requirement.requirementType === 'skill'
                          )
                          .map(({ id: requirementId, description }) => (
                            <p
                              key={requirementId}
                              className='requirement-paragraphs'
                            >
                              {description}
                            </p>
                          ))}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{ ...tableBody, borderTop: 0, borderLeft: 0 }}
                    >
                      <div className='requirements'>
                        {qualificationRequirement
                          ?.filter(
                            (requirement) =>
                              requirement.requirementType ===
                              'responsibilityAndIndependence'
                          )
                          .map(({ id: requirementId, description }) => (
                            <p
                              key={requirementId}
                              className='requirement-paragraphs'
                            >
                              {description}
                            </p>
                          ))}
                      </div>
                    </Grid>
                  </Grid>
                )
              )}
              {/* ----- End table-body ----- */}
            </Grid>
            {/* ----- End table ----- */}
          </Grid>
        </Grid>
        <Prev className='back-btn' onClick={() => history.goBack()}>
          {t('pageSurvey.back')}
        </Prev>
      </Grid>
      <Prompt
        when={surveyStarted}
        message={(location) =>
          allowNavigation(location.pathname)
            ? true
            : t('pageSurvey.ongoingSurvey')
        }
      />
    </SubQualificationWrapper>
  )
}

export default QualificationAreas
