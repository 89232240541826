import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";

import { ArrowButton, Button } from "../../../components/button/Button";
import Modal from "../../../components/modal/Modal";
import StyledLabel from "../../../components/styledLabel/StyledLabel";
// import EmployeeInfo from './EmployeeInfo'
import StyledSelect from "../../../components/styledSelect/StyledSelect";
import { selectedDatePlus60Days } from "../../../utils/helper";
import { EmployeeSubPageContext } from "../EmployeeSubPage";
import { addQualificationSurvey } from "../services/crud";

const StyledModal = styled.div`
  .error {
    color: red;
    font-size: small;
  }
  .error::before {
    content: "* ";
  }
  .modal {
    width: 886px;
  }
  .modal-content {
    padding: 20px;
    .person {
      display: flex;
      border-bottom: 1px solid #cfcfd5;
      margin-bottom: 15px;
      span:not(:last-child) {
        padding-right: 10px;
      }
      span {
        font-size: 22px;
      }
      span:not(:first-child) {
        padding-left: 5px;
      }
    }
    .info-text {
      padding-bottom: 15px;
    }
    .to-catalog-btn {
      padding-top: 20px;
    }
    .survey-wrapper {
      display: flex;
      justify-content: space-between;
      input {
        width: 100%;
        text-overflow: ellipsis;
      }
      .price {
        padding-right: 30px;
        margin-top: 35px;
      }
    }
    .survey-date-wrapper {
      width: 80%;
      padding-top: 15px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .start-date {
        width: 170px;
      }
      .end-date {
        width: 150px;
      }
      .survey-date-info-text {
        width: 80%;
        padding: 15px 0;
      }
    }
    .add-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
const AddSurvey = () => {
  const { t } = useTranslation();
  // ---------- Contexts ----------
  const {
    row,
    employee,
    employee: { professionalRoleName },
    individual: { id: individualId },
    setAddSurveyModal,
    loadOldStates,
    qualificationsByRole,
    qualificationsByOrganisation,
    ongoing,
    setOngoing,
    addBtnClicked,
    setShowSuggestionModal,
  } = useContext(EmployeeSubPageContext);

  const { employeeNumber, firstName, lastName } = employee;

  // ---------- States ----------
  const [error, setError] = useState({ fromDate: false, qualification: false });

  const { catalogQualification: locationCatalogQualification } =
    useLocation().state || {};
  const [catalogQualification, setCatalogQualification] = useState(
    addBtnClicked ? null : locationCatalogQualification
  );

  const [
    selectedQualificationByProfessionalRoleToAdd,
    setSelectedQualificationByProfessionalRoleToAdd,
  ] = useState(
    loadOldStates && !catalogQualification
      ? JSON.parse(
          localStorage.getItem("employeeSubAddSurveyQualificationByRole")
        )
      : {
          label: t("pageEmployees.addSurvey.selectPlaceholder"),
          value: "",
        }
  );
  // eslint-disable-next-line
  const [price, setPrice] = useState(0.0);

  const [
    selectedQualificationByOrganisationToAdd,
    setSelectedQualificationByOrganisationToAdd,
  ] = useState(
    loadOldStates && !catalogQualification
      ? JSON.parse(
          localStorage.getItem("employeeSubAddSurveyQualificationByOrg")
        )
      : {
          value: "",
          label: t("pageEmployees.addSurvey.selectPlaceholder"),
        }
  );

  const [selectedSurveyFromDate, setSelectedSurveyFromDate] = useState(
    loadOldStates
      ? localStorage.getItem("employeeSubSelectedSurveyFromDate")
      : new Date().toISOString().split("T")[0]
  );

  const [selectedSurveyToDate, setSelectedSurveyToDate] = useState(
    selectedDatePlus60Days(selectedSurveyFromDate)
  );

  // ---------- useEffects ----------
  // Qualification can only be selected from one source either by "role" or by "organisation" or "Catalog"
  useEffect(() => {
    localStorage.setItem(
      "employeeSubAddSurveyQualificationByRole",
      JSON.stringify(selectedQualificationByProfessionalRoleToAdd)
    );
    if (selectedQualificationByProfessionalRoleToAdd?.value) {
      setSelectedQualificationByOrganisationToAdd({
        label: t("pageEmployees.addSurvey.selectPlaceholder"),
        value: "",
      });
      setCatalogQualification(null);
    }
  }, [selectedQualificationByProfessionalRoleToAdd]);

  useEffect(() => {
    localStorage.setItem(
      "employeeSubAddSurveyQualificationByOrg",
      JSON.stringify(selectedQualificationByOrganisationToAdd)
    );
    if (selectedQualificationByOrganisationToAdd?.value) {
      setSelectedQualificationByProfessionalRoleToAdd({
        label: t("pageEmployees.addSurvey.selectPlaceholder"),
        value: "",
      });
      setCatalogQualification(null);
    }
  }, [selectedQualificationByOrganisationToAdd]);

  useEffect(() => {
    localStorage.setItem(
      "employeeSubSelectedSurveyFromDate",
      selectedSurveyFromDate
    );
  }, [selectedSurveyFromDate]);

  const history = useHistory();
  return (
    <StyledModal>
      <Modal
        setState={setAddSurveyModal}
        title={t("pageEmployees.addSurvey.title")}
      >
        <Grid container columns={24}>
          {/** ---------- Person ---------- */}
          <Grid item xs={24} className="person">
            <span>{employeeNumber}</span>
            <span>
              {firstName} {lastName}
            </span>
            <span>{professionalRoleName}</span>
          </Grid>

          {/** ---------- Info-text ---------- */}
          <Grid item xs={24}>
            <Grid item xs={12} className="info-text">
              <p>{t("pageEmployees.addSurvey.infoText")}</p>
            </Grid>
          </Grid>

          {/** ---------- Left Columns ---------- */}
          <Grid item xs={12}>
            {/** ---------- Role Qualifications ---------- */}
            <Grid item xs={12} mb={2}>
              {error.qualification && (
                <span className="error">
                  {t("pageEmployees.addSurvey.errorQualification")}
                </span>
              )}

              <StyledLabel>
                <p>{t("pageEmployees.addSurvey.labelRoleQualifications", {professionalRoleName})}</p>
                <StyledSelect
                  dark
                  options={qualificationsByRole
                    ?.filter((roleQual) =>
                      ongoing.every(
                        (ongoingQual) =>
                          ongoingQual.qualificationId !== roleQual.id
                      )
                    )
                    .map((qualification) => ({
                      label: qualification.qualificationName,
                      value: qualification,
                    }))}
                  value={selectedQualificationByProfessionalRoleToAdd}
                  onChange={(e) => {
                    setSelectedQualificationByProfessionalRoleToAdd({
                      label: e.value.qualificationName,
                      value: { ...e?.value, price, selectedSurveyFromDate },
                    });
                  }}
                />
              </StyledLabel>
            </Grid>

            {/** ---------- Other Internal Qualifications ---------- */}
            <Grid item xs={12} mb={2}>
              <StyledLabel>
                <p>{t("pageEmployees.addSurvey.labelOtherQualifications")}</p>
                <StyledSelect
                  dark
                  options={qualificationsByOrganisation
                    ?.filter((orgQual) =>
                      ongoing.every(
                        (ongoingQual) =>
                          ongoingQual.qualificationId !== orgQual.id
                      )
                    )
                    .map((qualification) => ({
                      label: qualification.qualificationName,
                      value: qualification,
                    }))}
                  value={selectedQualificationByOrganisationToAdd}
                  onChange={(e) => {
                    setSelectedQualificationByOrganisationToAdd({
                      label: e?.value.qualificationName,
                      value: { ...e?.value, price, selectedSurveyFromDate },
                    });
                  }}
                />
              </StyledLabel>
            </Grid>

            {/** ---------- Catalog Button ---------- */}
            <Grid item xs={1} mb={1}>
              <ArrowButton
                sm
                className="pointer"
                style={{ width: "180px" }}
                onClick={() => {
                  history.push({
                    pathname: "/catalog",
                    state: {
                      includeResults: ["qualifications"],
                      prevPath: `/employees/${employee.firstName} ${employee.lastName}`,
                      saveStates: true,
                      addQualificationSurvey: true,
                      ongoing: JSON.parse(
                        localStorage.getItem("employeesOngoing")
                      ),
                    },
                  });
                }}
              >
                {t("pageEmployees.addSurvey.toCatalog")}
              </ArrowButton>
            </Grid>
          </Grid>

          {/** ---------- Right Columns ---------- */}
          <Grid item xs={12} px={0}>
            <Grid item xs={24}>
              <div className="survey-wrapper">
                <StyledLabel>
                  <p>
                    {t("pageEmployees.addSurvey.labelSelectedQualification")}
                  </p>
                  <input
                    value={
                      catalogQualification?.qualificationName ||
                      (selectedQualificationByProfessionalRoleToAdd?.value &&
                        selectedQualificationByProfessionalRoleToAdd.label) ||
                      (selectedQualificationByOrganisationToAdd?.value &&
                        selectedQualificationByOrganisationToAdd.label)
                    }
                    type="text"
                    readOnly
                  />
                </StyledLabel>
                <span className="price">{price.toFixed(2)} kr</span>
              </div>

              <div className="survey-date-wrapper">
                <div className="start-date">
                  <StyledLabel>
                    <p>{t("pageEmployees.addSurvey.labelStartDate")}</p>
                    <input
                      value={selectedSurveyFromDate}
                      onChange={(e) => {
                        const regex = new RegExp(
                          /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
                        );
                        if (regex.test(e.target.value)) {
                          setSelectedSurveyFromDate(e.target.value);
                          setSelectedSurveyToDate(
                            (prev) =>
                              (prev = selectedDatePlus60Days(e.target.value))
                          );
                        }
                      }}
                      min={new Date().toISOString().split("T")[0]}
                      id="date"
                      type="date"
                    />
                  </StyledLabel>
                </div>

                <div className="end-date">
                  <StyledLabel>
                    <p>{t("pageEmployees.addSurvey.labelEndDate")}</p>
                    <input
                      value={selectedSurveyToDate}
                      readOnly
                      type="text"
                      style={{ maxWidth: "90%" }}
                    />
                  </StyledLabel>
                </div>

                <div className="survey-date-info-text">
                  <p>{t("pageEmployees.addSurvey.dateInfoText")}</p>
                </div>
              </div>
            </Grid>
          </Grid>

          {/** ---------- Add Button ---------- */}
          <Grid item xs={24} className="add-btn">
            <Button
              onClick={(e) => {
                if (
                  !catalogQualification?.id &&
                  !selectedQualificationByProfessionalRoleToAdd?.value &&
                  !selectedQualificationByOrganisationToAdd?.value
                ) {
                  setError((prev) => ({ ...prev, qualification: true }));
                  return;
                }
                addQualificationSurvey({
                  individualId: row,
                  qualificationId:
                    catalogQualification?.id ||
                    (selectedQualificationByProfessionalRoleToAdd?.value &&
                      selectedQualificationByProfessionalRoleToAdd.value.id) ||
                    (selectedQualificationByOrganisationToAdd?.value &&
                      selectedQualificationByOrganisationToAdd.value.id),
                  price,
                  startDate: selectedSurveyFromDate,
                  setAddSurveyModal,
                })
                  .then((data) => {
                    setOngoing((prev) => [
                      ...prev,
                      {
                        id: data.id,
                        qualificationName: data.qualificationName,
                        individualId,
                        qualificationId:
                          catalogQualification?.id ||
                          (selectedQualificationByProfessionalRoleToAdd?.value &&
                            selectedQualificationByProfessionalRoleToAdd.value
                              .id) ||
                          (selectedQualificationByOrganisationToAdd?.value &&
                            selectedQualificationByOrganisationToAdd.value.id),
                        price,
                        startDate: selectedSurveyFromDate,
                        endDate: selectedSurveyToDate,
                        setAddSurveyModal,
                      },
                    ]);
                    localStorage.removeItem(
                      "employeeSubAddSurveyQualificationByOrg"
                    );
                    setShowSuggestionModal(true);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              {t("buttons.addButton")}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </StyledModal>
  );
};

export default AddSurvey;
