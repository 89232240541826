import { useContext, useState } from 'react'
import Flag from 'react-world-flags'
import { Secondary } from '../../../components/button/Button'
import StyledLabel, {
  StyledInput,
} from '../../../components/styledLabel/StyledLabel'
import StyledSelect from '../../../components/styledSelect/StyledSelect'
import { CatalogContext } from '../Catalog'
import { getEducations, getQualifications } from '../utils/functions'
import { useTranslation } from 'react-i18next'
const Search = ({ globalCountry, globalLanguage }) => {
  const { t } = useTranslation()
  const countryOptions = [
    {
      label: 'Sweden',
      value: 'Sweden',
      code: 'SE',
      icon: (
        <Flag
          code='SE'
          height={30}
          width={30}
          style={{ marginRight: '10px' }}
        />
      ),
    },
    {
      label: 'Great Britain',
      value: 'Great Britain',
      code: 'GB',
      icon: (
        <Flag
          code='GB'
          height={30}
          width={30}
          style={{ marginRight: '10px' }}
        />
      ),
    },
  ]
  const languageOptions = [
    { label: 'Swedish', value: 'SE' },
    { label: 'English', value: 'GB' },
  ]
  const [country, setCountry] = useState(
    globalCountry === 'SE' ? countryOptions[0] : countryOptions[1]
  )
  const [language, setLanguage] = useState(
    globalLanguage === 'SE' ? languageOptions[0] : languageOptions[1]
  )
  const [text, setText] = useState('')

  const { setResults, setResultsLoading } = useContext(CatalogContext)

  return (
    <form
      className='search'
      onSubmit={async (e) => {
        e.preventDefault()
        setResults([])
        setResultsLoading(true)


        try {
          setResults([
            ...await getQualifications(country.label, language.label, text),
            ...await getEducations(country.label, language.label, text)
          ])
          setResultsLoading(false)
        } catch (error) {
          setResultsLoading(false)
        }



        /*
        setResults([
          ...await getQualifications(country.label, language.label, text),
          ...await getEducations(country.label, language.label, text)
        ])
        setResultsLoading(false)

         */
      }}
    >
      <div className='country-language'>
        <StyledLabel>
          <p>{t('common.country')}</p>
          <StyledSelect
            border
            options={countryOptions}
            value={{
              label: country.label,
              value: country.value,
              icon: (
                <Flag
                  code={country.value}
                  height={30}
                  width={30}
                  style={{ marginRight: '10px' }}
                />
              ),
            }}
            onChange={(e) => setCountry(e)}
          />
        </StyledLabel>
        <StyledLabel>
          <p>{t('common.language')}</p>
          <StyledSelect
            border
            options={languageOptions}
            value={{ label: language.label, value: language.value }}
            onChange={(e) => setLanguage(e)}
          />
        </StyledLabel>
      </div>
      <StyledInput
        placeholder={t('catalogue.searchPlaceholder')}
        value={text}
        onInput={(e) => setText(e.target.value)}
      />
      <Secondary>{t('buttons.searchButton')}</Secondary>
    </form>
  )
}

export default Search
