/** -------------------- Education Get Add Edit Delete -------------------- */
import { simpleUser } from './../../../utils/Amplify';

/** --- Get all --- */
export const getAllEducations = async ({ organisationId }) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationId}/education/get/all`,
    {
      method:'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      }
    }
  )

  if (!response.ok) {
    return
  }
  const allEducations = await response.json()

  return allEducations
}

/** --- Add --- */
export const addEducation = async (
  e,
  {
    addFormData,
    setModalAddIsVisible,
    qualificationId,
    organisationId,
    setEducationsList,
  }
) => {
  e.preventDefault()
  if (!qualificationId) return
  if (qualificationId) {
    const options = {
      method: 'POST',
      body: JSON.stringify({ ...addFormData }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationId}/qualification/${qualificationId}/education/create`,
        options
      )
      const education = await response.json()
      if (!response.ok) {
        return
      }
      setEducationsList((prev) => [...prev, education])
    } catch (error) {
      console.log(error)
    }
  }

  setModalAddIsVisible(false)
}

/** --- Edit --- */
export const editEducation = async (
  e,
  {
    setModalEditIsVisible,
    selectedEducation: { id: educationId },
    addFormData,
    setEducationsList,
    qualificationId,
    educationsList,
  }
) => {
  e.preventDefault()
  const options = {
    method: 'PATCH',
    body: JSON.stringify({
      ...addFormData,
      qualification: { id: qualificationId },
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (await simpleUser()).token,
    },
  }
  const newEducationsList = [...educationsList]
  const educationIndex = educationsList.findIndex(
    (education) => education.id === educationId
  )

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/education/${educationId}/update`,
      options
    )
    const education = await response.json()

    if (!response.ok) console.log(response)
    newEducationsList[educationIndex] = education
    console.log(newEducationsList)
    setEducationsList(newEducationsList)
  } catch (error) {
    console.log(error)
  }
  setModalEditIsVisible(false)
}

/** --- Delete --- */
export const deleteEducation = async ({
  setModalDeleteIsVisible,
  selectedEducation: { id: educationId },
  educationsList,
  setEducationsList,
}) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (await simpleUser()).token,
    },
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/organisation/education/${educationId}/delete`,
    options
  )
  if (response.ok) {
    setModalDeleteIsVisible(false)
    setEducationsList((educations) =>
      educations
        .filter((education) => education.id !== educationId)
        .map((education) => education)
    )
    return
  }
  if (!response.ok) {
    console.log(response.message)
    return
  }
}

/** -------------------- Education-Share Bind & unBind with country -------------------- */

/** --- Bind --- */
export const publishEducation = async (
  e,
  {
    selectedEducation,
    modalConfirmTempCountry: tempCountry,
    educationsList,
    setSelectedEducation,
    setEducationsList,
    setModalConfirmIsVisible,
    setModalShareIsVisible,
  }
) => {
  let newEducationsList = educationsList
  const selectedEducationIndex = educationsList.findIndex(
    (education) => education.id === selectedEducation.id
  )

  const options = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (await simpleUser()).token,
    },
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/education/${selectedEducation.id}/add/country/${tempCountry.label}`,
      options
    )
    const data = await response.json()
    if (response.status === 200) {
      setSelectedEducation(data)
      newEducationsList[selectedEducationIndex] = data
      setEducationsList(newEducationsList)
      setModalConfirmIsVisible(false)
      setModalShareIsVisible(true)
    }
  } catch (error) {
    console.log(error)
  }
}

/** --- Unbind --- */
export const unPublishEducation = async (
  e,
  {
    organisationId,
    setModalDeleteIsVisible,
    educationsList,
    setEducationsList,
    selectedEducation,
    setSelectedEducation,
    selectedCountryToRemove,
  }
) => {
  let newEducationsList = educationsList

  const selectedEducationIndex = educationsList.findIndex(
    (education) => education.id === selectedEducation.id
  )

  try {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/education/delete/country/${selectedCountryToRemove.id}`,
      options
    )

    const newSelectedEducation = selectedEducation.educationCountries
      .filter((country) => country.id !== selectedCountryToRemove.id)
      .map((country) => country)

    setSelectedEducation({
      ...selectedEducation,
      educationCountries: newSelectedEducation,
    })

    newEducationsList[selectedEducationIndex].qualificationCountries =
      newSelectedEducation ? newSelectedEducation : []

    // Close the modals

    setEducationsList(newEducationsList)

    setModalDeleteIsVisible(false)
  } catch (error) {
    console.log(error)
  }
}
