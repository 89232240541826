import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useContext, useEffect, useRef } from "react"
import { Link } from "react-router-dom"

import PageTitleSpecial from "../../../../../components/pageTitle/PageTitleSpecial"
import { getSubQualifications } from "../../../../../private/qualifications/functions"
import { CatalogContext } from "../../../Catalog"
import { CatalogEducationContext } from "../CatalogEducation"
import { simpleUser } from './../../../../../utils/Amplify';
import { useTranslation } from 'react-i18next';

const CatalogEducationModules = ({ setModalModule }) => {
    const mounted = useRef(true)

    const {
        selectedEducation,
        subQualifications, setSubQualifications,
        modules, setModules
    } = useContext(CatalogEducationContext)

    const { setSelectedSubQualification } = useContext(CatalogContext)
    const { t } = useTranslation();

    const selectedQualification = selectedEducation.qualification

    useEffect(() => {
        const getModules = async () => {
            const modules = await fetch(`${process.env.REACT_APP_BACKEND_URL}/organisation/education/get/module/${selectedEducation.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (await simpleUser()).token,
                  },
            }).then(res => res.json())

            if (mounted.current) {
                setModules(modules)
            }
        }

        getSubQualifications(selectedQualification.id).then((res) => {
            if (mounted.current) {
                setSubQualifications(res)
            }
        })

        getModules()

        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="modules-table">
            <p className="subtitle">{t('catalogue.morePage.qualification')}</p>
            <PageTitleSpecial
                className='small'
                title={selectedQualification.qualificationName}
                description={
                    <p className="bold">{selectedQualification.qualificationDescription}</p>
                }
            />
            <TableContainer>
                <Table size="small">
                    <colgroup>
                        <col style={{ width: '60%' }} />
                        <col style={{ width: '40%' }} />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ borderRight: '1px solid #CFCFD5' }}>
                                <p className="subtitle">{t('catalogue.morePage.subQualification')}</p>
                            </TableCell>
                            <TableCell>
                                <p className="subtitle">{t('catalogue.morePage.educationModule')}</p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subQualifications.map((subQualification, i) => {
                            const module = modules.find(module => module.subQualification.id === subQualification.id)

                            return (
                                <TableRow key={i}>
                                    <TableCell sx={{ borderRight: '1px solid #CFCFD5' }}>
                                        <Link
                                            to={{
                                                pathname: `/catalog/${selectedEducation.educationName}/${subQualification.name}`,
                                                state: {
                                                    education: true
                                                }
                                            }}
                                            onClick={() => setSelectedSubQualification(subQualification)}
                                        >{subQualification.name}</Link>
                                    </TableCell>
                                    <TableCell>
                                        {module &&
                                            <p className="link" style={{ display: 'inline-block' }}
                                                onClick={() => setModalModule(module)}
                                            >
                                                {module.moduleName}
                                            </p>
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default CatalogEducationModules
