import React, { useContext } from 'react'
import { t } from 'i18next'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import pencil from '../../../../assets/img/Edit_pencil.svg'
import { AddButton } from '../../../../components/button/Button'
import { RequirementQuestionContext } from '../RequirementQuestion'
import { QuestionWrapper } from '../Style'

const RequirementQuestionTable = () => {
  const {
    setModalAddIsVisible,
    setModalEditIsVisible,
    questionList,
    setSelectedQuestionId,
  } = useContext(RequirementQuestionContext)

  return (
    <QuestionWrapper>
      <TableContainer style={{ overflow: 'hidden' }}>
        <Table aria-label='simple table'>
          <colgroup>
            <col style={{ width: '100%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingBottom: 0 }}>
                <p className='bold'>
                  {t('pageRequirementQuestion.tableQuestions')}
                </p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionList.map((question, i) => (
              <TableRow key={question.id}>
                <TableCell sx={{ borderBottom: 'none' }}>
                  <span className='bold'>{`${i + 1}.`}</span>{' '}
                  {question.questionName}
                </TableCell>
                <TableCell align='center'>
                  <img
                    className='pointer'
                    id={question.id}
                    src={pencil}
                    alt='Edit pencil'
                    onClick={() => {
                      setSelectedQuestionId(question.id)
                      setModalEditIsVisible(true)
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: 'none',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <AddButton
                  className='add-button'
                  onClick={() => setModalAddIsVisible(true)}
                />
                <p
                  className='bold no-of-questions'
                  style={{ marginRight: questionList.length ? '-20px' : '0' }}
                >
                  {t('pageRequirementQuestion.noOfQuestions')}{' '}
                  {questionList.length}
                </p>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </QuestionWrapper>
  )
}

export default RequirementQuestionTable
