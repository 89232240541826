import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import pencil from '../../../../assets/img/Edit_pencil.svg'
import shareActive from '../../../../assets/img/share-active.svg'
import share from '../../../../assets/img/share.svg'
import { AddButton } from '../../../../components/button/Button'
import { Context } from '../../../../utils/Context'
import { QualificationContext } from '../Qualification'
import { useTranslation } from 'react-i18next'

const QualificationTable = () => {
  const { t } = useTranslation();
  const { employee:{qualificationAccess} } = useContext(Context)
  const {
    qualificationsList,
    setModalEditIsVisible,
    setModalAddIsVisible,
    setModalShareIsVisible,
    setQualificationId,
    setSelectedQualification,
  } = useContext(QualificationContext)

  return (
    <TableContainer style={{ overflow: 'hidden' }}>
      <Table aria-label='simple table'>
        <colgroup>
          <col style={{ width: '25%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '5%' }} />
          <col style={{ width: '5%' }} />
          <col style={{ width: '5%' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell sx={{ paddingBottom: 0 }}>{t('tableQualifications.name')}</TableCell>
            <TableCell sx={{ paddingBottom: 0 }} align='left'>
            {t('tableQualifications.language')}
            </TableCell>
            <TableCell sx={{ paddingBottom: 0 }} align='left'>
            {t('tableQualifications.industry')}
            </TableCell>
            <TableCell sx={{ paddingBottom: 0 }} align='left'>
            {t('tableQualifications.type')}
            </TableCell>
            <TableCell sx={{ paddingBottom: 0 }} align='left'>
            {t('tableQualifications.authorization')}
            </TableCell>
            <TableCell sx={{ paddingBottom: 0 }} align='right'>
            {t('tableQualifications.eqfLevel')}
            </TableCell>
            <TableCell sx={{ paddingBottom: 0 }} align='right'>
            {t('tableQualifications.ver')}
            </TableCell>
            <TableCell sx={{ paddingBottom: 0 }} align='right'>
            {t('tableQualifications.share')}
            </TableCell>
            <TableCell sx={{ paddingBottom: 0 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {qualificationsList.map((qualification, i) => (
            <TableRow
              key={qualification.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={
                {
                  /*background: ( i % 2 === 0? '#33333315':'')*/
                }
              }
            >
              <TableCell component='th' scope='row' sx={{ maxWidth: '200px' }}>
                <Link
                  onClick={() => {
                    setSelectedQualification({
                      qualificationId: qualification.id,
                      name: qualification.qualificationName,
                      description: qualification.qualificationDescription,
                    })
                  }}
                  to={(location) => ({
                    ...location,
                    pathname: `${location.pathname}/${qualification.qualificationName}`,
                  })}
                >
                  {qualification.qualificationName}
                </Link>
              </TableCell>
              <TableCell align='left'>
                {qualification.qualificationLanguage}
              </TableCell>
              <TableCell align='left'>
                {qualification.qualificationArea}
              </TableCell>
              <TableCell align='left'>
                {qualification.qualificationType}
              </TableCell>
              <TableCell align='left'>
                {qualification.qualificationAccess}
              </TableCell>
              <TableCell align='right'>
                {qualification.qualificationEqf}
              </TableCell>
              <TableCell align='right'>{qualification.version}</TableCell>
              <TableCell align='right'>
                {qualificationAccess === 'edit' && 
                  <img
                    className='pointer'
                    src={qualification.qualificationCountries.length >= 1 ? shareActive : share}
                    alt='Share arrow'
                    onClick={() => {
                      setSelectedQualification(qualification)
                      setModalShareIsVisible(true)
                    }}
                  />
                }
              </TableCell>
              <TableCell align='center'>
                {qualificationAccess === 'edit' &&
                  <img
                    className='pointer'
                    id={qualification.id}
                    src={pencil}
                    alt='Edit pencil'
                    onClick={(e) => {
                      setQualificationId(qualification.id)
                      setModalEditIsVisible(true)
                    }}
                  />
                }
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell sx={{ borderBottom: 'none' }}>
              {qualificationAccess === 'edit' &&
                <AddButton
                  className='add-button'
                  onClick={() => setModalAddIsVisible(true)}
                />
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default QualificationTable
