import React, { Fragment, useContext, useEffect, useState } from 'react'

import { Button, Delete } from '../../../../components/button/Button'
// import { Link } from 'react-router-dom'
import Modal from '../../../../components/modal/Modal'
import StyledLabel from '../../../../components/styledLabel/StyledLabel'
import { handleEditFormChange, handleSubEditFormSubmit } from '../../functions'
import { SubQualificationContext } from '../SubQualification'
import { Wrapper } from './Style'
import { useTranslation } from 'react-i18next'

const EditSubQualification = () => {
  // FIXME Translation needed!
  const { t } = useTranslation();

  const {
    subQualificationsList,
    setSubQualificationsList,
    modalEditIsVisible,
    dispatchModalEditIsVisible,
    setModalDeleteIsVisible,
    subQualificationId,
    setSubQualificationId,
  } = useContext(SubQualificationContext)

  const [currentIndex, setCurrentIndex] = useState(
    subQualificationsList.findIndex(
      (qualification) => qualification.id === subQualificationId
    )
  )

  useEffect(() => {
    setCurrentIndex(
      subQualificationsList.findIndex(
        (qualification) => qualification.id === subQualificationId
      )
    )
    // eslint-disable-next-line
  }, [setSubQualificationId])

  const [editFormData, setEditFormData] = useState({
    name: subQualificationsList[`${currentIndex}`].name,
    description: subQualificationsList[`${currentIndex}`].description,
    published: 0,
    modified: 1,
  })

  return (
    <Fragment>
      <Modal
        title={t('pageSubQualification.modals.addEdit.editSubQual')}
        dispatcher={{ modalEditIsVisible, dispatchModalEditIsVisible }}
      >
        <Wrapper>
          <form
            onSubmit={(e) =>
              handleSubEditFormSubmit(e, {
                editFormData,
                subQualificationsList,
                setSubQualificationsList,
                subQualificationId,
                dispatchModalEditIsVisible,
                modalEditIsVisible,
              })
            }
          >
            <StyledLabel>
              <p>{t('pageSubQualification.modals.addEdit.subQual')} *</p>
              <input
                type='text'
                name='name'
                required='required'
                placeholder={t('pageSubQualification.modals.addEdit.placeholders.giveName')}
                defaultValue={editFormData.name}
                onChange={(e) =>
                  handleEditFormChange(e, editFormData, setEditFormData)
                }
              />
            </StyledLabel>
            <StyledLabel>
              <p>{t('pageSubQualification.modals.addEdit.desc')} *</p>
              <textarea
                name='description'
                className='textarea'
                required='required'
                placeholder={t('pageSubQualification.modals.addEdit.placeholders.giveDesc')}
                defaultValue={editFormData.description}
                onChange={(e) =>
                  handleEditFormChange(e, editFormData, setEditFormData)
                }
              ></textarea>
            </StyledLabel>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '36px',
              }}
            >
              <Delete
                onClick={(e) => {
                  e.preventDefault()
                  setModalDeleteIsVisible(true)
                }}
              >
                {t('buttons.deleteButton')}
              </Delete>
              <Button type='submit' style={{ marginLeft: '10px' }}>
              {t('buttons.saveButton')}
              </Button>
            </div>
          </form>
        </Wrapper>
      </Modal>
    </Fragment>
  )
}

export default EditSubQualification
