import _ from "lodash";
import { createContext, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useRouteMatch } from "react-router";
import { useParams } from "react-router-dom";

import { Button, Secondary } from "../../components/button/Button";
import FilterOption from "../../components/filterOption/FilterOption";
import Modal from "../../components/modal/Modal";
import StyledLabel from "../../components/styledLabel/StyledLabel";
import { Context } from "../../utils/Context";
import { getQualifications } from "../qualifications/functions";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import EmployeeSubPage from "./EmployeeSubPage";
import { getProfessionalRoles, getOrganisationById } from "./requests";
import { Wrapper } from "./style";
import DenseTable from "./Table";

export const EmployeesContext = createContext();

const Employees = () => {
  // ---------- Destructuring ----------
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const {
    organisation,
    individual,
    permissions: { rightsAccess, employeeAccess },
  } = useContext(Context);

  let { organisationId } = useParams();
  let hasOrganisationId = true;

  if (!organisationId) {
    hasOrganisationId = false;
    organisationId = organisation.id;
  }

  // ---------- States ----------
  const [localOrganisation, setLocalOrganisation] = useState(null);
  const [baseRoles, setBaseRoles] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [mode, setMode] = useState("org");
  const [searchFilter, setSearchFilter] = useState({
    employeeNumber: "",
    firstName: "",
    lastName: "",
    levelOne: "",
    levelTwo: "",
    levelThree: "",
    levelFour: "",
  });
  const [rows, setRows] = useState([]);
  const [row, setRow] = useState(
    JSON.parse(localStorage.getItem("employeesSelectedRow"))
  );
  const [ongoing, setOngoing] = useState(
    JSON.parse(localStorage.getItem("employeesOngoing"))
  );
  const [completed, setCompleted] = useState(
    JSON.parse(localStorage.getItem("employeesCompleted"))
  );
  const [error, setError] = useState(false);

  const handleAddEmployeeClick = () => {
    const isEmployeeCountWithinLimit =
      rows.length < localOrganisation?.subscription;

    if (!isEmployeeCountWithinLimit) {
      setError(t("pageEmployees.addEmployee.errors.subscriptionLimitReached"));
      return;
    }

    setShowAddEmployee(true);
  };

  // const [filterEducations, setFilterEducations] = useState({
  //   include: true,
  //   items: [
  //     { label: 'JavaScript', value: 'JavaScript' },
  //     { label: 'Java', value: 'Java' },
  //     { label: 'Skrubbning', value: 'Skrubbning' },
  //   ],
  //   selectedItems: [],
  // })
  const [filterPermissions, setFilterPermissions] = useState({
    include: true,
    items: [
      {
        label: t("pageHome.employeesTitle"),
        options: [
          {
            label: t("pageEmployees.addEmployee.permissionsNone"),
            value: "employees-none",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsRead"),
            value: "employees-read",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsEdit"),
            value: "employees-edit",
          },
        ],
      },
      {
        label: t("extra.permissions"),
        options: [
          {
            label: t("pageEmployees.addEmployee.permissionsNone"),
            value: "permissions-none",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsRead"),
            value: "permissions-read",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsEdit"),
            value: "permissions-edit",
          },
        ],
      },
      {
        label: t("pageHome.initiationsTitle"),
        options: [
          {
            label: t("pageEmployees.addEmployee.permissionsNone"),
            value: "initiations-none",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsRead"),
            value: "initiations-read",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsEdit"),
            value: "initiations-edit",
          },
        ],
      },
      {
        label: t("pageHome.competencePlanningTitle"),
        options: [
          {
            label: t("pageEmployees.addEmployee.permissionsNone"),
            value: "competencePlanning-none",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsRead"),
            value: "competencePlanning-read",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsEdit"),
            value: "competencePlanning-edit",
          },
        ],
      },
      {
        label: t("pageHome.qualificationsTitle"),
        options: [
          {
            label: t("pageEmployees.addEmployee.permissionsNone"),
            value: "qualifications-none",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsRead"),
            value: "qualifications-read",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsEdit"),
            value: "qualifications-edit",
          },
        ],
      },
      // {
      //   label: t('pageHome.educationsTitle'),
      //   options: [
      //     {
      //       label: t('pageEmployees.addEmployee.permissionsNone'),
      //       value: 'educations-none',
      //     },
      //     {
      //       label: t('pageEmployees.addEmployee.permissionsRead'),
      //       value: 'educations-read',
      //     },
      //     {
      //       label: t('pageEmployees.addEmployee.permissionsEdit'),
      //       value: 'educations-edit',
      //     },
      //   ],
      // },
      {
        label: t("pageHome.statsTitle"),
        options: [
          {
            label: t("pageEmployees.addEmployee.permissionsNone"),
            value: "stats-none",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsRead"),
            value: "stats-read",
          },
          {
            label: t("pageEmployees.addEmployee.permissionsEdit"),
            value: "stats-edit",
          },
        ],
      },
    ],
    selectedItems: [],
  });

  const fetchOrganisationData = async () => {
    try {
      const orgData = await getOrganisationById(organisationId);
      setLocalOrganisation(orgData);
    } catch (error) {
      console.error("Error fetching organisation data:", error);
    }
  };

  const levelLabels = {
    levelOne: localOrganisation
      ? localOrganisation.levelOne?.label || null
      : null,
    levelTwo: localOrganisation
      ? localOrganisation.levelTwo?.label || null
      : null,
    levelThree: localOrganisation
      ? localOrganisation.levelThree?.label || null
      : null,
    levelFour: localOrganisation
      ? localOrganisation.levelFour?.label || null
      : null,
  };
  // ---------- Effects ----------
  useEffect(() => {
    localStorage.setItem("employeesOngoing", JSON.stringify(ongoing));
  }, [ongoing]);
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [showEditEmployee, setShowEditEmployee] = useState(false);
  const [filterProfessionalRoleNames, setFilteremployeeTitles] = useState({
    include: true,
    items: [],
    selectedItems: [],
  });
  const [filterQualifications, setFilterQualifications] = useState({
    include: true,
    items: [],
    selectedItems: [],
  });

  useEffect(() => {
    fetchOrganisationData();
  }, []);

  useEffect(() => {
    let isLoading = true;
    if (!localOrganisation || !row) {
      return;
    }

    const getQualsAndRoles = () => {
      getProfessionalRoles(localOrganisation.organisationNumber).then(
        async (res) => {
          setBaseRoles(res);
          const roleQuals = res.map((role) => role.qualification).flat();

          const qualifications = await getQualifications(
            localOrganisation.organisationNumber
          );

          const allQuals = _.uniqBy([...roleQuals, ...qualifications], "id");
          setFilterQualifications({
            ...filterQualifications,
            items: allQuals.map(({ qualificationName, id }) => ({
              label: qualificationName,
              value: id,
            })),
          });
        }
      );
    };

    if (isLoading) {
      getQualsAndRoles();
    }

    return () => {
      isLoading = false;
    };
    // eslint-disable-next-line
  }, [localOrganisation, row]);

  useEffect(() => {
    const roles = baseRoles.map((role) => ({
      label: role.professionalRoleName,
      value: role,
    }));
    const roleOptions = [
      {
        label: "Ingen",
        value: null,
      },
      ...roles,
    ];
    setRoleOptions(roleOptions);
  }, [baseRoles]);

  useEffect(() => {
    localStorage.setItem("employeesSelectedRow", JSON.stringify(row));
  }, [row]);

  useEffect(() => {
    const employeeTitleList = _.uniqBy(
      rows
        .map((row) => ({
          label: row.professionalRoleName,
          value: row.professionalRoleName,
        }))
        .filter((item) => item.value),
      (item) => item.value
    );
    setFilteremployeeTitles({
      ...filterProfessionalRoleNames,
      items: employeeTitleList,
      selectedItems: [],
    });

    //eslint-disable-next-line
  }, [rows]);
  return (
    <EmployeesContext.Provider
      value={{
        row,
        setRow,
        rows,
        setRows,
        levels: levelLabels,
        baseRoles,
        setBaseRoles,
        roleOptions,
        ongoing,
        setOngoing,
        completed,
        setCompleted,
      }}
    >
      <Switch>
        <Route exact path={path}>
          <Wrapper
            className="employee-wrapper"
            levels={Object.keys(levelLabels).length}
          >
            {showAddEmployee && (
              <Modal
                title={t("pageEmployees.addEmployee.title")}
                setState={setShowAddEmployee}
                closeOnClickOutside
              >
                <AddEmployee
                  setShowAddEmployee={setShowAddEmployee}
                  localOrganisation={localOrganisation}
                  setLocalOrganisation={setLocalOrganisation}
                />
              </Modal>
            )}
            {showEditEmployee && (
              <Modal
                title={t("pageEmployees.editEmployee.title")}
                setState={setShowEditEmployee}
                closeOnClickOutside
              >
                <EditEmployee
                  setShowEditEmployee={setShowEditEmployee}
                  setLocalOrganisation={setLocalOrganisation}
                  localOrganisation={localOrganisation}
                />
              </Modal>
            )}
            <section className="employee-search">
              <div className="top">
                <h3>{t("pageEmployees.title.title")}</h3>
                {employeeAccess === "edit" && (
                  <Secondary onClick={handleAddEmployeeClick}>
                    {t("pageEmployees.title.buttonText")}
                  </Secondary>
                )}
                {error && (
                  <Modal
                    title={t("pageEmployees.addEmployee.subscription")}
                    message={error}
                    setState={() => setError(false)}
                    closeOnClickOutside
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button onClick={() => setError(false)}>
                        {t("buttons.okButton")}
                      </Button>
                    </div>
                  </Modal>
                )}
              </div>
              <form>
                <div className="form-fields">
                  <div className="text-fields">
                    <StyledLabel>
                      <p>
                        {t(
                          "pageEmployees.employeeSearch.textFields.employeeNumber"
                        )}
                      </p>
                      <input
                        type="text"
                        value={searchFilter.employeeNumber}
                        onInput={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            employeeNumber: e.target.value,
                          })
                        }
                      ></input>
                    </StyledLabel>
                    <StyledLabel>
                      <p>
                        {t("pageEmployees.employeeSearch.textFields.firstName")}
                      </p>
                      <input
                        type="text"
                        value={searchFilter.firstName}
                        onInput={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            firstName: e.target.value,
                          })
                        }
                      ></input>
                    </StyledLabel>
                    <StyledLabel>
                      <p>
                        {t("pageEmployees.employeeSearch.textFields.lastName")}
                      </p>
                      <input
                        type="text"
                        value={searchFilter.lastName}
                        onInput={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            lastName: e.target.value,
                          })
                        }
                      ></input>
                    </StyledLabel>
                    {levelLabels.levelOne && (
                      <StyledLabel>
                        <p>{levelLabels.levelOne}</p>
                        <input
                          type="text"
                          value={searchFilter.levelOne}
                          onInput={(e) =>
                            setSearchFilter({
                              ...searchFilter,
                              levelOne: e.target.value,
                            })
                          }
                        ></input>
                      </StyledLabel>
                    )}
                    {levelLabels.levelTwo && (
                      <StyledLabel>
                        <p>{levelLabels.levelTwo}</p>
                        <input
                          type="text"
                          value={searchFilter.levelTwo}
                          onInput={(e) =>
                            setSearchFilter({
                              ...searchFilter,
                              levelTwo: e.target.value,
                            })
                          }
                        ></input>
                      </StyledLabel>
                    )}
                    {levelLabels.levelThree && (
                      <StyledLabel>
                        <p>{levelLabels.levelThree}</p>
                        <input
                          type="text"
                          value={searchFilter.levelThree}
                          onInput={(e) =>
                            setSearchFilter({
                              ...searchFilter,
                              levelThree: e.target.value,
                            })
                          }
                        ></input>
                      </StyledLabel>
                    )}
                    {levelLabels.levelFour && (
                      <StyledLabel>
                        <p>{levelLabels.levelFour}</p>
                        <input
                          type="text"
                          value={searchFilter.levelFour}
                          onInput={(e) =>
                            setSearchFilter({
                              ...searchFilter,
                              levelFour: e.target.value,
                            })
                          }
                        ></input>
                      </StyledLabel>
                    )}
                  </div>
                  <div className="filter-options organization">
                    <FilterOption
                      name={t(
                        "pageEmployees.employeeSearch.filterOptions.filterProfessionalRoleNames"
                      )}
                      kebabCaseName="professional-role"
                      options={filterProfessionalRoleNames.items}
                      value={filterProfessionalRoleNames.selectedItems}
                      radioChecked={filterProfessionalRoleNames.include}
                      onRadioChange={(bool) =>
                        setFilteremployeeTitles({
                          ...filterProfessionalRoleNames,
                          include: bool,
                        })
                      }
                      onDropdownChange={(selectedItems) =>
                        setFilteremployeeTitles({
                          ...filterProfessionalRoleNames,
                          selectedItems,
                        })
                      }
                    />
                    <FilterOption
                      name={t(
                        "pageEmployees.employeeSearch.filterOptions.filterQualifications"
                      )}
                      kebabCaseName="qualification"
                      options={filterQualifications.items}
                      value={filterQualifications.selectedItems}
                      radioChecked={filterQualifications.include}
                      onRadioChange={(bool) =>
                        setFilterQualifications({
                          ...filterQualifications,
                          include: bool,
                        })
                      }
                      onDropdownChange={(selectedItems) =>
                        setFilterQualifications({
                          ...filterQualifications,
                          selectedItems,
                        })
                      }
                    />
                    {/* <FilterOption
                      name={t(
                        'pageEmployees.employeeSearch.filterOptions.filterEducations'
                      )}
                      kebabCaseName='education'
                      options={filterEducations.items}
                      value={filterEducations.selectedItems}
                      radioChecked={filterEducations.include}
                      onRadioChange={(bool) =>
                        setFilterEducations({
                          ...filterEducations,
                          include: bool,
                        })
                      }
                      onDropdownChange={(selectedItems) =>
                        setFilterEducations({
                          ...filterEducations,
                          selectedItems,
                        })
                      }
                    /> */}
                    {rightsAccess !== "none" && (
                      <FilterOption
                        name={t("extra.permissions")}
                        kebabCaseName="permissions"
                        options={filterPermissions.items}
                        value={filterPermissions.selectedItems}
                        radioChecked={filterPermissions.include}
                        onRadioChange={(bool) =>
                          setFilterPermissions({
                            ...filterPermissions,
                            include: bool,
                          })
                        }
                        onDropdownChange={(selectedItems) =>
                          setFilterPermissions({
                            ...filterPermissions,
                            selectedItems,
                          })
                        }
                      />
                    )}
                  </div>
                </div>
              </form>
            </section>
            <hr />
            <section className="employee-results">
              <div className="mode-select-wrapper">
                <div className="mode-select">
                  {rightsAccess !== "none" && (
                    <>
                      <h4
                        className={mode === "org" ? "selected" : ""}
                        onClick={() => setMode("org")}
                      >
                        {t("extra.organization")}
                      </h4>
                      <h4 className="selected">|</h4>
                      <h4
                        className={mode === "perm" ? "selected" : ""}
                        onClick={() => setMode("perm")}
                      >
                        {t("extra.permissions")}
                      </h4>
                    </>
                  )}
                </div>
              </div>
              <p className="small-copy">
                {mode === "org" ? (
                  <>
                    <span></span>
                    <span></span>
                  </>
                ) : (
                  <>
                    <span>
                      {t(
                        "pageEmployees.searchResults.glossary.permissionsNone"
                      )}
                    </span>
                    <span>
                      {t(
                        "pageEmployees.searchResults.glossary.permissionsRead"
                      )}
                    </span>
                    <span>
                      {t(
                        "pageEmployees.searchResults.glossary.permissionsEdit"
                      )}
                    </span>
                  </>
                )}
              </p>
              <DenseTable
                setShowEditEmployee={setShowEditEmployee}
                mode={mode}
                searchFilter={searchFilter}
                filterProfessionalRoleNames={filterProfessionalRoleNames}
                filterQualifications={filterQualifications}
                // filterEducations={filterEducations}
                filterPermissions={filterPermissions}
                organisationId={organisationId}
                hasOrganisationId={hasOrganisationId}
              />
            </section>
          </Wrapper>
        </Route>
        <Route path={`${path}/:name`}>
          {row && organisation && (
            <EmployeeSubPage
              employee={row}
              organisation={organisation}
              individual={individual}
            />
          )}
        </Route>
      </Switch>
    </EmployeesContext.Provider>
  );
};

export default Employees;
