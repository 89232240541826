import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch, Link } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styled from "styled-components";
import { simpleUser } from "../../utils/Amplify";
import { OrganisationsContext } from "./Organisations";
import { Context } from "../../utils/Context";
import { freshPerms } from "../../utils/Backend";
import { ReactComponent as LoadingSpinner } from "../../assets/img/spinner.svg";
import { ReactComponent as EditPencil } from "../../assets/img/Edit_pencil.svg";

const Wrapper = styled.div`
  white-space: nowrap;
  border-bottom: 1px solid #cfcfd5;

  .loading-organisations {
    width: 1160px;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 30px;

    svg {
      height: 50px;
    }
  }

  .MuiTableHead-root {
    border-bottom: 1px solid #cfcfd5;
  }

  .MuiTableCell-head {
    height: 66px;
    vertical-align: bottom;
    padding-bottom: 5px;
    line-height: 1em;
    background-color: white;
    color: #858585;
  }

  .MuiTableRow-root {
    border-bottom: 1px solid #cfcfd5;
  }

  .MuiTableCell-root {
    border-bottom: none;
    height: fit-content;
    padding-left: 15px;
    padding-right: 15px;
  }

  .border {
    border-left: 1px solid #cfcfd5;

    &.end {
      border-right: 1px solid #cfcfd5;
    }
  }

  .grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: fit-content() fit-content();
    grid-template-rows: 36px 19px;
    text-align: center;
    margin-bottom: -1px;
    align-items: end;

    .span2 {
      font-weight: 400;
      line-height: 1.2em;
      grid-column: 1 / 3;

      &.bold {
        font-weight: inherit;
      }
    }
  }

  .data-grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;

    .span2 {
      grid-column: 1 / 3;
    }
  }

  .last {
    min-width: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const OrganisationsTable = ({ setShowEditOrganisation, searchFilter }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setRow, rows, setRows } = useContext(OrganisationsContext);

  const [rowsLoading, setRowsLoading] = useState(false);

  useEffect(() => {
    const getOrganisationsData = async () => {
      setRowsLoading(true);
      try {
        const storedPerms = await freshPerms();

        if (storedPerms.employeeAccess === "none") {
          let throwMsg = "Not authorized";
          throw throwMsg;
        }

        const data = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/organisations`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + (await simpleUser()).token,
            },
          }
        ).then((res) => res.json());
        setRows(
          data.map((org) => {
            return {
              ...org,
            };
          })
        );
      } catch (err) {
        typeof err === "string" ? alert(err) : console.log(err);
        history.push("/home");
      }
      setRowsLoading(false);
    };

    getOrganisationsData();
  }, []);

  const { path } = useRouteMatch();
  const employeesPath = path.replace(
    "organisations",
    "employees/organisationId"
  );

  return (
    <Wrapper className="table-wrapper">
      <TableContainer>
        <Table size="small" aria-label="organisation table">
          <TableHead>
            <TableRow>
              <TableCell>
                {t(
                  "pageOrganisations.organisationSearch.textFields.organisationID"
                )}
              </TableCell>
              <TableCell>
                {t("pageOrganisations.organisationSearch.textFields.name")}
              </TableCell>
              <TableCell>
                {t("pageOrganisations.organisationSearch.textFields.city")}
              </TableCell>
              <TableCell>
                {t("pageOrganisations.organisationSearch.textFields.country")}
              </TableCell>
              <TableCell>
                {t(
                  "pageOrganisations.organisationSearch.textFields.subscription"
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0
              ? rows
                  .filter((row, i) => {
                    Object.keys(row).forEach((key) => {
                      if (row[key] === null) {
                        row[key] = undefined;
                      }
                    });

                    return Object.keys(searchFilter).every((key) => {
                      const str =
                        key === "name" ? row.orgName + "" : row[key] + "";
                      return str
                        .toLowerCase()
                        .startsWith(searchFilter[key].toLowerCase());
                    });
                  })
                  .map((org) => (
                    <TableRow key={org.id} classes={{ root: "row" }}>
                      <TableCell component="th" align="left">
                        <Link to={`${employeesPath}/${org.id}`}>{org.id}</Link>
                      </TableCell>
                      <TableCell>{org.orgName}</TableCell>
                      <TableCell>{org.city}</TableCell>
                      <TableCell>{org.country}</TableCell>
                      <TableCell>{org.subscription}</TableCell>
                      <TableCell
                        classes={{ root: "last" }}
                        onClick={() => {
                          setRow(org);
                          setShowEditOrganisation(true);
                        }}
                      >
                        <EditPencil />
                      </TableCell>
                    </TableRow>
                  ))
              : rowsLoading && (
                  <TableRow className="loading-organisations">
                    <TableCell>
                      <LoadingSpinner />
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};

export default OrganisationsTable;
