import Modal from "../../../../../components/modal/Modal";

const CatalogModuleModal = ({ modalModule, setModalModule }) => {
    return (
        <Modal
            title={modalModule.moduleName}
            setState={setModalModule}
        >
            <p>{modalModule.moduleDescription}</p>
        </Modal>
    )
}

export default CatalogModuleModal;