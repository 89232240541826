import React, { Fragment, useContext, useState } from 'react'

import { Button } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import StyledLabel from '../../../../components/styledLabel/StyledLabel'
import StyledSelect from '../../../../components/styledSelect/StyledSelect'
import { handleAddFormChange, handleAddFormSubmit } from '../../functions'
import { QualificationContext } from '../Qualification'
import { QualModalStyle } from './QualModalStyle'
import { useTranslation } from 'react-i18next'

const AddQualification = () => {
  const { t } = useTranslation();
  // TODO Translation needed!
  const initializedAddFormData = {
    qualificationName: '',
    qualificationArea: '',
    qualificationType: '',
    qualificationAccess: '',
    qualificationDescription: '',
    qualificationLanguage: 'Swedish',
    qualificationEqf: 0,
    version: 1,
    published: 0,
    modified: 1,
  }
  // State for the form inputs
  const [addFormData, setAddFormData] = useState(initializedAddFormData)

  // Getting som states from
  const {
    qualificationsList,
    setQualificationsList,
    setModalAddIsVisible,
    organisationNumber,
  } = useContext(QualificationContext)

  return (
    <Fragment>
      <Modal
        title={t('pageAddNewQualification.title')}
        setState={setModalAddIsVisible}
      >
        <QualModalStyle>
          <form
            onSubmit={(e) =>
              handleAddFormSubmit(e, {
                organisationNumber,
                addFormData,
                qualificationsList,
                setQualificationsList,
                setModalAddIsVisible,
              })
            }
          >
            <StyledLabel>
              <p>{t('pageAddNewQualification.name')}</p>
              <input
                type='text'
                name='qualificationName'
                required='required'
                placeholder={t('pageAddNewQualification.namePlaceholder')}
                onChange={(e) =>
                  handleAddFormChange(e, addFormData, setAddFormData)
                }
              />
            </StyledLabel>
            <div className='flex custom-style-1'>
              <StyledLabel>
                <p>{t('pageAddNewQualification.areaLabel')}</p>
                <input
                  type='text'
                  name='qualificationArea'
                  placeholder={t('pageAddNewQualification.areaPlaceholder')}
                  onChange={(e) =>
                    handleAddFormChange(e, addFormData, setAddFormData)
                  }
                />
              </StyledLabel>
              <StyledLabel>
                <p>{t('pageAddNewQualification.typeLabel')}</p>
                <input
                  type='text'
                  name='qualificationType'
                  placeholder={t('pageAddNewQualification.typePlaceholder')}
                  onChange={(e) =>
                    handleAddFormChange(e, addFormData, setAddFormData)
                  }
                />
              </StyledLabel>
            </div>
            <StyledLabel>
              <p>{t('pageAddNewQualification.authorizationLabel')}</p>
              <input
                type='text'
                name='qualificationAccess'
                placeholder={t('pageAddNewQualification.authorizationPlaceholde')}
                onChange={(e) =>
                  handleAddFormChange(e, addFormData, setAddFormData)
                }
              />
            </StyledLabel>
            <StyledLabel>
              <p>{t('pageAddNewQualification.descriptionLabel')}</p>
              <textarea
                name='qualificationDescription'
                required='required'
                placeholder={t('pageAddNewQualification.descriptionPlaceholder')}
                onChange={(e) =>
                  handleAddFormChange(e, addFormData, setAddFormData)
                }
              ></textarea>
            </StyledLabel>
            <div className='flex custom-style-2'>
              <StyledLabel className='language-select'>
                <p>{t('pageAddNewQualification.languageLabel')}</p>
                <StyledSelect
                  dark
                  options={[
                    { value: 'Swedish', label: 'Swedish' },
                    { value: 'English', label: 'English' },
                  ]}
                  value={{
                    value: addFormData.qualificationLanguage,
                    label: addFormData.qualificationLanguage,
                  }}
                  onChange={(e) => {
                    setAddFormData({
                      ...addFormData,
                      qualificationLanguage: e.value,
                    })
                  }}
                />
              </StyledLabel>
              <StyledLabel>
                <p>{t('pageAddNewQualification.eqfLabel')}</p>

                <input
                  name='qualificationEqf'
                  style={{
                    background: '#dbdbdf',
                    minWidth: '160px',
                    width: 'auto',
                    maxWidth: 'fit-content',
                  }}
                  type='number'
                  value={addFormData.qualificationEqf}
                  min='0'
                  max='8'
                  onFocus={(e) => e.target.select()}
                  onClick={(e) => e.target.select()}
                  onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === 'E') e.preventDefault()
                  }}
                  onChange={(e) => {
                    if (e.target.value >= 0 && e.target.value <= 8)
                      setAddFormData({
                        ...addFormData,
                        qualificationEqf: e.target.value,
                      })
                  }}
                />
              </StyledLabel>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '30px',
              }}
            >
              <Button type='Add'>{t('pageAddNewQualification.addButton')}</Button>
            </div>
          </form>
        </QualModalStyle>
      </Modal>
    </Fragment>
  )
}

export default AddQualification
