import { Auth } from 'aws-amplify'
import React, { useContext, useEffect, useState } from 'react'

import { ReactComponent as EditProfilePic } from '../../assets/img/edit-profile-pic.svg'
import { Button, Delete } from '../../components/button/Button'
import StyledLabel from '../../components/styledLabel/StyledLabel'
import {
  emailIsValid,
  passwordIsValid,
  phoneIsValid,
} from '../../utils/Validation'
import { Wrapper } from './style'
import { useTranslation } from 'react-i18next'
import { ClickAwayListener, Tooltip } from '@mui/material'
import { simpleUser } from '../../utils/Amplify'
import ErrorMsg from '../../components/errorMsg/ErrorMsg'
import UpdateMsg from '../../components/updateMsg/UpdateMsg'
import { Context } from '../../utils/Context'
import Employer from './Employer'
import DeleteEmployer from './modals/deleteEmployer/DeleteEmployer'

import { ReactComponent as LoadingSpinner } from '../../assets/img/spinner.svg'

const Profile = () => {
  const [formInputs, setFormInputs] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    newPW: '',
    confirmPW: '',
    currentPW: '',
  })
  const [errorMsg, setErrorMsg] = useState({})
  const [modifiedAttributes, setModifiedAttributes] = useState({
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false,
  })
  const [modifiedPassword, setModifiedPassword] = useState(false)
  const [oldAttributes, setOldAttributes] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  })
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [newEmployerInputs, setNewEmployerInputs] = useState({
    organisationId: '',
    employeeNumber: '',
  })
  const [showDeleteEmployer, setShowDeleteEmployer] = useState(false)
  const [addingEmployer, setAddingEmployer] = useState(false)

  const [connectErrorMsg, setConnectErrorMsg] = useState('')

  const { individual, organisation, getUserAgain, setGetUserAgain } =
    useContext(Context)

  // Sets input fields and old attributes to saved values from Amplify
  useEffect(() => {
    let isLoading = true
    const getFields = async () => {
      if (isLoading) {
        try {
          const currentUserInfo = await Auth.currentUserInfo()
          const {
            'custom:firstName': firstName,
            'custom:lastName': lastName,
            'custom:phoneString': phoneNumber,
            email,
          } = currentUserInfo.attributes

          setFormInputs({
            ...formInputs,
            firstName,
            lastName,
            phoneNumber: phoneNumber ? phoneNumber : '',
            email,
          })
          setOldAttributes({
            ...oldAttributes,
            firstName,
            lastName,
            phoneNumber: phoneNumber ? phoneNumber : '',
            email,
          })
        } catch (err) {
          console.log('error fetching user info: ', err)
        }
      }
    }

    getFields()
    return () => {
      isLoading = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Updates old attributes after attributes are modified
  useEffect(() => {
    const updateOldAttributes = async () => {
      try {
        const currentUserInfo = await Auth.currentUserInfo()
        const {
          'custom:firstName': firstName,
          'custom:lastName': lastName,
          'custom:phoneString': phoneNumber,
          email,
        } = currentUserInfo.attributes

        setTimeout(() => {
          setOldAttributes({
            ...oldAttributes,
            firstName,
            lastName,
            phoneNumber: phoneNumber ? phoneNumber : '',
            email,
          })
        }, 5000)
      } catch (err) {
        console.log('error fetching user info: ', err)
      }
    }

    updateOldAttributes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedAttributes])

  // Updates email, first name, last name and phone number in Amplify and SQL database
  const updateAttributes = async () => {
    await Auth.currentAuthenticatedUser()
      .then(async (user) => {
        return await Auth.updateUserAttributes(user, {
          email: formInputs.email,
          'custom:firstName': formInputs.firstName,
          'custom:lastName': formInputs.lastName,
          'custom:phoneString': formInputs.phoneNumber,
        })
      })
      .then(async () => {
        setModifiedAttributes({
          firstName: formInputs.firstName !== oldAttributes.firstName,
          lastName: formInputs.lastName !== oldAttributes.lastName,
          email: formInputs.email !== oldAttributes.email,
          phoneNumber: formInputs.phoneNumber !== oldAttributes.phoneNumber,
        })
        setTimeout(() => {
          setModifiedAttributes({
            firstName: false,
            lastName: false,
            email: false,
            phoneNumber: false,
          })
        }, 3000)

        const options = {
          method: 'PATCH',
          body: JSON.stringify({
            firstName: formInputs.firstName,
            lastName: formInputs.lastName,
            phoneNumber: formInputs.phoneNumber,
            email: formInputs.email,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + (await simpleUser()).token,
          },
        }

        fetch(
          `${process.env.REACT_APP_BACKEND_URL}/individual/profile/${oldAttributes.email}`,
          options
        )
      })
      .catch((err) => console.log(err))
  }

  let tempError = {}
  // Updates password in Amplify and SQL database
  const updatePassword = async () => {
    // request options
    const options = {
      method: 'PATCH',
      body: JSON.stringify({
        password: formInputs.newPW,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }
    await Auth.currentAuthenticatedUser()
      .then(async (user) => {
        return await Auth.changePassword(
          user,
          formInputs.currentPW,
          formInputs.newPW
        )
      })
      .then(async (data) => {
        setModifiedPassword(true)
        setTimeout(() => setModifiedPassword(false), 5000)
        await Auth.currentSession()
          .then((res) => {
            fetch(
              `${process.env.REACT_APP_BACKEND_URL}/individual/profile/${oldAttributes.email}`,
              options
            )
              .then((res) => {
                res.json()
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => console.log(err))
      })
      .catch((err) => {
        console.log(err.toString())
        if (
          err.toString() ===
          'NotAuthorizedException: Incorrect username or password.'
        ) {
          tempError.pwIncorrect = true
          console.log(err)
        }
      })
    setFormInputs({ ...formInputs, newPW: '', confirmPW: '', currentPW: '' })
  }

  //Checks input field values and runs updateAttributes and/or updatePasssword
  const handleSubmit = async (e) => {
    e.preventDefault()

    setErrorMsg({})

    if (
      formInputs.firstName.length > 0 &&
      formInputs.lastName.length > 0 &&
      emailIsValid(formInputs.email) &&
      (!formInputs.phoneNumber ||
        (formInputs.phoneNumber && phoneIsValid(formInputs.phoneNumber)))
    ) {
      await updateAttributes()
    } else {
      tempError = {
        ...tempError,
        firstNameMissing: formInputs.firstName === '',
        lastNameMissing: formInputs.lastName === '',
        phoneInvalid:
          formInputs.phoneNumber !== '' &&
          !phoneIsValid(formInputs.phoneNumber),
        emailMissing: formInputs.email === '',
        emailInvalid:
          formInputs.email !== '' && !emailIsValid(formInputs.email),
      }
    }

    if (
      passwordIsValid(formInputs.newPW) &&
      formInputs.confirmPW === formInputs.newPW &&
      formInputs.currentPW !== ''
    ) {
      await updatePassword()
    } else {
      if (
        !(
          formInputs.newPW === '' &&
          formInputs.confirmPW === '' &&
          formInputs.currentPW === ''
        )
      ) {
        tempError = {
          ...tempError,
          pwInvalid: !passwordIsValid(formInputs.newPW),
          pwMismatch: formInputs.confirmPW !== formInputs.newPW,
          pwMissing: formInputs.currentPW === '',
          pwIncorrect:
            formInputs.currentPW !== '' &&
            !passwordIsValid(formInputs.currentPW),
        }
      }
    }

    setErrorMsg(tempError)
  }

  const addNewEmployer = async () => {
    setAddingEmployer(true)
    try {
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/individual/${individual.email}/connect/${newEmployerInputs.employeeNumber}/${newEmployerInputs.organisationId}`,
        options
      )

      if (response.status === 404) {
        const res = await response.json()

        let errorMsg = ''
        if (res.errorMessage === 'Could not find employee') {
          errorMsg = t('pageProfile.errors.employeeNotFound')
        } else if (
          res.errorMessage ===
          'The first name or last name does not match the employee'
        ) {
          errorMsg = t('pageProfile.errors.nameMissmatch')
        }

        setConnectErrorMsg(errorMsg)
      } else if (response.status === 409) {
        setConnectErrorMsg(t('pageProfile.errors.alreadyConnected'))
      } else {
        setGetUserAgain(getUserAgain + 1)
        setConnectErrorMsg('')
      }
    } catch (err) {
      console.log(err)
    }
    setTimeout(() => setAddingEmployer(false), 5000)
  }

  const { t } = useTranslation()

  return (
    <Wrapper>
      {showDeleteEmployer && (
        <DeleteEmployer setState={setShowDeleteEmployer} />
      )}
      <h3>{t('pageProfile.title')}</h3>
      <section className='profile__edit'>
        <form onSubmit={(e) => handleSubmit(e)}>
          <StyledLabel className='row1'>
            <p>{t('pageProfile.firstName')}</p>
            <input
              className={errorMsg.firstNameMissing ? 'error' : ''}
              value={formInputs.firstName}
              type='text'
              name='first-name'
              id='first-name'
              onInput={(e) => {
                setFormInputs({
                  ...formInputs,
                  firstName: e.target.value,
                })
              }}
            />
            {modifiedAttributes.firstName && <UpdateMsg />}
            {errorMsg.firstNameMissing && (
              <ErrorMsg>
                <p>{t('pageProfile.errors.firstNameMissing')}</p>
              </ErrorMsg>
            )}
          </StyledLabel>
          <StyledLabel className='row1'>
            <p>{t('pageProfile.lastName')}</p>
            <input
              className={errorMsg.lastNameMissing ? 'error' : ''}
              value={formInputs.lastName}
              type='text'
              name='last-name'
              id='last-name'
              onInput={(e) => {
                setFormInputs({
                  ...formInputs,
                  lastName: e.target.value,
                })
              }}
            />
            {modifiedAttributes.lastName && <UpdateMsg />}
            {errorMsg.lastNameMissing && (
              <ErrorMsg>
                <p>{t('pageProfile.errors.lastNameMissing')}</p>
              </ErrorMsg>
            )}
          </StyledLabel>
          <StyledLabel className='row1'>
            <p>{t('pageProfile.phone')}</p>
            <input
              className={errorMsg.phoneInvalid ? 'error' : ''}
              value={formInputs.phoneNumber}
              type='text'
              name='phoneNumber'
              id='phoneNumber'
              onInput={(e) => {
                setFormInputs({
                  ...formInputs,
                  phoneNumber: e.target.value,
                })
              }}
            />
            {modifiedAttributes.phoneNumber && <UpdateMsg />}
            {errorMsg.phoneInvalid && (
              <ErrorMsg>
                <p>{t('pageProfile.errors.phoneInvalid')}</p>
              </ErrorMsg>
            )}
          </StyledLabel>
          <StyledLabel className='row2'>
            <p>{t('pageProfile.email')}</p>
            <input
              autoComplete='email'
              className={
                errorMsg.emailInvalid || errorMsg.emailMissing ? 'error' : ''
              }
              value={formInputs.email}
              name='email'
              id='email'
              onInput={(e) => {
                setFormInputs({
                  ...formInputs,
                  email: e.target.value,
                })
              }}
            />
            {modifiedAttributes.email && <UpdateMsg />}
            {errorMsg.emailInvalid && (
              <ErrorMsg>
                <p>{t('pageProfile.errors.emailInvalid')}</p>
              </ErrorMsg>
            )}
            {errorMsg.emailMissing && (
              <ErrorMsg>
                <p>{t('pageProfile.errors.emailMissing')}</p>
              </ErrorMsg>
            )}
          </StyledLabel>
          <StyledLabel className='row3'>
            <p>{t('pageProfile.newPW')}</p>
            <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
              <Tooltip
                title={t('pageProfile.passwordReqs')}
                arrow
                placement='top'
                disableHoverListener
                open={tooltipOpen}
              >
                <input
                  className={errorMsg.pwInvalid ? 'error' : ''}
                  value={formInputs.newPW}
                  type='password'
                  name='new-password'
                  id='new-password'
                  autoComplete='new-password'
                  onInput={(e) => {
                    setFormInputs({
                      ...formInputs,
                      newPW: e.target.value,
                    })
                  }}
                  onClick={() => setTooltipOpen(true)}
                />
              </Tooltip>
            </ClickAwayListener>
            {modifiedPassword && <UpdateMsg />}
            {errorMsg.pwInvalid && (
              <ErrorMsg>
                <p>{t('pageProfile.errors.pwInvalid')}</p>
              </ErrorMsg>
            )}
          </StyledLabel>
          <StyledLabel className='row3'>
            <p>{t('pageProfile.confirmPW')}</p>
            <input
              className={errorMsg.pwMismatch ? 'error' : ''}
              value={formInputs.confirmPW}
              type='password'
              name='confirm-password'
              id='confirm-password'
              autoComplete='new-password'
              onInput={(e) => {
                setFormInputs({
                  ...formInputs,
                  confirmPW: e.target.value,
                })
              }}
            />
            {errorMsg.pwMismatch && (
              <ErrorMsg>
                <p>{t('pageProfile.errors.pwMismatch')}</p>
              </ErrorMsg>
            )}
          </StyledLabel>
          <StyledLabel className='row3'>
            <p>{t('pageProfile.currentPW')}</p>
            <input
              autoComplete='current-password'
              className={
                errorMsg.pwMissing || errorMsg.pwIncorrect ? 'error' : ''
              }
              disabled={!formInputs.newPW}
              value={formInputs.currentPW}
              type='password'
              name='current-password'
              id='current-password'
              onInput={(e) => {
                setFormInputs({
                  ...formInputs,
                  currentPW: e.target.value,
                })
              }}
            />
            {errorMsg.pwMissing && (
              <ErrorMsg>
                <p>{t('pageProfile.errors.pwMissing')}</p>
              </ErrorMsg>
            )}
            {errorMsg.pwIncorrect && (
              <ErrorMsg>
                <p>{t('pageProfile.errors.pwIncorrect')}</p>
              </ErrorMsg>
            )}
          </StyledLabel>
          <Button className='row4'>{t('pageProfile.saveChanges')}</Button>
        </form>
        <div className='profile__edit-pfp'>
          <EditProfilePic />
          <Button sm>{t('pageProfile.editPic')}</Button>
        </div>
      </section>

      <section className='profile__employee-info'>
        {organisation ? (
          <>
            <h4>{t('pageProfile.currentEmployer')}</h4>
            <p className='button-text'>{organisation.orgName.toUpperCase()}</p>
            <Employer />
            <Delete
              className='delete-employer'
              onClick={(e) => {
                e.preventDefault()
                setShowDeleteEmployer(true)
              }}
            >
              {t('buttons.deleteButton')}
            </Delete>
          </>
        ) : (
          <>
            <h4>{t('pageProfile.addEmployer')}</h4>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                addNewEmployer()
              }}
            >
              <StyledLabel>
                <p>{t('pageProfile.inputOrgID')}</p>
                <input
                  value={newEmployerInputs.organisationId}
                  onChange={(e) => {
                    setNewEmployerInputs({
                      ...newEmployerInputs,
                      organisationId: e.target.value,
                    })
                  }}
                />
              </StyledLabel>
              <StyledLabel>
                <p>{t('pageProfile.inputEmployeeNumber')}</p>
                <input
                  value={newEmployerInputs.employeeNumber}
                  onChange={(e) => {
                    setNewEmployerInputs({
                      ...newEmployerInputs,
                      employeeNumber: e.target.value,
                    })
                  }}
                />
              </StyledLabel>
              <Button>{t('buttons.addButton')}</Button>
              {addingEmployer && (
                <LoadingSpinner height={40} style={{ margin: 0 }} />
              )}
            </form>
            <p style={{ marginTop: '10px', color: 'red' }}>{connectErrorMsg}</p>
          </>
        )}
      </section>
    </Wrapper>
  )
}

export default Profile
