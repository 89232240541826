import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import PdfHeader from './PdfHeader'
import { t } from 'i18next'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'http://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf',
    },
    {
      src: 'http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
    },
  ],
})

/** Styles for PDF */
const styles = StyleSheet.create({
  bold: { fontFamily: 'Roboto', fontWeight: 700 },
  regular: { fontFamily: 'Roboto', fontWeight: 400 },
  individual: { fontSize: 20, marginBottom: 14 },
  subQualification: { fontSize: 18, marginBottom: 10 },
  view: { margin: 30, fontSize: 14 },
  page: {
    paddingLeft: 20,
    paddingTop: 30,
    paddingRight: 20,
    paddingBottom: 30,
  },
})

/** PDF document */
export const PdfDoc = ({ pdfData, individual, completedDate }) => {
  return (
    <Document>
      <Page style={styles.page} wrap size='A4'>
        <View style={styles.view}>
          <View style={{ marginBottom: 30 }}>
            <PdfHeader />
          </View>
          <View style={{ marginBottom: 14 }}>
            <Text style={styles.bold}>
              {individual.firstName} {individual.lastName}
            </Text>
            <Text style={styles.regular}>{t('pagePdf.verifiedKnowledge')}</Text>
            <Text style={[styles.regular, { fontSize: 28 }]}>
              {pdfData.QualificationName}
            </Text>
          </View>
          <View style={{ marginBottom: 16 }}>
            <Text style={styles.regular}>
              <Text style={styles.bold}>
                {' '}
                {t('pagePdf.date')} {''}
              </Text>
              {completedDate}
            </Text>

            <Text style={styles.regular}>
              <Text style={styles.bold}>
                {' '}
                {t('pagePdf.language')} {''}
              </Text>
              {pdfData.QualificationLanguage}
            </Text>
            <Text style={styles.regular}>
              <Text style={styles.bold}>
                {' '}
                {t('pagePdf.name')} {''}
              </Text>
              {pdfData.QualificationName}
            </Text>
            <Text style={styles.regular}>
              <Text style={styles.bold}>
                {' '}
                {t('pagePdf.area')} {''}
              </Text>{' '}
              {pdfData.QualificationArea}
            </Text>
            <Text style={styles.regular}>
              <Text style={styles.bold}>
                {' '}
                {t('pagePdf.type')} {''}
              </Text>
              {pdfData.QualificationType}
            </Text>
            <Text style={styles.regular}>
              <Text style={styles.bold}>
                {' '}
                {t('pagePdf.access')} {''}
              </Text>
              {pdfData.QualificationAccess}
            </Text>
            <Text style={styles.regular}>
              <Text style={styles.bold}>
                {' '}
                {t('pagePdf.eqf')} {''}
              </Text>
              {pdfData.QualificationEqf}
            </Text>
            <Text style={styles.regular}>
              <Text style={styles.bold}>
                {' '}
                {t('pagePdf.author')} {''}
              </Text>
              {pdfData.QualificationAuthor}
            </Text>
            <Text style={styles.regular}>
              <Text style={styles.bold}>
                {' '}
                {t('pagePdf.organisation')} {''}
              </Text>
              {individual.organisationEmployee.organisation.orgName}
            </Text>
          </View>
          <Text style={[styles.regular, { marginBottom: 16 }]}>
            {pdfData.QualificationDescription}
          </Text>

          {pdfData.subQualifications.map((subQual, index) => (
            <View wrap={false} style={{ marginBottom: 24 }} key={index}>
              <Text style={[styles.bold, styles.subQualification]}>
                {subQual.SubQualificationName}
              </Text>
              <Text style={styles.regular}>
                {subQual.SubQualificationDescription}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )
}
