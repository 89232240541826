import { useTranslation } from "react-i18next"
import StyledLabel from "../styledLabel/StyledLabel"
import StyledSelect from "../styledSelect/StyledSelect"

/**
 * Customized ReactSelect with StyledLabel and radio buttons dictating whether to exclude or include selected options in a search result.
 * This component expects to be controlled with two useStates (one for dropdown, one for radio buttons).
 * @param {name} param0: Name and label of input
 * @param {kebabCaseName} param1: Name in kebab case format (kebab-case)
 * @param {options} param2: Options of dropdown, should look like this:
 *  [
 *      {
 *          label: string,
 *          value: any
 *      },
 *      ...
 *  ]
 * @param {value} param3: Put dropdown state here, should look like this:
 *  {
 *      label: string,
 *      value: any
 *  }
 * @param {onDropdownChange} param4: Dictates what happens when a dropdown option is selected.
 * @param {radioChecked} param5: Put radio state here, should be a boolean.
 * @param {onRadioChange} param6: Put radio setState here.
 * @param {className} param7: self-explanatory
 */
const FilterOption = ({
    name,
    kebabCaseName,
    options,
    value,
    onDropdownChange,
    radioChecked,
    onRadioChange,
    className
}) => {

    const { t } = useTranslation()

    return (
        <div className={`filter-option ${kebabCaseName} ${className}`}>
            <label className='include'>
                <input
                    checked={radioChecked}
                    onChange={() => onRadioChange(true)}
                    type='radio'
                    name={`${kebabCaseName}-radio`}
                />
                {t('pageEmployees.employeeSearch.filterOptions.include')}
            </label>
            <label className='exclude'>
                <input
                    checked={!radioChecked}
                    onChange={() => onRadioChange(false)}
                    type='radio'
                    name={`${kebabCaseName}-radio`}
                />
                {t('pageEmployees.employeeSearch.filterOptions.exclude')}
            </label>
            <StyledLabel className='dropdown'>
                <p>{name}</p>
                <StyledSelect
                    options={options}
                    isMulti
                    hasAllOption
                    isSearchable
                    onChange={onDropdownChange}
                    value={value}
                />
            </StyledLabel>
        </div>
    )
}

export default FilterOption
