import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/button/Button";
import StyledLabel from "../../components/styledLabel/StyledLabel";
import { Context } from "../../utils/Context";
import { OrganisationsContext } from "./Organisations";
import { addOrganisation, checkOrganisationExists } from "./requests";

import ErrorMsg from "../../components/errorMsg/ErrorMsg";

const AddOrganisation = ({ setShowAddOrganisation }) => {
  const { t } = useTranslation();
  const { rows, setRows } = useContext(OrganisationsContext);

  const [formData, setFormData] = useState({
    name: "",
    city: "",
    country: "",
    organisationNumber: "",
    subscription: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    city: "",
    country: "",
    subscription: "",
    organisationNumber: "",
    organisationNumberAlreadyExists: "",
  });

  const updateRows = (organisation) => {
    const newRows = [...rows];

    newRows.push(organisation);

    setRows(newRows);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();

    const newErrors = {
      name: formData.name ? "" : t("pageOrganisations.errors.nameMissing"),
      city: formData.city ? "" : t("pageOrganisations.errors.cityMissing"),
      country: formData.country
        ? ""
        : t("pageOrganisations.errors.countryMissing"),
      subscription: formData.subscription
        ? ""
        : t("pageOrganisations.errors.subscriptionMissing"),
      organisationNumber: formData.organisationNumber
        ? ""
        : t("pageOrganisations.errors.organisationNumberMissing"),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      try {
        const organisationNumberExists = await checkOrganisationExists(
          formData.organisationNumber
        );
        if (organisationNumberExists) {
          setErrors({
            organisationNumberAlreadyExists: t(
              "pageOrganisations.errors.organisationNumberAlreadyExists"
            ),
          });
          throw new Error("Organisation number already exists");
        }
        const response = await addOrganisation(formData);
        const updatedFormData = {
          ...formData,
          id: response.id,
          orgName: formData.name,
        };
        updateRows(updatedFormData);
        setShowAddOrganisation(false);
      } catch (error) {
        console.error("Error saving organisation:", error);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSaveClick}>
        <StyledLabel>
          <p>
            {t("pageOrganisations.organisationSearch.textFields.name") + " *"}
          </p>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          {errors.name && (
            <ErrorMsg>
              <p>{errors.name}</p>
            </ErrorMsg>
          )}
        </StyledLabel>
        <StyledLabel>
          <p>
            {t("pageOrganisations.organisationSearch.textFields.city") + " *"}
          </p>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
          />
          {errors.city && (
            <ErrorMsg>
              <p>{errors.city}</p>
            </ErrorMsg>
          )}{" "}
        </StyledLabel>
        <StyledLabel>
          <p>
            {t("pageOrganisations.organisationSearch.textFields.country") +
              " *"}
          </p>
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
          />
          {errors.country && (
            <ErrorMsg>
              <p>{errors.country}</p>
            </ErrorMsg>
          )}{" "}
        </StyledLabel>
        <StyledLabel>
          <p>
            {t(
              "pageOrganisations.organisationSearch.textFields.organisationNumber"
            ) + " *"}
          </p>
          <input
            type="text"
            name="organisationNumber"
            value={formData.organisationNumber}
            onChange={handleInputChange}
          />
          {errors.organisationNumber && (
            <ErrorMsg>
              <p>{errors.organisationNumber}</p>
            </ErrorMsg>
          )}
          {errors.organisationNumberAlreadyExists && (
            <ErrorMsg>
              <p>{errors.organisationNumberAlreadyExists}</p>
            </ErrorMsg>
          )}
        </StyledLabel>
        <StyledLabel>
          <p>
            {t(
              "pageOrganisations.organisationSearch.textFields.subscriptionLong"
            ) + " *"}
          </p>
          <input
            type="text"
            name="subscription"
            value={formData.subscription}
            onChange={handleInputChange}
          />
          {errors.subscription && (
            <ErrorMsg>
              <p>{errors.subscription}</p>
            </ErrorMsg>
          )}
        </StyledLabel>
        <div
          className="buttons"
          style={{
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={handleSaveClick}>{t("buttons.addButton")}</Button>
        </div>
      </form>
    </div>
  );
};

export default AddOrganisation;
