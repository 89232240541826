import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/button/Button";
import StyledLabel from "../../components/styledLabel/StyledLabel";
import { OrganisationsContext } from "./Organisations";
import { saveOrganisation, checkOrganisationExistsAllowSelf } from "./requests";
import ErrorMsg from "../../components/errorMsg/ErrorMsg";

const EditOrganisation = ({ setShowEditOrganisation }) => {
  const { t } = useTranslation();
  const { row } = useContext(OrganisationsContext);

  const [formInputs, setFormInputs] = useState({
    id: row.id,
    orgName: row.orgName,
    city: row.city,
    country: row.country,
    subscription: row.subscription,
    organisationNumber: row.organisationNumber,
  });

  const [errors, setErrors] = useState({
    orgName: "",
    city: "",
    country: "",
    subscription: "",
    organisationNumber: "",
    organisationNumberAlreadyExists: "",
  });

  const handleSave = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const newErrors = {
      orgName:
        formInputs.orgName.trim() === ""
          ? t("pageOrganisations.errors.nameMissing")
          : "",
      city:
        formInputs.city.trim() === ""
          ? t("pageOrganisations.errors.cityMissing")
          : "",
      country:
        formInputs.country.trim() === ""
          ? t("pageOrganisations.errors.countryMissing")
          : "",
      subscription:
        formInputs.subscription.toString().trim() === ""
          ? t("pageOrganisations.errors.subscriptionMissing")
          : "",
      organisationNumber:
        formInputs.organisationNumber.toString().trim() === ""
          ? t("pageOrganisations.errors.organisationNumberMissing")
          : "",
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== "")) {
      console.log("Validation errors:", newErrors);
      return;
    }

    try {
      const organisationNumberExists = await checkOrganisationExistsAllowSelf(
        formInputs.organisationNumber,
        formInputs.id
      );
      if (organisationNumberExists) {
        setErrors({
          ...newErrors,
          organisationNumberAlreadyExists: t(
            "pageOrganisations.errors.organisationNumberAlreadyExists"
          ),
        });
        throw new Error("Organisation number already exists");
      }
      await saveOrganisation(formInputs);
      window.location.reload();
    } catch (err) {
      console.error("Error saving organisation:", err);
    }
  };

  return (
    <form>
      <StyledLabel>
        <p>
          {t("pageOrganisations.organisationSearch.textFields.organisationID")}
        </p>
        <input type="text" value={formInputs.id} disabled />
      </StyledLabel>
      <StyledLabel>
        <p>{t("pageOrganisations.organisationSearch.textFields.name")}</p>
        <input
          type="text"
          value={formInputs.orgName}
          onInput={(e) =>
            setFormInputs({ ...formInputs, orgName: e.target.value })
          }
        />
        {errors.orgName && (
          <ErrorMsg>
            <p>{errors.orgName}</p>
          </ErrorMsg>
        )}
      </StyledLabel>
      <StyledLabel>
        <p>{t("pageOrganisations.organisationSearch.textFields.city")}</p>
        <input
          type="text"
          value={formInputs.city}
          onInput={(e) =>
            setFormInputs({ ...formInputs, city: e.target.value })
          }
        />
        {errors.city && (
          <ErrorMsg>
            <p>{errors.city}</p>
          </ErrorMsg>
        )}
      </StyledLabel>
      <StyledLabel>
        <p>{t("pageOrganisations.organisationSearch.textFields.country")}</p>
        <input
          type="text"
          value={formInputs.country}
          onInput={(e) =>
            setFormInputs({ ...formInputs, country: e.target.value })
          }
        />
        {errors.country && (
          <ErrorMsg>
            <p>{errors.country}</p>
          </ErrorMsg>
        )}
      </StyledLabel>
      <StyledLabel>
        <p>
          {t(
            "pageOrganisations.organisationSearch.textFields.organisationNumber"
          )}
        </p>
        <input
          type="text"
          value={formInputs.organisationNumber}
          onInput={(e) =>
            setFormInputs({ ...formInputs, organisationNumber: e.target.value })
          }
        />
        {errors.organisationNumber && (
          <ErrorMsg>
            <p>{errors.organisationNumber}</p>
          </ErrorMsg>
        )}
        {errors.organisationNumberAlreadyExists && (
          <ErrorMsg>
            <p>{errors.organisationNumberAlreadyExists}</p>
          </ErrorMsg>
        )}
      </StyledLabel>
      <StyledLabel>
        <p>
          {t("pageOrganisations.organisationSearch.textFields.subscription")}
        </p>
        <input
          type="text"
          value={formInputs.subscription}
          onInput={(e) =>
            setFormInputs({ ...formInputs, subscription: e.target.value })
          }
        />
        {errors.subscription && (
          <ErrorMsg>
            <p>{errors.subscription}</p>
          </ErrorMsg>
        )}
      </StyledLabel>
      <div
        className="buttons"
        style={{
          gridColumn: "3 / span 1",
          gridRow: "3 / span 1",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={handleSave}>{t("buttons.saveButton")}</Button>
      </div>
    </form>
  );
};

export default EditOrganisation;
