import { useContext } from 'react'
import { t } from 'i18next'

import { Button } from '../../../../../components/button/Button'
import Modal from '../../../../../components/modal/Modal'
import { SubContext } from '../Survey'
import { useHistory } from 'react-router-dom'

const SurveyResult = ({ result }) => {
  const { setShowResultModal, setHasUpdated } = useContext(SubContext)

  const history = useHistory()

  const message = () => {
    if (result.passed) {
      return t('pageSurvey.passedInfo')
    } else if (!result.passed && result.attempts === 3) {
      return t('pageSurvey.failedInfo')
    } else {
      return t('pageSurvey.failedAttemptInfo')
    }
  }

  return (
    <Modal title={t('pageSurvey.survey')}>
      <p className='bold result-info'>{message()}</p>
      <div className='button-container'>
        <Button
          onClick={() => {
            setShowResultModal(false)

            if (result.passed || (!result.passed && result.attempts === 3)) {
              setHasUpdated(true)
              history.push('/competence-profile')
            }
          }}
        >
          {t('pageSurvey.close')}
        </Button>
      </div>
    </Modal>
  )
}

export default SurveyResult
