import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { StyledNav } from './style'

const Navbar = () => {

  const { t } = useTranslation()

  const location = useLocation()

  return (
    <StyledNav>
      <ul className='bold'>
        {location.pathname !== '/home' && !location.pathname.startsWith('/catalog') &&
          <Link to="/home">
            <li>{t('header.home')}</li>
          </Link>}
      </ul>
    </StyledNav >
  )
}

export default Navbar
