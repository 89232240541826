import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { EmployeeSubPageContext } from "../EmployeeSubPage";
import Education from "./education/Education";
import SubQualifications from "./SubQualifications";

const QualEduHub = () => {

    const { search } = useLocation()

    const location  = useLocation()
    const isOrgIdPath = location.pathname.includes("/employees/organisationId");
   
    const type = search.split('=')[1]

    const { selectedSurveyID, selectedEducation, selectedUncompletedSurveyID } = useContext(EmployeeSubPageContext)

    if (isOrgIdPath) {
        return <></>
    }
    
    
    return (
        <>
            {
                type === 'qualification' ?
                    (selectedSurveyID || selectedUncompletedSurveyID) && <SubQualifications />
                    :
                    selectedEducation && <Education />
            }
        </>
    )
}

export default QualEduHub;