import styled from 'styled-components';

const DotsContainer = styled.div`
    /**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */

    position: relative;
    right: 15px;

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #CFCFD5;
  color: #CFCFD5;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #CFCFD5;
  color: #CFCFD5;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #CFCFD5;
  color: #CFCFD5;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #CFCFD5;
  }
  50%,
  100% {
    background-color: #ECECEE;
  }
}
`

const LoadingDots = () => {

  return (
    <DotsContainer className="snippet" data-title=".dot-flashing">
      <div className="stage">
        <div className="dot-flashing"></div>
      </div>
    </DotsContainer>
  )
}

export default LoadingDots