import React, { useContext, useState, useEffect } from 'react'
import Modal from '../../../components/modal/Modal'
import { EmployeeSubPageContext } from '../EmployeeSubPage'
import { EmployeesContext } from '../Employees'
import styled from 'styled-components'
import { Grid } from '@mui/material'
// import EmployeeInfo from './EmployeeInfo'
import StyledLabel from '../../../components/styledLabel/StyledLabel'
import { Button, Delete } from '../../../components/button/Button'
import { selectedDatePlus60Days } from '../../../utils/helper'
import { useLocation } from 'react-router-dom'
import {
  editSurveyOrEducation,
  deleteSurveyOrEducation,
} from '../services/crud'

import { useTranslation } from 'react-i18next'; // Importing the useTranslation hook

const StyledModal = styled.div`
  /* ... (unchanged) ... */
`

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`

const EditEducation = () => {
  // ---------- Contexts ----------
  const {
    employee,
    employee: { professionalRoleName },
    editEducationModal,
    setEditEducationModal,
    loadOldStates,
    addBtnClicked,
    selectedEducation,
  } = useContext(EmployeeSubPageContext)
  const { ongoing, setOngoing } = useContext(EmployeesContext)

  const { employeeNumber, firstName, lastName } = employee
  // ---------- States ----------
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  // eslint-disable-next-line
  const [educationToEditSelected, setEducationToEditSelected] = useState(
    JSON.parse(localStorage.getItem('employeeOngoingEducationSelected')) || ''
  )
  const [error, setError] = useState({ fromDate: false, education: false })
  const { catalogQualification: locationCatalogQualification } =
    useLocation().state || {}

  const [catalogQualification, setCatalogQualification] = useState(
    addBtnClicked ? null : locationCatalogQualification
  )
  // eslint-disable-next-line
  const [price, setPrice] = useState(Number('0.00'))
  // eslint-disable-next-line
  const [
    selectedQualificationByOrganisationToAdd,
    setSelectedQualificationByOrganisationToAdd,
  ] = useState(
    loadOldStates && !catalogQualification
      ? JSON.parse(
          localStorage.getItem('employeeSubAddSurveyQualificationByOrg')
        )
      : {
          value: '',
          label: 'Välj kartläggning',
        }
  )
  const [selectedSurveyFromDate, setSelectedSurveyFromDate] = useState(
    educationToEditSelected.startDate
  )
  const [selectedSurveyToDate, setSelectedSurveyToDate] = useState(
    educationToEditSelected?.startDate &&
      selectedDatePlus60Days(selectedSurveyFromDate)
  )

  const { t } = useTranslation(); // Initializing the useTranslation hook

  // ---------- useEffects ----------
  // Qualification can only be selected from one source either by "role" or by "organisation" or "Catalog"
  useEffect(() => {
    localStorage.removeItem('employeeSubResetOnReload2')
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'employeeSubSelectedSurveyFromDate',
      selectedSurveyFromDate
    )
  }, [selectedSurveyFromDate])

  useEffect(() => {
    localStorage.setItem(
      'employeeSubAddSurveyQualificationByOrg',
      JSON.stringify(selectedQualificationByOrganisationToAdd)
    )
    if (selectedQualificationByOrganisationToAdd?.value) {
      setCatalogQualification(null)
    }
  }, [selectedQualificationByOrganisationToAdd])

  useEffect(() => {
    if (catalogQualification?.education) {
      setError({ ...error, education: false })
    }
    if (selectedQualificationByOrganisationToAdd?.value)
      setError({ ...error, education: false })
    // eslint-disable-next-line
  }, [
    catalogQualification?.educationName,
    selectedQualificationByOrganisationToAdd?.value,
  ])

  return (
    <StyledModal>
      <Modal modalStyle={{maxWidth: '50vw'}} setState={setEditEducationModal} title={t('pageEmployees.editEducation.title')}>
        <Grid container columns={24}>
          {/** ---------- Person ---------- */}
          <Grid style={{ marginTop: 20}} item xs={24} className='person'>
            {/* <EmployeeInfo /> */}
            <span style={{ marginRight: 5}} >{employeeNumber}</span>

            <span style={{ marginRight: 5}}>
              {firstName} {lastName}
            </span>
            <span>{professionalRoleName}</span>
          </Grid>

          {/** ---------- Info-text ---------- */}
          <Grid item xs={24}>
            <Grid item xs={24} className='info-text'>
              <p style={{ margin: "20px 0px"}}>
                {t('pageEmployees.editEducation.infoText')}
              </p>
            </Grid>
          </Grid>

          {/** ---------- Right Columns ---------- */}
          <Grid item xs={12} px={0}>
            <Grid item xs={24}>
              <div className='survey-wrapper'>
                <FlexContainer>
                <StyledLabel>
                  <p>{t('pageEmployees.editEducation.selectedEducation')}</p>
                  <input
                    value={
                      selectedEducation?.educationName ||
                      catalogQualification?.educationName ||
                      (selectedQualificationByOrganisationToAdd?.value &&
                        selectedQualificationByOrganisationToAdd.label)
                    }
                    type='text'
                    readOnly
                  />
                </StyledLabel>
                <span style={{marginTop: 25}} className='price'>{price.toFixed(2)} {t('common.currency')}</span>
                </FlexContainer>
              </div>

              <div className='survey-date-wrapper'>
                <FlexContainer>
                <div className='start-date'>
                  <StyledLabel>
                    <p>
                      {t('pageEmployees.editEducation.startDate')}
                      {error.fromDate && (
                        <span style={{ color: 'red' }}>{t('pageEmployees.editEducation.selectDate')}</span>
                      )}
                    </p>
                    <input
                      value={selectedSurveyFromDate}
                      onChange={(e) => {
                        const regex = new RegExp(
                          /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
                        )
                        if (regex.test(e.target.value)) {
                          setSelectedSurveyFromDate(e.target.value)
                          setSelectedSurveyToDate(
                            (prev) =>
                              (prev = selectedDatePlus60Days(e.target.value))
                          )
                        }
                      }}
                      min={new Date().toISOString().split('T')[0]}
                      id='date'
                      type='date'
                    />
                  </StyledLabel>
                </div>

                <div className='end-date'>
                  <StyledLabel>
                    <p>{t('pageEmployees.editEducation.endDate')}</p>
                    <input
                      value={selectedSurveyToDate}
                      readOnly
                      type='text'
                      style={{ maxWidth: '90%' }}
                    />
                  </StyledLabel>
                </div>
                </FlexContainer>
                

                <div className='survey-date-info-text'>
                  <p>
                    {t('pageEmployees.editEducation.surveyAccessInfo')}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 20 }} item xs={24} className='add-btn '>
            
            <Delete
              style={{ marginRight: 20 }}
              onClick={() => setShowDeleteConfirmModal(true)}
            >
              {t('buttons.removeButton')}
            </Delete>
            <Button
              onClick={(e) => {
                selectedSurveyFromDate !== null &&
                  editSurveyOrEducation({
                    editEducationModal,
                    price,
                    startDate: selectedSurveyFromDate,
                    id: selectedEducation.id,
                    setEditEducationModal,
                  }).then((data) => {
                    let newOngoing = [...ongoing]

                    const indexOfSelected = ongoing.findIndex(
                      (survey) => survey.id === selectedEducation.id
                    )
                    newOngoing[indexOfSelected] = data

                    setOngoing(newOngoing)

                    setEditEducationModal(false)
                    localStorage.removeItem(
                      'employeeSubAddSurveyQualificationByOrg'
                    )
                  })
              }}
            >
              {t('buttons.saveButton')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      {showDeleteConfirmModal && (
        <Modal
          setState={setShowDeleteConfirmModal}
          title={t('pageEmployees.editEducation.confirmDeleteTitle')}
          modalStyle={{ width: '500px' }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={12} py={2}>
                <div>
                  <p>
                    {t('pageEmployees.editEducation.confirmDeleteText', {
                      educationName: selectedEducation.educationName,
                      employeeDetails: `${employeeNumber} ${firstName} ${lastName} ${professionalRoleName}`
                    })}
                  </p>
                  <br />
                  <p>
                    {t('pageEmployees.editEducation.confirmDeleteChargeInfo')}
                  </p>
                </div>
              </Grid>
            <Grid style={{ display: "flex", marginBottom: 20, justifyContent: "flex-end"}} item xs={12} className='add-btn '>
                <Button
                  onClick={(e) => {
                    setShowDeleteConfirmModal(false)
                  }}
                >
                  {t('buttons.cancelButton')}
                </Button>
                <Delete
                  style={{ margin: '0 20px' }}
                  onClick={() => {
                    deleteSurveyOrEducation({
                      id: selectedEducation.id,
                      setOngoing,
                    })
                    setEditEducationModal(false)
                  }}
                >
                  {t('buttons.removeButton')}
                </Delete>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      )}
    </StyledModal>
  )
}

export default EditEducation
