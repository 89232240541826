import React, { Fragment, useContext, useState } from 'react'

import { Button } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import StyledLabel from '../../../../components/styledLabel/StyledLabel'
import { addRequirement, handleAddFormChange } from '../../functions'
import { AreaContext } from '../QualificationAreas'
import { Wrapper } from '../Style'
import { useTranslation } from 'react-i18next'

const AddRequirement = () => {
  const { t } = useTranslation();

  const initializedAddFormData = {
    description: '',
    published: 0,
    modified: 1,
    passQuestions: Number(),
    totalQuestions: Number(),
  }

  const [addFormData, setAddFormData] = useState(initializedAddFormData)

  const {
    modalAddIsVisible,
    dispatchModalAddIsVisible,
    areaList,
    setAreaList,
    selectedArea,
  } = useContext(AreaContext)

  return (
    <Fragment>
      <Modal
        title={t('pageQualifications.modalAddRequirement.title')}
        dispatcher={{ modalAddIsVisible, dispatchModalAddIsVisible }}
      >
        <Wrapper>
          <form
            onSubmit={(e) =>
              addRequirement(e, {
                selectedArea,
                modalAddIsVisible,
                addFormData,
                areaList,
                setAreaList,
                dispatchModalAddIsVisible,
              })
            }
          >
            <StyledLabel>
              <p>{t('pageQualifications.modalAddRequirement.descriptionLabel')}</p>
              <textarea
                name='description'
                className='textarea'
                required='required'
                rows='5'
                placeholder={t('pageQualifications.modalAddRequirement.descriptionPlaceholder')}
                onChange={(e) =>
                  handleAddFormChange(e, addFormData, setAddFormData)
                }
              ></textarea>
            </StyledLabel>

            <div className='questions'>
              <StyledLabel>
                <p>{t('pageQualifications.modalAddRequirement.totalQuestionsLabel')}</p>
                <input
                  type='number'
                  required='required'
                  onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === 'E') e.preventDefault()
                  }}
                  onChange={(e) =>
                    handleAddFormChange(e, addFormData, setAddFormData)
                  }
                  name='totalQuestions'
                  id='totalQuestions'
                />
              </StyledLabel>
              <StyledLabel>
                <p>{t('pageQualifications.modalAddRequirement.passQuestionsLabel')}</p>
                <input
                  type='number'
                  required='required'
                  onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === 'E') e.preventDefault()
                  }}
                  onChange={(e) =>
                    handleAddFormChange(e, addFormData, setAddFormData)
                  }
                  name='passQuestions'
                  id='passQuestions'
                />
              </StyledLabel>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '30px',
              }}
            >
              <Button>{t('pageQualifications.modalAddRequirement.addButton')}</Button>
            </div>
          </form>
        </Wrapper>
      </Modal>
    </Fragment>
  )
}

export default AddRequirement
