import { Auth } from "aws-amplify";
import React, { useContext, useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router";

import Organisations from "./organisations/Organisations";
import Employees from "./Employees/Employees";
import Home from "./home/Home";
import Profile from "./profile/Profile";
import Catalog from "../pages/catalog/Catalog";
import Qualifications from "./qualifications/qualification/Qualification";
import { Context } from "../utils/Context";
import CompetencePlanning from "./competencePlanning/professionalRole/CompetencePlanning";
import Educations from "./educations/Educations";
import CompetenceProfile from "./competenceProfile/CompetenceProfile";

import { simpleUser } from "../utils/Amplify";
import jwt_decode from "jwt-decode";

const PrivateRoute = () => {
  const history = useHistory();

  const location = useLocation();

  const { organisation } = useContext(Context);

  const checkAuthState = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      const decodedToken = jwt_decode((await simpleUser()).token);
      /* Commenting out the check for cognito groups, as they should have access to certain urls such as /home.
      if (!(Array.isArray(decodedToken['cognito:groups']) && decodedToken['cognito:groups'].includes('hon'))) {
        history.push('/')
      }
      */
    } catch (err) {
      history.push("/login");
    }
  };

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/signup" ||
      location.pathname.includes("/catalog")
    ) {
    } else {
      checkAuthState();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <Route path="/home">
        <Home />
      </Route>
      <Route path="/catalog" component={Catalog} />
      <Route path="/profile">
        <Profile />
      </Route>
      <Route path="/competence-profile">
        {organisation && <CompetenceProfile />}
      </Route>
      {organisation && (
        <>
          <Route path="/employees">
            <Employees />
          </Route>
          <Route exact path="/employees/organisationId/:organisationId">
            <Employees />
          </Route>
          <Route path="/competence-planning">
            <CompetencePlanning />
          </Route>
          <Route path="/qualifications">
            <Qualifications />
          </Route>
          <Route path="/educations">
            <Educations />
          </Route>
          <Route path="/organisations">
            <Organisations />
          </Route>
        </>
      )}
    </>
  );
};

export default PrivateRoute;
