// MuiTable
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Link, Route, useRouteMatch, useHistory } from 'react-router-dom'

import PageTitleSpecial from '../../../components/pageTitle/PageTitleSpecial'
import { Context } from '../../../utils/Context'
import { getSelectedRoleQualifications } from '../services/Crud'
import { PROFILE, VIEWSUBQUALIFICATION } from '../services/routes'
import { Wrapper } from '../style'
import ViewSubQualifications from './components/ViewSubQualifications'
import ModalBindUnbindQualification from './ModalBindUnbindQualification'

import { ProfessionalRoleContext } from '../professionalRole/CompetencePlanning'
import { AddButton } from '../../../components/button/Button'
import BreadCrumbs from '../../../utils/BreadCrumbs'
import { useTranslation } from 'react-i18next'

export const CompetenceProfileContext = createContext()
const Profile = () => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const [selectedRoleQualifications, setSelectedRoleQualifications] = useState(
    []
  )
  const [selectQualificationToUnbind, setSelectQualificationToUnbind] =
    useState({})
  const [modalAddIsVisible, setModalAddIsVisible] = useState(false)
  const [modalDeleteIsVisible, setModalDeleteIsVisible] = useState(false)
  const [selectedQualification, setSelectedQualification] = useState(
    JSON.parse(localStorage.getItem('selectedRoleQualification')) !== null ? JSON.parse(localStorage.getItem('selectedRoleQualification')) : []
  )

  const {
    selectedRole,
    selectedRole: { id: roleId, professionalRoleName, roleDescription },
  } = useContext(ProfessionalRoleContext)

  const {
    organisation: { organisationNumber, orgName },
  } = useContext(Context)

  useEffect(() => {
    let isLoading = true
    getSelectedRoleQualifications({ organisationNumber, roleId }).then(
      (data) => {
        if (isLoading) {
          if (data.length > 0) {
            setSelectedRoleQualifications(data)
          }
        }
      }
    )

    return () => {
      isLoading = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (selectedQualification !== null && selectedQualification.length > 0) {
      localStorage.setItem(
        'selectedRoleQualification',
        JSON.stringify(selectedQualification)
      )
    }
  }, [selectedQualification])

  const initialContext = {
    organisationNumber,
    professionalRole: { roleId, professionalRoleName, roleDescription },
    selectQualificationToUnbind,
    setSelectQualificationToUnbind,
    selectedRoleQualifications,
    setSelectedRoleQualifications,
    modalAddIsVisible,
    setModalAddIsVisible,
    modalDeleteIsVisible,
    setModalDeleteIsVisible,
    selectedQualification,
    setSelectedQualification,
  }

  const history = useHistory()

  return (
    <CompetenceProfileContext.Provider value={initialContext}>
      <Route exact path={PROFILE}>
        <Wrapper className='wrapper-prs'>
          {modalAddIsVisible && <ModalBindUnbindQualification option='add' />}
          {modalDeleteIsVisible && (
            <ModalBindUnbindQualification option='delete' />
          )}
          <BreadCrumbs custom />
          <p className='page-title'>{t('competencePlanning.profile.pageTitle')}</p>
          <br />
          <PageTitleSpecial
            title={professionalRoleName}
            info={roleDescription}
            description={
              <Fragment>
                <br />
                <p>
                  {t('competencePlanning.profile.info1')}
                </p>
                <br />
                <p>
                {t('competencePlanning.profile.info2')}
                </p>
                <br />
                <p>
                {t('competencePlanning.profile.info3')}{t('competencePlanning.profile.info4')}
                </p>
                <br />
                <p>
                {t('competencePlanning.profile.info5')}
                </p>
                <br />
                <p>
                {t('competencePlanning.profile.info6')}
                </p>
                <br />
              </Fragment>
            }
          />
          <div style={{ margin: '50px 0' }}>
            <br />
            <h5 className='bold'>{t('competencePlanning.profile.catalogueHeader')}</h5>
            <TableContainer>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ paddingBottom: 0 }}>{t('common.name')}</TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='left'>
                      {t('common.language')}
                    </TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='left'>
                      {t('competencePlanning.profile.businessArea')}
                    </TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='left'>
                      {t('common.type')}
                    </TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='left'>
                      {t('common.level')}
                    </TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='center'>
                      {t('common.eqfLevel')}
                    </TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='center'>
                      Ver
                    </TableCell>
                    <TableCell
                      sx={{ paddingBottom: 0 }}
                      align='center'
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRoleQualifications &&
                    selectedRoleQualifications
                      .filter(({ author }) => author !== orgName)
                      .map((qualification) => {
                        return (
                          <TableRow key={qualification.id}>
                            <TableCell sx={{ maxWidth: '200px' }}>
                              <Link
                                onClick={() => {
                                  setSelectedQualification(qualification)
                                }}
                                to={{
                                  pathname: `${url}/${qualification.qualificationName}`,
                                }}
                              >
                                {qualification.qualificationName}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {qualification.qualificationLanguage}
                            </TableCell>
                            <TableCell>
                              {qualification.qualificationArea}
                            </TableCell>
                            <TableCell>
                              {qualification.qualificationType}
                            </TableCell>
                            <TableCell>
                              {qualification.qualificationAccess}
                            </TableCell>
                            <TableCell align='center'>
                              {qualification.qualificationEqf}
                            </TableCell>
                            <TableCell align='center'>
                              {qualification.version}
                            </TableCell>
                            <TableCell>
                              <p
                                className='pointer'
                                onClick={(e) => {
                                  setSelectQualificationToUnbind(qualification)
                                  setModalDeleteIsVisible(true)
                                }}
                              >
                                {t('common.remove')}
                              </p>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  <TableRow>
                    <TableCell
                      sx={{ borderBottom: 'none' }}
                    >
                      <AddButton
                        onClick={() => {
                          history.push({
                            pathname: '/catalog',
                            state: {
                              professionalRole: selectedRole,
                              prevPath: url,
                              includeResults: ['qualifications'],
                            }
                          })
                        }}
                        className='add-button' />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div>
            <br />
            <h5 className='bold'>{t('competencePlanning.profile.individualQualifications')}</h5>
            <TableContainer>
              <Table sx={{ maxWidth: '100%' }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ paddingBottom: 0 }}>
                      {t('common.name')}
                    </TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='left'>
                      {t('common.language')}
                    </TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='left'>
                      {t('competencePlanning.profile.businessArea')}
                    </TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='left'>
                      {t('common.type')}
                    </TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='left'>
                      {t('common.level')}
                    </TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='center'>
                      {t('common.eqfLevel')}
                    </TableCell>
                    <TableCell sx={{ paddingBottom: 0 }} align='center'>
                      Ver
                    </TableCell>
                    <TableCell
                      sx={{ paddingBottom: 0 }}
                      align='center'
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    /** .filter(({ author }) => author === orgName) */
                    selectedRoleQualifications &&
                    selectedRoleQualifications
                      .filter(({ author }) => author === orgName)
                      .map((qualification) => {
                        return (
                          <TableRow key={qualification.id}>
                            <TableCell sx={{ maxWidth: '200px' }}>
                              <Link
                                onClick={() => {
                                  setSelectedQualification(qualification)
                                }}
                                to={{
                                  pathname: `${url}/${qualification.qualificationName}`,
                                }}
                              >
                                {qualification.qualificationName}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {qualification.qualificationLanguage}
                            </TableCell>
                            <TableCell>
                              {qualification.qualificationArea}
                            </TableCell>
                            <TableCell>
                              {qualification.qualificationType}
                            </TableCell>
                            <TableCell>
                              {qualification.qualificationAccess}
                            </TableCell>
                            <TableCell align='center'>
                              {qualification.qualificationEqf}
                            </TableCell>
                            <TableCell align='center'>
                              {qualification.version}
                            </TableCell>
                            <TableCell>
                              <p
                                className='pointer'
                                onClick={(e) => {
                                  setSelectQualificationToUnbind(
                                    qualification
                                  )
                                  setModalDeleteIsVisible(true)
                                }}
                              >
                                {t('common.remove')}
                              </p>
                            </TableCell>
                          </TableRow>
                        )
                      })
                  }
                  <TableRow>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      <AddButton
                        className='add-button'
                        onClick={(e) => setModalAddIsVisible(true)}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Wrapper>
      </Route>
      <Route path={VIEWSUBQUALIFICATION} component={ViewSubQualifications} />
    </CompetenceProfileContext.Provider>
  )
}

export default Profile
