import { Auth } from 'aws-amplify'
// eslint-disable-next-lin
import { CognitoIdentityServiceProvider } from 'aws-sdk'

import { emailIsValid, passwordIsValid } from './Validation'

export const signOut = async () => {
  try {
    await Auth.signOut()
    localStorage.removeItem('individual')
    localStorage.removeItem('groups')
    localStorage.removeItem('organisation')
    localStorage.removeItem('latestPath')
    localStorage.removeItem('mode')
  } catch (error) {
    console.error('Error sign out: ', error)
  }
}

export const checkAuthState = async () => {
  try {
    await Auth.currentAuthenticatedUser()
  } catch (err) {
    console.log('false')
    return false
  }
  return true
}

//Easy access to logged-in user data
export const simpleUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return {
      firstName: await user.attributes['custom:firstName'],
      lastName: await user.attributes['custom:lastName'],
      phoneNumber: await user.attributes['custom:phoneNumber'],
      email: await user.attributes.email,
      groups: await user.signInUserSession.accessToken.payload[
        'cognito:groups'
      ],
      token: await user.signInUserSession.accessToken.jwtToken,
    }
  } catch (err) {
    if (err === 'The user is not authenticated') {
      return null
    }
  }
}

// Send to Backend
export const backendSignup = async (creds) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(creds),
    headers: {
      'Content-Type': 'application/json'
    },
  }

  fetch(`${process.env.REACT_APP_BACKEND_URL}/signup`, options)
}

export const signUp = async (
  fName,
  lName,
  email,
  reEmail,
  password,
  rePassword
) => {
  let formData = {}
  if (
    (fName.length !== 0) &
    (lName.length !== 0) &
    emailIsValid(email) &
    (reEmail === email) &
    passwordIsValid(password) &
    (rePassword === password)
  ) {
    formData = { firstName: fName, lastName: lName, email, password }
  }

  if (Object.keys(formData).length > 0) {
    try {
      // Amplify Signup
      const { user } = await Auth.signUp({
        username: formData.email,
        password: formData.password,
        attributes: {
          'custom:firstName': formData.firstName,
          'custom:lastName': formData.lastName,
          'custom:phoneString': '',
        },
      })

      // Backend Signup
      backendSignup({
        firstName: fName,
        lastName: lName,
        email,
      })

      return user
    } catch (err) {
      console.log('error signing up:', err)
      return err
    }
  }
}

export const adminSignUp = async (fName, lName, email, reEmail) => {
  const cognitoProvider = await new CognitoIdentityServiceProvider({
    region: 'eu-west-1',
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
  })

  if (
    (fName.length !== 0) &
    (lName.length !== 0) &
    emailIsValid(email) &
    (reEmail === email)
  ) {
    const params = {
      UserPoolId: 'eu-west-1_o9YCdc0KB' /* required */,
      Username: email,
      DesiredDeliveryMediums: ['EMAIL'],
      UserAttributes: [
        {
          Name: 'custom:firstName' /* required */,
          Value: fName,
        },
        {
          Name: 'custom:lastName' /* required */,
          Value: lName,
        },
        {
          Name: 'custom:phoneString' /* required */,
          Value: '',
        },
      ],
    }
    const user = await cognitoProvider.adminCreateUser(
      params,
      async (err, data) => {
        if (err) {
          console.log(err)
          return err
        } else {
          backendSignup({
            firstName: fName,
            lastName: lName,
            email,
          })

          return data.User
        }
      }
    )
    return user
  }
}

export const createNewPassword = async (email, password, session) => {
  const cognitoProvider = new CognitoIdentityServiceProvider({
    region: 'eu-west-1',
    credentials: {
      sessionToken: session,
    },
  })

  const params = {
    ChallengeName: 'NEW_PASSWORD_REQUIRED' /* required */,
    ClientId: process.env.REACT_APP_ENV === "development" ? '6o0akvscb7o51r0ieglfannpn0': "4og74g894psddhv9p3eqpp1m79" /* required */,
    ChallengeResponses: {
      USERNAME: email,
      NEW_PASSWORD: password,
    },
    Session: session,
  }

  const res = cognitoProvider.respondToAuthChallenge(params, (err, data) => {
    if (err) {
      console.log(err, err.stack)
      return err
    } else {
      console.log(data.AuthenticationResult.AccessToken)
      return data.AuthenticationResult.AccessToken
    }
  })
  return res
}
