import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { Wrapper } from './style'

import DropdownArrow from '../../assets/img/button-arrow-dark.svg'
import { collapseSection, expandSection } from '../../utils/Misc'

/**
 * @param {linkTo} param0 optional: makes PageTitle into a react router dom link to value of linkTo
 * @param {title} param1: self-explanatory
 * @param {children} param2: put descriptions and the like here. this is the collapsable part of the page title.
*/
const PageTitle = ({ linkTo, title, children }) => {
  const [showInfo, setShowInfo] = useState(
    localStorage.getItem(`expand-${title}`) === 'false' ? false : true
  )

  const descDiv = useRef(null)

  useEffect(() => {
    if (descDiv.current) {
      if (showInfo) {
        expandSection(descDiv.current)
      } else {
        collapseSection(descDiv.current)
      }
    }
    // eslint-disable-next-line
  }, [showInfo, descDiv])

  return (
    <Wrapper className='page-title'>
      <div className='title'>
        {linkTo ? (
          <Link to={linkTo}>
            <h3>{title}</h3>
          </Link>
        ) : (
          <h3>{title}</h3>
        )}
        {children && (
          <img
            src={DropdownArrow}
            alt='dropdown arrow'
            onClick={() => {
              localStorage.setItem(`expand-${title}`, !showInfo)
              setShowInfo(!showInfo)
            }}
            className={showInfo ? 'open' : 'closed'}
          />
        )}
      </div>
      {children && (
        <>
          <div
            ref={descDiv}
            className={`info ${showInfo ? 'show-info' : 'hide-info'}`}
          >
            {children}
          </div>
          <hr />
        </>
      )}
    </Wrapper>
  )
}

export default PageTitle
