import styled from 'styled-components'

export const Wrapper = styled.main`
  margin-top: 43px;
  .share-active {
    outline: 2px solid yellow;
    border-radius: 100%;
  }
  .MuiTableContainer-root {
    margin: 50px 0;
  }
  label {
    margin-bottom: 10px;
  }
  .modal {
    .textarea {
      margin-top: 2px;
      height: 127px;
      resize: none;
    }
    width: 600px;
    & form {
      padding: 30px 0;
      label {
        display: flex;
        flex-direction: column;
        font-size: 1.2em;
      }
    }
  }
  .table-wrapper {
    margin-top: 54px;
    h4 {
      margin-bottom: 26px;
    }
    /* Last row inside the table */
    .MuiTableBody-root > :last-child {
      border-bottom: 1px solid #cfcfd5;
    }
  }

  .area-flex {
    display: flex;
    justify-content: space-between;
  }

  .add-button {
    &:not(.collapse) {
      margin-top: 20px;
    }

    .left-2 {
      left: 2px;
    }
  }

  /* Inside modal for adding qualifications */
  .language-container {
    display: flex;
    align-items: center;
    > p {
      margin-right: 10px;
    }
  }
  .modal-btn {
    display: flex;
    justify-content: flex-end;
  }
  .first_btn-margin {
    margin-right: 17px;
  }

  /* Qualification.js */
  .un-subscribe {
    color: #c7c7c7;
    font-size: 1em;
    font-weight: bold;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .darker {
    background: rgba(0, 0, 0, 0.05);
  }
  .MuiTableCell-root {
    border: none;
    padding: 0;
    /* border-bottom: none; */
  }
  .share {
    padding: 10px;
    min-height: 300px;
    nav {
      ul {
        display: flex;
        li:not(:first-child) {
          padding: 0 5px;
          margin: 0 5px;
          border-left: 1px solid grey;
        }
      }
    }
    .m-top-btm-20 {
      margin: 20px 0;
    }
    .flex {
      display: flex;
    }
    .align-button {
      align-items: flex-end;
    }
    .sb {
      justify-content: space-between;
    }
    .border-btm {
      border-bottom: 1px solid grey;
      padding-bottom: 20px;
    }
    .land .date {
      flex-direction: column;
    }
    .land {
      width: calc(50% - 30px);
    }
    .date {
      width: calc(50% - 30px);
    }
    .title {
      font-weight: bold;
    }
    .item,
    .title {
      border-bottom: 1px solid grey;
      padding: 3px 0;
    }
  }
  .un-publish-msg-wrapper {
    display: flex;
    flex-direction: column;
    > p {
      padding-top: 20px;
    }
    > div {
      display: flex;
      justify-content: flex-end;
    }
  }
  .area-table-container {
    margin-bottom: 10px;
    padding: 5px 0;
    > img {
      padding-top: 3px;
    }
  }

  .area-table-container:not(:last-child) {
    border-bottom: 1px solid #cfcfd5;
  }

  .modal-content-body {
    padding: 20px;
  }
`
