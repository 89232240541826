import { simpleUser } from "./../../utils/Amplify";

export const getProfessionalRoles = async (orgNum) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/organisation/${orgNum}/all/professionalrole`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await simpleUser()).token,
      },
    }
  );
  const data = await response.json();
  return data;
};

export const getOrganisationById = async (orgID) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/organisation/${orgID}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await simpleUser()).token,
      },
    }
  );
  const data = await response.json();
  return data;
};

export const saveOrganisation = async (formData) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/organisation/updateOrg/${formData.id}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await simpleUser()).token,
      },
      body: JSON.stringify({
        orgName: formData.orgName,
        city: formData.city,
        country: formData.country,
        organisationNumber: formData.organisationNumber,
        subscription: formData.subscription,
      }),
    }
  );
  const data = await response.json();
  return data;
};

export const addOrganisation = async (formData) => {
  const body = JSON.stringify({
    orgName: formData.name,
    city: formData.city,
    country: formData.country,
    subscription: formData.subscription,
    organisationNumber: formData.organisationNumber,
    language: "Swedish",
    levelOne: "Enhet", // TODO Use defaults in backend
    levelTwo: "Division",
    levelThree: "Avdelning",
    levelFour: "Team",
  });
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/organisation/createOrganisation`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await simpleUser()).token,
      },
      body: body,
    }
  );
  const data = await response.json();
  return data;
};

export const checkOrganisationExists = async (organisationNumber) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationNumber}/get`
    );
    const data = await response.json();
    if (response.ok) {
      return true;
    } else if (response.status === 404) {
      return false;
    }
  } catch (error) {
    console.error("Error checking organisation existence:", error);
    return true;
  }
};

export const checkOrganisationExistsAllowSelf = async (
  organisationNumber,
  organisationId
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationNumber}/get`
    );
    const data = await response.json();
    if (response.ok && data.id !== organisationId) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error checking organisation existence:", error);
    return false;
  }
};
