import { Grid } from '@mui/material'
import { useContext } from 'react'
import React, { createContext, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min'

import { ReactComponent as LoadingSpinner } from '../../../assets/img/spinner.svg'
import { LinkRight } from '../../../components/button/Button'
import PageTitleSpecial from '../../../components/pageTitle/PageTitleSpecial'
import { QualificationWrapper } from '../../../pages/catalog/subPages/style'
import { getSubQualifications } from '../../../private/qualifications/functions'
import BreadCrumbs from '../../../utils/BreadCrumbs'
import { getSubQualificationList } from '../../competencePlanning/services/Crud'
import { EmployeeSubPageContext } from '../EmployeeSubPage'
import { getSurvey } from '../services/crud'
import QualificationAreas from './QualificationAreas'
import { simpleUser } from './../../../utils/Amplify'

const SubQualifications = () => {
  const SubContext = createContext(null)
  const { selectedSurveyID, selectedUncompletedSurveyID } = useContext(
    EmployeeSubPageContext
  )

  const [qualification, setQualification] = useState({})
  const [qualLoading, setQualLoading] = useState(true)

  const [subQualifications, setSubQualifications] = useState([])
  const [subQualLoading, setSubQualLoading] = useState(true)

  const [selectedSubQualification, setSelectedSubQualification] = useState(
    JSON.parse(localStorage.getItem('employeeSelectedSubQualification'))
  )
  useEffect(() => {
    localStorage.setItem(
      'employeeSelectedSubQualification',
      JSON.stringify(selectedSubQualification)
    )
  }, [selectedSubQualification])

  useEffect(() => {
    let loaded = true

    const getQualsAndSubQuals = async () => {
      let qualRes
      let subQuals

      if (selectedSurveyID) {
        qualRes = await getSurvey(selectedSurveyID).then(
          (res) => res.qualification
        )
        subQuals = await getSubQualifications(qualRes.id)
      } else {
        qualRes = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/organisation/qualification/get/${selectedUncompletedSurveyID}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + (await simpleUser()).token,
            },
          }
        ).then((res) => res.json())
        subQuals = await getSubQualificationList(selectedUncompletedSurveyID)
      }

      if (loaded) {
        setQualification(qualRes)
        setQualLoading(false)
        setSubQualifications(subQuals)
        setSubQualLoading(false)
      }
    }
    getQualsAndSubQuals()

    return () => {
      loaded = false
    }
    // eslint-disable-next-line
  }, [])

  const { qualificationName, qualificationDescription } = qualification

  const initialContext = { subQualifications, setSubQualifications }
  const { url } = useRouteMatch()

  return (
    <SubContext.Provider value={initialContext}>
      <Switch>
        <Route exact path='/employees/:name/:qualification'>
          <QualificationWrapper className='sub-page-wrapper'>
            <Grid container item xs={12}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <BreadCrumbs custom />
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} py={1}>
                  <p className='pre-title'>
                    DELKVALIFIKATIONER OCH KOMPETENSOMRÅDEN
                  </p>
                </Grid>
                {/** -------------------------------- Qualification section -------------------------------- */}
                {qualification && subQualifications ? (
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className='qualification'
                  >
                    <Grid container item xs={12} mb={3}>
                      <PageTitleSpecial
                        className='qualification-title'
                        title={qualificationName}
                        info={qualificationDescription}
                      />
                    </Grid>

                    {/** -------------------------------- Sub-qualification section -------------------------------- */}

                    <Grid container item xs={12} className='sub-qualifications'>
                      {/** Table Head */}
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        className='table-head'
                      >
                        <Grid item xs={6}>
                          <h4>Delkvalifikation</h4>
                        </Grid>
                        <Grid item xs={6}>
                          <h4>Kompetensområde</h4>
                        </Grid>
                      </Grid>

                      {/** Table Body */}
                      <Grid container item xs={12} pt={2}>
                        {subQualifications &&
                          subQualifications.map((sub) => {
                            const {
                              description,
                              id,
                              name: subName,
                              qualificationArea,
                            } = sub
                            return (
                              <Grid
                                key={id}
                                container
                                item
                                xs={12}
                                pt={2}
                                spacing={2}
                                className='table-body'
                              >
                                <Grid item xs={6}>
                                  <p className='title bold'>{subName}</p>
                                  <p>{description}</p>

                                  {/** Sub Description */}
                                </Grid>

                                <Grid item xs={6}>
                                  {/* -------- Areas -------- */}
                                  {qualificationArea &&
                                    qualificationArea.map(
                                      ({ description, id, name: areaName }) => (
                                        <Grid
                                          key={id}
                                          container
                                          item
                                          xs={12}
                                          className='areas'
                                        >
                                          <Grid item xs={12}>
                                            <Grid pb={2}>
                                              <p className='area-title bold title'>
                                                {areaName}
                                              </p>
                                              <p className='area-body'>
                                                {description}
                                              </p>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )
                                    )}

                                  <Grid item xs={12} align='right'>
                                    <LinkRight
                                      onClick={() => {
                                        setSelectedSubQualification(sub)
                                      }}
                                      to={`${url}/${sub.name}?type=qualification`}
                                    >
                                      Mer...
                                    </LinkRight>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  qualLoading &&
                  subQualLoading && <LoadingSpinner width={300} height={300} />
                )}
              </Grid>
            </Grid>
          </QualificationWrapper>
        </Route>
        <Route path='/employees/:name/:qualification/:subQual'>
          {selectedSubQualification ? (
            <QualificationAreas
              selectedSubQualification={selectedSubQualification}
            />
          ) : subQualLoading ? (
            <LoadingSpinner width={300} height={300} />
          ) : (
            'Ingen delkvalifikation hittades'
          )}
        </Route>
      </Switch>
    </SubContext.Provider>
  )
}

export default SubQualifications
