import { TrendingUpOutlined } from '@mui/icons-material'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Route, Switch, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import { Button, Delete } from '../../components/button/Button'
import Modal from '../../components/modal/Modal'
import PageTitle from '../../components/pageTitle/PageTitle'
import { freshPerms } from '../../utils/Backend'
import BreadCrumbs from '../../utils/BreadCrumbs'
import {
  getAllQualificationsByOrganisation,
  getSelectedRoleQualifications,
} from '../competencePlanning/services/Crud'
import { getAllEducations } from './../educations/services/Crud'
import { EmployeesContext } from './Employees'
import { getQualificationSurvey } from './services/crud'
import { SubPageWrapper } from './style'
import AddEducationSurvey from './subPageComponents/AddEducation'
import AddSurvey from './subPageComponents/AddSurvey'
import CompletedEducations from './subPageComponents/CompletedEducations'
import CompletedSurveys from './subPageComponents/CompletedSurveys'
import EditEducation from './subPageComponents/EditEducation'
import EditSurvey from './subPageComponents/EditSurvey'
import EmployeeInfo from './subPageComponents/EmployeeInfo'
import OngoingEducations from './subPageComponents/OngoingEducations'
import OngoingSurveys from './subPageComponents/OngoingSurveys'
import QualEduHub from './subPageComponents/QualEduHub'
import UnCompletedSurvey from './subPageComponents/UnCompletedSurvey'
export const EmployeeSubPageContext = createContext()

const EmployeeSubPage = ({
  employee,
  organisation,
  individual,
  individual: { id: individualId },
}) => {
  const { ongoing, row, setOngoing, completed, setCompleted } =
    useContext(EmployeesContext)
  const history = useHistory()
  const location = useLocation()
  const { loadOldStates: locationStateLoadOldStates } = location.state || {
    loadOldStates: false,
  }
  /** ---------- useState ---------- */
  const { t } = useTranslation()
  const [initializeAccess, setInitializeAccess] = useState()
  const [addBtnClicked, setAddBtnClicked] = useState(false)
  const [editBtnClicked, setEditBtnClicked] = useState(false)
  const [loadOldStates, setLoadOldStates] = useState(
    locationStateLoadOldStates &&
      !JSON.parse(localStorage.getItem('employeeSubResetOnReload'))
  )

  const [addSurveyModal, setAddSurveyModal] = useState(
    loadOldStates && JSON.parse(localStorage.getItem('employeeAddSurveyOpen'))
  )
  const [addEducationModal, setAddEducationModal] = useState(
    loadOldStates && JSON.parse(localStorage.getItem('employeeAddEduOpen'))
  )
  const [editEducationModal, setEditEducationModal] = useState(
    loadOldStates && JSON.parse(localStorage.getItem('employeeEditEduOpen'))
  )
  const [editQualificationSurveyModal, setEditQualificationSurveyModal] =
    useState(
      loadOldStates &&
        JSON.parse(localStorage.getItem('employeeEditSurveyOpen'))
    )
  const [showSuggestionModal, setShowSuggestionModal] = useState(false)
  const [fetchedEducations, setFetchedEducations] = useState([])
  const [qualificationsByRole, setQualificationsByRole] = useState([])
  const [qualificationsByOrganisation, setQualificationsByOrganisation] =
    useState([])
  const [selectedSurveyID, setSelectedSurveyID] = useState(
    localStorage.getItem('employeeSelectedSurveyID')
  )
  const [selectedEducation, setSelectedEducation] = useState(
    JSON.parse(localStorage.getItem('employeeSelectedEducation'))
  )
  const [selectedSurvey, setSelectedSurvey] = useState(
    JSON.parse(localStorage.getItem('employeeSelectedSurvey'))
  )
  const [selectedUncompletedSurveyID, setSelectedUncompletedSurveyID] =
    useState(localStorage.getItem('employeeSelectedUncompletedSurveyID'))

  /** ---------- useEffect ---------- */
  useEffect(() => {
    let isLoading1 = true
    let isLoading2 = true
    let isLoading3 = true
    freshPerms().then((res) => {
      if (isLoading1) {
        setInitializeAccess(res.initializeAccess)
        if (res.initializeAccess === 'none') {
          history.goBack()
        }
      }
    })
    getQualificationSurvey({ individualId: row.individualId })
      .then((survey) => {
        if (isLoading2) {
          const ongoingSurveys = survey.filter((s) => !s.completed)
          const completedSurveys = survey.filter((s) => s.completed)
          setOngoing(ongoingSurveys)
          setCompleted(completedSurveys)
        }
      })
      .catch((err) => console.log(err))

    getAllEducations({ organisationId: organisation.id }).then((educations) => {
      if (isLoading3) {
        setFetchedEducations(educations)
      }
    })

    return () => {
      isLoading1 = false
      isLoading2 = false
      isLoading3 = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    localStorage.setItem('employeeSelectedSurveyID', selectedSurveyID)
  }, [selectedSurveyID])
  useEffect(() => {
    localStorage.setItem(
      'employeeSelectedEducation',
      JSON.stringify(selectedEducation)
    )
  }, [selectedEducation])
  useEffect(() => {
    localStorage.setItem(
      'employeeSelectedSurvey',
      JSON.stringify(selectedSurvey)
    )
  }, [selectedSurvey])
  useEffect(() => {
    localStorage.setItem(
      'employeeSelectedUncompletedSurveyID',
      selectedUncompletedSurveyID
    )
  }, [selectedUncompletedSurveyID])

  // Keeps track of which modals are open
  useEffect(() => {
    localStorage.setItem('employeeAddSurveyOpen', addSurveyModal)
    localStorage.setItem('employeeEditSurveyOpen', editQualificationSurveyModal)
    localStorage.setItem('employeeAddEduOpen', addEducationModal)
    localStorage.setItem('employeeEditEduOpen', editEducationModal)
  }, [
    addSurveyModal,
    editQualificationSurveyModal,
    addEducationModal,
    editEducationModal,
  ])

  // reset on reload and close modal
  useEffect(() => {
    let isLoading = TrendingUpOutlined
    if (locationStateLoadOldStates) {
      localStorage.setItem('employeeSubResetOnReload', JSON.stringify(true))
      if (isLoading) {
        setLoadOldStates(false)
      }
    } else {
      localStorage.removeItem('employeeSubResetOnReload')
    }
    return () => {
      isLoading = false
    }
  }, [locationStateLoadOldStates])

  useEffect(() => {
    let isLoading1 = true
    let isLoading2 = true

    const fetchQualificationsByOrganisation = async () => {
      let response = await getAllQualificationsByOrganisation({
        organisationNumber: organisation.organisationNumber,
      })
      let fetchedQualificationsByOrganisation = await response
      return fetchedQualificationsByOrganisation
    }

    const fetchQualificationsByRole = async function () {
      let response = await getSelectedRoleQualifications({
        organisationNumber: organisation.organisationNumber,
        roleId: employee.professionalRoleId,
        // roleId: organisation.professionalRole[0].id,
      })
      let fetchedQualificationsByRole = await response
      return fetchedQualificationsByRole
    }

    fetchQualificationsByRole().then((qualification) => {
      if (isLoading1) {
        setQualificationsByRole(qualification)
      }
    })

    fetchQualificationsByOrganisation().then((qualification) => {
      if (isLoading2) {
        setQualificationsByOrganisation(qualification)
      }
    })

    return () => {
      isLoading1 = false
      isLoading2 = false
    }

    // eslint-disable-next-line
  }, [])

  const initialContext = {
    row,
    employee,
    organisation,
    individual,
    addSurveyModal,
    setAddSurveyModal,
    addEducationModal,
    setAddEducationModal,
    editQualificationSurveyModal,
    setEditQualificationSurveyModal,
    loadOldStates,
    qualificationsByRole,
    qualificationsByOrganisation,
    selectedSurveyID,
    setSelectedSurveyID,
    selectedEducation,
    setSelectedEducation,
    selectedSurvey,
    setSelectedSurvey,
    addBtnClicked,
    setAddBtnClicked,
    editBtnClicked,
    setEditBtnClicked,
    editEducationModal,
    setEditEducationModal,
    setShowSuggestionModal,
    initializeAccess,
    ongoing,
    setOngoing,
    completed,
    setCompleted,
    selectedUncompletedSurveyID,
    setSelectedUncompletedSurveyID,
    fetchedEducations,
  }

  return (
    <EmployeeSubPageContext.Provider value={initialContext}>
      <Switch>
        <Route exact path={'/employees/:name'}>
          {addSurveyModal && <AddSurvey />}
          {addEducationModal && <AddEducationSurvey />}
          {editQualificationSurveyModal && <EditSurvey />}
          {editEducationModal && <EditEducation />}
          <SubPageWrapper>
            {showSuggestionModal && (
              <Modal
                title={t('employeeSubpage.title')}
                setState={setShowSuggestionModal}
              >
                <form
                  className='suggestion-form'
                  onSubmit={(e) => e.preventDefault()}
                >
                  <p>
                  {t('employeeSubpage.modalEducationSuggestion1')}
                    <br />
                    <br />
                    {t('employeeSubpage.modalEducationSuggestion2')}
                  </p>
                  <div className='buttons'>
                    <Delete
                      onClick={(e) => {
                        e.preventDefault()
                        setShowSuggestionModal(false)
                      }}
                    >
                      {t('employeeSubpage.no')}
                    </Delete>
                    <Button
                      onClick={(e) => {
                        e.preventDefault()
                        setShowSuggestionModal(false)
                        setAddEducationModal(true)
                      }}
                    >
                      {t('employeeSubpage.yes')}
                    </Button>
                  </div>
                </form>
              </Modal>
            )}
            <BreadCrumbs />
            <PageTitle title={t('employeeSubpage.secondaryTitle')}>
              <p>
                {t('employeeSubpage.competenceProfileText1')} <span className='bold'>{t('employeeSubpage.competenceProfileText2')}</span>.
                {t('employeeSubpage.competenceProfileText3')}
              </p>
              <br />
              <p>
                {t('employeeSubpage.competenceProfileText4')} <span className='bold'>{t('employeeSubpage.competenceProfileText5')}</span>{' '}
                {t('employeeSubpage.competenceProfileText6')} <span className='bold'>{t('employeeSubpage.competenceProfileText7')}</span>
                {t('employeeSubpage.competenceProfileText8')}
              </p>
              <br />
              <p>
              {t('employeeSubpage.competenceProfileText9')}{' '}
                <span className='bold'>{t('employeeSubpage.competenceProfileText10')}</span>
                {t('employeeSubpage.competenceProfileText11')}
              </p>
            </PageTitle>

            <EmployeeInfo />
            {employee && employee.professionalRoleId &&  <UnCompletedSurvey />}
            <OngoingSurveys />
            <OngoingEducations />
            <CompletedSurveys />
            <CompletedEducations />
          </SubPageWrapper>
        </Route>
        <Route path={`/employees/:name/:qualOrEdu`}>
          <QualEduHub />
        </Route>
      </Switch>
    </EmployeeSubPageContext.Provider>
  )
}

export default EmployeeSubPage
