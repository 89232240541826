import { Auth } from 'aws-amplify'

import StyledLabel from '../styledLabel/StyledLabel'
import { Button } from '../button/Button'
import { StyledForm } from './style'
import { useState } from 'react'
import { createNewPassword } from '../../utils/Amplify'

const NewPasswordRequired = ({
  email,
  signIn,
  session,
  setLoggedIn,
  setToken,
}) => {
  const [password, setPassword] = useState()

  const handleNewPassword = async (e) => {
    e.preventDefault()

    createNewPassword(email, password, session)

    setTimeout(async () => {
      try {
        const user = await Auth.signIn(email, password)

        const jwtToken = await user.signInUserSession.accessToken.jwtToken
        if (jwtToken) setLoggedIn(true)
        setToken(jwtToken)
      } catch (err) {
        console.log('Error signing in: ' + err)
      }
    }, 1000)
  }

  return (
    <StyledForm>
      <StyledLabel>
        <p>Nytt lösenord</p>
        <input
          type='password'
          value={password}
          onInput={(e) => setPassword(e.target.value)}
        />
      </StyledLabel>
      <Button onClick={(e) => handleNewPassword(e)}>SPARA</Button>
    </StyledForm>
  )
}

export default NewPasswordRequired
