import React from 'react'
import { useTranslation } from 'react-i18next'

import { Wrapper } from '../banner/style'

const Banner = () => {

    const { t } = useTranslation()

    return (
        <Wrapper>
            <div>
                <h1>{t('pageLanding.title')}</h1>
            </div>
        </Wrapper>
    )
}

export default Banner
