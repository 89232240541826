import {simpleUser} from '../../../utils/Amplify'
import { getAreas, getSubQualifications } from '../../qualifications/functions'

/**
 *
 *
 *                      ProfessionalRole
 *
 *
 */
export const getAllProfessionalRole = async ({ organisationNumber }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationNumber}/all/professionalrole`,
      {
        method:'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        }
      }
    )
    const professionalRolls = await response.json()
    return professionalRolls
  } catch (error) {
    console.log('Fetch error: ', error)
  }
}

export const addProfessionalRole = (
  e,
  {
    addFormData,
    organisationNumber,
    setModalAddIsVisible,
    setProfessionalRoleList,
  }
) => {
  e.preventDefault()

  const addNewProfessionalRole = async () => {
    try {
      const options = {
        method: 'POST',
        body: JSON.stringify(addFormData),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationNumber}/professionalrole/add`,
        options
      )
      const newRoleObject = await response.json()
      setProfessionalRoleList((prev) => [...prev, newRoleObject])
      setModalAddIsVisible(false)
    } catch (error) {
      console.log('Fetch error: ', error)
    }
  }
  addNewProfessionalRole()
}

export const editProfessionalRole = (
  e,
  {
    addFormData,
    setModalEditIsVisible,
    professionalRole,
    professionalRoleList,
    setProfessionalRoleList,
  }
) => {
  e.preventDefault()
  const updateProfessionalRole = async () => {
    try {
      const options = {
        method: 'PATCH',
        body: JSON.stringify({
          professionalRoleName: addFormData.professionalRoleName,
          roleDescription: addFormData.roleDescription,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/professionalrole/${professionalRole.id}/update`,
        options
      )

      const editedProfessionalRoleObject = await response.json()
      const updatedProfessionalRoleList = professionalRoleList
      const professionalRoleIndex = professionalRoleList.findIndex(
        (role) => role.id === professionalRole.id
      )
      updatedProfessionalRoleList.splice(
        professionalRoleIndex,
        1,
        editedProfessionalRoleObject
      )
      setProfessionalRoleList(updatedProfessionalRoleList)
      setModalEditIsVisible(false)
    } catch (error) {
      console.log('Fetch error: ', error)
    }
  }
  updateProfessionalRole()
}

export const deleteProfessionalRole = (
  e,
  { setModalDeleteIsVisible, setProfessionalRoleList, professionalRole }
) => {
  e.preventDefault()
  setModalDeleteIsVisible(false)
  const deleteProfessionalRole = async () => {
    try {
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        },
      }
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/professionalrole/${professionalRole.id}/delete`,
        options
      )

      setProfessionalRoleList((oldProfessionalRoleList) =>
        oldProfessionalRoleList
          .filter((role) => role.id !== professionalRole.id)
          .map((role) => role)
      )
    } catch (error) {
      console.log('Fetch error: ', error)
    }
  }
  deleteProfessionalRole()
}

/**
 *
 *
 *                      CompetenceProfile
 *
 *
 */

export const getSelectedRoleQualifications = async ({
  organisationNumber,
  roleId,
}) => {
  if(!roleId) return
  return fetch(
    `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationNumber}/get/professionalrole/${roleId}`,{
      method:'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      }
    }
  )
    .then((res) => res.json())
    .then((data) => data.qualification)
      .catch(error=>console.log(error))
}

// Get all qualifications from the current organisation
export const getAllQualificationsByOrganisation = async ({ organisationNumber }) => {
  try {
    return fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationNumber}/qualification/get/all`,{
        method:'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (await simpleUser()).token,
        }
      }
    )
      .then((res) => res.json())
      .then((data) => data)
  } catch (error) {
    console.log(error)
  }
}

export const bindRoleWithQualification = async (
  e,
  {
    addFormData,
    roleId,
    organisationNumber,
    setModalAddIsVisible,
    setSelectedRoleQualifications,
  }
) => {
  try {
    const options = {
      method: 'PATCH',
      body: JSON.stringify({
        qualificationName: addFormData.qualificationName,
        qualificationDescription: addFormData.qualificationDescription,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationNumber}/professionalrole/${roleId}/join/${addFormData.id}`,
      options
    )

    setSelectedRoleQualifications((prev) => ([...prev, addFormData]))
    setModalAddIsVisible(false)
  } catch (error) {
    console.log(error)
  }
}

export const unbindRoleWithQualification = async (
  e,
  {
    roleId,
    organisationNumber,
    setModalDeleteIsVisible,
    selectQualificationToUnbind,
    setSelectedRoleQualifications,
  }
) => {
  try {
    const options = {
      method: 'PATCH',
      body: JSON.stringify({
        qualificationName: selectQualificationToUnbind.qualificationName,
        qualificationDescription:
          selectQualificationToUnbind.qualificationDescription,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationNumber}/professionalrole/${roleId}/disconnect/${selectQualificationToUnbind.id}`,
      options
    )

    setSelectedRoleQualifications((prev) => [
      ...prev.filter(
        (qualification) => qualification.id !== selectQualificationToUnbind.id
      ),
    ])
    setModalDeleteIsVisible(false)
  } catch (error) {
    console.log(error)
  }
}

/**
 *
 *
 *                      CompetenceProfile-Components
 *
 *
 */

export const getSubQualificationList = async (qualificationId) => {
  return getSubQualifications(qualificationId)
}
export const getAreaList = async (subQualificationId) => {
  try {
    return getAreas(subQualificationId).then((data) => data)
  } catch (error) {
    console.log(error)
  }
}
