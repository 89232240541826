import styled from 'styled-components'

export const Wrapper = styled.header`
  padding: 30px 0;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  position: relative;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  .small-copy {
    color: #000;
  }
  .logout,
  button,
  .logo {
    cursor: pointer;
  }

  .logout {
    position: relative;
    float: right;
  }

  .country-langugage-select {
    grid-row: 1;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .logo {
    grid-column: 1;
    grid-row: 1 / 3;
    height: 75px;
    width: auto;
  }

  .identity {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    text-align: right;
    color: #a4a4ad;
  }

  .id {
    grid-column: 1;
    grid-row: 2;
  }

  nav {
    justify-self: end;
    grid-row: 2;
    width: 503px;

    *:not-svg() {
      width: fit-content;
      white-space: nowrap;
    }

    ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      list-style-type: none;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      color: #3f3d56;

      li {
        cursor: pointer;

        a {
          color: inherit;
        }
      }

      .login {
        margin-right: 0;
      }
    }

    .menu {
      float: right;

      svg {
        cursor: pointer;
      }

      .logout {
        font-weight: bold;
      }
    }
  }
`
