import { createContext, useContext, useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { Switch } from 'react-router-dom/cjs/react-router-dom.min'
import PageTitleSpecial from '../../../../components/pageTitle/PageTitleSpecial'
import EducationModulesWrapper from '../../../../private/educations/subPages/educationModules/style'
import BreadCrumbs from '../../../../utils/BreadCrumbs'
import { getSurvey } from '../../../Employees/services/crud'
import { CompetenceProfileContext } from '../../CompetenceProfile'
import EducationModules from './EducationModules'
import ModuleModal from './ModuleModal'
import SubQualification from './SubQualification'

export const EducationContext = createContext()

const Education = () => {
    const [modules, setModules] = useState([])
    const [subQualifications, setSubQualifications] = useState([])
    const [modalModule, setModalModule] = useState()

    const [selectedSubQualification, setSelectedSubQualification] = useState(JSON.parse(localStorage.getItem('competenceProfileSelectedSubQualification')))
    useEffect(() => {
        localStorage.setItem('competenceProfileSelectedSubQualification', JSON.stringify(selectedSubQualification))
    }, [selectedSubQualification])

    const { selectedEducation: contextEducation } = useContext(CompetenceProfileContext)
    const [selectedEducation, setSelectedEducation] = useState(contextEducation)

    useEffect(() => {
        let isLoading = true

        const getEducation = async () => {
            const { education } = await getSurvey(contextEducation.id)
            if (isLoading) {
                setSelectedEducation(education)
            }
        }

        getEducation()
        return () => { isLoading = false }
        // eslint-disable-next-line
    }, [])

    return (
        <EducationContext.Provider
            value={{
                modules, setModules,
                subQualifications, setSubQualifications,
                selectedEducation,
                selectedSubQualification, setSelectedSubQualification
            }}
        >
            <Switch>
                <Route exact path='/:name/:education'>
                    <EducationModulesWrapper className='education-modules'>
                        {modalModule &&
                            <ModuleModal
                                modalModule={modalModule}
                                setModalModule={setModalModule}
                            />
                        }
                        <BreadCrumbs custom />
                        <PageTitleSpecial
                            title={
                                <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                    <span>{selectedEducation.educationName}</span>
                                    <span style={{ fontSize: '16px', color: '#929292' }}>Ver {selectedEducation.version}{selectedEducation.educationCountries ? ' | '[0].localDate : null}</span>
                                </span>
                            }
                            info={selectedEducation.educationDescription}
                        />
                        {selectedEducation.qualification && <EducationModules setModalModule={setModalModule} />}
                    </EducationModulesWrapper>
                </Route>
                <Route path='/:name/:education/:subQual' >
                    {selectedSubQualification && <SubQualification />}
                </Route>
            </Switch>
        </EducationContext.Provider>
    )
}

export default Education