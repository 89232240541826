import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'
import { Link, useRouteMatch } from 'react-router-dom'
import { t } from 'i18next'

import { Secondary } from '../../../../../components/button/Button'
import { useContext } from 'react'
import { SubContext } from '../Survey'
import SurveyTimer from './SurveyTimer'

const SurveyTable = () => {
  const {
    subQualifications,
    setSelectedSubQualification,
    surveyStarted,
    setShowQuestionModal,
    setSubQualifications,
    mappingTime,
    totalNoOfMappingAnswers,
    mappingQuestions,
  } = useContext(SubContext)

  const { url } = useRouteMatch()

  /** Get the number of mapping question for each sub qualification */
  const noOfMappingQuestions = (subQualificationId) => {
    const subQualQuestions = mappingQuestions.length > 0 && mappingQuestions.find(
      (question) => question.subQualificationId === subQualificationId
    )

    if (subQualQuestions) {
      return subQualQuestions.Questions.length
    }
  }

  /** Get the number of mapping question answers for each sub qualification */
  const noOfMappingAnswers = (subQualificationId) => {
    const subQualQuestions = mappingQuestions.length > 0 && mappingQuestions.find(
      (questions) => questions.subQualificationId === subQualificationId
    )

    if (subQualQuestions) {
      return subQualQuestions.Questions.filter(
        (question) => question.selectedAnswer !== 0
      ).length
    }
  }

  /** Get the total number of mapping questions */
  const noOfTotalMappingQuestions = () => {
    return mappingQuestions.reduce((total, question) => {
      return total + question.Questions.length
    }, 0)
  }

  const statusColor = (status) => {
    switch (status) {
      case 1:
        return '#183eb5'
      case 2:
        return 'green'
      case 3:
        return 'red'
      default:
        return 'gray'
    }
  }

  const statusText = (status) => {
    switch (status) {
      case 1:
        return t('pageSurvey.started')
      case 2:
        return t('pageSurvey.completed')
      case 3:
        return t('pageSurvey.failed')
      default:
        return t('pageSurvey.notStarted')
    }
  }

  return (
    <Table>
      <TableHead className='table-header'>
        <TableRow>
          <TableCell>{t('pageSurvey.subQualification')}</TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            {t('pageSurvey.noOfQuestions')}
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            {t('pageSurvey.answers')}
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            {t('pageSurvey.status')}
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {subQualifications &&
          subQualifications.map((sub) => {
            const { id, name: subName, status } = sub

            return (
              <TableRow sx={{ height: '65px' }} key={id}>
                <TableCell>
                  {!surveyStarted ? (
                    <Link
                      onClick={() => {
                        setSelectedSubQualification(sub)
                      }}
                      to={`${url}/${sub.name}?type=qualification`}
                    >
                      {subName}
                    </Link>
                  ) : (
                    <p className='sub-name'>{subName}</p>
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {noOfMappingQuestions(sub.id) ?? '-'}
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {noOfMappingAnswers(sub.id) ?? '-'}
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  <p
                    className='bold'
                    style={{
                      color: statusColor(status),
                    }}
                  >
                    {statusText(status)}
                  </p>
                </TableCell>
                <TableCell>
                  {surveyStarted && (
                    <Secondary
                      className='survey-question-btn'
                      disabled={!mappingQuestions.length || status===2}
                      onClick={() => {
                        setSelectedSubQualification(sub)
                        setShowQuestionModal(true)

                        // Update the started property of the subQualification
                        if (status === 0) {
                          setSubQualifications(
                            subQualifications.map((subQual) => {
                              if (subQual.id === id) {
                                return {
                                  ...subQual,
                                  status: subQual.status ? subQual.status : 1,
                                }
                              }
                              return subQual
                            })
                          )
                        }
                      }}
                    >
                      {t('pageSurvey.questions')}
                    </Secondary>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell>
            {mappingTime > 0 && mappingQuestions.length && surveyStarted ? <SurveyTimer /> : ''}
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }} className='bold'>
            {mappingQuestions.length ? noOfTotalMappingQuestions() : '-'}
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }} className='bold'>
            {mappingQuestions.length ? totalNoOfMappingAnswers : '-'}
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default SurveyTable
