import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { Wrapper } from './style'

import DropdownArrow from '../../assets/img/button-arrow-dark.svg'
import { collapseSection, expandSection } from '../../utils/Misc'

/**
 * PageTitle without children
 * @param {linkTo} param0 optional: makes PageTitle into a react router dom link to value of linkTo
 * @param {title} param1: self-explanatory
 * @param {description} param2: default description, is collapsible.
 * @param {info} param3: use to display something like user created data - is not collapsible
 * @param {className} param4: self-explanatory
 * @param {altCollapseTracker} param5: we needed to add this due to component for some reason not remembering to stay collapsed for some reason
*/
const PageTitleSpecial = ({ linkTo, title, description, info, className, altCollapseTracker }) => {
  const [showInfo, setShowInfo] = useState(
    localStorage.getItem(`expand-${altCollapseTracker || title}`) === 'false' ? false : true
  )

  const descDiv = useRef()

  useEffect(() => {
    if (descDiv.current) {
      if (showInfo) {
        expandSection(descDiv.current)
      } else {
        collapseSection(descDiv.current)
      }
    }
  }, [showInfo, descDiv])

  return (
    <Wrapper className={`page-title ${className}`}>
      <div className='title2'>
        {linkTo ? (
          <Link to={linkTo}>
            <h3>{title}</h3>
            <p>{info}</p>
          </Link>
        ) : (
          <>
            <h3>
              {title}
              {description && (
                <img
                  src={DropdownArrow}
                  alt='dropdown arrow'
                  onClick={() => {
                    localStorage.setItem(`expand-${altCollapseTracker || title}`, !showInfo)
                    setShowInfo(!showInfo)
                  }}
                  className={showInfo ? 'open' : 'closed'}
                />
              )}
            </h3>
            <p className='bold custom-info'>{info}</p>
          </>
        )}
      </div>
      {description && (
        <>
          <div ref={descDiv} className={`info ${showInfo ? 'show-info' : 'hide-info'}`}>
            {description}
          </div>
          <hr />
        </>
      )}
    </Wrapper>
  )
}

export default PageTitleSpecial
