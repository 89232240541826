import React, { Fragment, useContext } from 'react'

import { Button, Delete } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import { deleteSubArea } from '../../functions'
import { SubQualificationContext } from '../SubQualification'
import { useTranslation } from 'react-i18next'

const DeleteSubArea = () => {
  const { t } = useTranslation()
  const {
    setModalDeleteIsVisible,
    dispatchModalEditIsVisible,
    modalEditIsVisible,
    subQualificationsList,
    setSubQualificationsList,
    subQualificationId,
    selectedArea,
  } = useContext(SubQualificationContext)

  return (
    <Fragment>
      <Modal
        title={t('competenceArea.modal.deleteTitle')}
        setState={setModalDeleteIsVisible}
      >
        <br />
        <br />
        <p>
          {t('competenceArea.modal.deleteInfo1')}<span className='bold'>{selectedArea.name}</span>?
        </p>
        <br />
        <p>
        {t('competenceArea.modal.deleteInfo2')}
        </p>
        <br />
        <p>{t('competenceArea.modal.deleteInfo3')}</p>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '36px',
            paddingBottom: '20px',
          }}
        >
          <Delete
            onClick={() =>
              deleteSubArea({
                dispatchModalEditIsVisible,
                modalEditIsVisible,
                setModalDeleteIsVisible,
                subQualificationsList,
                setSubQualificationsList,
                subQualificationId,
                selectedArea,
              })
            }
          >
            {t('competenceArea.modal.buttonDelete')}
          </Delete>
          <Button
            type='submit'
            style={{ marginLeft: '10px' }}
            onClick={() => setModalDeleteIsVisible(false)}
          >
            {t('competenceArea.modal.buttonCancel')}
          </Button>
        </div>
      </Modal>
    </Fragment>
  )
}

export default DeleteSubArea
