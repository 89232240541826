import { Auth } from 'aws-amplify'
import React, { useContext, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Redirect, useHistory } from 'react-router-dom'

import { ReactComponent as LogoDark } from '../../assets/img/logo-dark.svg'
//import { Button, Secondary } from '../../assets/img/undraw-login.svg'
import { Button, Secondary } from '../../components/button/Button'
import Modal from '../../components/modal/Modal'
import NewPasswordRequired from '../../components/newPasswordRequired/NewPasswordRequired'
import Countries from '../../components/styledFlagsSelect/Countries'
import Languages from '../../components/styledFlagsSelect/Languages'
import StyledLabel from '../../components/styledLabel/StyledLabel'
import { Context } from '../../utils/Context'
import ConfirmSignup from './../../utils/ConfirmSignup'
import { Wrapper } from './style'

const LogInErrorMsg = () => {
  const styling = {
    backgroundColor: '#ffd1d1',
    color: 'black',
    display: 'block',
    padding: '10px',
    marginBottom: '10px',
  }
  return <p style={styling}>Incorrect username or password</p>
}

const Login = () => {
  const passwordField = useRef('')
  const [verifyModuleIsHidden, setVerifyModuleIsHidden] = useState(true)

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const { email, password } = formData
  const org = localStorage.getItem('org')
    ? JSON.parse(localStorage.getItem('org'))
    : false
  const [token, setToken] = useState(null)
  const [loginError, setLoginError] = useState(false)
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false)
  const [session, setSession] = useState()

  const history = useHistory()

  const settingToken = async () => {
    let localToken
    try {
      const res = await Auth.currentSession()
      localToken = res.getAccessToken().getJwtToken()
    } catch (error) {
      // console.log(error)
    }
    setToken(localToken)
  }

  // Checks if user is logged in when component renders
  useEffect(() => {
    settingToken()
  }, [])

  //   user  and redirects to home
  async function signIn() {
    try {
      const user = await Auth.signIn(email, password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setSession(user.Session)
        setShowNewPasswordModal(true)
        return
      } else {
        const jwtToken = await user.signInUserSession.accessToken.jwtToken
        if (jwtToken) setLoggedIn(true)
        setToken(jwtToken)
      }
    } catch (error) {
      console.log('error signing in', error)
      if (error === 'UserNotConfirmedException: User is not confirmed.') {
        setVerifyModuleIsHidden(false)
      }
      setLoginError(true)
    }
  }

  const { setLoggedIn, setMode } = useContext(Context)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (org) {
      setMode('employee')
    }
    signIn()
  }
  /* 
      Most of the time you only need the t function to translate your content,
      you can also get the i18n instance (in order to change the language)
  */
  const { t } = useTranslation()

  return (
    <>
      <Wrapper>
        {!verifyModuleIsHidden && (
          <ConfirmSignup
            email={email}
            signIn={signIn}
            setVerifyModuleIsHidden={setVerifyModuleIsHidden}
          />
        )}
        {showNewPasswordModal && (
          <Modal
            title='Skapa ett nytt lösenord'
            setState={setShowNewPasswordModal}
          >
            <NewPasswordRequired
              email={email}
              signIn={signIn}
              session={session}
              setLoggedIn={setLoggedIn}
              setToken={setToken}
            />
          </Modal>
        )}
        {token && <Redirect to='/home' />}
        <section className='left-side'>
          <div className='left-side-content'>
            <LogoDark className='logo' />
            <nav>
              <p>
                <Link to='/'>{t('header.home')}</Link> / {t('header.signIn')}
              </p>
            </nav>
            {/*<UndrawLogin className='undraw' />*/}
          </div>
        </section>
        <section className='right-side'>
          <form onSubmit={(e) => handleSubmit(e)}>
            <h3>{t('pageLogin.pageTitle')}</h3>
            <div className='country-langugage-select'>
              <Countries />
              <Languages />
            </div>
            {loginError && <LogInErrorMsg />}
            <StyledLabel>
              <p>{t('pageLogin.mailLabel')}</p>
              <input
                value={email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                type='email'
                name='email'
                id='email'
              />
            </StyledLabel>
            <StyledLabel>
              <p>{t('pageLogin.passwordLabel')}</p>
              <input
                ref={passwordField}
                // value={password.current}
                // onChange={changeHandler}
                type='password'
                name='password'
                id='password'
              />
            </StyledLabel>
            <br />
            <Link to='#' className='forgot-password hoverUnderline'>
              {t('pageLogin.forgotPassword')}?
            </Link>
            <Button
              onClick={() =>
                setFormData(
                  (prev) =>
                    (prev = {
                      email: formData.email,
                      password: passwordField.current.value,
                    })
                )
              }
            >
              {t('pageLogin.signInBtn')}
            </Button>
          </form>
          {org ? (
            <div className='signup-info'>
              <p>{t('pageLogin.notRegisteredOrg')}?</p>
              <a href='https://www.headonnails.com/contact/'>
                <Secondary>{t('pageLogin.regOfInterests')}</Secondary>
              </a>
            </div>
          ) : (
            <div className='signup-info'>
              <p>{t('pageLogin.notRegistered')}?</p>
              <Secondary onClick={() => history.push('/signup')}>
                {t('pageLogin.createAccountBtn')}
              </Secondary>
            </div>
          )}
        </section>
      </Wrapper>
    </>
  )
}

export default Login
