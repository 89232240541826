import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import certificateIcon from '../../../assets/img/Certificate.svg'
import { EmployeeSubPageContext } from '../EmployeeSubPage'
const CompletedSurveys = () => {
  /** ---------- CONTEXTS ---------- */
  const { t } = useTranslation()
  const { completed } = useContext(EmployeeSubPageContext)

  return (
    <>
      {/** ---------- Completed Surveys ---------- */}
      <Grid item xs={12} mt={6}>
        <Grid item xs={12}>
          <h4
            style={{
              color: '#bfbecf',
              paddingBottom: '5px',
            }}
          >
            {t('competenceProfile.completedMappings')}
          </h4>
        </Grid>
        {completed?.filter((survey) => survey.qualificationName).length > 0 && (
          <Grid container item xs={12}>
            <Grid item xs={12}></Grid>
            {/** Grid head */}
            <Grid
              container
              item
              sx={{ borderBottom: '1px solid #e0e0e0', padding: '10px' }}
              columns={30}
            >
              <Grid item xs={13}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.qualification')}</p>
              </Grid>
              <Grid item xs={16}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.completed2')}</p>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            {/** Grid body */}
            {completed
              ?.filter((survey) => survey.qualificationName)
              .map(({ qualificationName, id, completedDate }) => (
                <Grid
                  key={id}
                  container
                  item
                  sx={{ borderBottom: '1px solid #e0e0e0', padding: '10px' }}
                  columns={30}
                >
                  <Grid item xs={13}>
                    {qualificationName}
                  </Grid>
                  <Grid item xs={16}>
                    {completedDate}
                  </Grid>
                  <Grid item xs={1} sx={{ textAlign: 'right' }}>
                    <img src={certificateIcon} alt='Certificate icon' />
                  </Grid>
                </Grid>
              ))}
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default CompletedSurveys
