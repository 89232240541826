import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../../utils/Context";
import StyledLabel from "../styledLabel/StyledLabel";
import StyledSelect from "../styledSelect/StyledSelect";

/**
 * Sets language in SkillUpTool
 * @param {className} param0: self-explanatory
 */
const Languages = ({ className }) => {

    const { language, setLanguage } = useContext(Context)

    const languages = [
        {
            label: 'Swedish',
            value: 'SE'
        },
        {
            label: 'English',
            value: 'GB'
        }
    ]

    const { t, i18n } = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language, i18n])

    return (
        <StyledLabel className={className}>
            <p>{t('extra.language')}</p>
            <StyledSelect
                dark
                language
                isSearchable={false}
                options={languages}
                value={languages.filter(option => option.value === language)}
                onChange={e => {
                    setLanguage(e.value)
                }}
            />
        </StyledLabel>
    )
}

export default Languages
