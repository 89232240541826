import { useContext, useLayoutEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Button, LinkRight } from '../../../components/button/Button'
import { CatalogContext } from '../Catalog'
import { ReactComponent as LoadingSpinner } from '../../../assets/img/spinner.svg'
import { Grid } from '@mui/material'
import {
  addQualificationToProfessionalRoleFromCatalog,
  getProfessionalRoleQualifications,
} from '../utils/functions'
import { Context } from '../../../utils/Context'
import LoadingDots from '../../../components/loading/LoadingDots'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const Results = () => {
  const { t } = useTranslation()
  const {
    results,
    filter,
    setSelectedResult,
    resultsLoading,
    professionalRole,
    prevPath,
    confirmIsOpen,
    setConfirmIsOpen,
    helper,
    setHelper,
    includeResults,
  } = useContext(CatalogContext)
  const location = useLocation()
  const { ongoing, addQualificationSurvey } = location.state || {}

  const { organisation } = useContext(Context)
  const orgNr = organisation ? organisation.organisationNumber : null

  const [currentQualifications, setCurrentQualifications] = useState()

  useLayoutEffect(() => {
    const settingQuals = async () => {
      setCurrentQualifications(
        await getProfessionalRoleQualifications(orgNr, professionalRole.id)
      )
    }
    if (professionalRole && orgNr) {
      settingQuals()
    }
  }, [professionalRole, orgNr])

  const filteredResults = results.filter((result) => {
    return (
      (filter.qualifications && !filter.educations
        ? result.qualificationName
        : true) &&
      (!filter.qualifications && filter.educations
        ? result.educationName
        : true) &&
      Object.keys(filter).every((key) => {
        return (
          key === 'qualifications' ||
          key === 'educations' ||
          (Array.isArray(filter[key]) && filter[key].length === 0) ||
          (key === 'author' && result.educationName
            ? filter[key].includes(result[key])
            : Array.isArray(filter[key]) &&
              filter[key].includes(
                result.educationName ? result.qualification[key] : result[key]
              ))
        )
      })
    )
  })

  const { path } = useRouteMatch()

  const history = useHistory()

  return (
    <div className='results'>
      {filteredResults.length > 0 ? (
        filteredResults.map((result, i) => {
          const qualificationInfo = result.qualificationName
            ? result
            : result.qualification

          return (
            <div className='result' key={i}>
              <div className='result-header'>
                <h3>
                  {result.educationName || qualificationInfo.qualificationName}{' '}
                  - {result.id || qualificationInfo.id}
                </h3>
                <p className='subtitle'>
                  {result.educationName ?  t('educations.moduleTable.title2') : t('educations.moduleTable.title')}
                </p>
              </div>
              <p className='result-description'>
                {result.educationDescription ||
                  qualificationInfo.qualificationDescription}
              </p>
              <div className='bottom'>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={'space-between'}
                  alignItems={'flex-end'}
                >
                  <Grid container item xs={6}>
                    <div className='result-details'>
                      {result.educationName && (
                        <p>
                          <span>{t('catalogue.regardingTheQualification')}:</span>{' '}
                          {qualificationInfo.qualificationName} -{' '}
                          {qualificationInfo.id}
                        </p>
                      )}
                      <p>
                        <span>{t('catalogue.businessArea')}:</span>{' '}
                        {qualificationInfo.qualificationArea}
                      </p>
                      <p>
                        <span>{t('common.type')}:</span> {qualificationInfo.qualificationType}
                      </p>
                      <p>
                        <span>{t('common.level')}:</span>{' '}
                        {qualificationInfo.qualificationAccess}
                      </p>
                      <p>
                        <span>{t('common.eqfLevel')}:</span>{' '}
                        {qualificationInfo.qualificationEqf}
                      </p>
                      <p>
                        <span>{t('common.createdBy')}:</span> {result.author}
                      </p>
                    </div>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={6}
                    direction='column'
                    justifyContent='space-between'
                    alignItems='flex-end'
                    alignContent='stretch'
                  >
                    <Grid item xs={6} mb={2} pb={1.8}>
                      {
                        // Add qualification to professional role
                        professionalRole && result.qualificationName && (
                          <>
                            {!currentQualifications ? (
                              <LoadingDots />
                            ) : currentQualifications.some((qualification) => {
                                return qualification.id === qualificationInfo.id
                              }) ? (
                              'Redan tillagd'
                            ) : (
                              <Button
                                sm
                                onClick={() => {
                                  setHelper({
                                    addQualificationToProfessionalRoleFromCatalog,
                                    orgNr,
                                    professionalRole,
                                    qualificationRoleId: qualificationInfo.id,
                                    confirmIsOpen,
                                    setConfirmIsOpen,
                                    helper,
                                    setHelper,
                                    history,
                                    prevPath,
                                    qualificationName: result.qualificationName,
                                    qualification: result,
                                  })
                                  setConfirmIsOpen(true)
                                }}
                              >
                                {t('common.add')}
                              </Button>
                            )}
                          </>
                        )
                      }
                      {
                        // Add qualification
                        !professionalRole &&
                          !includeResults.includes('educations') &&
                          result.qualificationName &&
                          ((
                            Array.isArray(ongoing)
                              ? ongoing.every(
                                  (ongoingQual) =>
                                    ongoingQual.qualificationId !== result.id
                                )
                              : true
                          ) ? (
                            <Button
                              sm
                              onClick={() => {
                                history.push({
                                  pathname: prevPath,
                                  state: {
                                    loadOldStates: true,
                                    catalogQualification: result,
                                  },
                                })
                              }}
                            >
                              {t('common.add')}
                            </Button>
                          ) : (
                            'Redan tillagd'
                          ))
                      }
                      {
                        // Add education
                        !professionalRole &&
                          !addQualificationSurvey &&
                          includeResults.includes('educations') &&
                          !includeResults.includes('qualifications') &&
                          result.educationName &&
                          ((
                            Array.isArray(ongoing)
                              ? ongoing.every(
                                  (ongoingEd) =>
                                    ongoingEd.educationId !== result.id
                                )
                              : true
                          ) ? (
                            <Button
                              sm
                              onClick={() => {
                                history.push({
                                  pathname: prevPath,
                                  state: {
                                    loadOldStates: true,
                                    catalogQualification: result,
                                  },
                                })
                              }}
                            >
                              {t('common.add')}
                            </Button>
                          ) : (
                            'Redan tillagd'
                          ))
                      }
                    </Grid>
                    <Grid item xs={6} alignItems={'flex-end'}>
                      <LinkRight
                        onClick={() => {
                          setSelectedResult(result)
                        }}
                        to={`${path}/${
                          result.educationName || result.qualificationName
                        }`}
                      >
                        {t('common.more')}
                      </LinkRight>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          )
        })
      ) : (
        <div className='no-results'>
          {resultsLoading ? <LoadingSpinner /> : <h3>{t('common.noResults')}</h3>}
        </div>
      )}
    </div>
  )
}

export default Results
