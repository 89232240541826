import styled from 'styled-components'
import { ReactComponent as ButtonArrowLight } from '../../assets/img/button-arrow-light.svg'
import { ReactComponent as ButtonArrowDark } from '../../assets/img/button-arrow-dark.svg'
import ArrowBlue from '../../assets/img/arrow-blue.svg'
import { Link } from 'react-router-dom'

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  height: ${(props) => (props.sm ? '34px' : '40px')};
  border: none;
  border-radius: 60px;
  padding: ${(props) => (props.sm ? '10px 17px' : '13px 32px')};
  background-color: #183eb5;
  font-family: 'Roboto', sans-serif;
  font-size: ${(props) => (props.sm ? '12px' : '14px')};
  font-weight: bold;
  color: white;

  letter-spacing: ${(props) => (props.sm ? '0.03em' : '0.01em')};

  :hover {
    cursor: pointer;
    background-color: #526ec7;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    svg {
      height: 14px;
      position: relative;
      bottom: 1px;
      margin: 0;
      width: 9px;
    }
  }
`

export const ArrowButton = (props) => (
  <Button {...props}>
    <div>
      {props.children}
      <ButtonArrowLight />
    </div>
  </Button>
)

export const Secondary = styled(Button)`
  background-color: #d1d9f0;
  color: #3f3d56;
  font-weight: 500;

  :hover {
    background-color: #ececfd;
  }
`

export const Delete = styled(Button)`
  background-color: #fdcece;
  color: #3f3d56;
  font-weight: 500;

  :hover {
    background-color: #fdcece;
  }
`

const PublishBase = styled(Button)`
  height: 35px;
  border-radius: 60px;
  padding: 14px 24px;
  padding-top: 15px;
  background-color: #6fcf97;
  color: #323145;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  letter-spacing: 0.01em;
  font-size: 14px;

  :hover {
    background-color: #6fcf97;
  }

  svg {
    bottom: 0;
  }
`

export const Publish = (props) => (
  <PublishBase {...props}>
    <div>
      {props.children}
      <ButtonArrowDark />
    </div>
  </PublishBase>
)

const NextBase = styled(Button)`
  height: 35px;
  width: 150px;
  border: none;
  border-radius: 60px;
  padding: 14px 24px;
  padding-top: 15px;
  background-color: #183eb5;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  display: flex;
`

export const Next = (props) => (
  <NextBase {...props}>
    <div>
      {props.children}
      <ButtonArrowLight />
    </div>
  </NextBase>
)

const PrevBase = styled(NextBase)`
  svg {
    transform: rotate(180deg);
  }
`

export const Prev = (props) => (
  <PrevBase {...props}>
    <div>
      <ButtonArrowLight />
      {props.children}
    </div>
  </PrevBase>
)

export const Red = styled(Prev)`
  font-size: 14px;
`

const LinkLeftBase = styled(Link)`
  :before {
    content: url(${ArrowBlue});
    margin-right: 5px;
  }
`

export const LinkLeft = (props) => (
  <LinkLeftBase {...props}>{props.children}</LinkLeftBase>
)

const LinkRightBase = styled(Link)`
  line-height: 12px;
  :after {
    content: url(${ArrowBlue});
    display: inline-block;
    margin-left: 5px;
    transform: rotate(180deg);
  }
`

export const LinkRight = (props) => (
  <LinkRightBase {...props}>{props.children}</LinkRightBase>
)

export const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #dbdbdf;
  color: #323145;
  width: 31px;
  height: 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;

  ::after {
    content: '+';
  }
`
