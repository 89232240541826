import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import { Grid, Collapse } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { Fragment, useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import pencil from '../../../../assets/img/Edit_pencil.svg'
import { SubQualificationContext } from '../SubQualification'
import { SubTable } from './Style'
import { AddButton } from '../../../../components/button/Button'
import { Context } from '../../../../utils/Context'
import { getSubQualifications } from '../../functions'
import { QualificationContext } from '../../qualification/Qualification'
import { useTranslation } from 'react-i18next'
const SubQualificationTable = () => {
  const { t } = useTranslation();
  const {
    employee: { qualificationAccess },
  } = useContext(Context)
  const { setSelectedSubQualification } = useContext(SubQualificationContext)
  const {
    dispatchModalEditIsVisible,
    dispatchModalAddIsVisible,
    setSubQualificationId,
    setSelectedArea,
    subQualificationsList,
    setSubQualificationsList,
  } = useContext(SubQualificationContext)

  const [toggleOption, setToggleOption] = useState(
    JSON.parse(localStorage.getItem('subToggleOption')) ?? true
  )
  const [questionCollapse, setQuestionCollapse] = useState(
    JSON.parse(localStorage.getItem('subQuestionCollapsible')) ?? true
  )
  const [descriptionCollapse, setDescriptionCollapse] = useState(
    JSON.parse(localStorage.getItem('subDescriptionCollapsible')) ?? true
  )
  const {
    selectedQualification: { qualificationId: id },
  } = useContext(QualificationContext)

  // Runs on page loads
  useEffect(() => {
    let isLoading = true

    // Get all Subqualifications
    getSubQualifications(id).then((data) => {
      if (isLoading) {
        setSubQualificationsList(data)
      }
    })

    // clean up function
    return () => {
      isLoading = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'subQuestionCollapsible',
      JSON.stringify(questionCollapse)
    )
  }, [questionCollapse])
  useEffect(() => {
    localStorage.setItem(
      'subDescriptionCollapsible',
      JSON.stringify(descriptionCollapse)
    )
  }, [descriptionCollapse])
  useEffect(() => {
    localStorage.setItem('subToggleOption', JSON.stringify(toggleOption))
  }, [toggleOption])

  return (
    <SubTable>
      <>
        <Grid container item xs={12} mt={2} height={45}>
          <Grid item xs={12}>
            <span
              style={{ fontWeight: toggleOption ? 'bold' : '' }}
              className='pointer'
              onClick={() => {
                setToggleOption(true)
              }}
            >
              {t('pageSubQualification.toggleOption.questions')}
            </span>
            <span> | </span>
            <span
              style={{ fontWeight: !toggleOption ? 'bold' : '' }}
              className='pointer'
              onClick={() => {
                setToggleOption(false)
              }}
            >
              {t('pageSubQualification.toggleOption.description')}
            </span>
          </Grid>
        </Grid>

        {toggleOption && (
          <Table sx={{ maxWidth: '1010px' }} aria-label='collapsible table'>
            <colgroup>
              <col style={{ width: '0%' }} />
              <col style={{ width: '27.5%' }} />
              <col style={{ width: '27.5%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '15%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t('pageSubQualification.tableCell.subQualification')}</TableCell>
                <TableCell>
                {t('pageSubQualification.tableCell.qualificationAreas')}
                  <IconButton
                    aria-label={t('pageSubQualification.expandCollapseButton')}
                    size='small'
                    onClick={(e) => {
                      setDescriptionCollapse((prev) => !prev)
                    }}
                  >
                    {descriptionCollapse ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                {t('pageSubQualification.tableCell.questionCount')}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                {t('pageSubQualification.tableCell.testQuestionCount')}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                {t('pageSubQualification.tableCell.approvedCount')}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subQualificationsList.map(
                ({ name, id, description, qualificationArea }, i) => (
                  <React.Fragment key={id}>
                    <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
                      <TableCell style={{ verticalAlign: 'bottom' }}>
                        {/** Prev icon */}
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'bottom' }}>
                        <Link
                          onClick={() => {
                            setSelectedSubQualification({
                              id,
                              name,
                              qualificationArea,
                              description,
                            })
                          }}
                          to={(location) => ({
                            ...location,
                            pathname: `${location.pathname}/${name}`,
                          })}
                        >
                          {name}
                        </Link>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell
                        align='center'
                        className='bold'
                        style={{ paddingRight: '2px' }}
                      >
                        {qualificationArea &&
                          qualificationArea
                            .flatMap((area) =>
                              area.qualificationRequirement
                                ? area.qualificationRequirement.flatMap(
                                    (requirement) => requirement.amountQuestions
                                  )
                                : []
                            )
                            .reduce((sum, total) => sum + total, 0)}
                      </TableCell>
                      <TableCell
                        align='center'
                        className='bold'
                        style={{ paddingRight: '2px' }}
                      >
                        {qualificationArea &&
                          qualificationArea
                            .flatMap((area) =>
                              area.qualificationRequirement
                                ? area.qualificationRequirement.flatMap(
                                    (requirement) => requirement.totalQuestions
                                  )
                                : []
                            )
                            .reduce((sum, total) => sum + total, 0)}
                      </TableCell>
                      <TableCell
                        align='center'
                        className='bold'
                        style={{ paddingRight: '2px' }}
                      >
                        {qualificationArea &&
                          qualificationArea
                            .flatMap((area) =>
                              area.qualificationRequirement
                                ? area.qualificationRequirement.flatMap(
                                    (requirement) => requirement.passQuestions
                                  )
                                : []
                            )
                            .reduce((sum, total) => sum + total, 0)}
                      </TableCell>
                      <TableCell
                        align='center'
                        style={{ verticalAlign: 'bottom', paddingLeft: '15px' }}
                      >
                        {qualificationAccess === 'edit' && (
                          <img
                            className='pointer'
                            id={id}
                            src={pencil}
                            alt='Edit pencil'
                            onClick={(e) => {
                              setSubQualificationId(id)
                              dispatchModalEditIsVisible({
                                type: 'sub',
                                current: false,
                              })
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse in={descriptionCollapse} timeout='auto'>
                          <Box sx={{ margin: '0' }}>
                            <Table>
                              <colgroup>
                                <col style={{ width: '0%' }} />
                                <col style={{ width: '27.5%' }} />
                                <col style={{ width: '27.5%' }} />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '13%' }} />
                              </colgroup>
                              <TableBody>
                                {qualificationArea &&
                                  qualificationArea.map((area) => (
                                    <Fragment key={area.id}>
                                      <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell
                                          style={{
                                            verticalAlign: 'bottom',
                                          }}
                                        >
                                          {area.name}
                                        </TableCell>
                                        <TableCell align='center'>
                                          {area.qualificationRequirement
                                            ? area.qualificationRequirement
                                                .map(
                                                  (requirement) =>
                                                    requirement.amountQuestions
                                                )
                                                .reduce(
                                                  (sum, total) => sum + total,
                                                  0
                                                )
                                            : 0}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            textAlign: 'center',
                                            verticalAlign: 'bottom',
                                          }}
                                        >
                                          {area.qualificationRequirement
                                            ? area.qualificationRequirement
                                                .map(
                                                  (requirement) =>
                                                    requirement.totalQuestions
                                                )
                                                .reduce(
                                                  (sum, total) => sum + total,
                                                  0
                                                )
                                            : 0}
                                        </TableCell>

                                        <TableCell
                                          style={{
                                            textAlign: 'center',
                                            verticalAlign: 'bottom',
                                            paddingLeft: '18px',
                                          }}
                                        >
                                          {area.qualificationRequirement
                                            ? area.qualificationRequirement
                                                .map(
                                                  (requirement) =>
                                                    requirement.passQuestions
                                                )
                                                .reduce(
                                                  (sum, total) => sum + total,
                                                  0
                                                )
                                            : 0}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            verticalAlign: 'bottom',
                                          }}
                                        >
                                          {qualificationAccess === 'edit' && (
                                            <img
                                              className='pointer'
                                              src={pencil}
                                              alt='Edit pencil'
                                              onClick={(e) => {
                                                setSubQualificationId(id)
                                                setSelectedArea(area)
                                                dispatchModalEditIsVisible({
                                                  type: 'area',
                                                  current: false,
                                                })
                                              }}
                                            />
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    </Fragment>
                                  ))}
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell>
                                    {qualificationAccess === 'edit' && (
                                      <AddButton
                                        className='add-button'
                                        data-id={id}
                                        onClick={(e) => {
                                          setSubQualificationId(id)
                                          dispatchModalAddIsVisible({
                                            type: 'area',
                                            current: false,
                                          })
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )
              )}
            </TableBody>
          </Table>
        )}
        {!toggleOption && (
          <Grid container item xs={12}>
            <Grid container item xs={12}>
              {/** -------------------------------- Qualification section -------------------------------- */}
              <Grid container item xs={12} className='qualification'>
                {/** -------------------------------- Sub-qualification section -------------------------------- */}

                <Grid container item xs={12} className='sub-qualifications'>
                  {/** Table Head */}
                  <Grid
                    container
                    item
                    xs={12}
                    className='table-head'
                    sx={{
                      fontFamily: 'Roboto, Helvetica, Arial,sans-serif',
                      fontWeight: '500',
                      fontSize: '0.875rem',
                      lineHeight: '1.5rem',
                      letterSpacing: '0.01071em',
                      borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      textAlign: 'left',
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                  >
                    <Grid container item xs={4} alignItems='center'>
                      <p>{t('pageSubQualification.tableCell.subQualification')}</p>
                    </Grid>

                    <Grid container item xs={8} alignItems='center'>
                      <p>{t('pageSubQualification.tableCell.qualificationAreas')}</p>
                      <span style={{}}>
                        <IconButton
                          classes={{ root: 'collapse' }}
                          sx={{
                            width: '24px',
                            height: '24px',
                          }}
                          aria-label={t('pageSubQualification.expandCollapseButton')}
                          size='small'
                          onClick={(e) => {
                            setQuestionCollapse((prev) => !prev)
                          }}
                        >
                          {questionCollapse ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </span>
                    </Grid>
                  </Grid>

                  {/** Table Body */}
                  <Grid container item xs={12}>
                    {subQualificationsList &&
                      subQualificationsList.map((sub) => {
                        const {
                          description,
                          id,
                          name: subName,
                          qualificationArea,
                        } = sub
                        return (
                          <Grid
                            key={id}
                            container
                            item
                            xs={12}
                            py={1}
                            className='table-body'
                            sx={{ borderBottom: '1px solid #e0e0e0' }}
                          >
                            <Grid item xs={12} sx={{ img: { float: 'right' } }}>
                              <Link
                                onClick={() => {
                                  setSelectedSubQualification({
                                    id,
                                    subName,
                                    qualificationArea,
                                    description,
                                  })
                                }}
                                to={(location) => ({
                                  ...location,
                                  pathname: `${location.pathname}/${subName}`,
                                })}
                              >
                                {subName}
                              </Link>
                              {qualificationAccess === 'edit' && (
                                <img
                                  className='pointer'
                                  id={id}
                                  src={pencil}
                                  alt='Edit pencil'
                                  onClick={(e) => {
                                    setSubQualificationId(id)
                                    dispatchModalEditIsVisible({
                                      type: 'sub',
                                      current: false,
                                    })
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Collapse in={questionCollapse}>
                                <Grid item xs={12}>
                                  <p>{description}</p>
                                  {/** Sub Description */}
                                </Grid>
                                {/* -------- Areas -------- */}
                                {qualificationArea &&
                                  qualificationArea.map((area) => {
                                    return (
                                      <Grid
                                        key={'area ' + area.id}
                                        container
                                        item
                                        xs={12}
                                        className='areas'
                                      >
                                        <Grid item xs={4}></Grid>
                                        <Grid item xs={7.5}>
                                          <Grid pt={2}>
                                            <div className='area-flex'>
                                              <p className='area-title bold'>
                                                {area.name}
                                              </p>
                                              {qualificationAccess ===
                                                'edit' && (
                                                <img
                                                  className='pointer'
                                                  src={pencil}
                                                  alt='Edit pencil'
                                                  onClick={(e) => {
                                                    setSubQualificationId(id)
                                                    setSelectedArea(area)
                                                    dispatchModalEditIsVisible({
                                                      type: 'area',
                                                      current: false,
                                                    })
                                                  }}
                                                />
                                              )}
                                            </div>
                                            <p className='area-body'>
                                              {area.description}
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    )
                                  })}
                                <Grid container>
                                  <Grid item xs={4}></Grid>
                                  <Grid item>
                                    {qualificationAccess === 'edit' && (
                                      <AddButton
                                        className='add-button'
                                        data-id={id}
                                        onClick={(e) => {
                                          setSubQualificationId(id)
                                          dispatchModalAddIsVisible({
                                            type: 'area',
                                            current: false,
                                          })
                                        }}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Collapse>
                            </Grid>
                          </Grid>
                        )
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    </SubTable>
  )
}

export default SubQualificationTable
