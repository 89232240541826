// Handle the addFormData-state for addQualification-modal onChange
export const updateField = (e, { addFormData, setAddFormData }) => {
  // Storing the field-name and field-value from the input-fields
  const fieldName = e.target.getAttribute('name')
  const fieldValue = e.target.value

  // Create a copy of addFormData-state
  const newFormData = { ...addFormData }

  // Manipulating the field-value for the current field
  newFormData[fieldName] = fieldValue

  // Updating the addFormData-state withe the newFormData-object
  setAddFormData(newFormData)
}

export const selectedDatePlus60Days = (selectedDate, plusDays = 60) => {
  const currentDate = new Date(selectedDate)
  currentDate.setDate(currentDate.getDate() + plusDays) //number  of days to add, e.x. 15 days
  const dateFormated = currentDate.toISOString().split('T')[0]
  return dateFormated
}

/**
 * ---------- Convert first letter in each word to uppercase ----------
 * @param {string} [String.input] - Some random sentence.
 * @param {String} [String.output] - Some Random Sentence.
 */
export const uppercaseWords = (str) =>
  str.toLowerCase().replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())

/******************************* HELPERS FOR REQUIREMENT QUESTIONS *******************************/

/** Update answer in state */
export const handleAddAnswerChange = (
  e,
  index,
  questionType,
  questionFormData,
  setQuestionFormData
) => {
  let newAnswers = []

  if (questionType === 'videoAnswer') {
    const videoId = e.target.value.split('v=')[1]
    newAnswers = questionFormData.questionAnswer.map((answer, i) => {
      return i === index ? { ...answer, questionVideoUrl: videoId } : answer
    })
  } else {
    newAnswers = questionFormData.questionAnswer.map((answer, i) => {
      return i === index ? { ...answer, answer: e.target.value } : answer
    })
  }
  setQuestionFormData({
    ...questionFormData,
    questionAnswer: newAnswers,
  })
}

/** Add a new answer option to state */
export const handleAddAnswerOption = (
  questionFormData,
  setQuestionFormData
) => {
  setQuestionFormData({
    ...questionFormData,
    questionAnswer: [
      ...questionFormData.questionAnswer,
      {
        answer: '',
        questionImageFile: null,
        questionImageKey: '',
        questionVideoUrl: '',
        correct: false,
        id: Math.random(),
      },
    ],
  })
}

/** Remove an answer option from state */
export const handleRemoveAnswer = (
  answerId,
  questionFormData,
  setQuestionFormData,
  questionType,
  answerImageRefs
) => {
  // Check the index of the answer to remove
  const answerIndex = questionFormData.questionAnswer.findIndex(
    (answer) => answer.id === answerId
  )

  // If the answer to remove is the first answer, set the next answer to correct
  if (answerIndex === 0) {
    const newAnswers = questionFormData.questionAnswer
      .map((answer, i) => {
        return i === 1 ? { ...answer, correct: true } : answer
      })
      .filter((answer) => answer.id !== answerId)

    setQuestionFormData({
      ...questionFormData,
      questionAnswer: newAnswers,
    })
  } else {
    const newAnswers = questionFormData.questionAnswer.filter(
      (answer) => answer.id !== answerId
    )
    setQuestionFormData({
      ...questionFormData,
      questionAnswer: newAnswers,
    })
  }

  if (questionType === 'imageAnswer') {
    answerImageRefs.current.splice(answerIndex, 1)
  }
}
