import { useContext } from 'react'
import { t } from 'i18next'

import { Button, Delete } from '../../../../../components/button/Button'
import Modal from '../../../../../components/modal/Modal'
import { SubContext } from '../Survey'

const SurveyStart = () => {
  const { selectedSurvey, setShowStartModal, setSurveyStarted, startSurvey } =
    useContext(SubContext)

  return (
    <Modal setState={setShowStartModal} title={t('pageSurvey.survey')}>
      <p className='bold start-info'>
        {t('pageSurvey.startInfo', {
          attempt: selectedSurvey.attempts + 1,
        })}
      </p>
      <div className='button-container'>
        <Delete onClick={() => setShowStartModal(false)} className='button'>
          {t('pageSurvey.cancel')}
        </Delete>
        <Button
          onClick={() => {
            setSurveyStarted(true)
            setShowStartModal(false)
            startSurvey()
          }}
        >
          {t('pageSurvey.start')}
        </Button>
      </div>
    </Modal>
  )
}

export default SurveyStart
