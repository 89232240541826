import styled from 'styled-components';

const EducationModulesWrapper = styled.main`
    

    &.education-modules {
        width: 1160px;
    }

    .modules-table {
        margin: 50px 0;
        
        .page-title {
            margin-top: 20px;
            
            h3 {
                font-size: 24px;

            }

            img {
                position: relative;
                top: 1px;
            }
        }

        .subtitle {
            font-size: 14px;
            font-weight: 700;
            color: #929292;
        }

        .MuiTable-root {
            .subtitle {
                font-size: 18px;
            }
        }
    }

    .modal {
        width: 600px;
        
        .modal-content {
            padding: 30px;
    
            span {
                color: #858585;
            }
    
            form {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .module-link {
                    display: block;
                    width: fit-content;
                    border-radius: 50px;
                    
                    button {
                        padding-left: 40px;
                        padding-right: 40px;
                    }
                }

                .buttons {
                    align-self: flex-end;
                    display: flex;
                    gap: 15px;
                }
            }

            .modal { //DeleteModal

                p {
                    margin-bottom: 100px;
                }

                .buttons {
                    display: flex;
                    gap: 15px;
                    justify-content: center;
                }
            }
        }
    }

`;

export default EducationModulesWrapper;