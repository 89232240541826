import { Grid } from '@mui/material'
import React, { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { ReactComponent as LoadingSpinner } from '../../../assets/img/spinner.svg'
import { CompetenceProfileContext } from '../CompetenceProfile'
import { useTranslation } from 'react-i18next'

const OngoingSurveysBody = ({ surveys }) => {  
  const { pathname } = useLocation()
  const { setSelectedSurvey, setSelectedSurveyID } = useContext(
    CompetenceProfileContext
  )

    if (!surveys) {
      return <LoadingSpinner width={100} height={100} />
    }

    const lowestIds = surveys.reduce((acc, survey) => {
      if (survey.attempts === 3 && survey.qualificationName) {
        const key = survey.qualificationName;
        if (!acc[key] || survey.id < acc[key]) {
          acc[key] = survey.id; // Store the lowest id for each qualificationName
        }
      }
      return acc;
    }, {});
  
    // Filter out surveys without a qualificationName and those with higher IDs for the same qualificationName with 3 attempts
    const filteredSurveys = surveys.filter(survey => {
      return survey.qualificationName && (survey.attempts !== 3 || survey.id === lowestIds[survey.qualificationName]);
    });

    const surveyElements = filteredSurveys.map(
      ({
        attempts,
        completed,
        endDate,
        id,
        qualificationName,
        startDate,
        price,
        qualificationId,
      }) => (
        <Fragment key={id}>
          <Grid item xs={7}>
              <Link
                to={`${pathname}/${qualificationName}?type=qualification`}
                onClick={() => {
                  setSelectedSurveyID(id)
                  setSelectedSurvey({
                    attempts,
                    completed,
                    endDate,
                    id,
                    qualificationName,
                    startDate,
                    price,
                    qualificationId,
                  })
                }}
              >
                {qualificationName}
              </Link>
            </Grid>
            <Grid item xs={3}>
              {startDate}
            </Grid>
            <Grid item xs={3}>
              {endDate}
            </Grid>
            <Grid item xs={3}>
              {attempts ?? 0}/3
            </Grid>
            <Grid item xs={3}>
              <p style={{ color: '#bfbecf' }}></p>
            </Grid>
            <Grid item xs={1} sx={{ textAlign: 'right' }}></Grid>
        </Fragment>
      )
    );

    return surveyElements;         
}

const OngoingSurveys = () => {
  /** ---------- CONTEXTS ---------- */
  const { t } = useTranslation()
  const { ongoing } = useContext(CompetenceProfileContext)

  return (
    <>
      {/** ---------- Ongoing Surveys ---------- */}
      <Grid item xs={12} mt={6}>
        <Grid
          container
          item
          xs={12}
          justifyContent='space-between'
          sx={{
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          <h4
            style={{
              color: '#bfbecf',
              paddingBottom: '5px',
            }}
          >
            {t('competenceProfile.initiatedMappings')}
          </h4>
        </Grid>
        {ongoing?.filter((survey) => survey.qualificationName).length > 0 && (
          <Grid container item xs={12}>
            {/** Grid head */}
            <Grid
              container
              item
              sx={{ borderBottom: '1px solid #e0e0e0', padding: '10px' }}
              columns={20}
            >
              <Grid item xs={7}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.qualification')}</p>
              </Grid>
              <Grid item xs={3}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.startDate')}</p>
              </Grid>
              <Grid item xs={3}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.finishDate')}</p>
              </Grid>
              <Grid item xs={3}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.completed')}</p>
              </Grid>
              <Grid item xs={3}>
                <p style={{ color: '#bfbecf' }}></p>
              </Grid>
              <Grid item xs={1} sx={{ textAlign: 'right' }}></Grid>
            </Grid>
            {/** Grid body */}
            <Grid
              container
              item
              sx={{ borderBottom: '1px solid #e0e0e0', padding: '10px' }}
              columns={20}
            >
              <OngoingSurveysBody surveys={ongoing} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default OngoingSurveys
