import React, { useEffect, useContext, useState, createContext } from 'react'
import PageTitle from '../../../components/pageTitle/PageTitle'
import { PROFILE } from '../services/routes'

import { Wrapper } from './../style'

import { getAllProfessionalRole } from '../services/Crud'
import { Context } from '../../../utils/Context'
import ProfessionalRoleTable from './ProfessionalRoleTable'
import ModalAddProfessionalRole from './ModalAddProfessionalRole'
import { Route, useRouteMatch } from 'react-router-dom'
import Profile from '../competenceProfile/Profile'
import { AddButton } from '../../../components/button/Button'
import { useTranslation } from 'react-i18next'
export const ProfessionalRoleContext = createContext()

const CompetencePlanning = () => {
  const { t } = useTranslation()
  const {
    organisation: { organisationNumber },
  } = useContext(Context)
  const [professionalRoleList, setProfessionalRoleList] = useState([])
  const [professionalRole, setProfessionalRole] = useState({})
  const [modalAddIsVisible, setModalAddIsVisible] = useState(false)
  const [modalEditIsVisible, setModalEditIsVisible] = useState(false)
  const [modalDeleteIsVisible, setModalDeleteIsVisible] = useState(false)
  const [selectedRole, setSelectedRole] = useState(
    JSON.parse(localStorage.getItem('selectedRole'))
  )

  const { path } = useRouteMatch()

  // ContextProvider-object
  const initialContext = {
    organisationNumber,
    professionalRoleList,
    setProfessionalRoleList,
    professionalRole,
    setProfessionalRole,
    modalAddIsVisible,
    setModalAddIsVisible,
    modalEditIsVisible,
    setModalEditIsVisible,
    modalDeleteIsVisible,
    setModalDeleteIsVisible,
    selectedRole,
    setSelectedRole,
  }

  useEffect(() => {
    let isLoading = true
    getAllProfessionalRole({ organisationNumber }).then((professionalRoles) => {
      if (isLoading) {
        setProfessionalRoleList(professionalRoles)
      }
    })
    return () => {
      isLoading = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    localStorage.setItem('selectedRole', JSON.stringify(selectedRole))
  }, [selectedRole])

  return (
    <ProfessionalRoleContext.Provider value={initialContext}>
      {(modalAddIsVisible && <ModalAddProfessionalRole option='add' />) ||
        (modalEditIsVisible && <ModalAddProfessionalRole option='edit' />) ||
        (modalDeleteIsVisible && <ModalAddProfessionalRole option='delete' />)}
      <Route exact path={path}>
        <Wrapper className='wrapper-prs'>
          <br />
          <PageTitle title={t('competencePlanning.profile.modalTitle')} className='page-title'>
            <br />
            <p>
            {t('competencePlanning.profile.info1')}
            </p>
            <br />
            <p>
            {t('competencePlanning.profile.info2')}
            </p>
            <br />
            <p>
            {t('competencePlanning.profile.info5')}
            </p>
            <br />
            <p>
            {t('competencePlanning.profile.info3')}{t('competencePlanning.profile.info4')}
            </p>
            <br />
            <p>
            {t('competencePlanning.profile.info6')}
            </p>
          </PageTitle>
          <div className='table-wrapper'>
            <h4>{t('competencePlanning.profile.rolesFunctions')}</h4>
            <ProfessionalRoleTable />
            <AddButton
              className='add-button'
              onClick={(e) => setModalAddIsVisible(true)}
            />
          </div>
        </Wrapper>
      </Route>
      <Route path={PROFILE} component={Profile} />
    </ProfessionalRoleContext.Provider>
  )
}

export default CompetencePlanning
