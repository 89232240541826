import styled from 'styled-components';

import bannerBg from '../../assets/img/banner-bg.png'

export const Wrapper = styled.div`
    height: 457px;
    background-image: url(${bannerBg});
    background-size: cover;
    color: #FFFFFF;
    text-align: center;
    
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 457px;
        background-color: rgba(46, 55, 81, 0.45);
    }

    h1 {
    text-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
    }

`