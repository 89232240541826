import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000080;

    .modal {
        background-color: #CFCFD5;
        border-radius: 5px;
        padding-bottom: 19px;
        max-height: 95vh;
        max-width: 95vw;
        overflow: auto;

        .modal-header {
            height: 66px;
            width: 100%;
            background-color: #CFCFD5;
            padding: 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;

            h4 {
                margin-right: 20px;
            }

            svg {
                cursor: pointer;
            }
        }

        .modal-content {
            padding-left: 30px;
            padding-right: 30px;
            background-color: white;
        }
    }
`