import React, { Fragment, useContext, useEffect, useState } from 'react'

import { Button, Delete } from '../../../../components/button/Button'
import Modal from '../../../../components/modal/Modal'
import {simpleUser} from '../../../../utils/Amplify'
import { deleteQualification } from '../../functions'
import { QualificationContext } from '../Qualification'
import { useTranslation } from 'react-i18next'

const DeleteQualification = ({ option }) => { 
  const { t } = useTranslation();
  const {
    setModalDeleteIsVisible,
    setModalEditIsVisible,
    qualificationsList,
    setQualificationsList,
    qualificationId,
    deleteOption,
    selectedQualification,
    setSelectedQualification,
    selectedCountryToRemove,
  } = useContext(QualificationContext)
  // To get the selected qualification
  const currentIndex = qualificationsList.findIndex(
    (qualification) => qualification.id === qualificationId
  )

  // The name is for the delete modal msg and the id is to the deleteQualification function
  const [currentQualification, setCurrentQualification] = useState({
    name: '',
    id: Number(),
  })

  useEffect(() => {
    // This is to update the state of name and id every time the edit btn is clicked in qualification table
    !deleteOption &&
      setCurrentQualification({
        name: qualificationsList[currentIndex].qualificationName,
        id: qualificationsList[currentIndex].id,
      })
    // eslint-disable-next-line
  }, [qualificationId])
  const { id, country } = selectedCountryToRemove

  const unPublishQualification = async (e) => {
    let newQualificationsList = qualificationsList

    const selectedQualificationIndex = qualificationsList.findIndex(
      (qualification) => qualification.id === selectedQualification.id
    )

    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await simpleUser()).token,
      },
    }

    try {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/qualification/remove/country/${id}`, options)

      const newSelectedQualification =
        selectedQualification.qualificationCountries
          .filter((country) => country.id !== id)
          .map((country) => country)

      setSelectedQualification({
        ...selectedQualification,
        qualificationCountries: newSelectedQualification,
      })

      newQualificationsList[selectedQualificationIndex].qualificationCountries =
        newSelectedQualification

      setQualificationsList(newQualificationsList ? newQualificationsList : [])
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Fragment>
      <Modal
        title={
          deleteOption === 'country'
            ? t('pageQualifications.deleteTitleFromCatalog')
            : t('pageQualifications.deleteTitle')
        }
        setState={setModalDeleteIsVisible}
      >
        <Fragment>
          <br />
          {deleteOption !== 'country' ? (
            qualificationsList[currentIndex].qualificationCountries.length >
              0 ? (
              <div className='un-publish-msg-wrapper'>
                <p>
                {t('pageQualifications.modalRemoveQualification.confirmDeleteQualificationMsgOnPublished')}
                <br />
                </p>
                <div className='btn-wrapper'>
                  <Button
                    type='submit'
                    style={{ marginLeft: '10px' }}
                    onClick={() => setModalDeleteIsVisible(false)}
                  >
                    {t('buttons.cancelButton')}
                  </Button>
                </div>
              </div>
            ) : (
              <Fragment>
                <p>
                {t('pageQualifications.modalRemoveQualification.confirmDeleteQualificationMsg-1')}{' '}
                  <span className='bold'>{currentQualification.name}</span>?
                </p>
                <br />
                <p>
                {t('pageQualifications.modalRemoveQualification.confirmDeleteQualificationMsg-2')}
                </p>
                <br />
                <p>{t('pageQualifications.modalRemoveQualification.confirmDeleteQualificationMsg-3')}</p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '36px',
                    paddingBottom: '25px',
                  }}
                >
                  <Delete
                    onClick={() =>
                      deleteQualification(
                        setModalEditIsVisible,
                        setModalDeleteIsVisible,
                        setQualificationsList,
                        currentQualification.id
                      )
                    }
                  >
                    {t('buttons.deleteButton')}
                  </Delete>
                  <Button
                    type='submit'
                    style={{ marginLeft: '10px' }}
                    onClick={() => setModalDeleteIsVisible(false)}
                  >
                    {t('buttons.cancelButton')}
                  </Button>
                </div>
              </Fragment>
            )
          ) : (
            <div>
              <p>
              {t('pageQualifications.modalRemoveQualification.confirmDeleteQualificationMsg-0')}{' '}
                <span className='bold'>{country}</span> {t('pageQualifications.modalRemoveQualification')}
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '36px',
                  paddingBottom: '25px',
                }}
              >
                <Delete
                  onClick={(e) => {
                    unPublishQualification(e, {
                      selectedQualification,
                      id,
                      country,
                    })
                    setModalDeleteIsVisible(false)
                  }}
                >
                  {t('buttons.removeButton')}
                </Delete>
                <Button
                  type='submit'
                  style={{ marginLeft: '10px' }}
                  onClick={() => setModalDeleteIsVisible(false)}
                >
                  {t('buttons.cancelButton')}
                </Button>
              </div>
            </div>
          )}
        </Fragment>
      </Modal>
    </Fragment>
  )
}

export default DeleteQualification
