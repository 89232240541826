import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Amplify from 'aws-amplify'
import awsExports from './aws-exports'
import './i18next'
import { BrowserRouter } from 'react-router-dom'
Amplify.configure(awsExports)

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Laddar ...</div>}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)
