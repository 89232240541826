import styled from 'styled-components'

export const Wrapper = styled.div`
  box-sizing: border-box;

  .title {
    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
    z-index: 1;

    img {
      height: 20px;
      width: 20px;
      padding: 2px 2px;
      margin-left: 20px;
      cursor: pointer;

      transition: transform 0.15s ease-in-out;
    }

    .open {
      transform: rotate(90deg);
    }
  }

  .info {
    transform-origin: top;
    margin-top: 5px;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
  }

  .show-info {
    height: fit-content;
    transition: all 0.15s ease-in-out;
  }

  .hide-info {
    height: 0;
    transition: all 0.15s ease-in-out;
  }

  .info, .custom-info {
    line-height: 1.4em;
  }

  hr {
    margin-top: 5px;
    border: none;
    border-top: 1px solid #cfcfd5;
  }

  /* PageTitleSpecial */
  .title2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    background-color: white;
    z-index: 1;

    img {
      height: 20px;
      width: 20px;
      padding: 2px 2px;
      margin-left: 20px;
      cursor: pointer;

      transition: transform 0.15s ease-in-out;
    }

    .open {
      transform: rotate(90deg);
    }

    .custom-info {
      margin-top: 7px;
    }
  }
`
