/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = process.env.REACT_APP_ENV === "development" ? {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:7069a4ab-d650-438f-a6dc-15a07d3848b2',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_rfs4DLghH',
  aws_user_pools_web_client_id: '6o0akvscb7o51r0ieglfannpn0',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
}:
 {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:48f0fc87-ebf3-4531-bf98-5153d9d3e117',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_W54ZnDmxH',
  aws_user_pools_web_client_id: '4og74g894psddhv9p3eqpp1m79',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
}
export default awsmobile
