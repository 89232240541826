import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { EmployeesContext } from '../Employees'

const EmployeeInfo = () => {
  const { t } = useTranslation()

  const { levels, row } = useContext(EmployeesContext)

  return (
    <TableContainer classes={{ root: 'table-container' }}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('pageEmployees.employeeSearch.textFields.employeeNumber')}
            </TableCell>
            <TableCell>
              {t('pageEmployees.employeeSearch.textFields.firstName')}
            </TableCell>
            <TableCell>
              {t('pageEmployees.employeeSearch.textFields.lastName')}
            </TableCell>
            <TableCell>
              {t('pageEmployees.searchResults.table.professionalRoleName')}
            </TableCell>
            {levels.levelOne && <TableCell>{t('pageEmployees.searchResults.table.level1')}</TableCell>}
            {levels.levelTwo && <TableCell>{t('pageEmployees.searchResults.table.level2')}</TableCell>}
            {levels.levelThree && <TableCell>{t('pageEmployees.searchResults.table.level3')}</TableCell>}
            {levels.levelFour && <TableCell>{t('pageEmployees.searchResults.table.level4')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{row.employeeNumber}</TableCell>
            <TableCell>{row.firstName}</TableCell>
            <TableCell>{row.lastName}</TableCell>
            <TableCell>{row.professionalRoleName}</TableCell>
            {levels.levelOne && <TableCell>{row.levelOne}</TableCell>}
            {levels.levelTwo && <TableCell>{row.levelTwo}</TableCell>}
            {levels.levelThree && <TableCell>{row.levelThree}</TableCell>}
            {levels.levelFour && <TableCell>{row.levelFour}</TableCell>}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EmployeeInfo
