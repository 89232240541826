import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { Button } from './../components/button/Button'
import Modal from './../components/modal/Modal'
import StyledLabel from './../components/styledLabel/StyledLabel'

const ConfirmSignup = ({ email, setVerifyModuleIsHidden, signIn }) => {
  const [verifyCode, setVerifyCode] = useState('')
  const handleSubmit = async (userName, code) => {
    try {
      setVerifyModuleIsHidden(true)
      await Auth.confirmSignUp(userName, code)
      await signIn()
      return <Redirect to='/home' />
    } catch (error) {
      setVerifyModuleIsHidden(false)
      return 'error confirming sign up: ' + error
    }
  }
  const { t } = useTranslation()
  return (
    <Modal
      title={t('pageSignUp.verifyTitle')}
      setState={setVerifyModuleIsHidden}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(email, verifyCode)
        }}
      >
        <StyledLabel>
          <p>
            {t('pageSignUp.verifyEmailTop')}
            <br />
            {t('pageSignUp.verifyEmailBottom')}
          </p>
          <input
            type='text'
            value={verifyCode}
            onChange={(e) => setVerifyCode(e.target.value)}
          />
          <Button>{t('pageSignUp.verifyEmailBtn')}</Button>
        </StyledLabel>
      </form>
    </Modal>
  )
}

export default ConfirmSignup
