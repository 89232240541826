import { simpleUser } from "./Amplify"

export const freshPerms = async () => {
    const perms = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/individual/profile/${(await simpleUser()).email}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    ).then(res => res.json())
        .then(data => ({ ...data.organisationEmployee }))

    return perms
} 