export const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const passwordIsValid = (password) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{8,}$/.test(
        password
    )
}

export const phoneIsValid = (phoneNumber) => {
    return /^\+(?:[0-9] ?){6,14}[0-9]$/.test(phoneNumber)
}