import { Grid } from '@mui/material'
import { useContext } from 'react'
import { CatalogContext } from '../Catalog'
import { QualificationWrapper } from './style'
import { useRouteMatch } from 'react-router-dom'
import React, { useState, useEffect, createContext } from 'react'
import { LinkRight } from '../../../components/button/Button'
import { getSubQualifications } from '../../../private/qualifications/functions'
import BreadCrumbs from '../../../utils/BreadCrumbs'
import PageTitleSpecial from '../../../components/pageTitle/PageTitleSpecial'
import { useTranslation } from 'react-i18next'

const CatalogQualification = () => {
  const { t } = useTranslation()
  const SubContext = createContext(null)
  const { selectedResult, setSelectedSubQualification } =
    useContext(CatalogContext)
  const [subQualifications, setSubQualifications] = useState([])
  const {
    id,
    qualificationName,
    qualificationDescription,
    qualificationCountries,
    version,
  } = selectedResult

  useEffect(() => {
    getSubQualifications(id).then((data) => {
      setSubQualifications(data)
    })
    // eslint-disable-next-line
  }, [])
  const initialContext = { subQualifications, setSubQualifications }
  const { url } = useRouteMatch()

  return (
    <SubContext.Provider value={initialContext}>
      <QualificationWrapper className='sub-page-wrapper'>
        <Grid container item xs={12}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <BreadCrumbs />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} py={1}>
              <p className='pre-title'>
                {t('catalogue.morePage.qualificationAndCompetencyAreas')}
              </p>
            </Grid>
            {/** -------------------------------- Qualification section -------------------------------- */}
            <Grid container item xs={12} spacing={2} className='qualification'>
              <Grid container item xs={12} mb={3}>
                <PageTitleSpecial
                  className='qualification-title'
                  title={
                    <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                      <span>{qualificationName}</span>
                      <span style={{ fontSize: '16px', color: '#929292' }}>Ver {version} | {qualificationCountries[0].localDate}</span>
                    </span>
                  }
                  info={qualificationDescription}
                />
              </Grid>

              {/** -------------------------------- Sub-qualification section -------------------------------- */}

              <Grid container item xs={12} className='sub-qualifications'>
                {/** Table Head */}
                <Grid container item xs={12} className='table-head'>
                  <Grid item xs={4}>
                    <h4>{t('catalogue.morePage.subQualification')}</h4>
                  </Grid>
                  <Grid item xs={8}>
                    <h4>{t('catalogue.morePage.competencyArea')}</h4>
                  </Grid>
                </Grid>

                {/** Table Body */}
                <Grid container item xs={12} pt={2}>
                  {subQualifications &&
                    subQualifications.map((sub) => {
                      const {
                        description,
                        id,
                        name: subName,
                        qualificationArea,
                      } = sub
                      return (
                        <Grid
                          key={id}
                          container
                          item
                          xs={12}
                          pt={2}
                          className='table-body'
                        >
                          <Grid item xs={4}>
                            <h4>{subName}</h4>
                          </Grid>
                          <Grid item xs={12}>
                            <p>{description}</p>
                            {/** Sub Description */}
                          </Grid>

                          {/* -------- Areas -------- */}
                          {qualificationArea &&
                            qualificationArea.map(
                              ({ description, id, name: areaName }) => (
                                <Grid
                                  key={id}
                                  container
                                  item
                                  xs={12}
                                  className='areas'
                                >
                                  <Grid item xs={4}></Grid>
                                  <Grid item xs={8}>
                                    <Grid pt={2}>
                                      <p className='area-title bold'>
                                        {areaName}
                                      </p>
                                      <p className='area-body'>{description}</p>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}

                          <Grid item xs={12} align='right'>
                            <LinkRight
                              onClick={() => {
                                setSelectedSubQualification(sub)
                              }}
                              to={`${url}/${sub.name}`}
                            >
                              {t('catalogue.morePage.more')}
                            </LinkRight>
                          </Grid>
                        </Grid>
                      )
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </QualificationWrapper>
    </SubContext.Provider>
  )
}

export default CatalogQualification
