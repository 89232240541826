import { Grid } from '@mui/material'
import React, { Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import pencil from '../../../assets/img/Edit_pencil.svg'
import { ReactComponent as LoadingSpinner } from '../../../assets/img/spinner.svg'
import { AddButton } from '../../../components/button/Button'
import { EmployeeSubPageContext } from '../EmployeeSubPage'

const OngoingSurveysBody = ({ surveys }) => {
  const { pathname } = useLocation()
  const {
    setSelectedSurveyID,
    setEditQualificationSurveyModal,
    setSelectedSurvey, 
    initializeAccess,
  } = useContext(EmployeeSubPageContext)
  
  const lowestIds = surveys.reduce((acc, survey) => {
    if (survey.attempts === 3 && survey.qualificationName) {
      const key = survey.qualificationName;
      if (!acc[key] || survey.id < acc[key]) {
        acc[key] = survey.id;
      }
    }
    return acc;
  }, {});

  // Filter out surveys without a qualificationName and those with higher IDs for the same qualificationName with 3 attempts
  const filteredSurveys = surveys.filter(survey => {
    return survey.qualificationName && (survey.attempts !== 3 || survey.id === lowestIds[survey.qualificationName]);
  });

  return !surveys ? (
    <LoadingSpinner width={100} height={100} />
  ) : (
    surveys.filter((survey) => survey.qualificationName).length > 0 &&
    filteredSurveys
        .filter((survey) => survey.qualificationName)
        .map(
          ({
            attempts,
            completed,
            endDate,
            id,
            qualificationName,
            startDate,
            price,
            qualificationId,
          }) => (
            <Fragment key={id}>
              <Grid item xs={7}>
                <Link
                  to={`${pathname}/${qualificationName}?type=qualification`}
                  onClick={() => setSelectedSurveyID(id)}
                >
                  {qualificationName}
                </Link>
              </Grid>
              <Grid item xs={3}>
                {startDate}
              </Grid>
              <Grid item xs={3}>
                {endDate}
              </Grid>
              <Grid item xs={3}>
                {attempts ?? 0}/3
              </Grid>
              <Grid item xs={3}>
                <p style={{ color: '#bfbecf' }}></p>
              </Grid>
              <Grid item xs={1} sx={{ textAlign: 'right' }}>
                {initializeAccess === 'edit' && (
                  <img
                    src={pencil}
                    alt='Certificate icon'
                    className='pointer'
                    onClick={(e) => {
                      setEditQualificationSurveyModal(true)
                      setSelectedSurvey({
                        attempts,
                        completed,
                        endDate,
                        id,
                        qualificationName,
                        startDate,
                        price,
                        qualificationId,
                      })
                      localStorage.setItem(
                        'employeeSelectedSurvey',
                        JSON.stringify({
                          attempts,
                          completed,
                          endDate,
                          id,
                          qualificationName,
                          startDate,
                          price,
                          qualificationId,
                        })
                      )
                    }}
                  />
                )}
              </Grid>
            </Fragment>
          )
        )
  )
}

const OngoingSurveys = () => {
  const { t } = useTranslation();
  /** ---------- CONTEXTS ---------- */
  const { setAddSurveyModal, setAddBtnClicked, initializeAccess, ongoing } =
    useContext(EmployeeSubPageContext)

  return (
    <>
      {/** ---------- Ongoing Surveys ---------- */}
      <Grid item xs={12} mt={6}>
        <Grid
          container
          item
          xs={12}
          justifyContent='space-between'
          sx={{
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          <h4
            style={{
              color: '#bfbecf',
              paddingBottom: '5px',
            }}
          >
          {t('competenceProfile.initiatedMappings')}
          </h4>
          {initializeAccess === 'edit' && (
            <AddButton
              className='add-button'
              data-id={'id'}
              onClick={(e) => {
                setAddSurveyModal(true)
                setAddBtnClicked(true)
              }}
            />
          )}
        </Grid>
        {ongoing?.filter((survey) => survey.qualificationName).length > 0 && (
          <Grid container item xs={12}>
            {/** Grid head */}
            <Grid
              container
              item
              sx={{ borderBottom: '1px solid #e0e0e0', padding: '10px' }}
              columns={20}
            >
              <Grid item xs={7}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.qualification')}</p>
              </Grid>
              <Grid item xs={3}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.startDate')}</p>
              </Grid>
              <Grid item xs={3}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.finishDate')}</p>
              </Grid>
              <Grid item xs={3}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.completed')}</p>
              </Grid>
              <Grid item xs={3}>
                <p style={{ color: '#bfbecf' }}></p>
              </Grid>
              <Grid item xs={1} sx={{ textAlign: 'right' }}></Grid>
            </Grid>
            {/** Grid body */}
            <Grid
              container
              item
              sx={{ borderBottom: '1px solid #e0e0e0', padding: '10px' }}
              columns={20}
            >
              <OngoingSurveysBody surveys={ongoing} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default OngoingSurveys
