import { Checkbox } from '@mui/material'
import _ from 'lodash'
import { useContext, useState } from 'react'
import dropdownArrow from '../../../assets/img/dropdown-arrow-dark.svg'
import { collapseSection, expandSection } from '../../../utils/Misc'
import { CatalogContext } from '../Catalog'
import { useTranslation } from 'react-i18next'
const Filter = () => {
  const { t } = useTranslation()
  const [expand, setExpand] = useState({
    qualifications: false,
    educations: false,
    area: false,
    type: false,
    access: false,
    seqf: false,
    author: false,
  })

  const { results, filter, setFilter } = useContext(CatalogContext)

  let qualifications = results.map(result => result = result.qualificationName ? result : result.qualification)

  return (
    <div className='filter'>
      <h3>{t('catalogue.finerSearch')}</h3>
      <div className='qualifications'>
        <div className='heading'>
          <h4>{t('catalogue.qualification')}</h4>
          <Checkbox
            checked={filter.qualifications}
            onChange={() =>
              setFilter({ ...filter, qualifications: !filter.qualifications })
            }
          />
        </div>
      </div>
      <div className='educations'>
        <div className='heading'>
          <h4>{t('catalogue.educations')}</h4>
          <Checkbox
            checked={filter.educations}
            onChange={() =>
              setFilter({ ...filter, educations: !filter.educations })
            }
          />
        </div>
      </div>
      <div className='area'>
        <div className='heading'>
          <h4>{t('catalogue.businessArea')}</h4>
          <img
            src={dropdownArrow}
            alt=''
            className={expand.area ? 'rotate' : ''}
            onClick={(e) => {
              const collapsibleElement =
                e.currentTarget.parentElement.parentElement.children[1]
              if (expand.area) {
                collapseSection(collapsibleElement)
              } else {
                expandSection(collapsibleElement)
              }
              setExpand({ ...expand, area: !expand.area })
            }}
          />
          {filter.qualificationArea.length > 0 && !expand.area && (
            <p className='filter-count'>{filter.qualificationArea.length}</p>
          )}
        </div>
        <div className='filter-content'>
          {_.uniqBy(qualifications, 'qualificationArea').map((result, i) => {
            return (
              <div key={i}>
                <p>{result.qualificationArea}</p>
                <Checkbox
                  onChange={(e, checked) => {
                    let temp = [...filter.qualificationArea]
                    if (checked) {
                      temp.push(result.qualificationArea)
                    } else {
                      temp = temp.filter(
                        (item) => item !== result.qualificationArea
                      )
                    }
                    setFilter({ ...filter, qualificationArea: temp })
                  }}
                />
              </div>
            )
          })}
          <br />
        </div>
      </div>
      <div className='type'>
        <div className='heading'>
          <h4>{t('common.type')}</h4>
          <img
            src={dropdownArrow}
            alt=''
            className={expand.type ? 'rotate' : ''}
            onClick={(e) => {
              const collapsibleElement =
                e.currentTarget.parentElement.parentElement.children[1]
              if (expand.type) {
                collapseSection(collapsibleElement)
              } else {
                expandSection(collapsibleElement)
              }
              setExpand({ ...expand, type: !expand.type })
            }}
          />
          {filter.qualificationType.length > 0 && !expand.type && (
            <p className='filter-count'>{filter.qualificationType.length}</p>
          )}
        </div>
        <div className='filter-content'>
          {_.uniqBy(qualifications, 'qualificationType').map((result, i) => {
            return (
              <div key={i}>
                <p>{result.qualificationType}</p>
                <Checkbox
                  onChange={(e, checked) => {
                    let temp = [...filter.qualificationType]
                    if (checked) {
                      temp.push(result.qualificationType)
                    } else {
                      temp = temp.filter(
                        (item) => item !== result.qualificationType
                      )
                    }
                    setFilter({ ...filter, qualificationType: temp })
                  }}
                />
              </div>
            )
          })}
          <br />
        </div>
      </div>
      <div className='access'>
        <div className='heading'>
          <h4>{t('common.level')}</h4>
          <img
            src={dropdownArrow}
            alt=''
            className={expand.access ? 'rotate' : ''}
            onClick={(e) => {
              const collapsibleElement =
                e.currentTarget.parentElement.parentElement.children[1]
              if (expand.access) {
                collapseSection(collapsibleElement)
              } else {
                expandSection(collapsibleElement)
              }
              setExpand({ ...expand, access: !expand.access })
            }}
          />
          {filter.qualificationAccess.length > 0 && !expand.access && (
            <p className='filter-count'>{filter.qualificationAccess.length}</p>
          )}
        </div>
        <div className='filter-content'>
          {_.uniqBy(qualifications, 'qualificationAccess').map((result, i) => {
            return (
              <div key={i}>
                <p>{result.qualificationAccess}</p>
                <Checkbox
                  onChange={(e, checked) => {
                    let temp = [...filter.qualificationAccess]
                    if (checked) {
                      temp.push(result.qualificationAccess)
                    } else {
                      temp = temp.filter(
                        (item) => item !== result.qualificationAccess
                      )
                    }
                    setFilter({ ...filter, qualificationAccess: temp })
                  }}
                />
              </div>
            )
          })}
          <br />
        </div>
      </div>
      <div className='SeQF'>
        <div className='heading'>
          <h4>{t('common.eqfLevel')}</h4>
          <img
            src={dropdownArrow}
            alt=''
            className={expand.seqf ? 'rotate' : ''}
            onClick={(e) => {
              const collapsibleElement =
                e.currentTarget.parentElement.parentElement.children[1]
              if (expand.seqf) {
                collapseSection(collapsibleElement)
              } else {
                expandSection(collapsibleElement)
              }
              setExpand({ ...expand, seqf: !expand.seqf })
            }}
          />
          {filter.qualificationEqf.length > 0 && !expand.seqf && (
            <p className='filter-count'>{filter.qualificationEqf.length}</p>
          )}
        </div>
        <div className='filter-content'>
          {_.uniqBy(qualifications, 'qualificationEqf').map((result, i) => {
            return (
              <div key={i}>
                <p>{result.qualificationEqf}</p>
                <Checkbox
                  onChange={(e, checked) => {
                    let temp = [...filter.qualificationEqf]
                    if (checked) {
                      temp.push(result.qualificationEqf)
                    } else {
                      temp = temp.filter(
                        (item) => item !== result.qualificationEqf
                      )
                    }
                    setFilter({ ...filter, qualificationEqf: temp })
                  }}
                />
              </div>
            )
          })}
          <br />
        </div>
      </div>
      <div className='author'>
        <div className='heading'>
          <h4>{t('common.createdBy')}</h4>
          <img
            src={dropdownArrow}
            alt=''
            className={expand.author ? 'rotate' : ''}
            onClick={(e) => {
              const collapsibleElement =
                e.currentTarget.parentElement.parentElement.children[1]
              if (expand.author) {
                collapseSection(collapsibleElement)
              } else {
                expandSection(collapsibleElement)
              }
              setExpand({ ...expand, author: !expand.author })
            }}
          />
          {filter.author.length > 0 && !expand.author && (
            <p className='filter-count'>{filter.author.length}</p>
          )}
        </div>
        <div className='filter-content'>
          {_.uniqBy(results, 'author').map((result, i) => {
            return (
              <div key={i}>
                <p>{result.author}</p>
                <Checkbox
                  onChange={(e, checked) => {
                    let temp = [...filter.author]
                    if (checked) {
                      temp.push(result.author)
                    } else {
                      temp = temp.filter((item) => item !== result.author)
                    }
                    setFilter({ ...filter, author: temp })
                  }}
                />
              </div>
            )
          })}
          <br />
        </div>
      </div>
    </div>
  )
}

export default Filter
