import React, { useContext } from "react";

import PageTitle from "../../components/pageTitle/PageTitle";
import { Wrapper } from "./style";
import { useTranslation } from "react-i18next";
import { Context } from "../../utils/Context";
import { TramRounded } from "@mui/icons-material";

const Home = () => {
  const { t } = useTranslation();

  const { mode, permissions } = useContext(Context);

  return (
    <Wrapper>
      <div className="grid">
        {mode === "employee" ? (
          <>
            {permissions.competencePlanningAccess !== "none" && (
              <>
                <PageTitle
                  linkTo="/competence-planning"
                  title={t("pageHome.competencePlanningTitle")}
                />
                <p>{t("pageHome.competencePlanningDesc")}</p>
                <hr />
              </>
            )}

            {permissions.employeeAccess !== "none" && (
              <>
                <PageTitle
                  linkTo="/Employees"
                  title={t("pageHome.employeesTitle")}
                />
                <p>{t("pageHome.employeesDesc")}</p>
                <hr />
              </>
            )}

            {permissions.qualificationAccess !== "none" && (
              <>
                <PageTitle
                  linkTo="/qualifications"
                  title={t("pageHome.qualificationsTitle")}
                />
                <p>{t("pageHome.qualificationsDesc")}</p>
                <hr />
              </>
            )}

            {permissions.educationAccess !== "none" && (
              <>
                <PageTitle
                  linkTo="/educations"
                  title={t("pageHome.educationsTitle")}
                />
                <p>{t("pageHome.educationsDesc")}</p>
                <hr />
              </>
            )}

            {/* {permissions.statisticAccess !== "none" && (
              <>
                <PageTitle
                  linkTo="/statistics"
                  title={t("pageHome.statsTitle")}
                />
                <p>{t("pageHome.statsDesc")}</p>
                <hr />
              </>
            )} */}
            {permissions.organisationAccess == true && (
              <>
                <PageTitle
                  linkTo="/organisations"
                  title={t("pageHome.organisationsTitle")}
                />
                <p>{t("pageHome.organisationsDesc")}</p>
                <hr />
              </>
            )}
          </>
        ) : (
          <>
            <PageTitle linkTo="/Profile" title={t("pageHome.profileTitle")} />
            <p>{t("pageHome.profileDesc")}</p>
            <hr />

            <PageTitle
              linkTo="/competence-profile"
              title={t("pageHome.myCompetenceProfileTitle")}
            />
            <p>{t("pageHome.myCompetenceProfileDesc")}</p>
            <hr />

            {/* <PageTitle linkTo="/cv" title={t("pageHome.resumeTitle")} />
            <p>{t("pageHome.resumeDesc")}</p>
            <hr />

            <PageTitle linkTo="/share" title={t("pageHome.shareTitle")} />
            <p>{t("pageHome.shareDesc")}</p>
            <hr /> */}
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default Home;
