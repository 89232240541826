import { Wrapper } from "./style";
import { ReactComponent as Cross } from "../../assets/img/cross-dark.svg";
import { useRef } from "react";
import useOutsideClick from "../../utils/useOutsideClick";

// Not self-closing. Add children between the Modal JSX tags.
// setState should be whatever state's 'set' function controls whether Modal is mounted.

const Modal = ({
  children,
  modalStyle,
  title,
  message,
  setState,
  closeOnClickOutside,
  dispatcher,
}) => {
  const modalRef = useRef(null);
  useOutsideClick(modalRef, setState);

  // Check if the dispatch is for the add- or edit modal
  function closeByDispatch() {
    if (
      typeof dispatcher.modalAddIsVisible === "object" &&
      dispatcher.modalAddIsVisible !== null
    ) {
      for (const [key, value] of Object.entries(dispatcher.modalAddIsVisible)) {
        if (value === true)
          dispatcher.dispatchModalAddIsVisible({ type: key, current: true });
      }
    }

    if (
      typeof dispatcher.modalEditIsVisible === "object" &&
      dispatcher.modalEditIsVisible !== null
    ) {
      for (const [key, value] of Object.entries(
        dispatcher.modalEditIsVisible
      )) {
        if (value === true)
          dispatcher.dispatchModalEditIsVisible({ type: key, current: true });
      }
    }
  }

  return (
    <Wrapper className="modal-wrapper">
      <div
        className="modal"
        style={modalStyle}
        ref={closeOnClickOutside ? modalRef : null}
      >
        <div className="modal-header">
          <h4>{title}</h4>
          {/* Close Modal onClick */}
          {dispatcher && <Cross onClick={() => closeByDispatch()} />}
          {setState && <Cross onClick={() => setState(false)} />}
        </div>
        <div className="modal-content">
          {message && (
            <>
              <p>{message}</p>
              <div style={{ marginBottom: "16px" }} />
            </>
          )}
          {children}
        </div>
      </div>
    </Wrapper>
  );
};

export default Modal;
