import Select, { components } from 'react-select'
import { ReactComponent as DropdownArrow } from '../../assets/img/dropdown-arrow-dark.svg'
import { ReactComponent as Checkmark } from '../../assets/img/Checkmark.svg'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

/** Single- or multiselect component.
 * @property {dark} bool - If true, uses dark theme
 * @property {onChange} function - What StyledSelect does when the value changes
 * @property {country} bool - Use this if usecase is for selecting country
 * @property {language} bool - Use this if usecase is for selecting language
 * @property {value} object - For controlled input with useState. Should have a 'label' and 'value' property.
 * @property {options} array - Options for dropdown. Should be array of objects with properties 'label' and 'value'.
 * For more props see React Select docs: https://react-select.com/home
 */
const StyledSelect = (props) => {
  const { t } = useTranslation()

  const valueRef = useRef(props.value)
  valueRef.current = props.value

  if (!props.options) {
    console.log('no options, returning null')
    return null
  }

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: t('styledSelect.selectAll'),
  }

  const editListOption = {
    value: '<EDIT>',
    label: '🖊️ Redigera lista',
  }

  const allGroups = props.options.filter((subOption) => subOption.options)

  const allGroupOptions = allGroups.map((group) => group.options).flat()

  const flatOptions = [
    ...props.options.filter((option) => !option.options),
    ...allGroupOptions,
  ]

  const isSelectAllSelected = () =>
    valueRef.current.length === flatOptions.length

  const isOptionSelected = (option) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected()

  const onChangeSelectAll = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta

    if (action === 'select-option' && option.value === selectAllOption.value) {
      props.onChange(flatOptions, actionMeta)
    } else if (
      (action === 'deselect-option' &&
        option.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta)
    } else if (
      actionMeta.action === 'deselect-option' &&
      isSelectAllSelected()
    ) {
      props.onChange(
        flatOptions.filter(({ value }) => value !== option.value),
        actionMeta
      )
    } else {
      props.onChange(newValue || [], actionMeta)
    }
  }

  const onChangeEditList = (newValue, actionMeta) => {
    const { action } = actionMeta

    if (action === 'select-option' && newValue.value === editListOption.value) {
      props.edit()
    } else {
      props.onChange(newValue || [], actionMeta)
    }
  }

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownArrow />
      </components.DropdownIndicator>
    )
  }

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div
          className='label'
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {props.data.icon && props.data.icon}
          <p>{props.data.label}</p>
        </div>
        <Checkmark
          style={{ visibility: props.isSelected ? 'visible' : 'hidden' }}
        />
      </components.Option>
    )
  }

  const CustomControl = (props) => {
    return (
      <components.Control {...props}>
        {props.selectProps.hasAllOption &&
          !props.isFocused &&
          (props.selectProps.value.length >= flatOptions.length ? (
            <p style={{ position: 'absolute' }}>{t('styledSelect.all')}</p>
          ) : (
            props.selectProps.value.length > 0 && (
              <p style={{ position: 'absolute' }}>
                {props.selectProps.value.length +
                  ' ' +
                  (props.selectProps.value.length > 1
                    ? t('styledSelect.multipleSelected')
                    : t('styledSelect.singleSelected'))}
              </p>
            )
          ))}
        {props.children}
      </components.Control>
    )
  }

  const CustomSingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div
          className='label'
          style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
          }}
        >
          {props.data.icon && props.data.icon}
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {props.data.label}
          </p>
        </div>
      </components.SingleValue>
    )
  }

  const CustomPlaceholder = (props) => {
    return (
      <components.Placeholder {...props}>
        <p>
          {props.selectProps.options.length > 1 &&
            ((props.isMulti && t('styledSelect.noneSelected')) ||
              t('styledSelect.noneSelectedSingle'))}
        </p>
      </components.Placeholder>
    )
  }

  const styles = {
    container: (provided, state) => ({
      ...provided,
      ...((state.selectProps.country || state.selectProps.language) && {
        width: '190px',
      }),
    }),
    control: (provided, state) => ({
      ...provided,
      width: 'fit-content',
      minWidth: '100%',
      maxWidth: '100%',
      padding: '0 13px',
      backgroundColor: state.selectProps.dark ? '#DBDBDF' : 'white',
      fontSize: '20px',
      lineHeight: '1.2em',
      height: '34px',
      minHeight: '34px',
      border: 'none',
      borderRadius: '2px',
      boxShadow: state.isFocused
        ? 'inset 0px 0px 0px 4px #9B9AF2'
        : state.selectProps.border
        ? 'inset 0px 0px 0px 1px #cfcfd5'
        : 'none',
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      transition: 'box-shadow 50ms ease-in-out',
      position: 'relative',
    }),
    valueContainer: (provided, state) => ({
      position: 'relative',
      height: '34px',
      minHeight: '34px',
      width: '100%',
      padding: '0',
      gridTemplateRows: '34px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(-180deg)',
      transition: 'transform 100ms ease-in-out',
      padding: '0',
    }),
    singleValue: (provided, state) => ({
      position: 'absolute',
      bottom: '0',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: '0',
    }),
    input: (provided, state) => ({
      position: 'absolute',
      bottom: '0',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      padding: '0',
      minWidth: '0',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      width: 'fit-content',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 'max-content',
      minWidth: '100%',
      backgroundColor: state.selectProps.dark ? '#DBDBDF' : 'white',
      borderRadius: '2px',
      zIndex: '3',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '0',
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: '#3F3D56',
      fontWeight: 'bold',
    }),
    option: (provided, state) => ({
      ...provided,
      boxSizing: 'border-box',
      height: '34px',
      paddingLeft: '19px',
      paddingRight: '19px',
      color: '#3F3D56',
      fontSize: state.data.value === '<EDIT>' ? '16px' : '18px',
      fontWeight: state.data.value === '<SELECT_ALL>' ? 'bold' : 'regular',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '38px',
      backgroundColor: 'transparent',
      borderBottom: `1px solid ${
        state.selectProps.dark ? '#FFFFFF80' : '#DBDBDF'
      }`,
      ':hover': {
        backgroundColor: state.selectProps.dark ? '#FFFFFF80' : '#00000020',
      },
    }),
    multiValue: () => ({
      display: 'none',
    }),
    clearIndicator: () => ({
      display: 'none',
    }),
  }

  return (
    <Select
      {...props}
      menuIsOpen={props.menuIsOpen}
      isDisabled={props.isDisabled}
      options={
        props.hasAllOption
          ? [selectAllOption, ...props.options]
          : props.edit
          ? [...props.options, editListOption]
          : props.options
      }
      isOptionSelected={props.hasAllOption ? isOptionSelected : undefined}
      hideSelectedOptions={false}
      menuPlacement={props.menuPlacement || 'auto'}
      closeMenuOnSelect={!props.isMulti}
      backspaceRemovesValue={false}
      components={{
        DropdownIndicator: CustomDropdownIndicator,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        Control: CustomControl,
        Placeholder: CustomPlaceholder,
      }}
      styles={styles}
      classNamePrefix='react-select'
      className='react-select'
      onChange={
        props.hasAllOption
          ? onChangeSelectAll
          : props.edit
          ? onChangeEditList
          : props.onChange
      }
    />
  )
}

export default StyledSelect
