import { Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ArrowButton, Button } from '../../../components/button/Button'
import Modal from '../../../components/modal/Modal'
import StyledLabel from '../../../components/styledLabel/StyledLabel'
// import EmployeeInfo from './EmployeeInfo'
import StyledSelect from '../../../components/styledSelect/StyledSelect'
import { selectedDatePlus60Days } from '../../../utils/helper'
import { EmployeeSubPageContext } from '../EmployeeSubPage'
import { addEducation } from '../services/crud'
import { useTranslation } from 'react-i18next'

const StyledModal = styled.div`
 .error{
   color: red;
   font-size:small;
  }
  .error::before {
    content: "* ";
  }
  .modal {
    width: 886px;
  }
  .modal-content {
    padding: 20px;
    .person {
      display: flex;
      border-bottom: 1px solid #cfcfd5;
      margin-bottom: 15px;
      span:not(:last-child) {
           padding-right:10px;
      }
      span {
        font-size: 22px;
      }
      span:not(:first-child) {
        padding-left: 5px;
      }
    }
    .info-text {
      padding-bottom: 15px;
    }
    .to-catalog-btn {
      padding-top: 20px;
    }
    .survey-wrapper {
     display:flex;
     justify-content: space-between;
     input {
        width: 100%;
        text-overflow: ellipsis;
      }
      .price{
           padding-right:30px;
           margin-top:35px;
           
      }
    }
    .survey-date-wrapper {
      width:80%;
      padding-top: 15px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .start-date {
        width: 170px;
      }
      .end-date {
        width: 150px;
      }
      .survey-date-info-text {
        width: 80%;
        padding: 15px 0;
      }
    }
    .add-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
`

const AddEducationSurvey = () => {

  const history = useHistory()
  const { t } = useTranslation();
  // ---------- Contexts ----------
  const {
    row,
    employee,
    employee: { professionalRoleName },
    individual: { id: individualId },
    setAddEducationModal,
    loadOldStates,
    ongoing,
    setOngoing,
    addBtnClicked,
    fetchedEducations
  } = useContext(EmployeeSubPageContext)
  const { employeeNumber, firstName, lastName } = employee
  // ---------- States ----------
  const [error, setError] = useState({ fromDate: false, education: false })
  const { catalogQualification: locationCatalogQualification } =
    useLocation().state || {}

  const [catalogQualification, setCatalogQualification] = useState(
    addBtnClicked ? null :
      locationCatalogQualification
  )

  //eslint-disable-next-line
  const [price, setPrice] = useState(Number('0.00'))

  const [selectedQualificationByOrganisationToAdd, setSelectedQualificationByOrganisationToAdd] = useState({
    value: '',
    label: '',
  });

  useEffect(() => {
    const storedData = localStorage.getItem('employeeSubAddSurveyEducation');
    const data = loadOldStates && !catalogQualification && storedData
      ? JSON.parse(storedData)
      : { value: '', label: t('pageEmployees.addEducation.educationPlaceholder') };
    setSelectedQualificationByOrganisationToAdd(data);
  }, [t, loadOldStates, catalogQualification]);

  const [selectedSurveyFromDate, setSelectedSurveyFromDate] = useState(
    loadOldStates
      ? localStorage.getItem('employeeSubSelectedEducationFromDate')
      : new Date().toISOString().split('T')[0]
  )
  const [selectedSurveyToDate, setSelectedSurveyToDate] = useState(
    selectedDatePlus60Days(selectedSurveyFromDate)
  )


  // ---------- useEffects ----------
  // Qualification can only be selected from one source either by "role" or by "organisation" or "Catalog"
  useEffect(() => {
    localStorage.removeItem('employeeSubResetOnReload2')
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'employeeSubSelectedEducationFromDate',
      selectedSurveyFromDate
    )
  }, [selectedSurveyFromDate])

  useEffect(() => {
    localStorage.setItem(
      'employeeSubAddSurveyEducation',
      JSON.stringify(selectedQualificationByOrganisationToAdd)
    )
    if (selectedQualificationByOrganisationToAdd?.value) {
      setCatalogQualification(null)
    }
  }, [selectedQualificationByOrganisationToAdd])

  useEffect(() => {
    if (catalogQualification?.education) {
      setError({ ...error, education: false })

    }
    if (selectedQualificationByOrganisationToAdd?.value)
      setError({ ...error, education: false })
    // eslint-disable-next-line
  }, [catalogQualification?.educationName, selectedQualificationByOrganisationToAdd?.value])

  return (
    <StyledModal>
      <Modal setState={setAddEducationModal} title={t('pageEmployees.addEducation.modalTitle')}>
        <Grid container columns={24}>
          <Grid item xs={24} className='person'>
            <span>{employeeNumber}</span>
            <span>{firstName} {lastName}</span>
            <span>{professionalRoleName}</span>
          </Grid>

          <Grid item xs={24}>
            <Grid item xs={24} className='info-text'>
              <p>
                {t('pageEmployees.addEducation.infoText')}
              </p>
            </Grid>
          </Grid>

          <Grid item xs={12}>
             <Grid item xs={24} mb={2}>
              <p>
                {t('pageEmployees.addEducation.selectEducation')}
              </p>
            </Grid>

            <Grid item xs={12} mb={2}>
              {error.education && (
                <span className='error'>
                  {t('pageEmployees.addEducation.selectEducationError')}
                </span>
              )}

              <StyledLabel>
                <p>{t('pageEmployees.addEducation.internalEducations')}</p>
                <StyledSelect
                  dark
                  options={fetchedEducations?.filter(fetchedEd => ongoing?.every(ongoingEd => ongoingEd.educationId !== fetchedEd.id)).map(
                    education => ({
                      label: education.educationName,
                      value: education,
                    })
                  )}
                  value={selectedQualificationByOrganisationToAdd}
                  onChange={(e) => {
                    setSelectedQualificationByOrganisationToAdd({
                      label: e?.value.educationName,
                      value: { ...e?.value, price, selectedSurveyFromDate },
                    })
                    setError({ ...error, education: false })
                  }}
                />
              </StyledLabel>
            </Grid>

            <Grid item xs={1} mb={1}>
              <ArrowButton
                sm
                className='pointer'
                style={{ width: '180px' }}
                onClick={() => {
                  history.push({
                    pathname: '/catalog',
                    state: {
                      includeResults: ['educations'],
                      prevPath: `/employees/${employee.firstName} ${employee.lastName}`,
                      saveStates: true,
                      ongoing: JSON.parse(localStorage.getItem('employeesOngoing'))
                    }
                  })
                }}
              >
                {t('pageEmployees.addEducation.toCatalog')}
              </ArrowButton>
            </Grid>
          </Grid>

          <Grid item xs={12} px={0}>
            <Grid item xs={24}>
              <div className='survey-wrapper'>
                <StyledLabel>
                  <p>{t('pageEmployees.addEducation.selectedEducation')}</p>
                  <input
                    value={
                      catalogQualification?.educationName ||
                      (selectedQualificationByOrganisationToAdd?.value &&
                        selectedQualificationByOrganisationToAdd.label)
                    }
                    type='text'
                    readOnly
                  />
                </StyledLabel>
                <span className='price'>{price.toFixed(2)} {t('common.currency')}</span>
              </div>

              <div className='survey-date-wrapper'>
                <div className='start-date'>
                  <StyledLabel>
                    <p>{t('pageEmployees.addEducation.startDateLabel')}{error.fromDate && <span style={{ color: 'red' }}>{t('pageEmployees.addEducation.startDateError')}</span>}</p>
                    <input
                      value={selectedSurveyFromDate}
                      onChange={(e) => {
                        const regex = new RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)
                        if (regex.test(e.target.value)) {
                          setSelectedSurveyFromDate(e.target.value)
                          setSelectedSurveyToDate(
                            (prev) =>
                              (prev = selectedDatePlus60Days(e.target.value))
                          )
                        }
                      }}
                      min={new Date().toISOString().split('T')[0]}
                      id='date'
                      type='date'
                    />
                  </StyledLabel>
                </div>

                <div className='end-date'>
                  <StyledLabel>
                    <p>{t('pageEmployees.addEducation.endDateLabel')}</p>
                    <input
                      value={selectedSurveyToDate}
                      readOnly
                      type='text'
                      style={{ maxWidth: '90%' }}
                    />
                  </StyledLabel>
                </div>

                <div className='survey-date-info-text'>
                  <p>
                    {t('pageEmployees.addEducation.educationAccess')}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={24} className='add-btn'>
            <Button
              onClick={(e) => {
                if (!selectedQualificationByOrganisationToAdd?.value && !catalogQualification?.id) {
                  setError(prev => prev = ({ ...error, education: true }))
                  return
                }
                selectedSurveyFromDate !== null &&
                  addEducation({
                    individualId: row,
                    educationId:
                      catalogQualification?.id ||
                      (selectedQualificationByOrganisationToAdd?.value &&
                        selectedQualificationByOrganisationToAdd.value.id),
                    price,
                    startDate: selectedSurveyFromDate,
                    setAddEducationModal,
                  }).then((data) => {
                    setOngoing((prev) => [
                      ...prev,
                      {
                        id: data.id,
                        educationName: data.educationName,
                        individualId,
                        educationId:
                          catalogQualification?.id ||
                          (selectedQualificationByOrganisationToAdd?.value &&
                            selectedQualificationByOrganisationToAdd.value.id),
                        price,
                        startDate: selectedSurveyFromDate,
                        endDate: selectedSurveyToDate,
                        setAddEducationModal,
                      },
                    ])
                    localStorage.removeItem('employeeSubAddSurveyEducation')
                  })
              }}
            >
              {t('buttons.addButton')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </StyledModal>
  )
}

export default AddEducationSurvey
