import { simpleUser } from './../../../utils/Amplify';
export const publishQualification = async (
  e,
  {
    selectedQualification,
    modalConfirmTempCountry,
    qualificationsList,
    setSelectedQualification,
    setQualificationsList,
    setModalConfirmIsVisible,
  }
) => {
  let newQualificationsList = qualificationsList
  const selectedQualificationIndex = qualificationsList.findIndex(
    (qualification) => qualification.id === selectedQualification.id
  )

  const options = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (await simpleUser()).token,
    },
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/qualification/${selectedQualification.id}/add/country/${modalConfirmTempCountry.label}`,
      options
    )
    const data = await response.json()
    if (response.status === 200) {
      setSelectedQualification(data)
      newQualificationsList[selectedQualificationIndex] = data
      setQualificationsList(newQualificationsList)
      setModalConfirmIsVisible(false)
    }
  } catch (error) {
    console.log(error)
  }
}
