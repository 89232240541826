import React, { useContext, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import pencil from '../../../../assets/img/Edit_pencil.svg'
import { AreaContext } from '../QualificationAreas'
import { getAreas } from '../../functions'
import { Context } from '../../../../utils/Context'
import { Link } from 'react-router-dom'

const AreasTable = () => {
  const { t } = useTranslation()

  const {
    employee: { qualificationAccess },
  } = useContext(Context)
  const {
    subQualification,
    areaList,
    setAreaList,
    setSelectedRequirement,
    setSelectedArea,
    dispatchModalEditIsVisible,
    dispatchModalAddIsVisible,
  } = useContext(AreaContext)

  useEffect(() => {
    let isLoading = true

    getAreas(subQualification.id).then((areas) => {
      if (isLoading) {
        setAreaList(areas)
      }
    })

    return () => {
      isLoading = false
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className='table'>
      <Table
        sx={{
          maxWidth: '100%',
          border: '1px solid black',
          marginBottom: '10px',
        }}
        aria-label='simple table'
      >
        <colgroup>
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        <TableHead className='table-header'>
          <TableRow>
            <TableCell sx={{ paddingBottom: 0, fontWeight: 700 }}>
              {t('pageQualificationAreas.columnHeaders.0')}
            </TableCell>
            <TableCell sx={{ paddingBottom: 0, fontWeight: 700 }}>
            {t('pageQualificationAreas.columnHeaders.1')}
            </TableCell>
            <TableCell sx={{ paddingBottom: 0, fontWeight: 700 }}>
            {t('pageQualificationAreas.columnHeaders.2')}
            </TableCell>
            <TableCell sx={{ paddingBottom: 0, fontWeight: 700 }}>
            {t('pageQualificationAreas.columnHeaders.3')}
            </TableCell>
          </TableRow>
        </TableHead>
        <colgroup>
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        <TableBody>
          {areaList?.map(
            ({ id, name, description, qualificationRequirement }, i) => (
              <TableRow
                key={id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <div className='flex-column'>
                    <div className='flex area-table-container area-name'>
                      <p className='requirement-paragraph'>{name}</p>
                    </div>
                  </div>
                </TableCell>

                <TableCell
                  sx={{
                    paddingBottom: 0,
                  }}
                  style={{ position: 'relative' }}
                >
                  <div className='top-requirement-container'>
                    {qualificationRequirement &&
                      qualificationRequirement
                        .filter(
                          (requirement) =>
                            requirement.requirementType === 'knowledge'
                        )
                        .map((requirement, i) => (
                          <div
                            className='flex area-table-container'
                            key={requirement.id}
                          >
                            <Link
                              onClick={() => {
                                setSelectedRequirement({
                                  id: requirement.id,
                                  description: requirement.description,
                                  requirementType: requirement.requirementType,
                                })
                              }}
                              to={(location) => ({
                                ...location,
                                pathname: `${location.pathname}/${name}`,
                              })}
                            >
                              <p className='requirement-paragraph'>
                                {requirement.description}
                              </p>
                            </Link>
                            <div className='question-summary'>
                              <p>
                                {requirement?.amountQuestions}/
                                {requirement?.totalQuestions}/
                                {requirement?.passQuestions}
                              </p>

                              {qualificationAccess === 'edit' && (
                                <img
                                  src={pencil}
                                  alt='Edit pencil'
                                  className='pointer requirement-paragraph'
                                  onClick={(e) => {
                                    setSelectedArea({
                                      id,
                                      name,
                                      description,
                                      qualificationRequirement,
                                    })
                                    setSelectedRequirement(requirement)
                                    dispatchModalEditIsVisible({
                                      type: 'knowledge',
                                      current: false,
                                    })
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                  </div>
                  <div className='btn-wrapper-areas bottom-requirement-container'>
                    {qualificationAccess === 'edit' && (
                      <p
                        className='add-btn-areas pointer'
                        onClick={() => {
                          setSelectedArea({
                            id,
                            name,
                            description,
                            qualificationRequirement,
                          })
                          dispatchModalAddIsVisible({
                            type: 'knowledge',
                            current: false,
                          })
                        }}
                      >
                        +
                      </p>
                    )}
                  </div>
                </TableCell>

                <TableCell
                  sx={{ paddingBottom: 0 }}
                  style={{ position: 'relative' }}
                >
                  <div className='top-requirement-container'>
                    {qualificationRequirement &&
                      qualificationRequirement
                        .filter(
                          (requirement) =>
                            requirement.requirementType === 'skill'
                        )
                        .map((requirement) => (
                          <div
                            className='flex area-table-container'
                            key={requirement.id}
                          >
                            <Link
                              onClick={() => {
                                setSelectedRequirement({
                                  id: requirement.id,
                                  description: requirement.description,
                                  requirementType: requirement.requirementType,
                                })
                              }}
                              to={(location) => ({
                                ...location,
                                pathname: `${location.pathname}/${name}`,
                              })}
                            >
                              <p className='requirement-paragraph'>
                                {requirement.description}
                              </p>
                            </Link>

                            <div className='question-summary'>
                              <p>
                                {requirement.amountQuestions}/
                                {requirement.totalQuestions}/
                                {requirement.passQuestions}
                              </p>
                              {qualificationAccess === 'edit' && (
                                <img
                                  src={pencil}
                                  alt='Edit pencil'
                                  className='pointer requirement-paragraph'
                                  onClick={(e) => {
                                    setSelectedArea({
                                      id,
                                      name,
                                      description,
                                      qualificationRequirement,
                                    })
                                    setSelectedRequirement(requirement)
                                    dispatchModalEditIsVisible({
                                      type: 'skill',
                                      current: false,
                                    })
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                  </div>
                  <div className='btn-wrapper-areas bottom-requirement-container'>
                    {qualificationAccess === 'edit' && (
                      <p
                        className='add-btn-areas'
                        onClick={() => {
                          setSelectedArea({
                            id,
                            name,
                            description,
                            qualificationRequirement,
                          })
                          dispatchModalAddIsVisible({
                            type: 'skill',
                            current: false,
                          })
                        }}
                      >
                        +
                      </p>
                    )}
                  </div>
                </TableCell>

                <TableCell
                  sx={{ paddingBottom: 0 }}
                  style={{ position: 'relative' }}
                >
                  <div className='top-requirement-container'>
                    {qualificationRequirement &&
                      qualificationRequirement
                        .filter(
                          (requirement) =>
                            requirement.requirementType ===
                            'responsibilityAndIndependence'
                        )
                        .map((requirement) => (
                          <div
                            className='flex area-table-container'
                            key={requirement.id}
                          >
                            <Link
                              onClick={() => {
                                setSelectedRequirement({
                                  id: requirement.id,
                                  description: requirement.description,
                                  requirementType: requirement.requirementType,
                                })
                              }}
                              to={(location) => ({
                                ...location,
                                pathname: `${location.pathname}/${name}`,
                              })}
                            >
                              <p className='requirement-paragraph'>
                                {requirement.description}
                              </p>
                            </Link>
                            <div className='question-summary'>
                              <p>
                                {requirement.amountQuestions}/
                                {requirement.totalQuestions}/
                                {requirement.passQuestions}
                              </p>
                              {qualificationAccess === 'edit' && (
                                <img
                                  src={pencil}
                                  className='pointer requirement-paragraph'
                                  alt='Edit pencil'
                                  onClick={(e) => {
                                    setSelectedArea({
                                      id,
                                      name,
                                      description,
                                      qualificationRequirement,
                                    })
                                    setSelectedRequirement(requirement)
                                    dispatchModalEditIsVisible({
                                      type: 'responsibilityAndIndependence',
                                      current: false,
                                    })
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                  </div>
                  <div className='btn-wrapper-areas bottom-requirement-container'>
                    {qualificationAccess === 'edit' && (
                      <p
                        className='add-btn-areas'
                        onClick={() => {
                          setSelectedArea({
                            id,
                            name,
                            description,
                            qualificationRequirement,
                          })
                          dispatchModalAddIsVisible({
                            type: 'responsibilityAndIndependence',
                            current: false,
                          })
                        }}
                      >
                        +
                      </p>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export default AreasTable
