import styled from 'styled-components'

export const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  background: linear-gradient(90deg, rgba(236,236,238,1) 50%, rgba(255,255,255,1) 50%);

  .left-side {
    background-color: #ececee;
    display: flex;
    justify-content: flex-end;
    
    .left-side-content {
      margin-top: 41px;
      display: flex;
      flex-direction: column;
      width: 483px;
      min-height: 985px;

      .logo {
        height: 55px;
        width: fit-content;
      }
  
      nav {
        margin-top: 27px;
        margin-left: 8px;
      }
  
      .undraw {
        align-self: center;
        margin-top: 166px;
      }
      div:nth-child(1) {
        width: 175px;
        margin-top: 41px;
        margin-bottom: 23px;
        margin-left: 132px;
      }
    }
  }

  .right-side {
    background-color: white;
    flex-basis: 830px;
    padding: 108px 0 108px 163px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    form {
      width: 420px;

      .country-langugage-select {
        margin-top: 67px;
        margin-bottom: 67px;
        display: flex;
        justify-content: space-between;
      }

      h3 {
        margin-bottom: 67px;
      }

      > input {
        width: 457px;
      }

      #email {
        margin-bottom: 35px;
      }

      #password {
        margin-bottom: 13px;
      }

      .forgot-password {
        display: block;
        color: #9f9eab;
        font-size: 12px;
        font-weight: 400;
      }

      button {
        margin-top: 43px;
      }
    }

    .signup-info {
      display: flex;
      align-items: flex-end;
      margin-top: 154px;

      > p {
        margin-right: 24px;
      }
    }
  }
`
