import { useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { t } from 'i18next'

import { Next, Prev } from '../../../../../components/button/Button'
import Modal from '../../../../../components/modal/Modal'
import ControlledRadioButtonsGroup from '../../../../../components/radioButton/RadioButton'
import {
  ModalWrapper,
  SurveyWrapper,
} from '../../../../../pages/catalog/subPages/style'
import { SubContext } from '../Survey'
import { getQuestionImage } from '../../../../qualifications/functions'
import { getQuestionType } from '../../../../qualifications/requirementQuestion/helper'

const SurveyQuestion = () => {
  const {
    setShowQuestionModal,
    selectedSubQualification,
    mappingQuestions,
    setMappingQuestions,
  } = useContext(SubContext)

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

  const [imageQuestionUrl, setImageQuestionUrl] = useState('')

  const [imageAnswerUrls, setImageAnswerUrls] = useState([])

  const [questionType, setQuestionType] = useState('')

  // Filter the questions based on the selected sub-qualification
  const subQuestions =
    mappingQuestions.find(
      (question) => question.subQualificationId === selectedSubQualification.id
    )?.Questions || []

  const currentQuestion = subQuestions[currentQuestionIndex]

  // Update the mapping questions when a question is answered
  const handleAnswerSelect = (answerId) => {
    const subQual = mappingQuestions.find(
      (question) => question.subQualificationId === selectedSubQualification.id
    )
    const questionIndex = subQual.Questions.findIndex(
      (question) => question.id === subQuestions[currentQuestionIndex].id
    )
    const updatedMappingQuestions = [...mappingQuestions]

    updatedMappingQuestions[
      updatedMappingQuestions.findIndex(
        (question) => question.subQualificationId === subQual.subQualificationId
      )
    ].Questions[questionIndex].selectedAnswer = Number(answerId)

    setMappingQuestions(updatedMappingQuestions)
  }

  useEffect(() => {
    setQuestionType(getQuestionType(currentQuestion))
  }, [currentQuestion])

  useEffect(() => {
    if (questionType === 'imageQuestion' && currentQuestion.questionImageKey) {
      const fetchImage = async () => {
        const image = await getQuestionImage(currentQuestion.questionImageKey)
        setImageQuestionUrl(image)
      }
      fetchImage()
    } else if (
      questionType === 'imageAnswer' &&
      currentQuestion.questionAnswer.every((answer) => answer.questionImageKey)
    ) {
      const fetchImage = async () => {
        const images = await Promise.all(
          currentQuestion.questionAnswer.map((answer) => {
            return getQuestionImage(answer.questionImageKey)
          })
        )
        setImageAnswerUrls(images)
      }
      fetchImage()
    }
  }, [currentQuestion, questionType])

  return (
    <ModalWrapper>
      <Modal
        className='survey-question-modal'
        setState={setShowQuestionModal}
        title={selectedSubQualification.name}
      >
        <SurveyWrapper>
          <Grid
            container
            item
            xs={12}
            paddingTop={2}
            gap={8}
            direction='column'
            alignItems='center'
            justifyContent='center'
            className='survey-container'
          >
            <Grid item paddingX={15} marginTop={4}>
              <p className='question-name'>{currentQuestion.questionName}</p>
            </Grid>
            {questionType === 'imageQuestion' && (
              <Grid item>
                <img
                  className='question-image'
                  alt='question'
                  src={imageQuestionUrl}
                />
              </Grid>
            )}
            {questionType === 'videoQuestion' && (
              <Grid item>
                <iframe
                  width='560'
                  height='315'
                  src={`https://www.youtube.com/embed/${currentQuestion.questionVideoUrl}`}
                  title='question video'
                />
              </Grid>
            )}
            {questionType === 'imageAnswer' ||
            questionType === 'videoAnswer' ? (
              <Grid container style={{ marginBottom: '20px' }}>
                {currentQuestion.questionAnswer.map((answerOption, index) => (
                  <Grid
                    key={answerOption.id}
                    container
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Grid item marginBottom='20px'>
                      <label>
                        <input
                          type='radio'
                          onChange={() => handleAnswerSelect(answerOption.id)}
                          value={answerOption.id}
                          checked={
                            answerOption.id === currentQuestion.selectedAnswer
                          }
                        />
                        {`  ${String.fromCharCode(65 + index)}) ${
                          answerOption.answer ?? ''
                        }`}
                      </label>
                    </Grid>

                    {questionType === 'videoAnswer' && (
                      <Grid item marginLeft='15px' marginBottom='40px'>
                        <iframe
                          width='360'
                          height='180'
                          src={`https://www.youtube.com/embed/${answerOption.questionVideoUrl}`}
                          title='answer video'
                        />
                      </Grid>
                    )}
                    {questionType === 'imageAnswer' && (
                      <Grid item marginLeft='15px' marginBottom='40px'>
                        <img
                          className='answer-image'
                          alt='answer'
                          src={imageAnswerUrls[index]}
                        />
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item>
                <ControlledRadioButtonsGroup
                  options={currentQuestion.questionAnswer.map(
                    (answerOption, index) => ({
                      value: answerOption.id,
                      label: `${String.fromCharCode(65 + index)}) ${
                        answerOption.answer
                      }`,
                    })
                  )}
                  selectOption={currentQuestion.selectedAnswer}
                  setSelectOption={handleAnswerSelect}
                />
              </Grid>
            )}
            <Grid
              container
              item
              justifyContent='space-between'
              marginBottom={4}
              paddingX={4}
            >
              <Grid item>
                <Prev
                  disabled={currentQuestionIndex === 0}
                  onClick={() =>
                    setCurrentQuestionIndex(currentQuestionIndex - 1)
                  }
                >
                  {t('pageSurvey.prevQuestion')}
                </Prev>
              </Grid>
              <Grid item>
                <p className='bold'>{`${currentQuestionIndex + 1}/${
                  subQuestions.length
                }`}</p>
              </Grid>

              <Grid item>
                <Next
                  onClick={() => {
                    if (currentQuestionIndex === subQuestions.length - 1) {
                      setShowQuestionModal(false)
                    } else {
                      setCurrentQuestionIndex(currentQuestionIndex + 1)
                    }
                  }}
                >
                  {currentQuestionIndex === subQuestions.length - 1
                    ? t('pageSurvey.closeSubqualification')
                    : t('pageSurvey.nextQuestion')}
                </Next>
              </Grid>
            </Grid>
          </Grid>
        </SurveyWrapper>
      </Modal>
    </ModalWrapper>
  )
}

export default SurveyQuestion
