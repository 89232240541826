import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { simpleUser } from "../../utils/Amplify";

import StyledLabel from "../../components/styledLabel/StyledLabel";
import { Button, Delete } from "../../components/button/Button";
import StyledSelect from "../../components/styledSelect/StyledSelect";
import { EmployeesContext } from "./Employees";
import { Context } from "../../utils/Context";
// import { DeleteModal, EditListModal, AddRole } from './Modals'
import { DeleteModal, EditListModal } from "./Modals";
import { freshPerms } from "../../utils/Backend";

const EditEmployee = ({
  setShowEditEmployee,
  localOrganisation,
  setLocalOrganisation,
}) => {
  const { t } = useTranslation();
  const [showDeleteEmployeeModal, setShowDeleteEmployeeModal] = useState(false);
  const [showEditList, setShowEditList] = useState(false);
  const [editListParams, setEditListParams] = useState({
    setShowEditList,
    orgLevel: "",
    orgLevelGroups: [],
  });

  const { row, rows, setRows, baseRoles, roleOptions } =
    useContext(EmployeesContext);

  const {
    individual,
    permissions: { rightsAccess: editorRightsAccess },
  } = useContext(Context);

  // const [showAddRole, setShowAddRole] = useState(false)
  const [formInputs, setFormInputs] = useState({
    ...row,
    reEmail: "",
    professionalRole: baseRoles.find(
      (role) => role.id === row.professionalRoleId
    ),
  });

  const professionalRoleName = formInputs.professionalRole
    ? formInputs.professionalRole.professionalRoleName
    : null;
  const professionalRoleId = formInputs.professionalRole
    ? formInputs.professionalRole.id
    : null;

  const [oldEmployeeEmail, setOldEmployeeEmail] = useState("");
  useEffect(() => {
    setOldEmployeeEmail(formInputs.employeeEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [oldEmployeeEmail]);
  const [permissions, setPermissions] = useState({ ...row.permissions });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    employeeNumber: "",
    reEmail: "",
  });

  const {
    firstName,
    lastName,
    email,
    professionalRole,
    phoneNumber,
    individualId,
    employeeNumber,
    employeeEmail,
    reEmail,
    levelOne,
    levelTwo,
    levelThree,
    levelFour,
    temporary,
  } = formInputs;

  const [oldEmployeeNumber, setOldEmployeeNumber] = useState();

  useEffect(() => {
    setOldEmployeeNumber(employeeNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    const tempErrors = { ...errors };

    try {
      const storedPerms = await freshPerms();

      if (storedPerms.employeeAccess !== "edit") {
        let throwMsg = "Not authorized";
        throw throwMsg;
      }

      if (!employeeNumber)
        tempErrors.employeeNumber = t(
          "pageProfile.errors.employeeNumberMissing"
        );
      if (!firstName)
        tempErrors.firstName = t("pageProfile.errors.firstNameMissing");
      if (!lastName)
        tempErrors.lastName = t("pageProfile.errors.lastNameMissing");
      if (
        employeeEmail &&
        employeeEmail !== reEmail &&
        employeeEmail !== oldEmployeeEmail
      )
        tempErrors.reEmail = t("pageProfile.errors.emailMismatch");

      if (Object.values(tempErrors).some((err) => err)) {
        let throwMsg = "validation failed";
        throw throwMsg;
      }

      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/${localOrganisation.organisationNumber}/update/employee/${oldEmployeeNumber}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await simpleUser()).token,
          },
          body: JSON.stringify({
            employeeEmail,
            employeeNumber,
            roleName: professionalRoleName,
            roleId: professionalRoleId,
            professionalRole,
            levelOne,
            levelTwo,
            levelThree,
            levelFour,
            qualificationAccess:
              storedPerms.rightsAccess === "edit"
                ? permissions.qualifications
                : "none",
            initializeAccess:
              storedPerms.rightsAccess === "edit"
                ? permissions.initiations
                : "none",
            validationAccess:
              storedPerms.rightsAccess === "edit"
                ? permissions.validations
                : "none",
            rightsAccess:
              storedPerms.rightsAccess === "edit"
                ? permissions.permissions
                : "none",
            employeeAccess:
              storedPerms.rightsAccess === "edit"
                ? permissions.employees
                : "none",
            qualificationPlanningAccess:
              storedPerms.rightsAccess === "edit"
                ? permissions.competencePlanning
                : "none",
            educationAccess:
              storedPerms.rightsAccess === "edit"
                ? permissions.educations
                : "none",
            statisticAccess:
              storedPerms.rightsAccess === "edit" ? permissions.stats : "none",
          }),
        }
      );

      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/individual/update/${individualId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await simpleUser()).token,
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            phoneNumber,
          }),
        }
      );

      const newRows = [...rows];

      newRows[rows.indexOf(row)] = {
        ...formInputs,
        permissions,
        professionalRoleName,
        professionalRoleId,
      };

      setRows(newRows);
      setShowEditEmployee(false);
    } catch (err) {
      console.log(err);
      setErrors(tempErrors);
    }
  };

  const handleDelete = async () => {
    try {
      const storedPerms = await freshPerms();

      if (storedPerms.employeeAccess !== "edit") {
        let throwMsg = "Not authorized";
        throw throwMsg;
      }

      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/delete/employee/${row.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + (await simpleUser()).token,
          },
        }
      );

      setRows(
        rows.filter((user) => user.employeeNumber !== row.employeeNumber)
      );

      setShowEditEmployee(false);
    } catch (err) {
      console.log(err);
    }
  };

  const permissionOptions = [
    { value: "none", label: t("pageEmployees.addEmployee.permissionsNone") },
    { value: "read", label: t("pageEmployees.addEmployee.permissionsRead") },
    { value: "edit", label: t("pageEmployees.addEmployee.permissionsEdit") },
  ];

  const levelOptions = {
    levelOne: localOrganisation.levelOne.groups.map((group) => {
      return {
        value: group.groupName,
        label: group.groupName,
      };
    }),
    levelTwo: localOrganisation.levelTwo
      ? localOrganisation.levelTwo.groups.map((group) => {
          return {
            value: group.groupName,
            label: group.groupName,
          };
        })
      : null,
    levelThree: localOrganisation.levelThree
      ? localOrganisation.levelThree.groups.map((group) => {
          return {
            value: group.groupName,
            label: group.groupName,
          };
        })
      : null,
    levelFour: localOrganisation.levelFour
      ? localOrganisation.levelFour.groups.map((group) => {
          return {
            value: group.groupName,
            label: group.groupName,
          };
        })
      : null,
  };
  return (
    <>
      {/* {showAddRole && <AddRole setShowAddRole={setShowAddRole} />} */}
      {showDeleteEmployeeModal && (
        <DeleteModal
          handleDelete={handleDelete}
          setShowDeleteEmployeeModal={setShowDeleteEmployeeModal}
          employeeNumber={formInputs.employeeNumber}
          firstName={formInputs.firstName}
          lastName={formInputs.lastName}
        />
      )}
      {showEditList && (
        <EditListModal
          {...editListParams}
          setLocalOrganisation={setLocalOrganisation}
        />
      )}
      <form>
        <div className="personal-info">
          <StyledLabel
            className={`${errors.firstName ? "error" : ""}`}
            errorContent={errors.firstName}
          >
            <p>{t("pageEmployees.employeeSearch.textFields.firstName")} *</p>
            <input
              disabled={!temporary}
              type="text"
              value={formInputs.firstName}
              onInput={(e) =>
                setFormInputs({ ...formInputs, firstName: e.target.value })
              }
              onFocus={() => setErrors({ ...errors, firstName: "" })}
            />
          </StyledLabel>
          <StyledLabel
            className={`${errors.lastName ? "error" : ""}`}
            errorContent={errors.lastName}
          >
            <p>{t("pageEmployees.employeeSearch.textFields.lastName")} *</p>
            <input
              disabled={!temporary}
              type="text"
              value={formInputs.lastName}
              onInput={(e) =>
                setFormInputs({ ...formInputs, lastName: e.target.value })
              }
              onFocus={() => setErrors({ ...errors, lastName: "" })}
            />
          </StyledLabel>
        </div>
        <StyledLabel
          className={`${errors.employeeNumber ? "error" : ""}`}
          errorContent={errors.employeeNumber}
        >
          <p>{t("pageEmployees.employeeSearch.textFields.employeeNumber")} *</p>
          <input
            type="text"
            value={formInputs.employeeNumber}
            onInput={(e) =>
              setFormInputs({ ...formInputs, employeeNumber: e.target.value })
            }
            onFocus={() => setErrors({ ...errors, employeeNumber: "" })}
          />
        </StyledLabel>
        <StyledLabel>
          <p>{t("extra.professionalRoleName")}</p>
          <StyledSelect
            options={roleOptions}
            // edit={() => {
            //   setShowAddRole(true)
            // }}
            value={{
              value: formInputs.professionalRole,
              label: formInputs.professionalRole
                ? formInputs.professionalRole.professionalRoleName
                : "Ingen",
            }}
            onChange={(e) =>
              setFormInputs({
                ...formInputs,
                professionalRole: e.value,
              })
            }
          />
        </StyledLabel>
        {localOrganisation.levelOne && (
          <StyledLabel>
            <p>{localOrganisation.levelOne.label}</p>
            {/* console.log(levelOptions.levelOne) */}
            <StyledSelect
              options={levelOptions.levelOne}
              edit={() => {
                setEditListParams({
                  ...editListParams,
                  orgLevel: localOrganisation.levelOne.label,
                  orgLevelGroups: localOrganisation.levelOne.groups,
                  localOrganisation: localOrganisation,
                });
                setShowEditList(true);
              }}
              value={{ value: formInputs.levelOne, label: formInputs.levelOne }}
              onChange={(e) =>
                setFormInputs({
                  ...formInputs,
                  levelOne: e.label,
                })
              }
            />
          </StyledLabel>
        )}
        {localOrganisation.levelTwo && (
          <StyledLabel>
            <p>{localOrganisation.levelTwo.label}</p>
            <StyledSelect
              options={levelOptions.levelTwo}
              value={{ value: formInputs.levelTwo, label: formInputs.levelTwo }}
              edit={() => {
                setEditListParams({
                  ...editListParams,
                  orgLevel: localOrganisation.levelTwo.label,
                  orgLevelGroups: localOrganisation.levelTwo.groups,
                  localOrganisation: localOrganisation,
                });
                setShowEditList(true);
              }}
              onChange={(e) =>
                setFormInputs({
                  ...formInputs,
                  levelTwo: e.value,
                })
              }
            />
          </StyledLabel>
        )}
        {localOrganisation.levelThree && (
          <StyledLabel>
            <p>{localOrganisation.levelThree.label}</p>
            <StyledSelect
              options={levelOptions.levelThree}
              value={{
                value: formInputs.levelThree,
                label: formInputs.levelThree,
              }}
              edit={() => {
                setEditListParams({
                  ...editListParams,
                  orgLevel: localOrganisation.levelThree.label,
                  orgLevelGroups: localOrganisation.levelThree.groups,
                  localOrganisation: localOrganisation,
                });
                setShowEditList(true);
              }}
              onChange={(e) =>
                setFormInputs({
                  ...formInputs,
                  levelThree: e.value,
                })
              }
            />
          </StyledLabel>
        )}
        {localOrganisation.levelFour && (
          <StyledLabel>
            <p>{localOrganisation.levelFour.label}</p>
            <StyledSelect
              options={levelOptions.levelFour}
              value={{
                value: formInputs.levelFour,
                label: formInputs.levelFour,
              }}
              edit={() => {
                setEditListParams({
                  ...editListParams,
                  orgLevel: localOrganisation.levelFour.label,
                  orgLevelGroups: localOrganisation.levelFour.groups,
                  localOrganisation: localOrganisation,
                });
                setShowEditList(true);
              }}
              onChange={(e) =>
                setFormInputs({
                  ...formInputs,
                  levelFour: e.value,
                })
              }
            />
          </StyledLabel>
        )}
        <div className="email">
          <StyledLabel>
            <p>
              {t("pageEmployees.addEmployee.email")}{" "}
              {t("pageEmployees.addEmployee.business")}
            </p>
            <input
              type="text"
              value={formInputs.employeeEmail}
              onInput={(e) =>
                setFormInputs({ ...formInputs, employeeEmail: e.target.value })
              }
            />
          </StyledLabel>
          <StyledLabel
            className={`${errors.reEmail ? "error" : ""}`}
            errorContent={errors.reEmail}
          >
            <p>{t("pageEmployees.addEmployee.reEmail")}</p>
            <input
              type="text"
              value={formInputs.reEmail}
              onInput={(e) =>
                setFormInputs({ ...formInputs, reEmail: e.target.value })
              }
              onFocus={() => setErrors({ ...errors, reEmail: "" })}
            />
          </StyledLabel>
        </div>
      </form>
      {individual && editorRightsAccess !== "none" && (
        <div className="permissions">
          <StyledLabel>
            <p>{t("pageHome.employeesTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={
                editorRightsAccess !== "edit" ||
                individual.organisationEmployee.employeeNumber ===
                  formInputs.employeeNumber
              }
              options={permissionOptions}
              value={{
                value: permissions.employees,
                label:
                  permissions.employees === "none"
                    ? t("pageEmployees.addEmployee.permissionsNone")
                    : permissions.employees === "read"
                    ? t("pageEmployees.addEmployee.permissionsRead")
                    : t("pageEmployees.addEmployee.permissionsEdit"),
              }}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  employees: e.value,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("extra.permissions")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={
                editorRightsAccess !== "edit" ||
                individual.organisationEmployee.employeeNumber ===
                  formInputs.employeeNumber
              }
              options={permissionOptions}
              value={{
                value: permissions.permissions,
                label:
                  permissions.permissions === "none"
                    ? t("pageEmployees.addEmployee.permissionsNone")
                    : permissions.permissions === "read"
                    ? t("pageEmployees.addEmployee.permissionsRead")
                    : t("pageEmployees.addEmployee.permissionsEdit"),
              }}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  permissions: e.value,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("pageHome.initiationsTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={
                editorRightsAccess !== "edit" ||
                individual.organisationEmployee.employeeNumber ===
                  formInputs.employeeNumber
              }
              options={permissionOptions}
              value={{
                value: permissions.initiations,
                label:
                  permissions.initiations === "none"
                    ? t("pageEmployees.addEmployee.permissionsNone")
                    : permissions.initiations === "read"
                    ? t("pageEmployees.addEmployee.permissionsRead")
                    : t("pageEmployees.addEmployee.permissionsEdit"),
              }}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  initiations: e.value,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("pageHome.competencePlanningTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={
                editorRightsAccess !== "edit" ||
                individual.organisationEmployee.employeeNumber ===
                  formInputs.employeeNumber
              }
              options={permissionOptions}
              value={{
                value: permissions.competencePlanning,
                label:
                  permissions.competencePlanning === "none"
                    ? t("pageEmployees.addEmployee.permissionsNone")
                    : permissions.competencePlanning === "read"
                    ? t("pageEmployees.addEmployee.permissionsRead")
                    : t("pageEmployees.addEmployee.permissionsEdit"),
              }}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  competencePlanning: e.value,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("pageHome.qualificationsTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={
                editorRightsAccess !== "edit" ||
                individual.organisationEmployee.employeeNumber ===
                  formInputs.employeeNumber
              }
              options={permissionOptions}
              value={{
                value: permissions.qualifications,
                label:
                  permissions.qualifications === "none"
                    ? t("pageEmployees.addEmployee.permissionsNone")
                    : permissions.qualifications === "read"
                    ? t("pageEmployees.addEmployee.permissionsRead")
                    : t("pageEmployees.addEmployee.permissionsEdit"),
              }}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  qualifications: e.value,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("pageHome.educationsTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={
                editorRightsAccess !== "edit" ||
                individual.organisationEmployee.employeeNumber ===
                  formInputs.employeeNumber
              }
              options={permissionOptions}
              value={{
                value: permissions.educations,
                label:
                  permissions.educations === "none"
                    ? t("pageEmployees.addEmployee.permissionsNone")
                    : permissions.educations === "read"
                    ? t("pageEmployees.addEmployee.permissionsRead")
                    : t("pageEmployees.addEmployee.permissionsEdit"),
              }}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  educations: e.value,
                });
              }}
            />
          </StyledLabel>
          <StyledLabel>
            <p>{t("pageHome.statsTitle")}</p>
            <StyledSelect
              dark
              isSearchable={false}
              isDisabled={
                editorRightsAccess !== "edit" ||
                individual.organisationEmployee.employeeNumber ===
                  formInputs.employeeNumber
              }
              options={permissionOptions}
              value={{
                value: permissions.stats,
                label:
                  permissions.stats === "none"
                    ? t("pageEmployees.addEmployee.permissionsNone")
                    : permissions.stats === "read"
                    ? t("pageEmployees.addEmployee.permissionsRead")
                    : t("pageEmployees.addEmployee.permissionsEdit"),
              }}
              onChange={(e) => {
                setPermissions({
                  ...permissions,
                  stats: e.value,
                });
              }}
            />
          </StyledLabel>
        </div>
      )}
      <div className="buttons">
        <Delete onClick={() => setShowDeleteEmployeeModal(true)}>
          {t("pageEmployees.editEmployee.deleteButton")}
        </Delete>
        <Button onClick={handleSave}>
          {t("pageEmployees.editEmployee.saveButton")}
        </Button>
      </div>
    </>
  );
};

export default EditEmployee;
