import styled from 'styled-components'

export const ModalWrapper = styled.div`
  textarea {
    padding: 5px 13px;
    margin-bottom: 15px;
    height: 115px;
    resize: none;
  }

  && form {
    padding: 20px 10px;
  }

  .addButton {
    margin-top: 10px;
  }

  && .modal {
    width: 800px;
  }

  .questionType {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .imageQuestion {
    width: 220px;
    margin-bottom: 0;
    margin-top: 10px;
    margin-right: 35px;
  }

  .videoQuestion {
    width: 50%;
    margin-left: 25px;
  }

  .fileName {
    margin-top: 10px;
    text-align: center;
  }
`

export const PreviewWrapper = styled.div`
  && .modal {
    width: unset;
  }

  && .modal-content {
    padding-left: 0;
    padding-right: 0;
  }

  && main {
    margin-top: 0;
  }
`

export const QuestionWrapper = styled.div`
  && .MuiTableCell-root:not(:first-child) {
    padding: 10px 0;
    width: 100%;
  }

  && .MuiTableRow-root:not(:last-child) {
    border-bottom: 1px solid #cfcfd5;
  }

  .no-of-questions {
    margin-top: 20px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;

  && label {
    margin-bottom: 15px;
    width: 200px;
  }

  .buttons {
    display: flex;
    gap: 15px;
    margin-left: auto;
  }

  .previewBtn:disabled {
    pointer-events: none;
    background-color: #cfcfd5;
    color: white;
  }
`

export const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Answer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;

  label {
    width: 100%;
  }

  .removeAnswerBtn {
    margin-top: 10px;
  }
`
