import React, { useContext } from 'react'
import { Checkbox, Grid, Tooltip } from '@mui/material'
import certificateIcon from '../../../assets/img/Certificate.svg'
import { CompetenceProfileContext } from '../CompetenceProfile'
import { pdf } from '@react-pdf/renderer'
import { getPdfData } from '../../qualifications/functions'
import { saveAs } from 'file-saver'
import { Context } from '../../../utils/Context'
import { PdfDoc } from '../../pdfCertificate/components/PdfDoc'
import crossDark from '../../../assets/img/cross-dark-alt.svg'
import { useTranslation } from 'react-i18next'

const CompletedSurveys = () => {
  /** ---------- CONTEXTS ---------- */
  const { t } = useTranslation()
  const { completed, setShowRemoveSurveyModal, setSelectedSurveyID } =
    useContext(CompetenceProfileContext)
  const { individual } = useContext(Context)

  /** Get data for pdf, generate markup and download it */
  const handlePDF = async (qualificationId, completedDate) => {
    try {
      const pdfData = await getPdfData(qualificationId)
      const pdfDoc = (
        <PdfDoc
          pdfData={pdfData}
          individual={individual}
          completedDate={completedDate}
        />
      )

      const pdfBlob = await pdf(pdfDoc).toBlob()
      saveAs(pdfBlob, `${pdfData.QualificationName}_intyg.pdf`)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {/** ---------- Completed Surveys ---------- */}
      <Grid item xs={12} mt={6}>
        <Grid item xs={12}>
          <h4
            style={{
              color: '#bfbecf',
              paddingBottom: '5px',
            }}
          >
            {t('competenceProfile.completedMappings')}
          </h4>
        </Grid>
        {completed?.filter((survey) => survey.qualificationName).length > 0 && (
          <Grid container item xs={12}>
            <Grid item xs={12}></Grid>
            {/** Grid head */}
            <Grid
              container
              item
              sx={{ borderBottom: '1px solid #e0e0e0', padding: '10px' }}
              columns={30}
            >
              <Grid item xs={13}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.qualification')}</p>
              </Grid>
              <Grid item xs={8}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.completed')}</p>
              </Grid>
              <Grid item xs={8}>
                <p style={{ color: '#bfbecf' }}>{t('competenceProfile.shareWithEmployer')}</p>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            {/** Grid body */}
            {completed
              ?.filter((survey) => survey.qualificationName)
              .map(
                ({ qualificationName, id, qualificationId, completedDate }) => (
                  <Grid
                    key={id}
                    container
                    item
                    sx={{
                      borderBottom: '1px solid #e0e0e0',
                      padding: '10px',
                      alignItems: 'center',
                    }}
                    columns={30}
                  >
                    <Grid item xs={13}>
                      {qualificationName}
                    </Grid>
                    <Grid item xs={8}>
                      {completedDate}
                    </Grid>
                    <Grid item xs={8}>
                      <Checkbox sx={{ padding: 0, marginLeft: '68px' }} />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: 'flex',
                        gap: '15px',
                        textAlign: 'right',
                      }}
                    >
                      <Tooltip title='Ladda ner intyg' placement='top'>
                        <img
                          className='pointer'
                          onClick={() =>
                            handlePDF(qualificationId, completedDate)
                          }
                          src={certificateIcon}
                          alt='Certificate icon'
                        />
                      </Tooltip>
                      <Tooltip title='Ta bort kartläggning' placement='top'>
                        <img
                          className='pointer'
                          onClick={() => {
                            setSelectedSurveyID(id)
                            setShowRemoveSurveyModal(true)
                          }}
                          id={id}
                          src={crossDark}
                          alt='Remove survey'
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )
              )}
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default CompletedSurveys
