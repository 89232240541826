import { createContext, useContext, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { Switch } from 'react-router-dom/cjs/react-router-dom.min'
import PageTitleSpecial from '../../../../components/pageTitle/PageTitleSpecial'
import BreadCrumbs from '../../../../utils/BreadCrumbs'
import { EducationContext } from '../../Educations'
import CompetenceAreas from '../competenceAreas/CompetenceAreas'
import DescAndReqWrapper from '../descAndReqsStyle'
import SubQualification from '../subQualification/SubQualification'
import AddEdit from './components/AddEdit'
import ModulesTable from './components/ModulesTable'
import EducationModulesWrapper from './style'
import { useTranslation } from 'react-i18next'

export const EducationModulesContext = createContext()

const EducationModules = () => {
    const { t } = useTranslation()
    const [modules, setModules] = useState([])
    const [subQualifications, setSubQualifications] = useState([])
    const [modalModule, setModalModule] = useState()
    const [modalSubQualification, setModalSubQualification] = useState()
    const [selectedSubQualification, setSelectedSubQualification] = useState(JSON.parse(localStorage.getItem('educationsSelectedSubQualification')))
    const [subQualMode, setSubQualMode] = useState('descriptions')

    const { selectedEducation } = useContext(EducationContext)

    useEffect(() => {
        localStorage.setItem('educationsSelectedSubQualification', JSON.stringify(selectedSubQualification))
    }, [selectedSubQualification])

    const { path } = useRouteMatch()

    return (
        <EducationModulesContext.Provider
            value={{
                modules, setModules,
                subQualifications, setSubQualifications,
                selectedSubQualification, setSelectedSubQualification,
                setModalSubQualification
            }}
        >
            <Switch>
                <Route exact path={path}>
                    <EducationModulesWrapper className='education-modules'>
                        {modalModule && <AddEdit modalModule={modalModule} setModalModule={setModalModule} modalSubQualification={modalSubQualification} />}
                        <BreadCrumbs custom />
                        <PageTitleSpecial
                            title={selectedEducation.educationName}
                            description={
                                <>
                                    <p>{t('educations.module.info1')}</p>
                                    <br />
                                    <p>{t('educations.module.info2')}<strong>{t('educations.module.info3')}</strong>.</p>
                                    <p>{t('educations.module.info4')}<strong>{t('educations.module.info5')}</strong>.</p>
                                    <p>{t('educations.module.info6')}<strong>{t('educations.module.info7')}</strong>.</p>
                                    <p>{t('educations.module.info8')}<strong>{t('educations.module.info9')}</strong>.</p>
                                </>
                            }
                            info={selectedEducation.educationDescription}
                        />
                        <ModulesTable setModalModule={setModalModule} />
                    </EducationModulesWrapper>
                </Route>
                <Route path={`${path}/:subQualification`}>
                    <DescAndReqWrapper subQualMode={subQualMode}>
                        {selectedSubQualification &&
                            subQualMode === 'descriptions' ?
                            <SubQualification setSubQualMode={setSubQualMode} />
                            :
                            <CompetenceAreas setSubQualMode={setSubQualMode} />
                        }
                    </DescAndReqWrapper>
                </Route>
            </Switch>
        </EducationModulesContext.Provider>
    )
}

export default EducationModules
