import React, { useContext, useState } from 'react'

import { Button, Delete } from '../../../components/button/Button'
import Modal from '../../../components/modal/Modal'
import StyledLabel from '../../../components/styledLabel/StyledLabel'
import { updateField } from '../../../utils/helper'
import {
  addProfessionalRole,
  deleteProfessionalRole,
  editProfessionalRole,
} from '../services/Crud'
import { ProfessionalRoleContext } from './CompetencePlanning'
import { useTranslation } from 'react-i18next'

const ModalAddProfessionalRole = ({ option }) => {
  const { t } = useTranslation()
  const {
    organisationNumber,
    professionalRoleList,
    setProfessionalRoleList,
    professionalRole,
    setModalAddIsVisible,
    setModalEditIsVisible,
    setModalDeleteIsVisible,
  } = useContext(ProfessionalRoleContext)
  // FIXME Översättning
  const [addFormData, setAddFormData] = useState({
    professionalRoleName:
      option === 'add' ? '' : professionalRole.professionalRoleName,
    roleDescription: option === 'add' ? '' : professionalRole.roleDescription,
  })
  return (
    <Modal
      setState={
        option === 'add'
          ? setModalAddIsVisible
          : option === 'edit'
          ? setModalEditIsVisible
          : setModalDeleteIsVisible
      }
      title={
        (option === 'add' && t('competencePlanning.modal.titleAdd')) ||
        (option === 'edit' && t('competencePlanning.modal.titleEdit')) ||
        (option === 'delete' && t('competencePlanning.modal.titleDelete'))
      }
    >
      {(option === 'add' || option === 'edit') && (
        <form
          style={{ paddingTop: '10px' }}
          onSubmit={(e) =>
            option === 'add'
              ? addProfessionalRole(e, {
                  addFormData,
                  organisationNumber,
                  setModalAddIsVisible,
                  setProfessionalRoleList,
                })
              : editProfessionalRole(e, {
                  addFormData,
                  setAddFormData,
                  setModalEditIsVisible,
                  professionalRole,
                  professionalRoleList,
                  setProfessionalRoleList,
                })
          }
        >
          <StyledLabel>
            <p>{t('competencePlanning.modal.labelJobFunction')}</p>
            <input
              type='text'
              name='professionalRoleName'
              value={addFormData.professionalRoleName}
              onChange={(e) => updateField(e, { addFormData, setAddFormData })}
            />
          </StyledLabel>
          <br />
          <br />
          <StyledLabel>
            <p>{t('competencePlanning.modal.description')}</p>
            <textarea
              style={{ resize: 'none', padding: '10px' }}
              type='text'
              rows='4'
              name='roleDescription'
              value={addFormData.roleDescription}
              onChange={(e) => updateField(e, { addFormData, setAddFormData })}
            />
          </StyledLabel>
          {option === 'add' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '30px',
                paddingBottom: '20px',
              }}
            >
              <Button type='Add'>{t('competencePlanning.modal.addButton')}</Button>
            </div>
          )}
          {option === 'edit' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '36px',
                paddingBottom: '20px',
              }}
            >
              <Delete
                onClick={(e) => {
                  e.preventDefault()
                  setModalEditIsVisible(false)
                  setModalDeleteIsVisible(true)
                }}
              >
                {t('competencePlanning.modal.deleteButton')}
              </Delete>
              <Button type='submit' style={{ marginLeft: '10px' }}>
              {t('competencePlanning.modal.saveButton')}
              </Button>
            </div>
          )}
        </form>
      )}
      {option === 'delete' && (
        <div>
          <br />
          <p>{t('competencePlanning.modal.promptSureDelete')}</p>
          <br />
          <p className='bold'>{professionalRole.professionalRoleName}?</p>
          <br />
          <p>{t('competencePlanning.modal.promptUnreversible')}</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '36px',
              paddingBottom: '25px',
            }}
          >
            <Delete
              onClick={(e) => {
                e.preventDefault()

                deleteProfessionalRole(e, {
                  setModalDeleteIsVisible,
                  setProfessionalRoleList,
                  professionalRole,
                })
              }}
            >
              {t('competencePlanning.modal.deleteButton')}
            </Delete>
            <Button
              type='submit'
              style={{ marginLeft: '10px' }}
              onClick={() => setModalDeleteIsVisible(false)}
            >
              {t('competencePlanning.modal.cancelButton')}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default ModalAddProfessionalRole
