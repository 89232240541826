import styled from 'styled-components';

export const DeleteEmployerForm = styled.form`
    width: 500px;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    gap: 25px;

    p {
        max-width: 100%;
    }

    .buttons {
        display: flex;
        justify-content: center;
        gap: 50px;
    }
`