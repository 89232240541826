import { createContext, useContext, useState } from 'react'
import PageTitleSpecial from '../../../../components/pageTitle/PageTitleSpecial'
import EducationModulesWrapper from '../../../../private/educations/subPages/educationModules/style'
import BreadCrumbs from '../../../../utils/BreadCrumbs'
import { CatalogContext } from '../../Catalog'
import CatalogEducationModules from './components/CatalogEducationModules'
import CatalogModuleModal from './components/CatalogModuleModal'

export const CatalogEducationContext = createContext()

const CatalogEducation = () => {
    const [modules, setModules] = useState([])
    const [subQualifications, setSubQualifications] = useState([])
    const [modalModule, setModalModule] = useState()

    const { selectedResult: selectedEducation } = useContext(CatalogContext)

    return (
        <CatalogEducationContext.Provider
            value={{
                modules, setModules,
                subQualifications, setSubQualifications,
                selectedEducation
            }}
        >
            <EducationModulesWrapper className='education-modules'>
                {modalModule &&
                    <CatalogModuleModal
                        modalModule={modalModule}
                        setModalModule={setModalModule}
                    />
                }
                <BreadCrumbs custom />
                <PageTitleSpecial
                    title={
                        <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                            <span>{selectedEducation.educationName}</span>
                            <span style={{ fontSize: '16px', color: '#929292' }}>Ver {selectedEducation.version} | {selectedEducation.educationCountries[0].localDate}</span>
                        </span>
                    }
                    info={selectedEducation.educationDescription}
                />
                <CatalogEducationModules setModalModule={setModalModule} />
            </EducationModulesWrapper>
        </CatalogEducationContext.Provider>
    )
}

export default CatalogEducation
