import { t } from "i18next";
import React, { useState } from "react";
import {
  AddButton,
  Button,
  Delete,
  Secondary,
} from "../../components/button/Button";
import Modal from "../../components/modal/Modal";
import StyledLabel from "../../components/styledLabel/StyledLabel";
import styled from "styled-components";
import { useContext } from "react";
import { Context } from "../../utils/Context";
import { simpleUser } from "../../utils/Amplify";
import _ from "lodash";

import { ReactComponent as Cross } from "../../assets/img/cross-dark.svg";
import { EmployeesContext } from "./Employees";
import { useEffect } from "react";
import { AddRoleWrapper } from "./style";

const DeleteWrapper = styled.form`
  #delete-modal {
    .modal {
      width: 500px;

      .modal-content {
        display: grid;
        grid-template-columns: 3fr;

        .message {
          margin-bottom: 40px;

          p {
            margin-bottom: 10px;
            line-height: 140%;
            color: #858585;
            font-size: 14px;
          }
        }
      }
      .btns {
        display: flex;
        justify-content: center;
        gap: 30px;
      }
    }
  }
`;

export const DeleteModal = ({
  handleDelete,
  setShowDeleteEmployeeModal,
  employeeNumber,
  firstName,
  lastName,
}) => {
  return (
    <DeleteWrapper>
      <div id="delete-modal">
        <Modal
          title={t("pageEmployees.editEmployee.removeEmployee")}
          setState={setShowDeleteEmployeeModal}
        >
          <div className="modal-btn">
            <StyledLabel>
              <div className="message">
                <p>{t("pageEmployees.editEmployee.deleteConfirmationMsg")}</p>
                <p>{`${employeeNumber} ${firstName} ${lastName}`}</p>
              </div>
              <br />
            </StyledLabel>
            <div className="btns">
              <Delete
                className={"first_btn-margin"}
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                }}
              >
                {t("buttons.deleteButton")}
              </Delete>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setShowDeleteEmployeeModal(false);
                }}
              >
                {t("extra.cancel")}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </DeleteWrapper>
  );
};

const EditListWrapper = styled.div`
  .modal-wrapper {
    .modal {
      width: 500px;

      .modal-content {
        form {
          display: flex;
          flex-direction: column;

          .inputs {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            height: 300px;
            overflow: auto;

            label {
              width: 400px;
              position: relative;

              .del-item {
                position: absolute;
                top: 10px;
                right: 15px;
                cursor: pointer;
              }
            }

            .add-btn {
              align-self: flex-start;
              margin-left: 21px;
            }
          }
        }
      }
    }
  }
`;

export const EditListModal = ({
  setShowEditList,
  orgLevel,
  orgLevelGroups,
  localOrganisation,
  setLocalOrganisation,
}) => {
  const { levels } = useContext(EmployeesContext);

  const groupsObject = orgLevelGroups.reduce(
    (obj, item) => Object.assign(obj, { [item.id]: item.groupName }),
    {}
  );

  const [newItems, setNewItems] = useState(
    Object.keys(groupsObject).length > 0 ? groupsObject : { new0: "" }
  );
  const [newestItem, setNewestItem] = useState();

  const { organisation, setOrganisation } = useContext(Context);

  const levelNumber = Object.keys(levels)
    .filter((key) => localOrganisation[key] !== null)
    .filter((key) => localOrganisation[key].label === orgLevel)[0];

  let shortLevelNumber;
  switch (levelNumber) {
    case "levelOne":
      shortLevelNumber = "One";
      break;

    case "levelTwo":
      shortLevelNumber = "Two";
      break;

    case "levelThree":
      shortLevelNumber = "Three";
      break;

    case "levelFour":
      shortLevelNumber = "Four";
      break;

    default:
      console.log("could not find a levelNumber");
      break;
  }

  const submitHandler = async (e) => {
    e.preventDefault();

    const ids = Object.keys(newItems);

    const token = await simpleUser().token;

    ids.forEach((id) => {
      if (id.startsWith("del")) {
        if (id.slice(3).startsWith("new")) {
          return;
        }

        fetch(
          `${process.env.REACT_APP_BACKEND_URL}/organisation/${id.slice(
            3
          )}/delete/level`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
      } else if (newItems[id]) {
        if (id.startsWith("new")) {
          fetch(
            `${process.env.REACT_APP_BACKEND_URL}/organisation/${localOrganisation.id}/add/level`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              body: JSON.stringify({
                levelName: newItems[id],
                typeOfLevel: shortLevelNumber,
              }),
            }
          );
        } else {
          fetch(
            `${process.env.REACT_APP_BACKEND_URL}/organisation/${id}/update/level`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              body: JSON.stringify({
                levelName: newItems[id],
              }),
            }
          );
        }
      }
    });

    const newOrgLevelGroups = ids
      .filter((id) => !id.startsWith("del"))
      .map((id) => ({ id, groupName: newItems[id] }));
    setLocalOrganisation({
      ...localOrganisation,
      [levelNumber]: {
        ...localOrganisation[levelNumber],
        groups: newOrgLevelGroups.filter((group) => group.groupName),
      },
    });
    if (organisation.id === localOrganisation.id) {
      setOrganisation({
        ...organisation,
        [levelNumber]: {
          ...organisation[levelNumber],
          groups: newOrgLevelGroups.filter((group) => group.groupName),
        },
      });
    }

    setShowEditList(false);
  };

  const deletePrimer = (id) => {
    const temp = { ...newItems };
    _.unset(temp, id);
    _.set(temp, "del" + id, newItems[id]);

    setNewItems(temp);
  };

  const handleEnter = (e) => {
    e.preventDefault();

    setNewItems({
      ...newItems,
      ["new" + Object.keys(newItems).length]: "",
    });

    setNewestItem("new" + Object.keys(newItems).length);
  };

  useEffect(() => {
    if (newestItem) {
      const newField = document.querySelector(`#group-${newestItem}`);

      newField.focus();
    }
  }, [newestItem]);

  return (
    <EditListWrapper>
      <Modal title={"Redigera " + orgLevel} setState={setShowEditList}>
        <form id="edit-list-form" onSubmit={(e) => submitHandler(e)}>
          <div className="inputs">
            {newItems &&
              Object.keys(newItems).map((id, i) => {
                const title = newItems[id];
                return (
                  <StyledLabel
                    key={id}
                    className={id.startsWith("del") ? "hidden" : ""}
                  >
                    <input
                      id={`group-${id}`}
                      value={title}
                      onInput={(e) => {
                        setNewItems({ ...newItems, [id]: e.target.value });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleEnter(e);
                        }
                      }}
                    />
                    <Cross
                      className="del-item"
                      onClick={() => deletePrimer(id)}
                    />
                  </StyledLabel>
                );
              })}
            <AddButton
              className="add-btn"
              onClick={(e) => {
                e.preventDefault();
                setNewItems({
                  ...newItems,
                  ["new" + Object.keys(newItems).length]: "",
                });
                setNewestItem("new" + Object.keys(newItems).length);
              }}
            />
          </div>
          <div className="buttons">
            <Secondary
              onClick={(e) => {
                e.preventDefault();
                setShowEditList(false);
              }}
            >
              {t("extra.cancel")}
            </Secondary>
            <Button type="submit">{t("buttons.saveButton")}</Button>
          </div>
        </form>
      </Modal>
    </EditListWrapper>
  );
};

export const AddRole = ({ setShowAddRole }) => {
  const { baseRoles, setBaseRoles } = useContext(EmployeesContext);

  const {
    organisation: { organisationNumber },
  } = useContext(Context);

  const [addFormData, setAddFormData] = useState({
    professionalRoleName: "",
    roleDescription: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/${organisationNumber}/professionalrole/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(addFormData),
        }
      );
      const role = await res.json();
      setBaseRoles([...baseRoles, role]);
      setShowAddRole(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AddRoleWrapper>
      <Modal
        title={"Lägg till en yrkesroll eller funktion"}
        setState={setShowAddRole}
      >
        <form
          style={{ paddingTop: "10px" }}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <StyledLabel>
            <p>Yrkesroll/funktion</p>
            <input
              type="text"
              name="professionalRoleName"
              value={addFormData.professionalRoleName}
              onChange={(e) =>
                setAddFormData({
                  ...addFormData,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </StyledLabel>
          <StyledLabel>
            <p>Beskrivning</p>
            <textarea
              style={{ resize: "none", padding: "10px" }}
              type="text"
              rows="4"
              name="roleDescription"
              value={addFormData.roleDescription}
              onChange={(e) =>
                setAddFormData({
                  ...addFormData,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </StyledLabel>
          <Button type="Add">LÄGG TILL</Button>
        </form>
      </Modal>
    </AddRoleWrapper>
  );
};
