import { ReactComponent as ErrorIcon } from '../../assets/img/error.svg'
import styled from 'styled-components';

const ErrorMsgBase = ({ className, children }) => {
    return (
        <div className={className}>
            <ErrorIcon />
            {children}
        </div>
    )
}

const ErrorMsg = styled(ErrorMsgBase)`
    position: relative;
    top: 10px;
    left: 5px;
    height: 0;
    display: flex;
    align-items: center;
    gap: 4px;

    p {
        font-size: 12px;
        color: #DE0500;
    }
`

export default ErrorMsg