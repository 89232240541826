import { useEffect } from "react";

function useOutsideClick(ref, setState) {
    useEffect(() => {
        /**
         * Close modal if user clicks outside
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setState(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setState]);
}

export default useOutsideClick