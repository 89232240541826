import { Auth } from 'aws-amplify'
import React, { useContext, useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { signUp as signUpFunc } from '../../utils/Amplify'

import { Button } from '../../components/button/Button'
import StyledLabel from '../../components/styledLabel/StyledLabel'
import { ReactComponent as LogoDark } from '../../assets/img/logo-dark.svg'
import { Context } from '../../utils/Context'
import { Wrapper } from './style'
import Modal from '../../components/modal/Modal'
import { Tooltip, ClickAwayListener } from '@mui/material'
import Countries from '../../components/styledFlagsSelect/Countries'
import Languages from '../../components/styledFlagsSelect/Languages'

const SignUp = () => {
  const [errorMsg, setErrorMsg] = useState({
    name: '',
    surName: '',
    email: '',
    emailMatch: '',
    password: '',
    passwordMatch: '',
    emailExists: '',
  })
  const [fName, setFName] = useState('')
  const [lName, setLName] = useState('')
  const [email, setEmail] = useState('')
  const [reEmail, setReEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')
  const [verifyModuleIsHidden, setVerifyModuleIsHidden] = useState(true)
  const [verifyCode, setVerifyCode] = useState('')
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [token, setToken] = useState(null)

  const settingToken = async () => {
    let localToken
    try {
      const res = await Auth.currentSession()
      localToken = res.getAccessToken().getJwtToken()
    } catch (error) {
      // console.log(error)
    }
    setToken(localToken)
  }

  const { setLoggedIn } = useContext(Context)

  const ErrorMsg = ({ msg }) => {
    const styling = {
      color: 'red',
      position: 'absolute',
      marginLeft: '5px',
      fontSize: '.8rem',
      display: 'inline-block',
    }
    return <span style={styling}>{msg}</span>
  }
  // Confirms the user's account via email verification and logs the user in
  const VerifyEmail = (userName, code) => {
    try {
      Auth.confirmSignUp(userName, code).then(() => {
        Auth.signIn(userName, password)
          .then(() => {
            setLoggedIn(true)
            settingToken()
          })
          .catch((err) => console.log(err))
      })
    } catch (error) {
      console.log('error confirming sign up', error)
    }
  }

  // Checks if user is logged in when component renders
  useEffect(() => {
    settingToken()
  }, [])

  const handleChange = (e, name) => {
    switch (name) {
      case 'first-name':
        setFName(e.target.value)
        break
      case 'last-name':
        setLName(e.target.value)
        break
      case 'e-mail':
        setEmail(e.target.value)
        break
      case 're-e-mail':
        setReEmail(e.target.value)
        break
      case 'password':
        setPassword(e.target.value)
        break
      case 're-password':
        setRePassword(e.target.value)
        break

      default:
        break
    }
  }
  const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }
  const passwordIsValid = (password) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/.test(
      password
    )
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrorMsg({
      name: '',
      surName: '',
      email: '',
      emailMatch: '',
      password: '',
      passwordMatch: '',
    })
    // Validate user inputs
    fName.length === 0
      ? setErrorMsg((err) => ({ ...err, name: true }))
      : setErrorMsg((err) => ({ ...err, name: false }))
    lName.length === 0
      ? setErrorMsg((err) => ({ ...err, surName: true }))
      : setErrorMsg((err) => ({ ...err, surName: '' }))

    !emailIsValid(email)
      ? setErrorMsg((err) => ({ ...err, email: true }))
      : reEmail !== email &&
        setErrorMsg((err) => ({ ...err, emailMatch: true }))
    !passwordIsValid(password)
      ? setErrorMsg((err) => ({ ...err, password: true }))
      : rePassword !== password &&
        setErrorMsg((err) => ({
          ...err,
          passwordMatch: true,
        }))

    const user = await signUpFunc(
      fName,
      lName,
      email,
      reEmail,
      password,
      rePassword
    )
    if (user instanceof Error) {
      user.toString().startsWith('UsernameExistsException') &&
        setErrorMsg((err) => ({ ...err, emailExists: true }))
    } else if (user) {
      setVerifyModuleIsHidden(false)
    } else {
      console.log('Unknown error signing up: ', user)
    }
  }

  const { t } = useTranslation()

  return (
    <Wrapper>
      {!verifyModuleIsHidden && (
        <Modal
          title={t('pageSignUp.verifyTitle')}
          setState={setVerifyModuleIsHidden}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault()
              VerifyEmail(email, verifyCode)
            }}
          >
            <StyledLabel>
              <p>
                {t('pageSignUp.verifyEmailTop')}
                <br />
                {t('pageSignUp.verifyEmailBottom')}
              </p>
              <input
                type='text'
                value={verifyCode}
                onChange={(e) => setVerifyCode(e.target.value)}
              />
              <Button>{t('pageSignUp.verifyEmailBtn')}</Button>
            </StyledLabel>
          </form>
        </Modal>
      )}
      {token && <Redirect to='/home' />}
      <section className='left-side'>
        <div className='left-side-content'>
          <LogoDark className='logo' />
          <nav>
            <p>
              <Link to='/'>{t('header.home')}</Link> / {t('header.signUp')}
            </p>
          </nav>
        </div>
      </section>
      <div className='right-side'>
        <form className='flex-column' onSubmit={(e) => handleSubmit(e)}>
          <h3>{t('pageSignUp.pageTitle')}</h3>
          <div className='country-language-select'>
            <Countries />
            <Languages />
          </div>
          <div>
            <div className='f-l-name-container'>
              <StyledLabel>
                <p>
                  {t('pageSignUp.firstNameLabel')}{' '}
                  {errorMsg.name && (
                    <ErrorMsg msg={t('pageSignUp.firstNameError')} />
                  )}
                </p>
                <input
                  type='text'
                  value={fName}
                  name='first-name'
                  id='firstName'
                  onChange={(e) => handleChange(e, e.target.name)}
                />
              </StyledLabel>

              <StyledLabel>
                <p>
                  {t('pageSignUp.lastNameLabel')}{' '}
                  {errorMsg.surName && (
                    <ErrorMsg msg={t('pageSignUp.lastNameError')} />
                  )}
                </p>
                <input
                  type='text'
                  value={lName}
                  name='last-name'
                  id='lastName'
                  onChange={(e) => handleChange(e, e.target.name)}
                />
              </StyledLabel>
            </div>
          </div>
          <div className='credentials'>
            <StyledLabel>
              <p>
                {t('pageSignUp.mailLabel')}{' '}
                {errorMsg.email && (
                  <ErrorMsg msg={t('pageSignUp.mailInvalidError')} />
                )}
                {errorMsg.emailExists && (
                  <ErrorMsg msg={t('pageSignUp.mailExistsError')} />
                )}
              </p>
              <input
                type='email'
                value={email}
                name='e-mail'
                id='eMail'
                onChange={(e) => handleChange(e, e.target.name)}
              />
            </StyledLabel>
            <StyledLabel>
              <p>
                {t('pageSignUp.confirmMailLabel')}{' '}
                {errorMsg.emailMatch && (
                  <ErrorMsg msg={t('pageSignUp.mailMismatchError')} />
                )}
              </p>
              <input
                type='email'
                value={reEmail}
                name='re-e-mail'
                id='re-Email'
                onChange={(e) => handleChange(e, e.target.name)}
              />
            </StyledLabel>
          </div>
          <StyledLabel>
            <p>
              {t('pageSignUp.passwordLabel')}
              {errorMsg.password && (
                <ErrorMsg msg={t('pageSignUp.passwordInvalidError')} />
              )}
            </p>
            <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
              <Tooltip
                title={t('pageSignUp.passwordReqs')}
                arrow
                placement='top'
                disableHoverListener
                open={tooltipOpen}
              >
                <input
                  type='password'
                  value={password}
                  name='password'
                  id='passwrod'
                  onChange={(e) => handleChange(e, e.target.name)}
                  onClick={() => setTooltipOpen(true)}
                />
              </Tooltip>
            </ClickAwayListener>
          </StyledLabel>
          <StyledLabel>
            <p>
              {t('pageSignUp.confirmPasswordLabel')}{' '}
              {errorMsg.passwordMatch && (
                <ErrorMsg msg={t('pageSignUp.passwordInvalidError')} />
              )}
            </p>
            <input
              className='btn'
              type='password'
              value={rePassword}
              name='re-password'
              id='re-password'
              onChange={(e) => handleChange(e, e.target.name)}
            />
          </StyledLabel>

          <Button>{t('pageSignUp.createAccountBtn')}</Button>
        </form>
      </div>
    </Wrapper>
  )
}

export default SignUp
