import styled from 'styled-components'

export const QualificationWrapper = styled.main`
  &.sub-page-wrapper {
    width: 1160px;
    margin-bottom: 50px;
  }

  .qualification-title {
    width: 100%;
  }

  .qualification {
    .version-date {
    }
  }

  .sub-qualifications {
    .more {
      img {
        margin-left: 10px;
        transform: rotateY(180deg);
        vertical-align: middle;
      }
    }
    .table-head {
      h4 {
        color: #6f6e80;
        margin: 0;
        padding: 0;
        padding-bottom: 10px;
      }
    }

    .table-head::after {
      content: '';
      position: relative;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid #cfcfd5;
      margin-left: 0px;
    }

    .table-body {
      .title {
        margin-bottom: 10px;
      }

      h4 {
        margin: 0;
        padding: 0;
        padding-bottom: 10px;
      }
      .areas {
      }
      .area-title {
      }
      .area-body {
      }
      padding-bottom: 10px;
    }

    .table-body::after {
      content: '';
      position: relative;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid #cfcfd5;
      margin-left: 0px;
      padding: 4px 0px;
    }
  }
`
export const SubQualificationWrapper = styled.main`
  .table-header {
    background-color: #ececee;
    border: 1px solid #ddd;
    font-weight: bold;
    padding: 10px 0 10px 5px;
  }

  &.sub-page-wrapper {
    width: 1160px;
    margin-bottom: 50px;
  }

  .survey-btn {
    margin: 20px 10px;
  }

  .qualification-title {
    width: 100%;
  }

  .qualification {
    .version-date {
    }
  }

  .sub-qualifications {
    .more {
      img {
        margin-left: 10px;
        transform: rotateY(180deg);
        vertical-align: middle;
      }
    }
    .table-head {
      h4 {
        color: #6f6e80;
        margin: 0;
        padding: 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #cfcfd5;
      }
    }
    .table-body {
      h4 {
        color: #6f6e80;
        margin: 0;
        padding: 0;
        padding-bottom: 10px;
      }
      .areas {
      }
      .area-title {
      }
      .area-body {
      }
      border-bottom: 1px solid #cfcfd5;
      .qualification-title {
        width: 100%;
      }

      .qualification {
        .version-date {
        }
      }

      .sub-qualifications {
        .more {
          img {
            margin-left: 10px;
            transform: rotateY(180deg);
            vertical-align: middle;
          }
        }
        .table-head {
          h4 {
            color: #6f6e80;
            margin: 0;
            padding: 0;
            padding-bottom: 10px;
          }
        }
        .table-body {
          h4 {
            color: #6f6e80;
            margin: 0;
            padding: 0;
            padding-bottom: 10px;
          }
          .areas {
          }
          .area-title {
          }
          .area-body {
          }
          border-bottom: 1px solid #cfcfd5;
          padding-bottom: 10px;
        }
      }
      padding-bottom: 10px;
    }
  }

  .mode-switch {
    h4 {
      display: flex;
      gap: 15px;

      span {
        &.desc {
          cursor: pointer;
          color: ${(props) =>
            props.subQualMode === 'descriptions' ? '#3f3d56' : '#858585'};
        }

        &.reqs {
          cursor: pointer;
          color: ${(props) =>
            props.subQualMode === 'descriptions' ? '#858585' : '#3f3d56'};
        }
      }
    }
  }

  .table-header {
    .qualification-category {
      font-weight: bold;
      padding: 5px 0;
      margin-left: 5px;
    }
  }

  .table-body {
    .area-name {
      color: #000;
    }

    .area-name,
    .area-name-link {
      letter-spacing: 0.4px;
      padding: 10px 5px;
    }

    .requirements {
      padding-bottom: 10px;
    }

    .requirements p:last-child {
      border: 0px;
    }
    .requirement-paragraphs {
      padding: 10px 5px;
      border-bottom: 1px solid #cfcfd5;
      min-height: 60px;
    }
  }
  .back-btn {
    margin-top: 30px;
  }
`

export const ModalWrapper = styled.main`
  .modal {
    .modal-content {
      padding-left: 0;
      padding-right: 0;
    }
  }
  main {
    margin-top: 0;
  }
`

export const SurveyWrapper = styled.main`
  &.sub-page-wrapper {
    width: 1160px;
    margin-bottom: 50px;
  }

  .survey-container {
    background-color: #ececee;
    min-height: 700px;
  }

  button {
    :disabled {
      pointer-events: none;
      background-color: #cfcfd5;
    }
  }

  .table-header {
    background-color: #ececee;
    font-weight: bold;
  }

  .sub-name {
    font-weight: bold;
    font-size: 16px;
    color: gray;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    padding-bottom: 20px;
    gap: 20px;
  }

  .exit-send-container {
    display: flex;
    gap: 15px;
    margin-left: auto;
  }

  .btn-spacing {
    display: flex;
    flex: 1;
    margin-top: 30px;
  }

  .start-info {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .result-info {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .survey-question-btn {
    height: 30px;
    width: 80px;
  }

  .time-left {
    color: red;
  }

  .question-name {
    font-weight: bold;
    text-align: center;
    margin-top: 60px;
  }

  .question-image {
    width: 560px;
    height: 315px;
    object-fit: cover;
  }

  .answer-image {
    width: 280px;
    height: 157.5px;
    object-fit: cover;
  }
`

export const tableBody = {
  p: 0.5,
  border: 1,
  borderColor: '#ddd',
}
