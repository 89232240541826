import styled from 'styled-components'

export const SubTable = styled.div`
  .MuiTableCell-body {
    margin: 50px 0;
  }
`
/* Add Edit SubQualification */
export const Wrapper = styled.div`
  textarea {
    padding: 5px 13px;
  }
  .textarea {
    margin-top: 2px;
    height: 127px;
    resize: none;
  }
  .questions {
    display: flex;
    gap: 30px;
    padding-top: 30px;
    width: 300px;
    label {
      input {
        margin: 0 auto;
        width: 100px;
        text-align: center;
      }
    }
  }
`
