import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LogoLight } from '../../assets/img/logo-light.svg'

import { Wrapper } from './style.js'

const Footer = () => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation()
  return (
    <Wrapper>
      <div>
        <LogoLight className='logo' />
        <p>
          {t('footer.contactUs')}{' '}
          <a href='https://headonnails.com/contact/'>
            {t('footer.linkTextHere')}
          </a>
        </p>
      </div>
    </Wrapper>
  )
}

export default Footer
