/** Functions and data used in multiple requirement question components */

import { t } from 'i18next'
import { useTranslation } from 'react-i18next'


export const useQuestionTypeOptions = () => {
  const { t } = useTranslation();

  return [
    {
      value: 'text',
      label: t('pageRequirementQuestion.textOnlyType'),
    },
    {
      value: 'imageQuestion',
      label: t('pageRequirementQuestion.imageQuestionType'),
    },
    {
      value: 'videoQuestion',
      label: t('pageRequirementQuestion.videoQuestionType'),
    },
    {
      value: 'imageAnswer',
      label: t('pageRequirementQuestion.imageAnswerType'),
    },
    {
      value: 'videoAnswer',
      label: t('pageRequirementQuestion.videoAnswerType'),
    },
   
  ];
};


/** Is preview for the question disabled? */
export const isPreviewDisabled = (
  questionType,
  formData,
  questionImageFile
) => {
  let isDisabled

  switch (questionType) {
    case 'text':
      isDisabled = formData.questionAnswer.some(
        (answer) => answer.answer === ''
      )
      break
    case 'imageQuestion':
      isDisabled =
        (formData.questionImageKey === null && questionImageFile === null) ||
        formData.questionAnswer.some((answer) => answer.answer === '')
      break
    case 'videoQuestion':
      isDisabled =
        formData.questionVideoUrl === '' ||
        formData.questionAnswer.some((answer) => answer.answer === '')
      break
    case 'imageAnswer':
      isDisabled = formData.questionAnswer.some(
        (answer, index) =>
          answer?.questionImageKey === null &&
          answer?.questionImageFile === null
      )
      break
    case 'videoAnswer':
      isDisabled = formData.questionAnswer.some(
        (answer) => answer.answerVideoUrl === ''
      )
      break
    default:
      isDisabled = true
  }
  return !formData.questionName || isDisabled
}

/** Labels for the question types */
export const getOptionLabels = (option) => {
  switch (option) {
    case 'text':
      return t('pageRequirementQuestion.textOnlyType')
    case 'imageQuestion':
      return t('pageRequirementQuestion.imageQuestionType')
    case 'videoQuestion':
      return t('pageRequirementQuestion.videoQuestionType')
    case 'imageAnswer':
      return t('pageRequirementQuestion.imageAnswerType')
    case 'videoAnswer':
      return t('pageRequirementQuestion.videoAnswerType')
    default:
      return ''
  }
}

/** Helper for getting question type */
export const getQuestionType = (question) => {
  if (question.questionImageKey) {
    return 'imageQuestion'
  } else if (question.questionVideoUrl) {
    return 'videoQuestion'
  } else if (question.questionAnswer[0].questionImageKey) {
    return 'imageAnswer'
  } else if (question.questionAnswer[0].questionVideoUrl) {
    return 'videoAnswer'
  } else {
    return 'text'
  }
}

/** Helper for handling question type change */
export const handleQuestionTypeChange = (
  type,
  questionType,
  formData,
  initializedFormData,
  setFormData,
  setQuestionType,
  answerImageRefs
) => {
  setQuestionType(type)
  if (questionType !== type) {
    setFormData({
      ...initializedFormData,
      questionName: formData.questionName,
      questionImageKey:
        type === 'imageQuestion' ? formData.questionImageKey : '',
      questionVideoUrl:
        type === 'videoQuestion' ? formData.questionVideoUrl : '',
      questionAnswer:
        questionType === 'text' ||
        questionType === 'imageQuestion' ||
        questionType === 'videoQuestion'
          ? formData.questionAnswer
          : initializedFormData.questionAnswer,
    })
    answerImageRefs.current = []
  }
}

export const handleSelectQuestionImg = (questionImageRef) => {
  questionImageRef.current.click()
}

export const handleSelectAnswerImg = (i, answerImageRefs) => {
  if (answerImageRefs.current[i]) {
    answerImageRefs.current[i].click()
  }
}
